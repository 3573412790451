import { buildDb, InvalidPassphrase, MaybeEncrypted, destroyDb } from "./Db";

const SETTINGS_DB_NAME = 'elm_expenses_settings';
const DATA_DB_NAME = 'elm_expenses_local';

class InitResponse {}
class InitResponseFirstRun extends InitResponse {}
class InitResponseAccounts extends InitResponse {
    constructor(accounts) {
        super();
        this.accounts = accounts;
    }
}
class InitResponseError extends InitResponse {
    constructor(message) {
        super();
        this.message = message;
    }
}

const SETTINGS_ID = 'settings';

function mapDocFromElm(doc) {
    doc._id = doc.id;
    doc._rev = doc.version;
    delete doc.id;
    delete doc.version;
    return doc;
}
  
function mapDocToElm(doc) {
    doc.id = doc._id;
    doc.version = doc._rev;
    delete doc._id;
    delete doc._rev;
    return doc;
}

function setRandomTxnId(txn) {
    if (txn._id != "") {
      return;
    }
    txn.timestamp = Date.now();
    txn._id = `${txn.date}.${txn.timestamp}-${window.crypto.randomUUID()}`;
    delete txn._rev;
}

const emptyCursor = () => ({ nextId: null });
function parsePageToken(pageToken) {
    if (null == pageToken) {
        return emptyCursor();
    }
    try {
        const json = atob(pageToken);
        return JSON.parse(json);
    } catch (e) {
        console.error("Error parsing token", e);
        return emptyCursor();
    }
}

function createPageToken(cursor) {
    return btoa(JSON.stringify(cursor));
}

function changedDate(txn) {
    if (txn._id == "") {
        return false;
    }
    return !txn._id.startsWith(txn.date);
}


var accountsDesignDoc = {
    _id: '_design/accounts',
    views: {
        accounts: {
            map: function mapFun(doc) {
                if (!doc.accounts) {
                    return;
                }
                const seen = {};
                doc.accounts.forEach(function(account) {
                    if (seen[account]) {
                        return;
                    }
                    seen[account] = true;
                    emit(account, 1);
                });
            }.toString(),
            reduce: '_count'
        },
        account_stats: {
            map: function mapFun(doc) {

                function emitAccounts(e) {
                    let parts = e.account.split(":");
                    for (let i = 0; i < parts.length; i++) {
                        const account = parts.slice(0, i + 1).join(":");
                        emit([account, e.currency], e.amount);
                    }
                }

                if (doc.destination) {
                    emitAccounts(doc.destination);
                }
                if (doc.source) {
                    emitAccounts(doc.source);
                }
                if (doc.entries) {
                    doc.entries.forEach(emitAccounts);
                }
            }.toString(),
            reduce: '_sum'
        }
    }
  }

class DbPort {

    constructor(username) {
        const prefix = username ? (btoa(username) + "-") : "";
        this.settingsDbName = prefix + SETTINGS_DB_NAME;
        this.dataDbName = prefix + DATA_DB_NAME;
    }

    async openDbs(password) {
        this.settingsDb = await buildDb(this.settingsDbName, password);
        this.dataDb = await buildDb(this.dataDbName, password);
        await this.dataDb.createIndex({
            index: {
                fields: ["accounts"]
            }
        });
        await this.dataDb.putDesignDoc(accountsDesignDoc);
        return Promise.resolve();
    }

    startSearchDbReplication() {
        return this.dataDb.startSearchDbReplication();
    }

    startSearchDbIndexing (onIndexed) {
        return this.dataDb.startSearchDbIndexing(onIndexed)
        .then(resp => {
            this.getAllAccounts();
            this.getAccountStats();
            return resp;
        });
    }

    getSettings() {
        return this.settingsDb.get('settings').then(doc => mapDocToElm(doc));
    }

    async createSettings(elmSettings, password) {
        await this.openDbs(password);
        return this.saveSettings(elmSettings);
    }

    async saveSettings(elmSettings) {

        const settings = {...elmSettings};
        settings.id = "settings";
        mapDocFromElm(settings);

        const resp = await this.settingsDb.put(settings);
        settings._rev = resp.rev;
        return mapDocToElm(settings);
    }

    getTransaction(id) {
        return this.dataDb.get(id).then(mapDocToElm);
    }

    async getTransactions(request) {
        if (request.account) {
            return this._getAccountTransactions(request);
        } else {
            return this._getAllTransactions(request);
        }
    }

    async _getAllTransactions(request) {
        const { doc_count } = await this.dataDb.info();
        const opts = {
            include_docs: true,
            descending: (request.descending === undefined ? true : request.descending) ,
            limit: request.maxPageSize + 1
        };
        const cursor = parsePageToken(request.pageToken);
        if (cursor.nextId) {
            opts.startkey = cursor.nextId;
        }
        const result = await this.dataDb.allDocs(opts);
        const results = result.rows
                        .slice(0, request.maxPageSize)
                        .map(row => mapDocToElm(row.doc));
        
        let pageToken = null;
        if (result.rows.length > 0) {
            pageToken = createPageToken({
                nextId: result.rows[0].id
            });
        }

        let nextPageToken = null;
        if (result.rows.length > request.maxPageSize) {
            // more results!
            nextPageToken = createPageToken({
                nextId: result.rows[request.maxPageSize].id
            });
        }
        return { total: doc_count, results, pageToken, nextPageToken };
    }

    async _getAccountTransactions(request) {
        const doc_count  = (await this.dataDb.query('accounts/accounts', {
            group_level:1,
            key: request.account 
        }).then(result => {
            return result.rows.map(row => row.value);
        }))[0];
        const query = {
            selector: {
                accounts: { $all: [request.account] },
                _id: { $gte: null }
            },
            sort: [{ _id: "desc" }],
            limit: request.maxPageSize + 1
        };
        const cursor = parsePageToken(request.pageToken);
        if (cursor.nextId) {
            query.selector._id = { $lte: cursor.nextId };
        }
        console.log(request, cursor, query);
        const result = await this.dataDb.find(query);
        console.log(result);
        const results = result.docs
                        .slice(0, request.maxPageSize)
                        .map(doc => mapDocToElm(doc));

        let nextPageToken = null;
        if (result.docs.length > request.maxPageSize) {
            // more results!
            nextPageToken = createPageToken({
                nextId: result.docs[request.maxPageSize]._id
            });
        }
        return { total: doc_count, results, nextPageToken };
    }

    

    async saveTransaction(elmTxn) {
        const txn = mapDocFromElm(elmTxn);
        if (changedDate(txn)) {
            return this._saveChangeDate(txn);
        }
        setRandomTxnId(txn);
        const resp = await this.dataDb.put(txn);
        txn._rev = resp.rev;
        return mapDocToElm(txn);
    }

    async _saveChangeDate(txn) {
        const newTxn = {...txn};
        newTxn.timestamp = Date.now();
        newTxn._id = `${txn.date}.${newTxn.timestamp}-${window.crypto.randomUUID()}`;
        delete newTxn._rev;
        txn._deleted = true;
        const resp = await this.dataDb.bulkDocs([newTxn, txn]);
        newTxn._rev = resp[0].rev;
        return mapDocToElm(newTxn);
    }

    saveTransactions(elmTransactions) {
        const transactions = elmTransactions.map(elmTxn => {
            const txn = mapDocFromElm(elmTxn);
            setRandomTxnId(txn);
            return txn;
        });
        return this.dataDb.bulkDocs(transactions);
    }

    sync(settings) {
        return this.dataDb.oneShotSync(settings);
    }

    testSync(settings, password) {
        return this.dataDb.testSync(settings, password);
    }

    async deleteTransaction(id, version) {
        await this.dataDb.remove(id, version);
    }

    async deleteDataDb() {
        await destroyDb(this.dataDbName);
        this.dataDb = null;
    }

    getAllTransactions() {
        return this.dataDb.allDocs({include_docs: true})
            .then(result => result.rows.map(row => mapDocToElm(row.doc)));
    }

    getAllAccounts() {
        return this.dataDb.query('accounts/accounts', {
            group_level:1
        }).then(result => {
            return result.rows.map(row => row.key);
        });
    }

    getAccountStats() {
        return this.dataDb.query('accounts/account_stats', {
            group_level: 2
        }).then(result => {
            return result.rows.map(row => ({
                account: row.key[0],
                currency: row.key[1],
                total: row.value
            }));
        });
    }

    /**
     * Destroys the databases.
     */
    async deleteAllData() {
        await destroyDb(this.settingsDbName);
        await destroyDb(this.dataDbName);
        this.settingsDb = null;
        this.dataDb = null;
    }
}

export { DbPort, InitResponse, InitResponseFirstRun, InitResponseError, InitResponseAccounts, InvalidPassphrase };