import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import { DevApi } from './DevApi';
import { buildGlue } from './ElmPort';

const exposeJsApi = process.env.ELM_APP_EXPOSE_TEST_JS == 'true';

class ModalWrapper extends HTMLElement {
  connectedCallback() {
    const modalClass = this.getAttribute('modal-class') || 'confirm-modal';
      if (this.innerHTML.trim() === '') {
          this.innerHTML = `
  <div class="ui mini modal ${modalClass}">
    <div class="content">
        <p>Are you sure?</p>
    </div>
    <div class="actions">
        <div class="ui red cancel inverted button" data-cy="cancel-modal">
            <i class="remove icon"></i>
            No
        </div>
        <div class="ui green ok inverted button" data-cy="confirm-modal">
            <i class="checkmark icon"></i>
            Yes
        </div>
    </div>
</div>
          `;
      }
  }
}

customElements.define('modal-wrapper', ModalWrapper);


function main() {

  const app = Elm.Main.init({
    node: document.getElementById('root')
  });

  const glue = buildGlue(app);

  if (exposeJsApi) {
    window.ElmExpenses = new DevApi(app.ports, null, np => glue.setDbPort(np));
  }

  return app;

}

const app = main();

serviceWorker.register({
  onUpdate: () => app.ports.gotDebugMessage.send("New content is available and will be used when all tabs are closed"),
  onSuccess: () => app.ports.gotDebugMessage.send("Content is cached for offline use!")
});
