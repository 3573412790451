(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cw.bx === region.b2.bx)
	{
		return 'on line ' + region.cw.bx;
	}
	return 'on lines ' + region.cw.bx + ' through ' + region.b2.bx;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fS,
		impl.gH,
		impl.gx,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		ef: func(record.ef),
		dl: record.dl,
		dd: record.dd
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.ef;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.dl;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.dd) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fS,
		impl.gH,
		impl.gx,
		function(sendToApp, initialModel) {
			var view = impl.gI;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fS,
		impl.gH,
		impl.gx,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.dj && impl.dj(sendToApp)
			var view = impl.gI;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.C);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.D) && (_VirtualDom_doc.title = title = doc.D);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.gd;
	var onUrlRequest = impl.ge;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		dj: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.eB === next.eB
							&& curr.d$ === next.d$
							&& curr.eu.a === next.eu.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		fS: function(flags)
		{
			return A3(impl.fS, flags, _Browser_getUrl(), key);
		},
		gI: impl.gI,
		gH: impl.gH,
		gx: impl.gx
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { fN: 'hidden', fg: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { fN: 'mozHidden', fg: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { fN: 'msHidden', fg: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { fN: 'webkitHidden', fg: 'webkitvisibilitychange' }
		: { fN: 'hidden', fg: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		eK: _Browser_getScene(),
		eY: {
			e1: _Browser_window.pageXOffset,
			e2: _Browser_window.pageYOffset,
			e0: _Browser_doc.documentElement.clientWidth,
			d_: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		e0: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		d_: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			eK: {
				e0: node.scrollWidth,
				d_: node.scrollHeight
			},
			eY: {
				e1: node.scrollLeft,
				e2: node.scrollTop,
				e0: node.clientWidth,
				d_: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			eK: _Browser_getScene(),
			eY: {
				e1: x,
				e2: y,
				e0: _Browser_doc.documentElement.clientWidth,
				d_: _Browser_doc.documentElement.clientHeight
			},
			fz: {
				e1: x + rect.left,
				e2: y + rect.top,
				e0: rect.width,
				d_: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.f0) { flags += 'm'; }
	if (options.ff) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $author$project$Main$ChangedUrl = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$ClickedLink = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.o) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.t),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.t);
		} else {
			var treeLen = builder.o * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.v) : builder.v;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.o);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.t) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.t);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{v: nodeList, o: (len / $elm$core$Array$branchFactor) | 0, t: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {fI: fragment, d$: host, gh: path, eu: port_, eB: protocol, eC: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Main$AppLoadingPage = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$Events$Visible = 0;
var $author$project$Main$GotAppLoadingMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Shared$ResetApp = {$: 0};
var $author$project$Main$Shared = function (a) {
	return {$: 9, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Effect$Shared = function (a) {
	return {$: 2, a: a};
};
var $author$project$Effect$fromShared = $author$project$Effect$Shared;
var $author$project$Route$AppLoading = {$: 0};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$RouteNames$loading = 'loading';
var $author$project$RouteNames$settings = 'settings';
var $author$project$Route$routeToPieces = function (page) {
	switch (page.$) {
		case 1:
			if (page.a.$ === 1) {
				var _v1 = page.a;
				return _List_Nil;
			} else {
				var f = page.a.a;
				return _List_fromArray(
					[f]);
			}
		case 0:
			return _List_fromArray(
				[$author$project$RouteNames$loading]);
		case 2:
			if (page.a.$ === 1) {
				var _v2 = page.a;
				return _List_fromArray(
					[$author$project$RouteNames$settings]);
			} else {
				switch (page.a.a.$) {
					case 0:
						var _v3 = page.a.a;
						return _List_fromArray(
							['import-json']);
					case 3:
						var _v4 = page.a.a;
						return _List_fromArray(
							['export-json']);
					case 1:
						var _v5 = page.a.a;
						return _List_fromArray(
							['import-sample']);
					default:
						var base64 = page.a.a.a;
						return _List_fromArray(
							['import-settings', base64]);
				}
			}
		case 3:
			return _List_fromArray(
				['new']);
		case 4:
			var id = page.a;
			return _List_fromArray(
				['edit', id]);
		case 5:
			return _List_fromArray(
				['accounts']);
		case 6:
			return _List_fromArray(
				['expenses-pie-chart']);
		case 7:
			return _List_fromArray(
				['expenses-bar-chart']);
		default:
			return _List_fromArray(
				['e2e']);
	}
};
var $author$project$Route$routeToString = function (page) {
	return '#/' + A2(
		$elm$core$String$join,
		'/',
		$author$project$Route$routeToPieces(page));
};
var $author$project$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Route$goToAppLoading = function (appModel) {
	return A2($author$project$Route$pushUrl, appModel.c0, $author$project$Route$AppLoading);
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Effect$toCmd = F2(
	function (_v0, effect) {
		var fromSharedMsg = _v0.a;
		var fromPageMsg = _v0.b;
		switch (effect.$) {
			case 0:
				return $elm$core$Platform$Cmd$none;
			case 1:
				var cmd = effect.a;
				return A2($elm$core$Platform$Cmd$map, fromPageMsg, cmd);
			case 2:
				var msg = effect.a;
				return A2(
					$elm$core$Task$perform,
					fromSharedMsg,
					$elm$core$Task$succeed(msg));
			default:
				var list = effect.a;
				return $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						$author$project$Effect$toCmd(
							_Utils_Tuple2(fromSharedMsg, fromPageMsg)),
						list));
		}
	});
var $author$project$Main$doInitilizeCmd = function (appModel) {
	return $elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				$author$project$Route$goToAppLoading(appModel),
				A2(
				$author$project$Effect$toCmd,
				_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotAppLoadingMsg),
				$author$project$Effect$fromShared($author$project$Shared$ResetApp))
			]));
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {az: frag, aF: params, ar: unvisited, ae: value, aS: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.ar;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.ae);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.ae);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.gh),
					$elm$url$Url$Parser$prepareQuery(url.eC),
					url.fI,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$AccountsRoute = {$: 5};
var $author$project$Route$E2ETest = {$: 8};
var $author$project$Route$EditRoute = function (a) {
	return {$: 4, a: a};
};
var $author$project$Route$ExpensesBarChartRoute = {$: 7};
var $author$project$Route$ExpensesPieChartRoute = {$: 6};
var $author$project$Route$ExportJson = {$: 3};
var $author$project$Route$ImportJson = {$: 0};
var $author$project$Route$ImportSample = {$: 1};
var $author$project$Route$ImportSettings = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$NewRoute = {$: 3};
var $author$project$Route$SettingsRoute = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$TransactionsRoute = function (a) {
	return {$: 1, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.aS;
		var unvisited = _v0.ar;
		var params = _v0.aF;
		var frag = _v0.az;
		var value = _v0.ae;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.aS;
			var unvisited = _v1.ar;
			var params = _v1.aF;
			var frag = _v1.az;
			var value = _v1.ae;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aS;
		var unvisited = _v0.ar;
		var params = _v0.aF;
		var frag = _v0.az;
		var value = _v0.ae;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.aS;
			var unvisited = _v0.ar;
			var params = _v0.aF;
			var frag = _v0.az;
			var value = _v0.ae;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$TransactionsRoute($elm$core$Maybe$Nothing),
			$elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$TransactionsRoute(
				$elm$core$Maybe$Just('sync')),
			$elm$url$Url$Parser$s('sync')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$AppLoading,
			$elm$url$Url$Parser$s($author$project$RouteNames$loading)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SettingsRoute($elm$core$Maybe$Nothing),
			$elm$url$Url$Parser$s($author$project$RouteNames$settings)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SettingsRoute(
				$elm$core$Maybe$Just($author$project$Route$ImportSample)),
			$elm$url$Url$Parser$s('import-sample')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SettingsRoute(
				$elm$core$Maybe$Just($author$project$Route$ImportJson)),
			$elm$url$Url$Parser$s('import-json')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SettingsRoute(
				$elm$core$Maybe$Just($author$project$Route$ExportJson)),
			$elm$url$Url$Parser$s('export-json')),
			A2(
			$elm$url$Url$Parser$map,
			function (d) {
				return $author$project$Route$SettingsRoute(
					$elm$core$Maybe$Just(
						$author$project$Route$ImportSettings(d)));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('import-settings'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NewRoute,
			$elm$url$Url$Parser$s('new')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$EditRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('edit'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$AccountsRoute,
			$elm$url$Url$Parser$s('accounts')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ExpensesPieChartRoute,
			$elm$url$Url$Parser$s('expenses-pie-chart')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ExpensesBarChartRoute,
			$elm$url$Url$Parser$s('expenses-bar-chart')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$E2ETest,
			$elm$url$Url$Parser$s('e2e'))
		]));
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Route$fromUrl = function (url) {
	return A2(
		$elm$url$Url$Parser$parse,
		$author$project$Route$parser,
		_Utils_update(
			url,
			{
				fI: $elm$core$Maybe$Nothing,
				gh: A2($elm$core$Maybe$withDefault, '', url.fI)
			}));
};
var $author$project$Page$AppLoading$Loading = 0;
var $author$project$Page$AppLoading$Locked = 1;
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Effect$None = {$: 0};
var $author$project$Effect$none = $author$project$Effect$None;
var $author$project$Page$AppLoading$init = function (appModel) {
	return _Utils_Tuple2(
		{
			as: appModel,
			a8: '',
			cl: $elm$core$Maybe$Nothing,
			cx: function () {
				var _v0 = appModel.di;
				switch (_v0.$) {
					case 2:
						return 1;
					case 4:
						return 1;
					default:
						return 0;
				}
			}(),
			be: A2(
				$elm$core$Maybe$withDefault,
				'',
				$elm$core$List$head(appModel.cH)),
			aU: false
		},
		$author$project$Effect$none);
};
var $author$project$Shared$DataNotLoaded = 0;
var $author$project$Session$Loading = {$: 0};
var $author$project$Ledger$Ledger = $elm$core$Basics$identity;
var $author$project$Ledger$emptyLedger = {aY: $elm$core$Dict$empty, aC: $elm$core$Dict$empty, ab: $elm$core$Dict$empty, B: _List_Nil, af: 0};
var $author$project$Ledger$empty = $author$project$Ledger$emptyLedger;
var $author$project$Shared$initialModel = F2(
	function (key, url) {
		return {dr: $elm$core$Dict$empty, cH: _List_Nil, dS: $elm$core$Dict$empty, fQ: 0, b9: $elm$core$Maybe$Nothing, bv: url, c0: key, fU: $author$project$Ledger$empty, gb: $elm$core$Maybe$Nothing, gi: $elm$core$Maybe$Nothing, di: $author$project$Session$Loading, B: _List_Nil, cE: url};
	});
var $author$project$Main$init = F3(
	function (_v0, url, key) {
		var appModel = A2($author$project$Shared$initialModel, key, url);
		var cmd = function () {
			var _v1 = $author$project$Route$fromUrl(url);
			if ((!_v1.$) && (_v1.a.$ === 8)) {
				var _v2 = _v1.a;
				return $elm$core$Platform$Cmd$none;
			} else {
				return $author$project$Main$doInitilizeCmd(appModel);
			}
		}();
		return _Utils_Tuple2(
			{
				bu: $elm$core$Maybe$Nothing,
				ca: url,
				j: $author$project$Main$AppLoadingPage(
					$author$project$Page$AppLoading$init(appModel).a),
				e: appModel,
				cE: url,
				$7: 0
			},
			cmd);
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Main$ContinueInitialization = {$: 11};
var $author$project$Main$OnVisibilityChange = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$ReloadApp = {$: 10};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Main$continueInitialization = _Platform_incomingPort('continueInitialization', $elm$json$Json$Decode$value);
var $elm$browser$Browser$Events$Document = 0;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {et: pids, eU: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {dO: event, c0: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.et,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.c0;
		var event = _v0.dO;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.eU);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$Hidden = 1;
var $elm$browser$Browser$Events$withHidden = F2(
	function (func, isHidden) {
		return func(
			isHidden ? 1 : 0);
	});
var $elm$browser$Browser$Events$onVisibilityChange = function (func) {
	var info = _Browser_visibilityInfo(0);
	return A3(
		$elm$browser$Browser$Events$on,
		0,
		info.fg,
		A2(
			$elm$json$Json$Decode$map,
			$elm$browser$Browser$Events$withHidden(func),
			A2(
				$elm$json$Json$Decode$field,
				'target',
				A2($elm$json$Json$Decode$field, info.fN, $elm$json$Json$Decode$bool))));
};
var $author$project$Main$reloadApp = _Platform_incomingPort('reloadApp', $elm$json$Json$Decode$value);
var $author$project$Main$mainSubscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Main$reloadApp(
			function (_v0) {
				return $author$project$Main$ReloadApp;
			}),
			$author$project$Main$continueInitialization(
			function (_v1) {
				return $author$project$Main$ContinueInitialization;
			}),
			$elm$browser$Browser$Events$onVisibilityChange($author$project$Main$OnVisibilityChange)
		]));
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Main$GotAccountsMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$GotEditMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$GotSettingsMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotTransactionsMsg = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Page$Accounts$subscriptions = $elm$core$Platform$Sub$none;
var $author$project$Page$AppLoading$GotDecryptionError = {$: 2};
var $author$project$Page$AppLoading$GotDecryptionSuccess = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$AppLoading$InitError = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Session$SessionMetadata = F4(
	function (settings, username, password, indexed) {
		return {fR: indexed, a8: password, bL: settings, be: username};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Settings$Settings = F9(
	function (version, destinationAccounts, sourceAccounts, defaultCurrency, otherCurrencies, replication, syncOnSave, reportCurrencies, defaultExpenseReportCurrency) {
		return {cQ: defaultCurrency, ft: defaultExpenseReportCurrency, cS: destinationAccounts, es: otherCurrencies, p: replication, go: reportCurrencies, dk: sourceAccounts, gy: syncOnSave, af: version};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (path, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					return A2(
						$elm$json$Json$Decode$at,
						path,
						nullOr(valDecoder));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				_List_fromArray(
					[key]),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Settings$ReplicationSettings = F3(
	function (url, username, password) {
		return {a8: password, cE: url, be: username};
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Settings$replicationSettingsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'password',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'username',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'url',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Settings$ReplicationSettings))));
var $author$project$Settings$settingsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'defaultExpenseReportCurrency',
	$elm$json$Json$Decode$string,
	'USD',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'reportCurrencies',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
		_List_Nil,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'syncOnSave',
			$elm$json$Json$Decode$bool,
			true,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'replication',
				$elm$json$Json$Decode$nullable($author$project$Settings$replicationSettingsDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'otherCurrencies',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
					_List_Nil,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'defaultCurrency',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'sourceAccounts',
							$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'destinationAccounts',
								$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'version',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Settings$Settings))))))))));
var $author$project$Session$decryptedSettingsDecoder = A2(
	$elm$json$Json$Decode$map,
	function (f) {
		return f(false);
	},
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'password',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'username',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'settings',
				$author$project$Settings$settingsDecoder,
				$elm$json$Json$Decode$succeed($author$project$Session$SessionMetadata)))));
var $author$project$Session$decodeDecryptedSettings = $elm$json$Json$Decode$decodeValue($author$project$Session$decryptedSettingsDecoder);
var $author$project$Misc$decodeString = $elm$json$Json$Decode$decodeValue($elm$json$Json$Decode$string);
var $author$project$Page$AppLoading$decryptedSettings = _Platform_incomingPort('decryptedSettings', $elm$json$Json$Decode$value);
var $author$project$Page$AppLoading$decryptedSettingsError = _Platform_incomingPort('decryptedSettingsError', $elm$json$Json$Decode$value);
var $author$project$Page$AppLoading$gotInitError = _Platform_incomingPort('gotInitError', $elm$json$Json$Decode$value);
var $author$project$Page$AppLoading$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Page$AppLoading$gotInitError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$AppLoading$InitError)),
			$author$project$Page$AppLoading$decryptedSettingsError(
			function (_v0) {
				return $author$project$Page$AppLoading$GotDecryptionError;
			}),
			$author$project$Page$AppLoading$decryptedSettings(
			A2($elm$core$Basics$composeR, $author$project$Session$decodeDecryptedSettings, $author$project$Page$AppLoading$GotDecryptionSuccess))
		]));
var $author$project$Page$Edit$DeleteCancelled = {$: 30};
var $author$project$Page$Edit$DeleteConfirmed = {$: 31};
var $author$project$Page$Edit$TransactionDeleted = function (a) {
	return {$: 32, a: a};
};
var $author$project$Page$Edit$TransactionDeletedError = function (a) {
	return {$: 33, a: a};
};
var $author$project$Page$Edit$TransactionLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Edit$TransactionLoadedError = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Edit$TransactionSaved = function (a) {
	return {$: 27, a: a};
};
var $author$project$Page$Edit$TransactionSavedError = function (a) {
	return {$: 28, a: a};
};
var $author$project$Page$Edit$deleteCancelled = _Platform_incomingPort('deleteCancelled', $elm$json$Json$Decode$value);
var $author$project$Page$Edit$deleteConfirmed = _Platform_incomingPort('deleteConfirmed', $elm$json$Json$Decode$value);
var $author$project$Transactions$Transaction = function (id) {
	return function (version) {
		return function (timestamp) {
			return function (date) {
				return function (description) {
					return function (destination) {
						return function (source) {
							return function (accounts) {
								return function (notes) {
									return function (entries) {
										return {dr: accounts, dH: date, dK: description, fu: destination, fA: entries, aj: id, ga: notes, gv: source, aP: timestamp, af: version};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {dB: col, fl: contextStack, ey: problem, eH: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.eH, s.dB, x, s.g));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.bF, s.c);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{dB: 1, g: s.g, h: s.h, bF: s.bF + 1, eH: s.eH + 1, c: s.c}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{dB: s.dB + 1, g: s.g, h: s.h, bF: newOffset, eH: s.eH, c: s.c}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.ey;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.c),
			s.bF) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.bF, s1.bF, s0.c),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.bF, s.eH, s.dB, s.c);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{dB: newCol, g: s.g, h: s.h, bF: newOffset, eH: newRow, c: s.c});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn)) ? $elm$core$Result$Err(
			'Invalid date: ' + (('month ' + ($elm$core$String$fromInt(mn) + ' is out of range')) + (' (1 to 12)' + ('; received (year ' + ($elm$core$String$fromInt(y) + (', month ' + ($elm$core$String$fromInt(mn) + (', day ' + ($elm$core$String$fromInt(d) + ')'))))))))) : ((!A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Err(
			'Invalid date: ' + (('day ' + ($elm$core$String$fromInt(d) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(
				A2(
					$justinmimbs$date$Date$daysInMonth,
					y,
					$justinmimbs$date$Date$numberToMonth(mn))) + ')')) + ((' for ' + $justinmimbs$date$Date$monthToName(
				$justinmimbs$date$Date$numberToMonth(mn))) + ((((mn === 2) && (d === 29)) ? (' (' + ($elm$core$String$fromInt(y) + ' is not a leap year)')) : '') + ('; received (year ' + ($elm$core$String$fromInt(y) + (', month ' + ($elm$core$String$fromInt(mn) + (', day ' + ($elm$core$String$fromInt(d) + ')'))))))))))) : $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		var daysInYear = $justinmimbs$date$Date$isLeapYear(y) ? 366 : 365;
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, daysInYear, od)) ? $elm$core$Result$Err(
			'Invalid ordinal date: ' + (('ordinal-day ' + ($elm$core$String$fromInt(od) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(daysInYear) + ')')) + ((' for ' + $elm$core$String$fromInt(y)) + ('; received (year ' + ($elm$core$String$fromInt(y) + (', ordinal-day ' + ($elm$core$String$fromInt(od) + ')')))))))) : $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		var weeksInYear = $justinmimbs$date$Date$is53WeekYear(wy) ? 53 : 52;
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, weeksInYear, wn)) ? $elm$core$Result$Err(
			'Invalid week date: ' + (('week ' + ($elm$core$String$fromInt(wn) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(weeksInYear) + ')')) + ((' for ' + $elm$core$String$fromInt(wy)) + ('; received (year ' + ($elm$core$String$fromInt(wy) + (', week ' + ($elm$core$String$fromInt(wn) + (', weekday ' + ($elm$core$String$fromInt(wdn) + ')')))))))))) : ((!A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn)) ? $elm$core$Result$Err(
			'Invalid week date: ' + (('weekday ' + ($elm$core$String$fromInt(wdn) + ' is out of range')) + (' (1 to 7)' + ('; received (year ' + ($elm$core$String$fromInt(wy) + (', week ' + ($elm$core$String$fromInt(wn) + (', weekday ' + ($elm$core$String$fromInt(wdn) + ')'))))))))) : $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {dB: col, ey: problem, eH: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.eH, p.dB, p.ey);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{dB: 1, g: _List_Nil, h: 1, bF: 0, eH: 1, c: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $author$project$Transactions$dateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $justinmimbs$date$Date$fromIsoString(str);
		if (!_v0.$) {
			var d = _v0.a;
			return $elm$json$Json$Decode$succeed(d);
		} else {
			return $elm$json$Json$Decode$fail('Invalid date ' + str);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Transactions$Entry = F5(
	function (account, currency, amount, description, date) {
		return {e6: account, e9: amount, fn: currency, dH: date, dK: description};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Transactions$entryDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'date',
	$elm$json$Json$Decode$nullable($author$project$Transactions$dateDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'description',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'amount',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'currency',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'account',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Transactions$Entry))))));
var $author$project$Transactions$transactionDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'entries',
	$elm$json$Json$Decode$nullable(
		$elm$json$Json$Decode$list($author$project$Transactions$entryDecoder)),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'notes',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'accounts',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			_List_Nil,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'source',
				$author$project$Transactions$entryDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'destination',
					$author$project$Transactions$entryDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'description',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'date',
							$author$project$Transactions$dateDecoder,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'timestamp',
								$elm$json$Json$Decode$int,
								0,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'version',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Transactions$Transaction)))))))))));
var $author$project$Page$Edit$transactionDeleted = _Platform_incomingPort('transactionDeleted', $elm$json$Json$Decode$value);
var $author$project$Page$Edit$transactionDeletedError = _Platform_incomingPort('transactionDeletedError', $elm$json$Json$Decode$value);
var $author$project$Page$Edit$transactionLoaded = _Platform_incomingPort('transactionLoaded', $elm$json$Json$Decode$value);
var $author$project$Page$Edit$transactionLoadedError = _Platform_incomingPort('transactionLoadedError', $elm$json$Json$Decode$value);
var $author$project$Page$Edit$transactionSaved = _Platform_incomingPort('transactionSaved', $elm$json$Json$Decode$value);
var $author$project$Page$Edit$transactionSavedError = _Platform_incomingPort('transactionSavedError', $elm$json$Json$Decode$value);
var $author$project$Page$Edit$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Page$Edit$transactionLoaded(
			A2(
				$elm$core$Basics$composeR,
				$elm$json$Json$Decode$decodeValue($author$project$Transactions$transactionDecoder),
				$author$project$Page$Edit$TransactionLoaded)),
			$author$project$Page$Edit$transactionLoadedError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Edit$TransactionLoadedError)),
			$author$project$Page$Edit$transactionSaved(
			A2(
				$elm$core$Basics$composeR,
				$elm$json$Json$Decode$decodeValue($author$project$Transactions$transactionDecoder),
				$author$project$Page$Edit$TransactionSaved)),
			$author$project$Page$Edit$transactionSavedError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Edit$TransactionSavedError)),
			$author$project$Page$Edit$transactionDeleted(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Edit$TransactionDeleted)),
			$author$project$Page$Edit$transactionDeletedError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Edit$TransactionDeletedError)),
			$author$project$Page$Edit$deleteCancelled(
			function (_v0) {
				return $author$project$Page$Edit$DeleteCancelled;
			}),
			$author$project$Page$Edit$deleteConfirmed(
			function (_v1) {
				return $author$project$Page$Edit$DeleteConfirmed;
			})
		]));
var $author$project$Page$Settings$DeleteAllCancelled = {$: 21};
var $author$project$Page$Settings$DeleteAllConfirmed = {$: 22};
var $author$project$Page$Settings$DeletedAllData = {$: 41};
var $author$project$Page$Settings$GotAllTransactions = function (a) {
	return {$: 31, a: a};
};
var $author$project$Page$Settings$GotAllTransactionsError = function (a) {
	return {$: 32, a: a};
};
var $author$project$Page$Settings$GotE2EJsonLoaded = function (a) {
	return {$: 26, a: a};
};
var $author$project$Page$Settings$GotQrScanResult = function (a) {
	return {$: 38, a: a};
};
var $author$project$Page$Settings$GotReplicationTest = function (a) {
	return {$: 39, a: a};
};
var $author$project$Page$Settings$GotReplicationTestError = function (a) {
	return {$: 40, a: a};
};
var $author$project$Page$Settings$ImportedSampleData = {$: 28};
var $author$project$Page$Settings$SettingsCreated = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Settings$SettingsCreatedError = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Settings$SettingsSaved = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$Settings$SettingsSavedError = function (a) {
	return {$: 18, a: a};
};
var $author$project$Page$Settings$TransactionsImported = function (a) {
	return {$: 27, a: a};
};
var $author$project$Page$Settings$TransactionsImportedError = function (a) {
	return {$: 29, a: a};
};
var $author$project$Misc$decodeInt = $elm$json$Json$Decode$decodeValue($elm$json$Json$Decode$int);
var $author$project$Settings$decodeSettings = function (jsonData) {
	return A2($elm$json$Json$Decode$decodeValue, $author$project$Settings$settingsDecoder, jsonData);
};
var $author$project$Transactions$decodeTransactions = function (jsonData) {
	return A2(
		$elm$json$Json$Decode$decodeValue,
		$elm$json$Json$Decode$list($author$project$Transactions$transactionDecoder),
		jsonData);
};
var $author$project$Page$Settings$deleteAllCancelled = _Platform_incomingPort('deleteAllCancelled', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$deleteAllConfirmed = _Platform_incomingPort('deleteAllConfirmed', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$deletedAllData = _Platform_incomingPort('deletedAllData', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$gotAllTransactions = _Platform_incomingPort('gotAllTransactions', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$gotAllTransactionsError = _Platform_incomingPort('gotAllTransactionsError', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$gotE2EJsonLoaded = _Platform_incomingPort('gotE2EJsonLoaded', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$gotQrScanResult = _Platform_incomingPort('gotQrScanResult', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$gotReplicationTest = _Platform_incomingPort('gotReplicationTest', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$gotReplicationTestError = _Platform_incomingPort('gotReplicationTestError', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$importedSampleData = _Platform_incomingPort('importedSampleData', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$settingsCreated = _Platform_incomingPort('settingsCreated', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$settingsCreatedError = _Platform_incomingPort('settingsCreatedError', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$settingsSaved = _Platform_incomingPort('settingsSaved', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$settingsSavedError = _Platform_incomingPort('settingsSavedError', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$transactionsImported = _Platform_incomingPort('transactionsImported', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$transactionsImportedError = _Platform_incomingPort('transactionsImportedError', $elm$json$Json$Decode$value);
var $author$project$Page$Settings$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Page$Settings$settingsCreated(
			A2($elm$core$Basics$composeR, $author$project$Session$decodeDecryptedSettings, $author$project$Page$Settings$SettingsCreated)),
			$author$project$Page$Settings$settingsCreatedError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Settings$SettingsCreatedError)),
			$author$project$Page$Settings$settingsSaved(
			A2($elm$core$Basics$composeR, $author$project$Settings$decodeSettings, $author$project$Page$Settings$SettingsSaved)),
			$author$project$Page$Settings$settingsSavedError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Settings$SettingsSavedError)),
			$author$project$Page$Settings$deleteAllCancelled(
			function (_v0) {
				return $author$project$Page$Settings$DeleteAllCancelled;
			}),
			$author$project$Page$Settings$deleteAllConfirmed(
			function (_v1) {
				return $author$project$Page$Settings$DeleteAllConfirmed;
			}),
			$author$project$Page$Settings$importedSampleData(
			function (_v2) {
				return $author$project$Page$Settings$ImportedSampleData;
			}),
			$author$project$Page$Settings$transactionsImportedError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Settings$TransactionsImportedError)),
			$author$project$Page$Settings$transactionsImported(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeInt, $author$project$Page$Settings$TransactionsImported)),
			$author$project$Page$Settings$gotAllTransactions(
			A2($elm$core$Basics$composeR, $author$project$Transactions$decodeTransactions, $author$project$Page$Settings$GotAllTransactions)),
			$author$project$Page$Settings$gotAllTransactionsError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Settings$GotAllTransactionsError)),
			$author$project$Page$Settings$gotReplicationTest(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Settings$GotReplicationTest)),
			$author$project$Page$Settings$gotReplicationTestError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Settings$GotReplicationTestError)),
			$author$project$Page$Settings$gotE2EJsonLoaded(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Settings$GotE2EJsonLoaded)),
			$author$project$Page$Settings$gotQrScanResult(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Settings$GotQrScanResult)),
			$author$project$Page$Settings$deletedAllData(
			function (_v3) {
				return $author$project$Page$Settings$DeletedAllData;
			})
		]));
var $author$project$Page$Transactions$GotTransactions = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Transactions$GotTransactionsError = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Transactions$SyncFailed = {$: 10};
var $author$project$Page$Transactions$SyncFinished = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Transactions$SyncResult = F2(
	function (sent, received) {
		return {de: received, eN: sent};
	});
var $author$project$Page$Transactions$syncResultDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'received',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'sent',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Page$Transactions$SyncResult)));
var $author$project$Page$Transactions$decodeSyncResult = $elm$json$Json$Decode$decodeValue($author$project$Page$Transactions$syncResultDecoder);
var $author$project$Page$Transactions$GetTransactionsResponse = F4(
	function (total, results, pageToken, nextPageToken) {
		return {an: nextPageToken, N: pageToken, aI: results, gE: total};
	});
var $author$project$Transactions$transactionsDecoder = $elm$json$Json$Decode$list($author$project$Transactions$transactionDecoder);
var $author$project$Page$Transactions$transactionsResponseDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'nextPageToken',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'pageToken',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'results',
			$author$project$Transactions$transactionsDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'total',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Page$Transactions$GetTransactionsResponse)))));
var $author$project$Page$Transactions$decodeTransactionsResponse = function (jsonData) {
	return A2($elm$json$Json$Decode$decodeValue, $author$project$Page$Transactions$transactionsResponseDecoder, jsonData);
};
var $author$project$Page$Transactions$gotTransactions = _Platform_incomingPort('gotTransactions', $elm$json$Json$Decode$value);
var $author$project$Page$Transactions$gotTransactionsError = _Platform_incomingPort('gotTransactionsError', $elm$json$Json$Decode$value);
var $author$project$Page$Transactions$syncFailed = _Platform_incomingPort('syncFailed', $elm$json$Json$Decode$value);
var $author$project$Page$Transactions$syncFinished = _Platform_incomingPort('syncFinished', $elm$json$Json$Decode$value);
var $author$project$Page$Transactions$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Page$Transactions$gotTransactions(
			A2($elm$core$Basics$composeR, $author$project$Page$Transactions$decodeTransactionsResponse, $author$project$Page$Transactions$GotTransactions)),
			$author$project$Page$Transactions$gotTransactionsError(
			A2($elm$core$Basics$composeR, $author$project$Misc$decodeString, $author$project$Page$Transactions$GotTransactionsError)),
			$author$project$Page$Transactions$syncFinished(
			A2($elm$core$Basics$composeR, $author$project$Page$Transactions$decodeSyncResult, $author$project$Page$Transactions$SyncFinished)),
			$author$project$Page$Transactions$syncFailed(
			function (_v0) {
				return $author$project$Page$Transactions$SyncFailed;
			})
		]));
var $author$project$Main$pageSubscriptions = function (model) {
	var _v0 = model.j;
	switch (_v0.$) {
		case 0:
			return A2($elm$core$Platform$Sub$map, $author$project$Main$GotAppLoadingMsg, $author$project$Page$AppLoading$subscriptions);
		case 1:
			return A2($elm$core$Platform$Sub$map, $author$project$Main$GotTransactionsMsg, $author$project$Page$Transactions$subscriptions);
		case 3:
			return A2($elm$core$Platform$Sub$map, $author$project$Main$GotEditMsg, $author$project$Page$Edit$subscriptions);
		case 2:
			return A2($elm$core$Platform$Sub$map, $author$project$Main$GotSettingsMsg, $author$project$Page$Settings$subscriptions);
		case 4:
			return A2($elm$core$Platform$Sub$map, $author$project$Main$GotAccountsMsg, $author$project$Page$Accounts$subscriptions);
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Shared$GotDebugMessage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Shared$GotFirstRun = {$: 1};
var $author$project$Shared$GotInitAccounts = function (a) {
	return {$: 2, a: a};
};
var $author$project$Shared$GotInitialTransactions = function (a) {
	return {$: 0, a: a};
};
var $author$project$Shared$GotLatestTransactions = function (a) {
	return {$: 3, a: a};
};
var $author$project$Shared$InnerMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Shared$decodeAccountsList = $elm$json$Json$Decode$decodeValue(
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Shared$InitialTransactions = F3(
	function (transactions, total, remaining) {
		return {dg: remaining, gE: total, B: transactions};
	});
var $author$project$Shared$initialTransactionsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'remaining',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'total',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'transactions',
			$author$project$Transactions$transactionsDecoder,
			$elm$json$Json$Decode$succeed($author$project$Shared$InitialTransactions))));
var $author$project$Shared$decodeInitialTransactions = function (jsonData) {
	return A2($elm$json$Json$Decode$decodeValue, $author$project$Shared$initialTransactionsDecoder, jsonData);
};
var $author$project$Shared$gotDebugMessage = _Platform_incomingPort('gotDebugMessage', $elm$json$Json$Decode$value);
var $author$project$Shared$gotFirstRun = _Platform_incomingPort('gotFirstRun', $elm$json$Json$Decode$value);
var $author$project$Shared$gotInitAccounts = _Platform_incomingPort('gotInitAccounts', $elm$json$Json$Decode$value);
var $author$project$Shared$gotInitialTransactions = _Platform_incomingPort('gotInitialTransactions', $elm$json$Json$Decode$value);
var $author$project$Shared$gotLatestTransactions = _Platform_incomingPort('gotLatestTransactions', $elm$json$Json$Decode$value);
var $author$project$Shared$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Shared$gotInitialTransactions(
			A2(
				$elm$core$Basics$composeR,
				$author$project$Shared$decodeInitialTransactions,
				A2($elm$core$Basics$composeR, $author$project$Shared$GotInitialTransactions, $author$project$Shared$InnerMsg))),
			$author$project$Shared$gotFirstRun(
			function (_v0) {
				return $author$project$Shared$InnerMsg($author$project$Shared$GotFirstRun);
			}),
			$author$project$Shared$gotInitAccounts(
			A2(
				$elm$core$Basics$composeR,
				$author$project$Shared$decodeAccountsList,
				A2($elm$core$Basics$composeR, $author$project$Shared$GotInitAccounts, $author$project$Shared$InnerMsg))),
			$author$project$Shared$gotLatestTransactions(
			A2(
				$elm$core$Basics$composeR,
				$author$project$Transactions$decodeTransactions,
				A2($elm$core$Basics$composeR, $author$project$Shared$GotLatestTransactions, $author$project$Shared$InnerMsg))),
			$author$project$Shared$gotDebugMessage(
			A2(
				$elm$core$Basics$composeR,
				$author$project$Misc$decodeString,
				A2($elm$core$Basics$composeR, $author$project$Shared$GotDebugMessage, $author$project$Shared$InnerMsg)))
		]));
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Main$mainSubscriptions,
				A2($elm$core$Platform$Sub$map, $author$project$Main$Shared, $author$project$Shared$subscriptions),
				$author$project$Main$pageSubscriptions(model)
			]));
};
var $author$project$Main$CheckAutoLock = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$EditPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Shared$LockSession = {$: 4};
var $author$project$Main$StoreHiddenAt = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$TransactionsPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$AppLoading$UpdateShared = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Edit$UpdateShared = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Transactions$UpdateShared = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Main$closeSidebar = _Platform_outgoingPort(
	'closeSidebar',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $author$project$Misc$msgToCommand = function (msg) {
	return A2(
		$elm$core$Task$perform,
		function (_v0) {
			return msg;
		},
		$elm$core$Task$succeed(msg));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Main$AccountsPage = function (a) {
	return {$: 4, a: a};
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Main$initializeSemanticUi = _Platform_outgoingPort(
	'initializeSemanticUi',
	$elm$json$Json$Encode$list($elm$json$Json$Encode$string));
var $author$project$Main$toAccounts = F2(
	function (model, _v0) {
		var pageModel = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$Main$AccountsPage(pageModel)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$author$project$Effect$toCmd,
						_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotAccountsMsg),
						effect),
						$author$project$Main$initializeSemanticUi(
						_List_fromArray(
							['dropdowns']))
					])));
	});
var $author$project$Main$toAppLoading = F2(
	function (model, _v0) {
		var pageModel = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$Main$AppLoadingPage(pageModel)
				}),
			A2(
				$author$project$Effect$toCmd,
				_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotAppLoadingMsg),
				effect));
	});
var $author$project$Main$toEdit = F2(
	function (model, _v0) {
		var pageModel = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$Main$EditPage(pageModel)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$author$project$Effect$toCmd,
						_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotEditMsg),
						effect),
						$author$project$Main$initializeSemanticUi(
						_List_fromArray(
							['dropdowns']))
					])));
	});
var $author$project$Main$ExpensesBarChartPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$GotExpensesBarChartMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$toExpensesBarChart = F2(
	function (model, _v0) {
		var pageModel = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$Main$ExpensesBarChartPage(pageModel)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$author$project$Effect$toCmd,
						_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotExpensesBarChartMsg),
						effect),
						$author$project$Main$initializeSemanticUi(
						_List_fromArray(
							['dropdowns']))
					])));
	});
var $author$project$Main$ExpensesPieChartPage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$GotExpensesPieChartMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$toExpensesPieChart = F2(
	function (model, _v0) {
		var pageModel = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$Main$ExpensesPieChartPage(pageModel)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$author$project$Effect$toCmd,
						_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotExpensesPieChartMsg),
						effect),
						$author$project$Main$initializeSemanticUi(
						_List_fromArray(
							['dropdowns']))
					])));
	});
var $author$project$Main$SettingsPage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$toSettings = F2(
	function (model, _v0) {
		var pageModel = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$Main$SettingsPage(pageModel)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$author$project$Effect$toCmd,
						_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotSettingsMsg),
						effect),
						$author$project$Main$initializeSemanticUi(
						_List_fromArray(
							['dropdowns']))
					])));
	});
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.eB;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.fI,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.eC,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.eu,
					_Utils_ap(http, url.d$)),
				url.gh)));
};
var $author$project$Main$toTransactions = F2(
	function (model, _v0) {
		var pageModel = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$Main$TransactionsPage(pageModel)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$author$project$Effect$toCmd,
						_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotTransactionsMsg),
						effect),
						$author$project$Main$initializeSemanticUi(
						_List_fromArray(
							['dropdowns']))
					])));
	});
var $author$project$Page$Accounts$emptyAccountStats = function (account) {
	return {e6: account, G: $elm$core$Dict$empty};
};
var $elm$core$List$sortBy = _List_sortBy;
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$List$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$List$cons, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, _List_Nil, list, _List_Nil);
};
var $author$project$Page$Accounts$buildNetWorth = function (accounts) {
	var liabilities = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Page$Accounts$emptyAccountStats('Liabilities'),
		A2($elm$core$Dict$get, 'Liabilities', accounts));
	var getTotal = F2(
		function (currency, stats) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.gE;
					},
					A2($elm$core$Dict$get, currency, stats.G)));
		});
	var assets = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Page$Accounts$emptyAccountStats('Assets'),
		A2($elm$core$Dict$get, 'Assets', accounts));
	var allCurrencies = $elm_community$list_extra$List$Extra$unique(
		$elm$core$List$sort(
			_Utils_ap(
				$elm$core$Dict$keys(assets.G),
				$elm$core$Dict$keys(liabilities.G))));
	var currencies = A2(
		$elm$core$List$map,
		function (currency) {
			var total = A2(getTotal, currency, assets) - A2(getTotal, currency, liabilities);
			return _Utils_Tuple2(
				currency,
				{fn: currency, gE: total});
		},
		allCurrencies);
	return {
		e6: 'Net Worth',
		G: $elm$core$Dict$fromList(currencies)
	};
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$Accounts$AccountStats = F2(
	function (account, currencies) {
		return {e6: account, G: currencies};
	});
var $author$project$Page$Accounts$CurrencyStats = F2(
	function (currency, total) {
		return {fn: currency, gE: total};
	});
var $elm_community$list_extra$List$Extra$groupWhile = F2(
	function (isSameGroup, items) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					if (!acc.b) {
						return _List_fromArray(
							[
								_Utils_Tuple2(x, _List_Nil)
							]);
					} else {
						var _v1 = acc.a;
						var y = _v1.a;
						var restOfGroup = _v1.b;
						var groups = acc.b;
						return A2(isSameGroup, x, y) ? A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								x,
								A2($elm$core$List$cons, y, restOfGroup)),
							groups) : A2(
							$elm$core$List$cons,
							_Utils_Tuple2(x, _List_Nil),
							acc);
					}
				}),
			_List_Nil,
			items);
	});
var $author$project$Misc$groupingBy = F2(
	function (accessor, list) {
		var groupedBy = A2(
			$elm_community$list_extra$List$Extra$groupWhile,
			F2(
				function (item1, item2) {
					return _Utils_eq(
						accessor(item1),
						accessor(item2));
				}),
			A2($elm$core$List$sortBy, accessor, list));
		var dictList = A2(
			$elm$core$List$map,
			function (nonEmptyList) {
				var _v0 = nonEmptyList;
				var head = _v0.a;
				var tail = _v0.b;
				return _Utils_Tuple2(
					accessor(head),
					A2($elm$core$List$cons, head, tail));
			},
			groupedBy);
		return dictList;
	});
var $author$project$Page$Accounts$processRawData = function (rawData) {
	var groupedByAccount = A2(
		$author$project$Misc$groupingBy,
		function ($) {
			return $.e6;
		},
		rawData);
	var processed = A2(
		$elm$core$List$map,
		function (_v0) {
			var account = _v0.a;
			var rows = _v0.b;
			var currencies = A2(
				$elm$core$List$map,
				function (row) {
					return _Utils_Tuple2(
						row.fn,
						A2($author$project$Page$Accounts$CurrencyStats, row.fn, row.gE));
				},
				rows);
			return _Utils_Tuple2(
				account,
				A2(
					$author$project$Page$Accounts$AccountStats,
					account,
					$elm$core$Dict$fromList(currencies)));
		},
		groupedByAccount);
	return $elm$core$Dict$fromList(processed);
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Page$Accounts$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (msg.a.$ === 1) {
					var decodeError = msg.a.a;
					return _Utils_Tuple2(model, $author$project$Effect$none);
				} else {
					var rawData = msg.a.a;
					var accounts = $author$project$Page$Accounts$processRawData(rawData);
					var assets = A2(
						$elm$core$List$filter,
						function (a) {
							return (a.e6 === 'Assets') || A2($elm$core$String$startsWith, 'Assets:', a.e6);
						},
						A2(
							$elm$core$List$map,
							$elm$core$Tuple$second,
							$elm$core$Dict$toList(accounts)));
					var liabilities = A2(
						$elm$core$List$filter,
						function (a) {
							return (a.e6 === 'Liabilities') || A2($elm$core$String$startsWith, 'Liabilities:', a.e6);
						},
						A2(
							$elm$core$List$map,
							$elm$core$Tuple$second,
							$elm$core$Dict$toList(accounts)));
					var netWorth = $author$project$Page$Accounts$buildNetWorth(accounts);
					var seenCurrencies = $elm_community$list_extra$List$Extra$unique(
						$elm$core$List$sort(
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									$elm$core$Dict$keys,
									A2(
										$elm$core$List$map,
										function ($) {
											return $.G;
										},
										$elm$core$Dict$values(accounts))))));
					var currencies = $elm_community$list_extra$List$Extra$unique(
						_Utils_ap(
							A2($elm$core$List$cons, model.bL.cQ, model.bL.es),
							seenCurrencies));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dr: accounts, bS: assets, G: currencies, ce: liabilities, bD: netWorth}),
						$author$project$Effect$none);
				}
			case 1:
				var maybeEmpty = msg.a;
				var selectedCurrencies = $elm$core$List$isEmpty(maybeEmpty) ? _List_fromArray(
					[model.bL.cQ]) : maybeEmpty;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: selectedCurrencies}),
					$author$project$Effect$none);
			case 2:
				var displayType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bq: displayType}),
					$author$project$Effect$none);
			default:
				return _Utils_Tuple2(model, $author$project$Effect$none);
		}
	});
var $author$project$Shared$DismissNotification = {$: 11};
var $author$project$Shared$DismissPopupNotification = {$: 10};
var $author$project$Session$Unlocked = function (a) {
	return {$: 3, a: a};
};
var $author$project$Shared$UpdateSession = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$AppLoading$decryptSettings = _Platform_outgoingPort(
	'decryptSettings',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Effect$Cmd = function (a) {
	return {$: 1, a: a};
};
var $author$project$Effect$fromCmd = $author$project$Effect$Cmd;
var $author$project$Page$AppLoading$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var shared = msg.a;
				var username = A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(shared.cH));
				var status = function () {
					var _v1 = shared.di;
					switch (_v1.$) {
						case 2:
							return 1;
						case 4:
							return 1;
						default:
							return 0;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{as: shared, cx: status, be: username}),
					$author$project$Effect$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cl: $elm$core$Maybe$Just(true),
							aU: false
						}),
					$author$project$Effect$none);
			case 3:
				if (!msg.a.$) {
					var metadata = msg.a.a;
					var session = $author$project$Session$Unlocked(metadata);
					var effect = $author$project$Effect$fromShared(
						$author$project$Shared$UpdateSession(session));
					return _Utils_Tuple2(model, effect);
				} else {
					var decodeError = msg.a.a;
					return _Utils_Tuple2(model, $author$project$Effect$none);
				}
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aU: true}),
					$author$project$Effect$fromCmd(
						$author$project$Page$AppLoading$decryptSettings(
							_Utils_Tuple2(model.be, model.a8))));
			case 0:
				if (!msg.a.$) {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $author$project$Effect$none);
				} else {
					var decodeError = msg.a.a;
					return _Utils_Tuple2(model, $author$project$Effect$none);
				}
			case 4:
				var username = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{be: username}),
					$author$project$Effect$none);
			case 5:
				var password = msg.a;
				var _v2 = ((model.a8 === '') && ($elm$core$String$length(password) > 3)) ? _Utils_Tuple2(
					$author$project$Effect$fromCmd(
						$author$project$Page$AppLoading$decryptSettings(
							_Utils_Tuple2(model.be, password))),
					true) : _Utils_Tuple2($author$project$Effect$none, model.aU);
				var effect = _v2.a;
				var working = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a8: password, aU: working}),
					effect);
			case 7:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromShared($author$project$Shared$DismissNotification));
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromShared($author$project$Shared$DismissPopupNotification));
		}
	});
var $author$project$Shared$AddTransaction = function (a) {
	return {$: 6, a: a};
};
var $author$project$Shared$DeleteTransaction = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Sync = 0;
var $author$project$Effect$Batch = function (a) {
	return {$: 3, a: a};
};
var $author$project$Effect$batch = $author$project$Effect$Batch;
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Page$Edit$toAmount = function (amount) {
	return $elm$core$String$fromFloat(amount / 100.0);
};
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {dI: d, c4: m, e3: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		da: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		e3: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.e3, 0, date.da);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.dI;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.c4;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.da;
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		eZ: 1 + (((rd - week1Day1) / 7) | 0),
		e_: wy,
		gK: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.eZ;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.e_;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.ci(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.c5(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.ci(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.cO(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.aT(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.aT(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.aT(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.dp(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.aT(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.aT(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.c);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.bF, offset) < 0,
					0,
					{dB: col, g: s0.g, h: s0.h, bF: offset, eH: row, c: s0.c});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.bF, s.eH, s.dB, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.bF, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	cO: $justinmimbs$date$Date$withOrdinalSuffix,
	c5: $justinmimbs$date$Date$monthToName,
	ci: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	dp: $justinmimbs$date$Date$weekdayToName,
	aT: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$Page$Edit$buildEntryInput = function (entry) {
	return {
		e6: entry.e6,
		e9: $author$project$Page$Edit$toAmount(entry.e9),
		fn: entry.fn,
		dH: A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, $justinmimbs$date$Date$toIsoString, entry.dH)),
		dK: A2($elm$core$Maybe$withDefault, '', entry.dK)
	};
};
var $author$project$Page$Edit$Full = 2;
var $author$project$Page$Edit$Simple = 0;
var $author$project$Page$Edit$isMultiCurrency = function (txn) {
	return !_Utils_eq(txn.fu.fn, txn.gv.fn);
};
var $author$project$Misc$isSomething = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Page$Edit$isFull = function (txn) {
	return $author$project$Misc$isSomething(txn.fA) ? true : $author$project$Page$Edit$isMultiCurrency(txn);
};
var $author$project$Page$Edit$chooseEditMode = function (txn) {
	return $author$project$Page$Edit$isFull(txn) ? 2 : 0;
};
var $author$project$Page$Edit$emptyEntryInput = {e6: '', e9: '', fn: '', dH: '', dK: ''};
var $author$project$Page$Edit$defaultFormInput = function (settings) {
	var defaultSource = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(settings.dk));
	var defaultDestination = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(settings.cS));
	return {
		e9: '',
		fn: settings.cQ,
		dH: '',
		dK: '',
		fu: defaultDestination,
		l: _Utils_update(
			$author$project$Page$Edit$emptyEntryInput,
			{e6: defaultDestination, fn: settings.cQ}),
		ai: $elm$core$Maybe$Nothing,
		fA: _List_Nil,
		ax: _List_Nil,
		ay: _List_Nil,
		aj: '',
		ga: '',
		gv: defaultSource,
		m: _Utils_update(
			$author$project$Page$Edit$emptyEntryInput,
			{e6: defaultSource, fn: settings.cQ}),
		aP: 0,
		af: ''
	};
};
var $author$project$Page$Edit$deleteTransaction = _Platform_outgoingPort(
	'deleteTransaction',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Page$Edit$formInput = F2(
	function (settings, maybeTxn) {
		if (maybeTxn.$ === 1) {
			return $author$project$Page$Edit$defaultFormInput(settings);
		} else {
			var txn = maybeTxn.a;
			return {
				e9: $author$project$Page$Edit$toAmount(txn.fu.e9),
				fn: txn.fu.fn,
				dH: $justinmimbs$date$Date$toIsoString(txn.dH),
				dK: txn.dK,
				fu: txn.fu.e6,
				l: $author$project$Page$Edit$buildEntryInput(txn.fu),
				ai: $elm$core$Maybe$Just(txn),
				fA: A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$map($author$project$Page$Edit$buildEntryInput),
						txn.fA)),
				ax: _List_fromArray(
					[txn.fu.e6]),
				ay: _List_fromArray(
					[txn.gv.e6]),
				aj: txn.aj,
				ga: A2($elm$core$Maybe$withDefault, '', txn.ga),
				gv: txn.gv.e6,
				m: $author$project$Page$Edit$buildEntryInput(txn.gv),
				aP: txn.aP,
				af: txn.af
			};
		}
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Route$goToTransactions = F2(
	function (maybeAction, key) {
		var fragment = A2(
			$elm$core$Maybe$map,
			function (a) {
				return 'sync';
			},
			maybeAction);
		return A2(
			$author$project$Route$pushUrl,
			key,
			$author$project$Route$TransactionsRoute(fragment));
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var _v0 = A2($elm$core$List$drop, index, l);
			if (!_v0.b) {
				return l;
			} else {
				var rest = _v0.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, l),
					rest);
			}
		}
	});
var $author$project$Page$Edit$resetBalanaceSuggestions = F2(
	function (pos, model) {
		var _v0 = model.a4;
		if (_v0.$ === 1) {
			return _Utils_update(
				model,
				{
					a4: $elm$core$Maybe$Just(pos)
				});
		} else {
			var lastPos = _v0.a;
			return _Utils_eq(lastPos, pos) ? model : _Utils_update(
				model,
				{
					a4: $elm$core$Maybe$Just(pos),
					a6: $elm$core$Maybe$Nothing,
					aq: $elm$core$Maybe$Nothing
				});
		}
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Page$Edit$saveTransaction = _Platform_outgoingPort(
	'saveTransaction',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'accounts',
					$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.dr)),
					_Utils_Tuple2(
					'date',
					$elm$json$Json$Encode$string($.dH)),
					_Utils_Tuple2(
					'description',
					$elm$json$Json$Encode$string($.dK)),
					_Utils_Tuple2(
					'destination',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'account',
									$elm$json$Json$Encode$string($.e6)),
									_Utils_Tuple2(
									'amount',
									$elm$json$Json$Encode$int($.e9)),
									_Utils_Tuple2(
									'currency',
									$elm$json$Json$Encode$string($.fn)),
									_Utils_Tuple2(
									'date',
									function ($) {
										return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
									}($.dH)),
									_Utils_Tuple2(
									'description',
									function ($) {
										return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
									}($.dK))
								]));
					}($.fu)),
					_Utils_Tuple2(
					'entries',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							$elm$json$Json$Encode$list(
								function ($) {
									return $elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'account',
												$elm$json$Json$Encode$string($.e6)),
												_Utils_Tuple2(
												'amount',
												$elm$json$Json$Encode$int($.e9)),
												_Utils_Tuple2(
												'currency',
												$elm$json$Json$Encode$string($.fn)),
												_Utils_Tuple2(
												'date',
												function ($) {
													return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
												}($.dH)),
												_Utils_Tuple2(
												'description',
												function ($) {
													return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
												}($.dK))
											]));
								}),
							$);
					}($.fA)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string($.aj)),
					_Utils_Tuple2(
					'notes',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.ga)),
					_Utils_Tuple2(
					'source',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'account',
									$elm$json$Json$Encode$string($.e6)),
									_Utils_Tuple2(
									'amount',
									$elm$json$Json$Encode$int($.e9)),
									_Utils_Tuple2(
									'currency',
									$elm$json$Json$Encode$string($.fn)),
									_Utils_Tuple2(
									'date',
									function ($) {
										return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
									}($.dH)),
									_Utils_Tuple2(
									'description',
									function ($) {
										return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
									}($.dK))
								]));
					}($.gv)),
					_Utils_Tuple2(
					'timestamp',
					$elm$json$Json$Encode$int($.aP)),
					_Utils_Tuple2(
					'version',
					$elm$json$Json$Encode$string($.af))
				]));
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, list),
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $elm_community$list_extra$List$Extra$setAt = F2(
	function (index, value) {
		return A2(
			$elm_community$list_extra$List$Extra$updateAt,
			index,
			$elm$core$Basics$always(value));
	});
var $author$project$Page$Edit$showDeleteModal = _Platform_outgoingPort(
	'showDeleteModal',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Transactions$JsonTransaction = function (id) {
	return function (version) {
		return function (date) {
			return function (timestamp) {
				return function (description) {
					return function (destination) {
						return function (source) {
							return function (accounts) {
								return function (notes) {
									return function (entries) {
										return {dr: accounts, dH: date, dK: description, fu: destination, fA: entries, aj: id, ga: notes, gv: source, aP: timestamp, af: version};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Transactions$JsonEntry = F5(
	function (account, currency, amount, description, date) {
		return {e6: account, e9: amount, fn: currency, dH: date, dK: description};
	});
var $author$project$Transactions$entryToJson = function (entry) {
	return A5(
		$author$project$Transactions$JsonEntry,
		entry.e6,
		entry.fn,
		entry.e9,
		entry.dK,
		A2($elm$core$Maybe$map, $justinmimbs$date$Date$toIsoString, entry.dH));
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Transactions$mapEntries = function (maybeEntries) {
	return A2(
		$elm$core$Maybe$andThen,
		function (entries) {
			return $elm$core$List$isEmpty(entries) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(entries);
		},
		A2(
			$elm$core$Maybe$map,
			$elm$core$List$map($author$project$Transactions$entryToJson),
			maybeEntries));
};
var $author$project$Transactions$transactionToJson = function (txn) {
	return $author$project$Transactions$JsonTransaction(txn.aj)(txn.af)(
		$justinmimbs$date$Date$toIsoString(txn.dH))(txn.aP)(txn.dK)(
		$author$project$Transactions$entryToJson(txn.fu))(
		$author$project$Transactions$entryToJson(txn.gv))(txn.dr)(txn.ga)(
		$author$project$Transactions$mapEntries(txn.fA));
};
var $author$project$Page$Edit$updateDestinationAccount = F2(
	function (text, input) {
		var de = input.l;
		var destinationEntry = _Utils_update(
			de,
			{e6: text});
		return _Utils_update(
			input,
			{l: destinationEntry});
	});
var $author$project$Page$Edit$updateDestinationAmount = F2(
	function (text, input) {
		var de = input.l;
		var destinationEntry = _Utils_update(
			de,
			{e9: text});
		return _Utils_update(
			input,
			{l: destinationEntry});
	});
var $author$project$Page$Edit$updateDestinationCurrency = F2(
	function (text, input) {
		var de = input.l;
		var destinationEntry = _Utils_update(
			de,
			{fn: text});
		return _Utils_update(
			input,
			{l: destinationEntry});
	});
var $author$project$Page$Edit$updateDestinationDate = F2(
	function (text, input) {
		var de = input.l;
		var destinationEntry = _Utils_update(
			de,
			{dH: text});
		return _Utils_update(
			input,
			{l: destinationEntry});
	});
var $author$project$Page$Edit$updateDestinationDescription = F2(
	function (text, input) {
		var de = input.l;
		var destinationEntry = _Utils_update(
			de,
			{dK: text});
		return _Utils_update(
			input,
			{l: destinationEntry});
	});
var $author$project$Page$Edit$insertMissingBalanace = F2(
	function (editPos, model) {
		var suggestedValue = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, $author$project$Page$Edit$toAmount, model.a6));
		var i = model.a;
		var allEntries = _Utils_ap(
			_List_fromArray(
				[model.a.l, model.a.m]),
			model.a.fA);
		var suggestedIndex = function () {
			var _v1 = model.aq;
			if (_v1.$ === 1) {
				return $elm$core$List$head(
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$first,
						A2(
							$elm$core$List$filter,
							function (_v2) {
								var i1 = _v2.a;
								var e1 = _v2.b;
								return (!_Utils_eq(i1, editPos)) && $elm$core$String$isEmpty(e1.e9);
							},
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i1, e1) {
										return _Utils_Tuple2(i1, e1);
									}),
								allEntries))));
			} else {
				return model.aq;
			}
		}();
		var modifiedEntries = function () {
			if (suggestedIndex.$ === 1) {
				return allEntries;
			} else {
				var pos = suggestedIndex.a;
				return A3(
					$elm_community$list_extra$List$Extra$updateAt,
					pos,
					function (e) {
						return _Utils_update(
							e,
							{e9: suggestedValue});
					},
					allEntries);
			}
		}();
		var destinationEntry = A2(
			$elm$core$Maybe$withDefault,
			model.a.l,
			A2($elm_community$list_extra$List$Extra$getAt, 0, modifiedEntries));
		var entries = A2($elm$core$List$drop, 2, modifiedEntries);
		var sourceEntry = A2(
			$elm$core$Maybe$withDefault,
			model.a.m,
			A2($elm_community$list_extra$List$Extra$getAt, 1, modifiedEntries));
		var input = _Utils_update(
			i,
			{l: destinationEntry, fA: entries, m: sourceEntry});
		return _Utils_update(
			model,
			{a: input, aq: suggestedIndex});
	});
var $elm$core$Basics$round = _Basics_round;
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Page$Edit$isAmountValid = function (a) {
	var _v0 = $elm$core$String$toFloat(a);
	if (_v0.$ === 1) {
		return $elm$core$Result$Err('Invalid amount: ' + a);
	} else {
		var _float = _v0.a;
		return (_float === 0.0) ? $elm$core$Result$Err('Amount cannot be zero') : $elm$core$Result$Ok(
			$elm$core$Basics$round(_float * 100));
	}
};
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm_community$list_extra$List$Extra$uncons = function (list) {
	if (!list.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var first = list.a;
		var rest = list.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(first, rest));
	}
};
var $author$project$Page$Edit$updateMissingBalance = F2(
	function (editPos, model) {
		var allEntries = _Utils_ap(
			_List_fromArray(
				[model.a.l, model.a.m]),
			model.a.fA);
		var entriesToUse = function () {
			var _v3 = model.aq;
			if (_v3.$ === 1) {
				return allEntries;
			} else {
				var pos = _v3.a;
				return A2($elm_community$list_extra$List$Extra$removeAt, pos, allEntries);
			}
		}();
		var byCurrency = A2(
			$author$project$Misc$groupingBy,
			function ($) {
				return $.fn;
			},
			entriesToUse);
		var missingBalance = function () {
			var _v0 = $elm_community$list_extra$List$Extra$uncons(byCurrency);
			if ((!_v0.$) && (!_v0.a.b.b)) {
				var _v1 = _v0.a;
				var _v2 = _v1.a;
				var entries = _v2.b;
				var rawAmounts = A2(
					$elm$core$List$map,
					function ($) {
						return $.e9;
					},
					entries);
				var goodAmounts = A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					A2(
						$elm$core$List$map,
						$elm$core$Result$toMaybe,
						A2($elm$core$List$map, $author$project$Page$Edit$isAmountValid, rawAmounts)));
				var total = $elm$core$List$sum(goodAmounts);
				return (!total) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just((-1) * total);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		return A2(
			$author$project$Page$Edit$insertMissingBalanace,
			editPos,
			_Utils_update(
				model,
				{a6: missingBalance}));
	});
var $author$project$Page$Edit$updateSourceAccount = F2(
	function (text, input) {
		var de = input.m;
		var sourceEntry = _Utils_update(
			de,
			{e6: text});
		return _Utils_update(
			input,
			{m: sourceEntry});
	});
var $author$project$Page$Edit$updateSourceAmount = F2(
	function (text, input) {
		var de = input.m;
		var sourceEntry = _Utils_update(
			de,
			{e9: text});
		return _Utils_update(
			input,
			{m: sourceEntry});
	});
var $author$project$Page$Edit$updateSourceCurrency = F2(
	function (text, input) {
		var de = input.m;
		var sourceEntry = _Utils_update(
			de,
			{fn: text});
		return _Utils_update(
			input,
			{m: sourceEntry});
	});
var $author$project$Page$Edit$updateSourceDate = F2(
	function (text, input) {
		var de = input.m;
		var sourceEntry = _Utils_update(
			de,
			{dH: text});
		return _Utils_update(
			input,
			{m: sourceEntry});
	});
var $author$project$Page$Edit$updateSourceDescription = F2(
	function (text, input) {
		var de = input.m;
		var sourceEntry = _Utils_update(
			de,
			{dK: text});
		return _Utils_update(
			input,
			{m: sourceEntry});
	});
var $author$project$Page$Edit$FullEditValidation = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Edit$SimpleEditValidation = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $author$project$Transactions$CurrencyConversionInformation = F6(
	function (currencyA, priceOfCurrencyB, currencyB, priceOfCurrencyA, totalCurrencyA, totalCurrencyB) {
		return {fo: currencyA, fp: currencyB, gj: priceOfCurrencyA, gk: priceOfCurrencyB, gF: totalCurrencyA, gG: totalCurrencyB};
	});
var $author$project$Transactions$EntriesAllSameSign = function (a) {
	return {$: 1, a: a};
};
var $author$project$Transactions$EntriesAreEmpty = {$: 3};
var $author$project$Transactions$EntriesHaveTooManyCurrencies = {$: 2};
var $author$project$Transactions$EntriesNotBalanced = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Transactions$sumEntriesAmount = function (entries) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.e9;
			},
			entries));
};
var $author$project$Transactions$balanceEntries = function (entries) {
	var _v0 = A2(
		$author$project$Misc$groupingBy,
		function ($) {
			return $.fn;
		},
		entries);
	if (_v0.b) {
		var head = _v0.a;
		var tail = _v0.b;
		var _v1 = $elm$core$List$length(tail);
		switch (_v1) {
			case 0:
				var _v2 = head;
				var currencyA = _v2.a;
				var entriesA = _v2.b;
				var total = $author$project$Transactions$sumEntriesAmount(entriesA);
				return (!total) ? $elm$core$Result$Ok(
					_Utils_Tuple2(entries, $elm$core$Maybe$Nothing)) : $elm$core$Result$Err(
					A2($author$project$Transactions$EntriesNotBalanced, currencyA, (-1) * total));
			case 1:
				var _v3 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2('', _List_Nil),
					$elm$core$List$head(tail));
				var currencyB = _v3.a;
				var entriesB = _v3.b;
				var totalCurrencyB = $author$project$Transactions$sumEntriesAmount(entriesB);
				var _v4 = head;
				var currencyA = _v4.a;
				var entriesA = _v4.b;
				var totalCurrencyA = $author$project$Transactions$sumEntriesAmount(entriesA);
				if (((totalCurrencyA < 0) && (totalCurrencyB > 0)) || ((totalCurrencyA > 0) && (totalCurrencyB < 0))) {
					var conversionInfo = A6(
						$author$project$Transactions$CurrencyConversionInformation,
						currencyA,
						$elm$core$Basics$abs(totalCurrencyA / totalCurrencyB),
						currencyB,
						$elm$core$Basics$abs(totalCurrencyB / totalCurrencyA),
						$elm$core$Basics$abs(totalCurrencyA),
						$elm$core$Basics$abs(totalCurrencyB));
					return $elm$core$Result$Ok(
						_Utils_Tuple2(
							entries,
							$elm$core$Maybe$Just(conversionInfo)));
				} else {
					return $elm$core$Result$Err(
						$author$project$Transactions$EntriesAllSameSign(
							_Utils_Tuple2(
								_Utils_Tuple2(currencyA, totalCurrencyA),
								_Utils_Tuple2(currencyB, totalCurrencyB))));
				}
			default:
				return $elm$core$Result$Err($author$project$Transactions$EntriesHaveTooManyCurrencies);
		}
	} else {
		return $elm$core$Result$Err($author$project$Transactions$EntriesAreEmpty);
	}
};
var $elm$core$String$trim = _String_trim;
var $author$project$Misc$isFieldNotBlank = F2(
	function (name, value) {
		var trimmed = $elm$core$String$trim(value);
		return $elm$core$String$isEmpty(trimmed) ? $elm$core$Result$Err(name + ' cannot be blank') : $elm$core$Result$Ok(trimmed);
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Page$Edit$buildEntryResult = F2(
	function (entryName, entryInput) {
		var res = {
			e6: A2($author$project$Misc$isFieldNotBlank, entryName + ' account', entryInput.e6),
			e9: A2(
				$elm$core$Result$mapError,
				function (e) {
					return entryName + (' ' + e);
				},
				$author$project$Page$Edit$isAmountValid(entryInput.e9)),
			fn: A2($author$project$Misc$isFieldNotBlank, entryName + ' currency', entryInput.fn),
			dH: function () {
				var _v0 = $elm$core$String$trim(entryInput.dH);
				if (_v0 === '') {
					return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
				} else {
					var d = _v0;
					return A2(
						$elm$core$Result$mapError,
						function (e) {
							return entryName + (' ' + e);
						},
						A2(
							$elm$core$Result$map,
							$elm$core$Maybe$Just,
							$justinmimbs$date$Date$fromIsoString(d)));
				}
			}(),
			dK: function () {
				var _v1 = $elm$core$String$trim(entryInput.dK);
				if (_v1 === '') {
					return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
				} else {
					var desc = _v1;
					return $elm$core$Result$Ok(
						$elm$core$Maybe$Just(desc));
				}
			}()
		};
		return res;
	});
var $author$project$Misc$dropError = function (res) {
	return A2(
		$elm$core$Result$mapError,
		function (_v0) {
			return '';
		},
		res);
};
var $elm$core$Result$map3 = F4(
	function (func, ra, rb, rc) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				if (rc.$ === 1) {
					var x = rc.a;
					return $elm$core$Result$Err(x);
				} else {
					var c = rc.a;
					return $elm$core$Result$Ok(
						A3(func, a, b, c));
				}
			}
		}
	});
var $elm$core$Result$map5 = F6(
	function (func, ra, rb, rc, rd, re) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				if (rc.$ === 1) {
					var x = rc.a;
					return $elm$core$Result$Err(x);
				} else {
					var c = rc.a;
					if (rd.$ === 1) {
						var x = rd.a;
						return $elm$core$Result$Err(x);
					} else {
						var d = rd.a;
						if (re.$ === 1) {
							var x = re.a;
							return $elm$core$Result$Err(x);
						} else {
							var e = re.a;
							return $elm$core$Result$Ok(
								A5(func, a, b, c, d, e));
						}
					}
				}
			}
		}
	});
var $author$project$Misc$dropSuccess = function (res) {
	return A2(
		$elm$core$Result$map,
		function (_v0) {
			return '';
		},
		res);
};
var $author$project$Misc$isError = function (res) {
	if (res.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$Edit$getFirstEntryResultError = function (result) {
	var results = _List_fromArray(
		[
			result.e6,
			$author$project$Misc$dropSuccess(result.e9),
			result.fn,
			$author$project$Misc$dropSuccess(result.dK),
			$author$project$Misc$dropSuccess(result.dH)
		]);
	var _v0 = $elm$core$List$head(
		A2($elm$core$List$filter, $author$project$Misc$isError, results));
	if ((!_v0.$) && (_v0.a.$ === 1)) {
		var message = _v0.a.a;
		return $elm$core$Maybe$Just(message);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Edit$pivotEntryResultList = function (results) {
	var ok = A2($elm$core$List$filterMap, $elm$core$Result$toMaybe, results);
	var firstErrors = A2(
		$elm$core$List$filterMap,
		function (res) {
			if (!res.$) {
				return $elm$core$Maybe$Nothing;
			} else {
				var err = res.a;
				return $author$project$Page$Edit$getFirstEntryResultError(err);
			}
		},
		results);
	var firstError = A2(
		$elm$core$Maybe$withDefault,
		'Error',
		$elm$core$List$head(firstErrors));
	return _Utils_eq(
		$elm$core$List$length(ok),
		$elm$core$List$length(results)) ? $elm$core$Result$Ok(ok) : $elm$core$Result$Err(firstError);
};
var $cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {ah: decimals, d7: integers, er: original, cm: prefix, cz: suffix};
	});
var $cuducos$elm_format_number$FormatNumber$Parser$Negative = 2;
var $cuducos$elm_format_number$FormatNumber$Parser$Positive = 0;
var $cuducos$elm_format_number$FormatNumber$Parser$Zero = 1;
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $cuducos$elm_format_number$FormatNumber$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.d7,
				$elm$core$List$singleton(formatted.ah))));
	return onlyZeros ? 1 : ((formatted.er < 0) ? 2 : 0);
};
var $elm$core$String$filter = _String_filter;
var $cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit = F2(
	function (desiredLength, value) {
		var length = $elm$core$String$length(value);
		var missing = (_Utils_cmp(length, desiredLength) < 0) ? $elm$core$Basics$abs(desiredLength - length) : 0;
		return _Utils_ap(
			value,
			A2($elm$core$String$repeat, missing, '0'));
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$FormatNumber$Parser$getDecimals = F2(
	function (locale, digits) {
		var _v0 = locale.ah;
		switch (_v0.$) {
			case 1:
				return $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(digits);
			case 2:
				return digits;
			default:
				var min = _v0.a;
				return A2($cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit, min, digits);
		}
	});
var $elm$core$Basics$ge = _Utils_ge;
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitInParts = F2(
	function (locale, value) {
		var toString = function () {
			var _v1 = locale.ah;
			switch (_v1.$) {
				case 1:
					var max = _v1.a;
					return $myrho$elm_round$Round$round(max);
				case 0:
					return $elm$core$String$fromFloat;
				default:
					var exact = _v1.a;
					return $myrho$elm_round$Round$round(exact);
			}
		}();
		var asList = A2(
			$elm$core$String$split,
			'.',
			toString(value));
		var decimals = function () {
			var _v0 = $elm$core$List$tail(asList);
			if (!_v0.$) {
				var values = _v0.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(values));
			} else {
				return '';
			}
		}();
		var integers = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(asList));
		return _Utils_Tuple2(integers, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$splitByIndian = function (integers) {
	var thousand = ($elm$core$String$length(integers) > 3) ? A2($elm$core$String$right, 3, integers) : integers;
	var reversedSplitHundreds = function (value) {
		return ($elm$core$String$length(value) > 2) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 2, value),
			reversedSplitHundreds(
				A2($elm$core$String$dropRight, 2, value))) : ((!$elm$core$String$length(value)) ? _List_Nil : _List_fromArray(
			[value]));
	};
	return $elm$core$List$reverse(
		A2(
			$elm$core$List$cons,
			thousand,
			reversedSplitHundreds(
				A2($elm$core$String$dropRight, 3, integers))));
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitByWestern = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitIntegers = F2(
	function (system, integers) {
		if (!system) {
			return $cuducos$elm_format_number$FormatNumber$Parser$splitByWestern(
				A2($elm$core$String$filter, $elm$core$Char$isDigit, integers));
		} else {
			return $cuducos$elm_format_number$FormatNumber$Parser$splitByIndian(
				A2($elm$core$String$filter, $elm$core$Char$isDigit, integers));
		}
	});
var $cuducos$elm_format_number$FormatNumber$Parser$parse = F2(
	function (locale, original) {
		var parts = A2($cuducos$elm_format_number$FormatNumber$Parser$splitInParts, locale, original);
		var integers = A2(
			$cuducos$elm_format_number$FormatNumber$Parser$splitIntegers,
			locale.cA,
			A2($elm$core$String$filter, $elm$core$Char$isDigit, parts.a));
		var decimals = A2($cuducos$elm_format_number$FormatNumber$Parser$getDecimals, locale, parts.b);
		var partial = A5($cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$FormatNumber$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{cm: locale.f2, cz: locale.c6});
			case 0:
				return _Utils_update(
					partial,
					{cm: locale.ev, cz: locale.ew});
			default:
				return _Utils_update(
					partial,
					{cm: locale.e4, cz: locale.e5});
		}
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.au, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$stringfy = F2(
	function (locale, formatted) {
		var stringfyDecimals = $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals(locale);
		var integers = A2($elm$core$String$join, locale.gB, formatted.d7);
		var decimals = stringfyDecimals(formatted.ah);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.cm, integers, decimals, formatted.cz]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A2(
			$cuducos$elm_format_number$FormatNumber$Stringfy$stringfy,
			locale,
			A2($cuducos$elm_format_number$FormatNumber$Parser$parse, locale, number_));
	});
var $cuducos$elm_format_number$FormatNumber$Locales$Exact = function (a) {
	return {$: 2, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$Min = function (a) {
	return {$: 0, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$Western = 0;
var $cuducos$elm_format_number$FormatNumber$Locales$base = {
	au: '.',
	ah: $cuducos$elm_format_number$FormatNumber$Locales$Min(0),
	f2: '−',
	c6: '',
	ev: '',
	ew: '',
	cA: 0,
	gB: '',
	e4: '',
	e5: ''
};
var $cuducos$elm_format_number$FormatNumber$Locales$usLocale = _Utils_update(
	$cuducos$elm_format_number$FormatNumber$Locales$base,
	{
		ah: $cuducos$elm_format_number$FormatNumber$Locales$Exact(2),
		gB: ','
	});
var $author$project$Transactions$formatMinor = function (amount) {
	return A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, amount / 100.0);
};
var $author$project$Transactions$unbalancedEntriesToString = function (error) {
	switch (error.$) {
		case 0:
			var currency = error.a;
			var minor = error.b;
			return 'Entries are not balanced by ' + (currency + (' ' + $author$project$Transactions$formatMinor(minor)));
		case 1:
			var _v1 = error.a;
			var _v2 = _v1.a;
			var currencyA = _v2.a;
			var amountA = _v2.b;
			var _v3 = _v1.b;
			var currencyB = _v3.a;
			var amountB = _v3.b;
			return 'We have two currencies but one must be positive, and another negative (' + (currencyA + (' ' + ($author$project$Transactions$formatMinor(amountA) + (', ' + (currencyB + (' ' + ($author$project$Transactions$formatMinor(amountB) + ')')))))));
		case 2:
			return 'We can only handle upto two currencies';
		default:
			return 'No entries';
	}
};
var $author$project$Page$Edit$validateEntryResult = function (res) {
	return A2(
		$elm$core$Result$mapError,
		function (_v0) {
			return res;
		},
		A6($elm$core$Result$map5, $author$project$Transactions$Entry, res.e6, res.fn, res.e9, res.dK, res.dH));
};
var $author$project$Page$Edit$validateTransaction = F6(
	function (model, date, description, destination, source, entries) {
		var mappedEntries = $elm$core$List$isEmpty(entries) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(entries);
		var i = model.a;
		var notes = function () {
			var _v0 = $elm$core$String$trim(i.ga);
			if (_v0 === '') {
				return $elm$core$Maybe$Nothing;
			} else {
				var s = _v0;
				return $elm$core$Maybe$Just(s);
			}
		}();
		var accounts = $elm_community$list_extra$List$Extra$unique(
			_Utils_ap(
				_List_fromArray(
					[destination.e6, source.e6]),
				A2(
					$elm$core$List$map,
					function ($) {
						return $.e6;
					},
					entries)));
		return $author$project$Transactions$Transaction(i.aj)(i.af)(i.aP)(date)(description)(destination)(source)(accounts)(notes)(mappedEntries);
	});
var $author$project$Page$Edit$validateFormFull = function (model) {
	var input = model.a;
	var source = $author$project$Page$Edit$validateEntryResult(
		A2($author$project$Page$Edit$buildEntryResult, 'Source', input.m));
	var entries = A2(
		$elm$core$List$map,
		$author$project$Page$Edit$validateEntryResult,
		A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, e) {
					return A2(
						$author$project$Page$Edit$buildEntryResult,
						'Entry ' + $elm$core$String$fromInt(i + 3),
						e);
				}),
			input.fA));
	var destination = $author$project$Page$Edit$validateEntryResult(
		A2($author$project$Page$Edit$buildEntryResult, 'Destination', input.l));
	var allEntries = A4(
		$elm$core$Result$map3,
		F3(
			function (s, d, others) {
				return A2(
					$elm$core$List$cons,
					s,
					A2($elm$core$List$cons, d, others));
			}),
		A2(
			$elm$core$Result$mapError,
			function (_v4) {
				return 'Source error';
			},
			source),
		A2(
			$elm$core$Result$mapError,
			function (_v5) {
				return 'Destination error';
			},
			destination),
		$author$project$Page$Edit$pivotEntryResultList(entries));
	var allEntriesResult = A2(
		$elm$core$Result$map,
		function (_v3) {
			return 'Ok';
		},
		A2(
			$elm$core$Result$andThen,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Transactions$balanceEntries,
				$elm$core$Result$mapError($author$project$Transactions$unbalancedEntriesToString)),
			allEntries));
	var results = {
		dH: $justinmimbs$date$Date$fromIsoString(input.dH),
		dK: A2($author$project$Misc$isFieldNotBlank, 'Description', input.dK),
		fu: destination,
		fA: entries,
		b3: allEntriesResult,
		gv: source
	};
	return A2(
		$elm$core$Result$andThen,
		function (_v1) {
			return A2(
				$elm$core$Result$mapError,
				function (_v2) {
					return results;
				},
				A6(
					$elm$core$Result$map5,
					$author$project$Page$Edit$validateTransaction(model),
					results.dH,
					results.dK,
					$author$project$Misc$dropError(results.fu),
					$author$project$Misc$dropError(results.gv),
					$author$project$Page$Edit$pivotEntryResultList(results.fA)));
		},
		A2(
			$elm$core$Result$mapError,
			function (_v0) {
				return results;
			},
			results.b3));
};
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $author$project$Page$Edit$validateFormSimple = function (input) {
	var sourceDescription = A2(
		$elm$core$Maybe$andThen,
		function ($) {
			return $.dK;
		},
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.gv;
			},
			input.ai));
	var sourceDate = A2(
		$elm$core$Maybe$andThen,
		function ($) {
			return $.dH;
		},
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.gv;
			},
			input.ai));
	var results = {
		e9: $author$project$Page$Edit$isAmountValid(input.e9),
		fn: A2($author$project$Misc$isFieldNotBlank, 'Currency', input.fn),
		dH: $justinmimbs$date$Date$fromIsoString(input.dH),
		dK: A2($author$project$Misc$isFieldNotBlank, 'Description', input.dK),
		fu: A2($author$project$Misc$isFieldNotBlank, 'Destination', input.fu),
		gv: A2($author$project$Misc$isFieldNotBlank, 'Source', input.gv)
	};
	var source = A2(
		$elm$core$Result$map,
		function (f) {
			return A2(f, sourceDescription, sourceDate);
		},
		A4(
			$elm$core$Result$map3,
			$author$project$Transactions$Entry,
			results.gv,
			results.fn,
			A2(
				$elm$core$Result$map,
				function (amnt) {
					return amnt * (-1);
				},
				results.e9)));
	var destinationDescription = A2(
		$elm$core$Maybe$andThen,
		function ($) {
			return $.dK;
		},
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.fu;
			},
			input.ai));
	var destinationDate = A2(
		$elm$core$Maybe$andThen,
		function ($) {
			return $.dH;
		},
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.fu;
			},
			input.ai));
	var destination = A2(
		$elm$core$Result$map,
		function (f) {
			return A2(f, destinationDescription, destinationDate);
		},
		A4($elm$core$Result$map3, $author$project$Transactions$Entry, results.fu, results.fn, results.e9));
	var partialTxn = A6(
		$elm$core$Result$map5,
		A2($author$project$Transactions$Transaction, input.aj, input.af),
		$elm$core$Result$Ok(input.aP),
		results.dH,
		results.dK,
		destination,
		source);
	var accounts = A3(
		$elm$core$Result$map2,
		F2(
			function (dest, src) {
				return _List_fromArray(
					[dest, src]);
			}),
		A2(
			$elm$core$Result$map,
			function ($) {
				return $.e6;
			},
			destination),
		A2(
			$elm$core$Result$map,
			function ($) {
				return $.e6;
			},
			source));
	var transaction = A3(
		$elm$core$Result$map2,
		F2(
			function (f, acnts) {
				return A3(
					f,
					acnts,
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.ga;
						},
						input.ai),
					$elm$core$Maybe$Nothing);
			}),
		partialTxn,
		accounts);
	return A2(
		$elm$core$Result$mapError,
		function (_v0) {
			return results;
		},
		transaction);
};
var $author$project$Page$Edit$validateForm = function (model) {
	var _v0 = model.V;
	if (_v0 === 2) {
		return A2(
			$elm$core$Result$mapError,
			$author$project$Page$Edit$FullEditValidation,
			$author$project$Page$Edit$validateFormFull(model));
	} else {
		return A2(
			$elm$core$Result$mapError,
			$author$project$Page$Edit$SimpleEditValidation,
			$author$project$Page$Edit$validateFormSimple(model.a));
	}
};
var $author$project$Page$Edit$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var shared = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dr: $elm$core$List$sort(
								$elm$core$Dict$keys(shared.dr)),
							bo: shared.dS
						}),
					$author$project$Effect$none);
			case 2:
				if (!msg.a.$) {
					var transaction = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								V: $author$project$Page$Edit$chooseEditMode(transaction),
								br: $elm$core$Maybe$Just(transaction),
								a: A2(
									$author$project$Page$Edit$formInput,
									model.bL,
									$elm$core$Maybe$Just(transaction))
							}),
						$author$project$Effect$none);
				} else {
					var decodeError = msg.a.a;
					return _Utils_Tuple2(model, $author$project$Effect$none);
				}
			case 3:
				if (!msg.a.$) {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $author$project$Effect$none);
				} else {
					var decodeError = msg.a.a;
					return _Utils_Tuple2(model, $author$project$Effect$none);
				}
			case 0:
				var currentDate = msg.a;
				var f = model.a;
				var date = (model.a.dH === '') ? $justinmimbs$date$Date$toIsoString(currentDate) : model.a.dH;
				var input = _Utils_update(
					f,
					{dH: date});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cM: currentDate, a: input}),
					$author$project$Effect$none);
			case 4:
				var date = msg.a;
				var f = model.a;
				var input = _Utils_update(
					f,
					{dH: date});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 5:
				var description = msg.a;
				var maybeDstSrc = A2($elm$core$Dict$get, description, model.bo);
				var f = model.a;
				var source = A2(
					$elm$core$Maybe$withDefault,
					f.gv,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.gv;
						},
						maybeDstSrc));
				var extraSources = (!_Utils_eq(source, f.gv)) ? A2($elm$core$List$cons, source, f.ay) : f.ay;
				var destination = A2(
					$elm$core$Maybe$withDefault,
					f.fu,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.fu;
						},
						maybeDstSrc));
				var extraDestinations = (!_Utils_eq(destination, f.fu)) ? A2($elm$core$List$cons, destination, f.ax) : f.ax;
				var newInput = A2(
					$author$project$Page$Edit$updateSourceAccount,
					source,
					A2($author$project$Page$Edit$updateDestinationAccount, destination, f));
				var input = _Utils_update(
					newInput,
					{dK: description, fu: destination, ax: extraDestinations, ay: extraSources, gv: source});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 6:
				var destination = msg.a;
				var f = model.a;
				var input = _Utils_update(
					f,
					{fu: destination});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 7:
				var source = msg.a;
				var f = model.a;
				var input = _Utils_update(
					f,
					{gv: source});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 8:
				var amount = msg.a;
				var f = model.a;
				var input = _Utils_update(
					f,
					{e9: amount});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 9:
				var currency = msg.a;
				var f = model.a;
				var input = _Utils_update(
					f,
					{fn: currency});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 25:
				var notes = msg.a;
				var f = model.a;
				var input = _Utils_update(
					f,
					{ga: notes});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 10:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a: A2($author$project$Page$Edit$updateDestinationAccount, text, model.a)
						}),
					$author$project$Effect$none);
			case 11:
				var text = msg.a;
				var newModel = A2(
					$author$project$Page$Edit$updateMissingBalance,
					0,
					A2(
						$author$project$Page$Edit$resetBalanaceSuggestions,
						0,
						_Utils_update(
							model,
							{
								a: A2($author$project$Page$Edit$updateDestinationAmount, text, model.a)
							})));
				return _Utils_Tuple2(newModel, $author$project$Effect$none);
			case 12:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a: A2($author$project$Page$Edit$updateDestinationCurrency, text, model.a)
						}),
					$author$project$Effect$none);
			case 13:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a: A2($author$project$Page$Edit$updateDestinationDescription, text, model.a)
						}),
					$author$project$Effect$none);
			case 14:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a: A2($author$project$Page$Edit$updateDestinationDate, text, model.a)
						}),
					$author$project$Effect$none);
			case 15:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a: A2($author$project$Page$Edit$updateSourceAccount, text, model.a)
						}),
					$author$project$Effect$none);
			case 16:
				var text = msg.a;
				var newModel = A2(
					$author$project$Page$Edit$updateMissingBalance,
					1,
					A2(
						$author$project$Page$Edit$resetBalanaceSuggestions,
						1,
						_Utils_update(
							model,
							{
								a: A2($author$project$Page$Edit$updateSourceAmount, text, model.a)
							})));
				return _Utils_Tuple2(newModel, $author$project$Effect$none);
			case 17:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a: A2($author$project$Page$Edit$updateSourceCurrency, text, model.a)
						}),
					$author$project$Effect$none);
			case 18:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a: A2($author$project$Page$Edit$updateSourceDescription, text, model.a)
						}),
					$author$project$Effect$none);
			case 19:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a: A2($author$project$Page$Edit$updateSourceDate, text, model.a)
						}),
					$author$project$Effect$none);
			case 20:
				var pos = msg.a;
				var text = msg.b;
				var i = model.a;
				var entries = function () {
					var _v1 = A2($elm_community$list_extra$List$Extra$getAt, pos, model.a.fA);
					if (!_v1.$) {
						var e = _v1.a;
						var entry = _Utils_update(
							e,
							{e6: text});
						return A3($elm_community$list_extra$List$Extra$setAt, pos, entry, model.a.fA);
					} else {
						return model.a.fA;
					}
				}();
				var input = _Utils_update(
					i,
					{fA: entries});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 21:
				var pos = msg.a;
				var text = msg.b;
				var i = model.a;
				var entries = function () {
					var _v2 = A2($elm_community$list_extra$List$Extra$getAt, pos, model.a.fA);
					if (!_v2.$) {
						var e = _v2.a;
						var entry = _Utils_update(
							e,
							{e9: text});
						return A3($elm_community$list_extra$List$Extra$setAt, pos, entry, model.a.fA);
					} else {
						return model.a.fA;
					}
				}();
				var input = _Utils_update(
					i,
					{fA: entries});
				var newModel = A2(
					$author$project$Page$Edit$updateMissingBalance,
					pos,
					_Utils_update(
						model,
						{a: input}));
				return _Utils_Tuple2(newModel, $author$project$Effect$none);
			case 22:
				var pos = msg.a;
				var text = msg.b;
				var i = model.a;
				var entries = function () {
					var _v3 = A2($elm_community$list_extra$List$Extra$getAt, pos, model.a.fA);
					if (!_v3.$) {
						var e = _v3.a;
						var entry = _Utils_update(
							e,
							{fn: text});
						return A3($elm_community$list_extra$List$Extra$setAt, pos, entry, model.a.fA);
					} else {
						return model.a.fA;
					}
				}();
				var input = _Utils_update(
					i,
					{fA: entries});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 23:
				var pos = msg.a;
				var text = msg.b;
				var i = model.a;
				var entries = function () {
					var _v4 = A2($elm_community$list_extra$List$Extra$getAt, pos, model.a.fA);
					if (!_v4.$) {
						var e = _v4.a;
						var entry = _Utils_update(
							e,
							{dK: text});
						return A3($elm_community$list_extra$List$Extra$setAt, pos, entry, model.a.fA);
					} else {
						return model.a.fA;
					}
				}();
				var input = _Utils_update(
					i,
					{fA: entries});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 24:
				var pos = msg.a;
				var text = msg.b;
				var i = model.a;
				var entries = function () {
					var _v5 = A2($elm_community$list_extra$List$Extra$getAt, pos, model.a.fA);
					if (!_v5.$) {
						var e = _v5.a;
						var entry = _Utils_update(
							e,
							{dH: text});
						return A3($elm_community$list_extra$List$Extra$setAt, pos, entry, model.a.fA);
					} else {
						return model.a.fA;
					}
				}();
				var input = _Utils_update(
					i,
					{fA: entries});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 35:
				var f = model.a;
				var input = _Utils_update(
					f,
					{
						fA: _Utils_ap(
							f.fA,
							_List_fromArray(
								[$author$project$Page$Edit$emptyEntryInput]))
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 36:
				var pos = msg.a;
				var f = model.a;
				var input = _Utils_update(
					f,
					{
						fA: A2($elm_community$list_extra$List$Extra$removeAt, pos, f.fA)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: input}),
					$author$project$Effect$none);
			case 37:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a4: $elm$core$Maybe$Nothing, a6: $elm$core$Maybe$Nothing, aq: $elm$core$Maybe$Nothing}),
					$author$project$Effect$none);
			case 26:
				var isValid = $author$project$Page$Edit$validateForm(model);
				var _v6 = function () {
					if (!isValid.$) {
						var transaction = isValid.a;
						return _Utils_Tuple3(
							$elm$core$Maybe$Nothing,
							$author$project$Effect$fromCmd(
								$author$project$Page$Edit$saveTransaction(
									$author$project$Transactions$transactionToJson(transaction))),
							true);
					} else {
						var e = isValid.a;
						return _Utils_Tuple3(
							$elm$core$Maybe$Just(e),
							$author$project$Effect$none,
							false);
					}
				}();
				var results = _v6.a;
				var cmd = _v6.b;
				var saving = _v6.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: results, T: saving}),
					cmd);
			case 27:
				if (msg.a.$ === 1) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{T: false}),
						$author$project$Effect$none);
				} else {
					var txn = msg.a.a;
					var action = model.bL.gy ? $elm$core$Maybe$Just(0) : $elm$core$Maybe$Nothing;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{T: false}),
						$author$project$Effect$batch(
							_List_fromArray(
								[
									$author$project$Effect$fromShared(
									$author$project$Shared$AddTransaction(txn)),
									$author$project$Effect$fromCmd(
									A2($author$project$Route$goToTransactions, action, model.c0))
								])));
				}
			case 28:
				if (msg.a.$ === 1) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{T: false}),
						$author$project$Effect$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{T: false}),
						$author$project$Effect$none);
				}
			case 34:
				var editMode = msg.a;
				var i = model.a;
				var defaults = $author$project$Page$Edit$defaultFormInput(model.bL);
				var _v8 = function () {
					var _v9 = model.br;
					if (_v9.$ === 1) {
						return _Utils_Tuple3(defaults.l, defaults.m, _List_Nil);
					} else {
						var txn = _v9.a;
						return _Utils_Tuple3(
							$author$project$Page$Edit$buildEntryInput(txn.fu),
							$author$project$Page$Edit$buildEntryInput(txn.gv),
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2(
									$elm$core$Maybe$map,
									$elm$core$List$map($author$project$Page$Edit$buildEntryInput),
									txn.fA)));
					}
				}();
				var destinationEntry = _v8.a;
				var sourceEntry = _v8.b;
				var entries = _v8.c;
				var input = _Utils_update(
					i,
					{l: destinationEntry, fA: entries, m: sourceEntry});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{V: editMode, a: input}),
					$author$project$Effect$none);
			case 29:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromCmd(
						$author$project$Page$Edit$showDeleteModal(0)));
			case 30:
				return _Utils_Tuple2(model, $author$project$Effect$none);
			case 31:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromCmd(
						$author$project$Page$Edit$deleteTransaction(
							_Utils_Tuple2(model.a.aj, model.a.af))));
			case 32:
				if (msg.a.$ === 1) {
					var decodeError = msg.a.a;
					return _Utils_Tuple2(model, $author$project$Effect$none);
				} else {
					var id = msg.a.a;
					return _Utils_Tuple2(
						model,
						$author$project$Effect$batch(
							_List_fromArray(
								[
									$author$project$Effect$fromShared(
									$author$project$Shared$DeleteTransaction(id)),
									$author$project$Effect$fromCmd(
									A2(
										$author$project$Route$goToTransactions,
										$elm$core$Maybe$Just(0),
										model.c0))
								])));
				}
			case 33:
				return _Utils_Tuple2(model, $author$project$Effect$none);
			default:
				return _Utils_Tuple2(model, $author$project$Effect$none);
		}
	});
var $justinmimbs$date$Date$Days = 3;
var $author$project$Page$ExpensesBarChart$PresetDate = F3(
	function (name, from, to) {
		return {fJ: from, a7: name, gC: to};
	});
var $justinmimbs$date$Date$Year = 0;
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.e3 - 1)) + ($justinmimbs$date$Date$monthToNumber(date.c4) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.dI,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Weeks = 2;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $author$project$Page$ExpensesBarChart$buildPresetDates = function (date) {
	var yearName = function (d) {
		return A2($justinmimbs$date$Date$format, 'yyyy', d);
	};
	var startThisYear = A2($justinmimbs$date$Date$floor, 0, date);
	var startPreviousYear = A2(
		$justinmimbs$date$Date$floor,
		0,
		A3($justinmimbs$date$Date$add, 0, -1, startThisYear));
	var preset = F2(
		function (from, to) {
			return A3(
				$author$project$Page$ExpensesBarChart$PresetDate,
				yearName(from),
				from,
				to);
		});
	var minusDay = function (d) {
		return A3($justinmimbs$date$Date$add, 3, -1, d);
	};
	var endThisYear = minusDay(
		A2($justinmimbs$date$Date$ceiling, 0, date));
	var thisYear = A2(preset, startThisYear, endThisYear);
	var endPreviousYear = minusDay(
		A2(
			$justinmimbs$date$Date$ceiling,
			0,
			A3($justinmimbs$date$Date$add, 0, -1, endThisYear)));
	var previousYear = A2(preset, startPreviousYear, endPreviousYear);
	return _Utils_Tuple2(
		_List_fromArray(
			[previousYear]),
		thisYear);
};
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Page$ExpensesBarChart$groupedOptions = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('(max)', $elm$core$Maybe$Nothing),
			_Utils_Tuple2(
			'2',
			$elm$core$Maybe$Just(2)),
			_Utils_Tuple2(
			'3',
			$elm$core$Maybe$Just(3)),
			_Utils_Tuple2(
			'4',
			$elm$core$Maybe$Just(4))
		]));
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Page$ExpensesBarChart$renderBarVisualization = _Platform_outgoingPort(
	'renderBarVisualization',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string($.fn)),
					_Utils_Tuple2(
					'data',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'expenses',
										$elm$json$Json$Encode$list(
											function ($) {
												var a = $.a;
												var b = $.b;
												return A2(
													$elm$json$Json$Encode$list,
													$elm$core$Basics$identity,
													_List_fromArray(
														[
															$elm$json$Json$Encode$string(a),
															$elm$json$Json$Encode$int(b)
														]));
											})($.cU)),
										_Utils_Tuple2(
										'month',
										$elm$json$Json$Encode$string($.c4)),
										_Utils_Tuple2(
										'otherDetails',
										$elm$json$Json$Encode$list(
											function ($) {
												var a = $.a;
												var b = $.b;
												return A2(
													$elm$json$Json$Encode$list,
													$elm$core$Basics$identity,
													_List_fromArray(
														[
															$elm$json$Json$Encode$string(a),
															$elm$json$Json$Encode$int(b)
														]));
											})($.db))
									]));
						})($.b_)),
					_Utils_Tuple2(
					'showLegend',
					$elm$json$Json$Encode$bool($.ct)),
					_Utils_Tuple2(
					'title',
					$elm$json$Json$Encode$string($.D))
				]));
	});
var $author$project$Page$ExpensesBarChart$fmt = function (amount) {
	return A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, amount / 100.0);
};
var $author$project$Page$ExpensesBarChart$updateChartData = function (model) {
	var total = $elm$core$List$sum(
		A2($elm$core$List$map, $elm$core$Tuple$second, model.cu));
	var title = model.aA + (' to ' + (model.aQ + ('\n' + ('Total: ' + (model.fn + (' ' + $author$project$Page$ExpensesBarChart$fmt(total)))))));
	var chartData = {fn: model.fn, b_: model.cj, ct: model.bb, D: title};
	return _Utils_update(
		model,
		{Q: chartData});
};
var $justinmimbs$date$Date$Month = 2;
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Transactions$accountAtDepth = F2(
	function (account, level) {
		var parts = A2($elm$core$String$split, ':', account);
		var accounts = A3(
			$elm$core$List$foldl,
			F2(
				function (part, acc) {
					var _v0 = $elm_community$list_extra$List$Extra$last(acc);
					if (!_v0.$) {
						var last = _v0.a;
						return _Utils_ap(
							acc,
							_List_fromArray(
								[last + (':' + part)]));
					} else {
						return _List_fromArray(
							[part]);
					}
				}),
			_List_Nil,
			parts);
		return A2(
			$elm$core$Maybe$withDefault,
			account,
			A2($elm_community$list_extra$List$Extra$getAt, level - 1, accounts));
	});
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Ledger$getInternal = function (ledger) {
	var internal = ledger;
	return internal;
};
var $justinmimbs$date$Date$isBetween = F3(
	function (_v0, _v1, _v2) {
		var a = _v0;
		var b = _v1;
		var x = _v2;
		return A3($justinmimbs$date$Date$isBetweenInt, a, b, x);
	});
var $author$project$Transactions$isExpenseAccountName = function (account) {
	return (account === 'Expenses') || A2($elm$core$String$startsWith, 'Expenses:', account);
};
var $author$project$Ledger$getExpensesByDate = F3(
	function (ledger, from, to) {
		var isExpenseInDateRange = function (txn) {
			return A2(
				$elm$core$List$any,
				function (entry) {
					var isExpense = $author$project$Transactions$isExpenseAccountName(entry.e6);
					var date = A2($elm$core$Maybe$withDefault, txn.dH, entry.dH);
					var isInRange = A3($justinmimbs$date$Date$isBetween, from, to, date);
					return isInRange && isExpense;
				},
				A2(
					$elm$core$List$cons,
					txn.fu,
					A2(
						$elm$core$List$cons,
						txn.gv,
						A2($elm$core$Maybe$withDefault, _List_Nil, txn.fA))));
		};
		var internal = $author$project$Ledger$getInternal(ledger);
		return A2($elm$core$List$filter, isExpenseInDateRange, internal.B);
	});
var $author$project$Page$ExpensesBarChart$isAccountExcluded = F2(
	function (model, account) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Dict$get, account, model.a1));
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Page$ExpensesBarChart$updateExpenses = function (model) {
	var toTheFirstOfMonth = function (date) {
		return A2($justinmimbs$date$Date$floor, 2, date);
	};
	var monthKey = function (d) {
		return A2($justinmimbs$date$Date$format, 'yyyy-MM', d);
	};
	var isIncluded = A2(
		$elm$core$Basics$composeR,
		$author$project$Page$ExpensesBarChart$isAccountExcluded(model),
		$elm$core$Basics$not);
	var entryToKey = function (entry) {
		var _v12 = model.b5;
		if (_v12.$ === 1) {
			return entry.e6;
		} else {
			var depth = _v12.a;
			return A2($author$project$Transactions$accountAtDepth, entry.e6, depth);
		}
	};
	var allMonths = F2(
		function (start, end) {
			var _v0 = A2($justinmimbs$date$Date$compare, start, end);
			if (_v0 === 2) {
				return _List_Nil;
			} else {
				var head = monthKey(start);
				return A2(
					$elm$core$List$cons,
					head,
					A2(
						allMonths,
						A3($justinmimbs$date$Date$add, 1, 1, start),
						end));
			}
		});
	var monthList = A2(
		allMonths,
		toTheFirstOfMonth(model.fJ),
		toTheFirstOfMonth(model.gC));
	var allEntries = A2(
		$elm$core$List$filter,
		function (e) {
			return _Utils_eq(model.fn, e.fn);
		},
		A2(
			$elm$core$List$filter,
			function (e) {
				return $author$project$Transactions$isExpenseAccountName(e.e6);
			},
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (txn) {
						return A2(
							$elm$core$List$cons,
							txn.gv,
							A2(
								$elm$core$List$cons,
								txn.fu,
								A2($elm$core$Maybe$withDefault, _List_Nil, txn.fA)));
					},
					A3($author$project$Ledger$getExpensesByDate, model.e.fU, model.fJ, model.gC)))));
	var byAccount = A2($author$project$Misc$groupingBy, entryToKey, allEntries);
	var allExpenses = $elm$core$List$reverse(
		A2(
			$elm$core$List$sortBy,
			$elm$core$Tuple$second,
			A2(
				$elm$core$List$map,
				function (_v11) {
					var account = _v11.a;
					var entries = _v11.b;
					var total = A3(
						$elm$core$List$foldl,
						$elm$core$Basics$add,
						0,
						A2(
							$elm$core$List$map,
							function ($) {
								return $.e9;
							},
							A2(
								$elm$core$List$filter,
								function (e) {
									return _Utils_eq(e.fn, model.fn);
								},
								entries)));
					return _Utils_Tuple2(account, total);
				},
				byAccount)));
	var shownExpenses = A2(
		$elm$core$List$filter,
		function (_v10) {
			var account = _v10.a;
			var total = _v10.b;
			return isIncluded(account) && (total > 0);
		},
		allExpenses);
	var shownTotal = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$add,
		0,
		A2($elm$core$List$map, $elm$core$Tuple$second, allExpenses));
	var top9Accounts = A2(
		$elm$core$List$map,
		$elm$core$Tuple$first,
		A2($elm$core$List$take, 9, allExpenses));
	var addDateToEntry = F2(
		function (txn, entry) {
			return _Utils_Tuple2(
				A2($elm$core$Maybe$withDefault, txn.dH, entry.dH),
				entry);
		});
	var allEntriesWithDate = A2(
		$elm$core$List$filter,
		function (_v9) {
			var e = _v9.b;
			return _Utils_eq(model.fn, e.fn);
		},
		A2(
			$elm$core$List$filter,
			function (_v8) {
				var e = _v8.b;
				return $author$project$Transactions$isExpenseAccountName(e.e6);
			},
			A2(
				$elm$core$List$concatMap,
				function (txn) {
					return A2(
						$elm$core$List$map,
						addDateToEntry(txn),
						A2(
							$elm$core$List$cons,
							txn.gv,
							A2(
								$elm$core$List$cons,
								txn.fu,
								A2($elm$core$Maybe$withDefault, _List_Nil, txn.fA))));
				},
				A3($author$project$Ledger$getExpensesByDate, model.e.fU, model.fJ, model.gC))));
	var byDate = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (_v7) {
				var k = _v7.a;
				var es = _v7.b;
				return _Utils_Tuple2(
					k,
					A2($elm$core$List$map, $elm$core$Tuple$second, es));
			},
			A2(
				$author$project$Misc$groupingBy,
				$elm$core$Tuple$first,
				A2(
					$elm$core$List$map,
					function (_v6) {
						var d = _v6.a;
						var e = _v6.b;
						return _Utils_Tuple2(
							monthKey(d),
							e);
					},
					allEntriesWithDate))));
	var byDateAndAccount = A2(
		$elm$core$Dict$map,
		F2(
			function (_v5, es) {
				return A2($author$project$Misc$groupingBy, entryToKey, es);
			}),
		byDate);
	var totalsByDateAndAccount = A2(
		$elm$core$Dict$map,
		F2(
			function (_v2, entriesByAccount) {
				return $elm$core$Dict$fromList(
					A2(
						$elm$core$List$filter,
						function (_v4) {
							var account = _v4.a;
							var total = _v4.b;
							return isIncluded(account) && (total > 0);
						},
						A2(
							$elm$core$List$map,
							function (_v3) {
								var account = _v3.a;
								var entries = _v3.b;
								return _Utils_Tuple2(
									account,
									A3(
										$elm$core$List$foldl,
										$elm$core$Basics$add,
										0,
										A2(
											$elm$core$List$map,
											function ($) {
												return $.e9;
											},
											A2(
												$elm$core$List$filter,
												function (e) {
													return _Utils_eq(e.fn, model.fn);
												},
												entries))));
							},
							entriesByAccount)));
			}),
		byDateAndAccount);
	var monthlyExpenses = A2(
		$elm$core$List$map,
		function (m) {
			var monthData = A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Dict$empty,
				A2($elm$core$Dict$get, m, totalsByDateAndAccount));
			var otherExpenses = A2(
				$elm$core$List$sortBy,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $elm$core$Basics$negate),
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$filter,
						F2(
							function (account, _v1) {
								return !A2($elm$core$List$member, account, top9Accounts);
							}),
						monthData)));
			var otherTotal = $elm$core$List$sum(
				A2($elm$core$List$map, $elm$core$Tuple$second, otherExpenses));
			var top9Expenses = A2(
				$elm$core$List$map,
				function (account) {
					return _Utils_Tuple2(
						account,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							A2($elm$core$Dict$get, account, monthData)));
				},
				top9Accounts);
			var monthExpenses = (otherTotal > 0) ? _Utils_ap(
				top9Expenses,
				_List_fromArray(
					[
						_Utils_Tuple2('Other', otherTotal)
					])) : top9Expenses;
			return {cU: monthExpenses, c4: m, db: otherExpenses};
		},
		monthList);
	return _Utils_update(
		model,
		{bR: allExpenses, cj: monthlyExpenses, cu: shownExpenses, cv: shownTotal});
};
var $author$project$Page$ExpensesBarChart$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				var _v1 = $author$project$Page$ExpensesBarChart$buildPresetDates(date);
				var others = _v1.a;
				var _default = _v1.b;
				var from = _default.fJ;
				var fromString = $justinmimbs$date$Date$toIsoString(from);
				var to = _default.gC;
				var toString = $justinmimbs$date$Date$toIsoString(to);
				var presetDates = _Utils_ap(
					others,
					_List_fromArray(
						[_default]));
				var newModel = $author$project$Page$ExpensesBarChart$updateChartData(
					$author$project$Page$ExpensesBarChart$updateExpenses(
						_Utils_update(
							model,
							{
								fJ: from,
								aA: fromString,
								bH: presetDates,
								aJ: $elm$core$Maybe$Just(_default.a7),
								gC: to,
								aQ: toString
							})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesBarChart$renderBarVisualization(newModel.Q)));
			case 6:
				var name = msg.a;
				var maybePreset = A2(
					$elm_community$list_extra$List$Extra$find,
					function (p) {
						return _Utils_eq(p.a7, name);
					},
					model.bH);
				var _v2 = function () {
					if (maybePreset.$ === 1) {
						return _Utils_Tuple2(model, $author$project$Effect$none);
					} else {
						var preset = maybePreset.a;
						var to = preset.gC;
						var toString = $justinmimbs$date$Date$toIsoString(to);
						var from = preset.fJ;
						var fromString = $justinmimbs$date$Date$toIsoString(from);
						var m = $author$project$Page$ExpensesBarChart$updateChartData(
							$author$project$Page$ExpensesBarChart$updateExpenses(
								_Utils_update(
									model,
									{
										fJ: from,
										aA: fromString,
										aJ: $elm$core$Maybe$Just(name),
										gC: to,
										aQ: toString
									})));
						return _Utils_Tuple2(
							m,
							$author$project$Effect$fromCmd(
								$author$project$Page$ExpensesBarChart$renderBarVisualization(m.Q)));
					}
				}();
				var newModel = _v2.a;
				var effect = _v2.b;
				return _Utils_Tuple2(newModel, effect);
			case 1:
				var fromDate = msg.a;
				var from = A2(
					$elm$core$Maybe$withDefault,
					model.fJ,
					$elm$core$Result$toMaybe(
						$justinmimbs$date$Date$fromIsoString(fromDate)));
				var newModel = $author$project$Page$ExpensesBarChart$updateChartData(
					$author$project$Page$ExpensesBarChart$updateExpenses(
						_Utils_update(
							model,
							{fJ: from, aA: fromDate, aJ: $elm$core$Maybe$Nothing})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesBarChart$renderBarVisualization(newModel.Q)));
			case 2:
				var toDate = msg.a;
				var to = A2(
					$elm$core$Maybe$withDefault,
					model.fJ,
					$elm$core$Result$toMaybe(
						$justinmimbs$date$Date$fromIsoString(toDate)));
				var newModel = $author$project$Page$ExpensesBarChart$updateChartData(
					$author$project$Page$ExpensesBarChart$updateExpenses(
						_Utils_update(
							model,
							{aJ: $elm$core$Maybe$Nothing, gC: to, aQ: toDate})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesBarChart$renderBarVisualization(newModel.Q)));
			case 3:
				var currency = msg.a;
				var newModel = $author$project$Page$ExpensesBarChart$updateChartData(
					$author$project$Page$ExpensesBarChart$updateExpenses(
						_Utils_update(
							model,
							{fn: currency})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesBarChart$renderBarVisualization(newModel.Q)));
			case 4:
				var groupedString = msg.a;
				var grouped = A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2($elm$core$Dict$get, groupedString, $author$project$Page$ExpensesBarChart$groupedOptions));
				var newModel = $author$project$Page$ExpensesBarChart$updateChartData(
					$author$project$Page$ExpensesBarChart$updateExpenses(
						_Utils_update(
							model,
							{b5: grouped, b6: groupedString})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesBarChart$renderBarVisualization(newModel.Q)));
			case 5:
				var account = msg.a;
				var updateFn = function (exists) {
					if ((!exists.$) && exists.a) {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(true);
					}
				};
				var excludedAccounts = A3($elm$core$Dict$update, account, updateFn, model.a1);
				var newModel = $author$project$Page$ExpensesBarChart$updateChartData(
					$author$project$Page$ExpensesBarChart$updateExpenses(
						_Utils_update(
							model,
							{a1: excludedAccounts})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesBarChart$renderBarVisualization(newModel.Q)));
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{av: !model.av}),
					$author$project$Effect$none);
			case 8:
				var newModel = $author$project$Page$ExpensesBarChart$updateChartData(
					$author$project$Page$ExpensesBarChart$updateExpenses(
						_Utils_update(
							model,
							{bb: !model.bb})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesBarChart$renderBarVisualization(newModel.Q)));
			default:
				return _Utils_Tuple2(model, $author$project$Effect$none);
		}
	});
var $author$project$Page$ExpensesPieChart$PresetDate = F3(
	function (name, from, to) {
		return {fJ: from, a7: name, gC: to};
	});
var $author$project$Page$ExpensesPieChart$buildPresetDates = function (date) {
	var startThisMonth = A2($justinmimbs$date$Date$floor, 2, date);
	var monthName = function (d) {
		return A2($justinmimbs$date$Date$format, 'MMMM', d);
	};
	var preset = F2(
		function (from, to) {
			return A3(
				$author$project$Page$ExpensesPieChart$PresetDate,
				monthName(from),
				from,
				to);
		});
	var minusDay = function (d) {
		return A3($justinmimbs$date$Date$add, 3, -1, d);
	};
	var endThisMonth = minusDay(
		A2($justinmimbs$date$Date$ceiling, 2, date));
	var thisMonth = A2(preset, startThisMonth, endThisMonth);
	var endLastMonth = minusDay(startThisMonth);
	var startLastMonth = A2($justinmimbs$date$Date$floor, 2, endLastMonth);
	var endMonthM2 = minusDay(startLastMonth);
	var startMonthM2 = A2($justinmimbs$date$Date$floor, 2, endMonthM2);
	var monthM2 = A2(preset, startMonthM2, endMonthM2);
	var thisQuarter = A3($author$project$Page$ExpensesPieChart$PresetDate, 'This Q', startMonthM2, endThisMonth);
	var lastMonth = A2(preset, startLastMonth, endLastMonth);
	return _Utils_Tuple2(
		_List_fromArray(
			[thisQuarter, monthM2, lastMonth]),
		thisMonth);
};
var $author$project$Page$ExpensesPieChart$groupedOptions = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('(max)', $elm$core$Maybe$Nothing),
			_Utils_Tuple2(
			'2',
			$elm$core$Maybe$Just(2)),
			_Utils_Tuple2(
			'3',
			$elm$core$Maybe$Just(3)),
			_Utils_Tuple2(
			'4',
			$elm$core$Maybe$Just(4))
		]));
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Page$ExpensesPieChart$renderPieVisualization = _Platform_outgoingPort(
	'renderPieVisualization',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string($.fn)),
					_Utils_Tuple2(
					'datasets',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'data',
										$elm$json$Json$Encode$list($elm$json$Json$Encode$float)($.b_)),
										_Utils_Tuple2(
										'label',
										$elm$json$Json$Encode$string($.c1))
									]));
						})($.b$)),
					_Utils_Tuple2(
					'labels',
					$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.cb)),
					_Utils_Tuple2(
					'showLegend',
					$elm$json$Json$Encode$bool($.ct)),
					_Utils_Tuple2(
					'title',
					$elm$json$Json$Encode$string($.D))
				]));
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$Page$ExpensesPieChart$fmt = function (amount) {
	return A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, amount / 100.0);
};
var $author$project$Page$ExpensesPieChart$updateChartData = function (model) {
	var total = $elm$core$List$sum(
		A2($elm$core$List$map, $elm$core$Tuple$second, model.cu));
	var toPcnt = function (i) {
		return 100 * (i / total);
	};
	var title = model.aA + (' to ' + (model.aQ + ('\n' + ('Total: ' + (model.fn + (' ' + $author$project$Page$ExpensesPieChart$fmt(total)))))));
	var labels = A2($elm$core$List$map, $elm$core$Tuple$first, model.cu);
	var fromMinor = function (x) {
		return x / 100.0;
	};
	var data = model.av ? A2(
		$elm$core$List$map,
		A2($elm$core$Basics$composeL, toPcnt, $elm$core$Tuple$second),
		model.cu) : A2(
		$elm$core$List$map,
		A2($elm$core$Basics$composeL, fromMinor, $elm$core$Tuple$second),
		model.cu);
	var chartData = {
		fn: model.fn,
		b$: _List_fromArray(
			[
				{b_: data, c1: 'Expenses'}
			]),
		cb: labels,
		ct: model.bb,
		D: title
	};
	return _Utils_update(
		model,
		{Q: chartData});
};
var $author$project$Page$ExpensesPieChart$isAccountExcluded = F2(
	function (model, account) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Dict$get, account, model.a1));
	});
var $author$project$Page$ExpensesPieChart$updateExpenses = function (model) {
	var isIncluded = A2(
		$elm$core$Basics$composeR,
		$author$project$Page$ExpensesPieChart$isAccountExcluded(model),
		$elm$core$Basics$not);
	var entryToKey = function (entry) {
		var _v2 = model.b5;
		if (_v2.$ === 1) {
			return entry.e6;
		} else {
			var depth = _v2.a;
			return A2($author$project$Transactions$accountAtDepth, entry.e6, depth);
		}
	};
	var allEntries = A2(
		$elm$core$List$filter,
		function (e) {
			return _Utils_eq(model.fn, e.fn);
		},
		A2(
			$elm$core$List$filter,
			function (e) {
				return $author$project$Transactions$isExpenseAccountName(e.e6);
			},
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (txn) {
						return A2(
							$elm$core$List$cons,
							txn.gv,
							A2(
								$elm$core$List$cons,
								txn.fu,
								A2($elm$core$Maybe$withDefault, _List_Nil, txn.fA)));
					},
					A3($author$project$Ledger$getExpensesByDate, model.e.fU, model.fJ, model.gC)))));
	var byAccount = A2($author$project$Misc$groupingBy, entryToKey, allEntries);
	var allExpenses = $elm$core$List$reverse(
		A2(
			$elm$core$List$sortBy,
			$elm$core$Tuple$second,
			A2(
				$elm$core$List$map,
				function (_v1) {
					var account = _v1.a;
					var entries = _v1.b;
					var total = A3(
						$elm$core$List$foldl,
						$elm$core$Basics$add,
						0,
						A2(
							$elm$core$List$map,
							function ($) {
								return $.e9;
							},
							A2(
								$elm$core$List$filter,
								function (e) {
									return _Utils_eq(e.fn, model.fn);
								},
								entries)));
					return _Utils_Tuple2(account, total);
				},
				byAccount)));
	var shownExpenses = A2(
		$elm$core$List$filter,
		function (_v0) {
			var account = _v0.a;
			var total = _v0.b;
			return isIncluded(account) && (total > 0);
		},
		allExpenses);
	var shownTotal = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$add,
		0,
		A2($elm$core$List$map, $elm$core$Tuple$second, allExpenses));
	return _Utils_update(
		model,
		{bR: allExpenses, cu: shownExpenses, cv: shownTotal});
};
var $author$project$Page$ExpensesPieChart$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				var _v1 = $author$project$Page$ExpensesPieChart$buildPresetDates(date);
				var others = _v1.a;
				var _default = _v1.b;
				var from = _default.fJ;
				var fromString = $justinmimbs$date$Date$toIsoString(from);
				var to = _default.gC;
				var toString = $justinmimbs$date$Date$toIsoString(to);
				var presetDates = _Utils_ap(
					others,
					_List_fromArray(
						[_default]));
				var newModel = $author$project$Page$ExpensesPieChart$updateChartData(
					$author$project$Page$ExpensesPieChart$updateExpenses(
						_Utils_update(
							model,
							{
								fJ: from,
								aA: fromString,
								bH: presetDates,
								aJ: $elm$core$Maybe$Just(_default.a7),
								gC: to,
								aQ: toString
							})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesPieChart$renderPieVisualization(newModel.Q)));
			case 6:
				var name = msg.a;
				var maybePreset = A2(
					$elm_community$list_extra$List$Extra$find,
					function (p) {
						return _Utils_eq(p.a7, name);
					},
					model.bH);
				var _v2 = function () {
					if (maybePreset.$ === 1) {
						return _Utils_Tuple2(model, $author$project$Effect$none);
					} else {
						var preset = maybePreset.a;
						var to = preset.gC;
						var toString = $justinmimbs$date$Date$toIsoString(to);
						var from = preset.fJ;
						var fromString = $justinmimbs$date$Date$toIsoString(from);
						var m = $author$project$Page$ExpensesPieChart$updateChartData(
							$author$project$Page$ExpensesPieChart$updateExpenses(
								_Utils_update(
									model,
									{
										fJ: from,
										aA: fromString,
										aJ: $elm$core$Maybe$Just(name),
										gC: to,
										aQ: toString
									})));
						return _Utils_Tuple2(
							m,
							$author$project$Effect$fromCmd(
								$author$project$Page$ExpensesPieChart$renderPieVisualization(m.Q)));
					}
				}();
				var newModel = _v2.a;
				var effect = _v2.b;
				return _Utils_Tuple2(newModel, effect);
			case 1:
				var fromDate = msg.a;
				var from = A2(
					$elm$core$Maybe$withDefault,
					model.fJ,
					$elm$core$Result$toMaybe(
						$justinmimbs$date$Date$fromIsoString(fromDate)));
				var newModel = $author$project$Page$ExpensesPieChart$updateChartData(
					$author$project$Page$ExpensesPieChart$updateExpenses(
						_Utils_update(
							model,
							{fJ: from, aA: fromDate, aJ: $elm$core$Maybe$Nothing})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesPieChart$renderPieVisualization(newModel.Q)));
			case 2:
				var toDate = msg.a;
				var to = A2(
					$elm$core$Maybe$withDefault,
					model.fJ,
					$elm$core$Result$toMaybe(
						$justinmimbs$date$Date$fromIsoString(toDate)));
				var newModel = $author$project$Page$ExpensesPieChart$updateChartData(
					$author$project$Page$ExpensesPieChart$updateExpenses(
						_Utils_update(
							model,
							{aJ: $elm$core$Maybe$Nothing, gC: to, aQ: toDate})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesPieChart$renderPieVisualization(newModel.Q)));
			case 3:
				var currency = msg.a;
				var newModel = $author$project$Page$ExpensesPieChart$updateChartData(
					$author$project$Page$ExpensesPieChart$updateExpenses(
						_Utils_update(
							model,
							{fn: currency})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesPieChart$renderPieVisualization(newModel.Q)));
			case 4:
				var groupedString = msg.a;
				var grouped = A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2($elm$core$Dict$get, groupedString, $author$project$Page$ExpensesPieChart$groupedOptions));
				var newModel = $author$project$Page$ExpensesPieChart$updateChartData(
					$author$project$Page$ExpensesPieChart$updateExpenses(
						_Utils_update(
							model,
							{b5: grouped, b6: groupedString})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesPieChart$renderPieVisualization(newModel.Q)));
			case 5:
				var account = msg.a;
				var updateFn = function (exists) {
					if ((!exists.$) && exists.a) {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(true);
					}
				};
				var excludedAccounts = A3($elm$core$Dict$update, account, updateFn, model.a1);
				var newModel = $author$project$Page$ExpensesPieChart$updateChartData(
					$author$project$Page$ExpensesPieChart$updateExpenses(
						_Utils_update(
							model,
							{a1: excludedAccounts})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesPieChart$renderPieVisualization(newModel.Q)));
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{av: !model.av}),
					$author$project$Effect$none);
			case 8:
				var newModel = $author$project$Page$ExpensesPieChart$updateChartData(
					$author$project$Page$ExpensesPieChart$updateExpenses(
						_Utils_update(
							model,
							{bb: !model.bb})));
				return _Utils_Tuple2(
					newModel,
					$author$project$Effect$fromCmd(
						$author$project$Page$ExpensesPieChart$renderPieVisualization(newModel.Q)));
			default:
				return _Utils_Tuple2(model, $author$project$Effect$none);
		}
	});
var $author$project$Page$Settings$Exporting = 3;
var $author$project$Page$Settings$Importing = 2;
var $author$project$Page$Settings$JsonLoaded = function (a) {
	return {$: 25, a: a};
};
var $author$project$Page$Settings$JsonSelected = function (a) {
	return {$: 24, a: a};
};
var $author$project$Page$Settings$NotWorking = 0;
var $author$project$Page$Settings$RenderQrCode = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Settings$Saving = 1;
var $author$project$Page$Settings$ScanQrCode = {$: 0};
var $author$project$Shared$SuccessPoupupMessage = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Page$Settings$TestingReplication = 4;
var $author$project$Page$Settings$addMissingAccounts = function (txn) {
	return $elm$core$List$isEmpty(txn.dr) ? _Utils_update(
		txn,
		{
			dr: _List_fromArray(
				[txn.fu.e6, txn.gv.e6])
		}) : txn;
};
var $author$project$Transactions$BalancedTransaction = $elm$core$Basics$identity;
var $author$project$Transactions$UnbalancedTransaction = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Transactions$balanceTransaction = function (txn) {
	var entries = A2(
		$elm$core$List$cons,
		txn.gv,
		A2(
			$elm$core$List$cons,
			txn.fu,
			A2($elm$core$Maybe$withDefault, _List_Nil, txn.fA)));
	var _v0 = $author$project$Transactions$balanceEntries(entries);
	if (!_v0.$) {
		return $elm$core$Result$Ok(txn);
	} else {
		var err = _v0.a;
		return $elm$core$Result$Err(
			A2($author$project$Transactions$UnbalancedTransaction, err, txn));
	}
};
var $author$project$Page$Settings$buildInputsFromSettings = function (settings) {
	return {
		G: A2(
			$elm$core$String$join,
			',',
			A2($elm$core$List$cons, settings.cQ, settings.es)),
		ft: settings.ft,
		cS: A2($elm$core$String$join, '\n', settings.cS),
		u: '',
		am: '',
		p: $author$project$Misc$isSomething(settings.p),
		bI: A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.a8;
				},
				settings.p)),
		bJ: A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.cE;
				},
				settings.p)),
		bK: A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.be;
				},
				settings.p)),
		go: A2($elm$core$String$join, ',', settings.go),
		dk: A2($elm$core$String$join, '\n', settings.dk),
		gy: settings.gy,
		be: '',
		af: settings.af
	};
};
var $author$project$Page$Settings$createSettings = _Platform_outgoingPort(
	'createSettings',
	function ($) {
		var a = $.a;
		var b = $.b;
		var c = $.c;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					function ($) {
					return $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'defaultCurrency',
								$elm$json$Json$Encode$string($.cQ)),
								_Utils_Tuple2(
								'defaultExpenseReportCurrency',
								$elm$json$Json$Encode$string($.ft)),
								_Utils_Tuple2(
								'destinationAccounts',
								$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.cS)),
								_Utils_Tuple2(
								'otherCurrencies',
								$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.es)),
								_Utils_Tuple2(
								'replication',
								function ($) {
									return A3(
										$elm$core$Maybe$destruct,
										$elm$json$Json$Encode$null,
										function ($) {
											return $elm$json$Json$Encode$object(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'password',
														$elm$json$Json$Encode$string($.a8)),
														_Utils_Tuple2(
														'url',
														$elm$json$Json$Encode$string($.cE)),
														_Utils_Tuple2(
														'username',
														$elm$json$Json$Encode$string($.be))
													]));
										},
										$);
								}($.p)),
								_Utils_Tuple2(
								'reportCurrencies',
								$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.go)),
								_Utils_Tuple2(
								'sourceAccounts',
								$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.dk)),
								_Utils_Tuple2(
								'syncOnSave',
								$elm$json$Json$Encode$bool($.gy)),
								_Utils_Tuple2(
								'version',
								$elm$json$Json$Encode$string($.af))
							]));
				}(a),
					$elm$json$Json$Encode$string(b),
					$elm$json$Json$Encode$string(c)
				]));
	});
var $author$project$Session$createSettings = F2(
	function (session, metadada) {
		if (session.$ === 1) {
			return $author$project$Session$Unlocked(metadada);
		} else {
			return session;
		}
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$Page$Settings$deleteAllData = _Platform_outgoingPort('deleteAllData', $elm$json$Json$Encode$string);
var $author$project$Transactions$dateEncoder = function (date) {
	return $elm$json$Json$Encode$string(
		$justinmimbs$date$Date$toIsoString(date));
};
var $author$project$Misc$maybeEncode = F2(
	function (map, maybe) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$json$Json$Encode$null,
			A2($elm$core$Maybe$map, map, maybe));
	});
var $author$project$Transactions$encodeEntry = function (entry) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'account',
				$elm$json$Json$Encode$string(entry.e6)),
				_Utils_Tuple2(
				'currency',
				$elm$json$Json$Encode$string(entry.fn)),
				_Utils_Tuple2(
				'amount',
				$elm$json$Json$Encode$int(entry.e9)),
				_Utils_Tuple2(
				'date',
				A2($author$project$Misc$maybeEncode, $author$project$Transactions$dateEncoder, entry.dH)),
				_Utils_Tuple2(
				'description',
				A2($author$project$Misc$maybeEncode, $elm$json$Json$Encode$string, entry.dK))
			]));
};
var $author$project$Transactions$encodeTransaction = function (txn) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(txn.aj)),
				_Utils_Tuple2(
				'version',
				$elm$json$Json$Encode$string(txn.af)),
				_Utils_Tuple2(
				'timestamp',
				$elm$json$Json$Encode$int(txn.aP)),
				_Utils_Tuple2(
				'date',
				$author$project$Transactions$dateEncoder(txn.dH)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(txn.dK)),
				_Utils_Tuple2(
				'destination',
				$author$project$Transactions$encodeEntry(txn.fu)),
				_Utils_Tuple2(
				'source',
				$author$project$Transactions$encodeEntry(txn.gv)),
				_Utils_Tuple2(
				'accounts',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, txn.dr)),
				_Utils_Tuple2(
				'notes',
				A2($author$project$Misc$maybeEncode, $elm$json$Json$Encode$string, txn.ga)),
				_Utils_Tuple2(
				'entries',
				A2(
					$author$project$Misc$maybeEncode,
					$elm$json$Json$Encode$list($author$project$Transactions$encodeEntry),
					txn.fA))
			]));
};
var $author$project$Transactions$encodeTransactions = function (txns) {
	return A2($elm$json$Json$Encode$list, $author$project$Transactions$encodeTransaction, txns);
};
var $author$project$Page$Settings$SharableSetings = F2(
	function (settings, password) {
		return {a8: password, bL: settings};
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $truqu$elm_base64$Base64$Encode$intToBase64 = function (i) {
	switch (i) {
		case 0:
			return 'A';
		case 1:
			return 'B';
		case 2:
			return 'C';
		case 3:
			return 'D';
		case 4:
			return 'E';
		case 5:
			return 'F';
		case 6:
			return 'G';
		case 7:
			return 'H';
		case 8:
			return 'I';
		case 9:
			return 'J';
		case 10:
			return 'K';
		case 11:
			return 'L';
		case 12:
			return 'M';
		case 13:
			return 'N';
		case 14:
			return 'O';
		case 15:
			return 'P';
		case 16:
			return 'Q';
		case 17:
			return 'R';
		case 18:
			return 'S';
		case 19:
			return 'T';
		case 20:
			return 'U';
		case 21:
			return 'V';
		case 22:
			return 'W';
		case 23:
			return 'X';
		case 24:
			return 'Y';
		case 25:
			return 'Z';
		case 26:
			return 'a';
		case 27:
			return 'b';
		case 28:
			return 'c';
		case 29:
			return 'd';
		case 30:
			return 'e';
		case 31:
			return 'f';
		case 32:
			return 'g';
		case 33:
			return 'h';
		case 34:
			return 'i';
		case 35:
			return 'j';
		case 36:
			return 'k';
		case 37:
			return 'l';
		case 38:
			return 'm';
		case 39:
			return 'n';
		case 40:
			return 'o';
		case 41:
			return 'p';
		case 42:
			return 'q';
		case 43:
			return 'r';
		case 44:
			return 's';
		case 45:
			return 't';
		case 46:
			return 'u';
		case 47:
			return 'v';
		case 48:
			return 'w';
		case 49:
			return 'x';
		case 50:
			return 'y';
		case 51:
			return 'z';
		case 52:
			return '0';
		case 53:
			return '1';
		case 54:
			return '2';
		case 55:
			return '3';
		case 56:
			return '4';
		case 57:
			return '5';
		case 58:
			return '6';
		case 59:
			return '7';
		case 60:
			return '8';
		case 61:
			return '9';
		case 62:
			return '+';
		default:
			return '/';
	}
};
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $truqu$elm_base64$Base64$Encode$toBase64 = function (_int) {
	return _Utils_ap(
		$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 18)),
		_Utils_ap(
			$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 12)),
			_Utils_ap(
				$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 6)),
				$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 0)))));
};
var $truqu$elm_base64$Base64$Encode$add = F2(
	function (_char, _v0) {
		var res = _v0.a;
		var count = _v0.b;
		var acc = _v0.c;
		var current = (acc << 8) | _char;
		if (count === 2) {
			return _Utils_Tuple3(
				_Utils_ap(
					res,
					$truqu$elm_base64$Base64$Encode$toBase64(current)),
				0,
				0);
		} else {
			return _Utils_Tuple3(res, count + 1, current);
		}
	});
var $truqu$elm_base64$Base64$Encode$chomp = F2(
	function (char_, acc) {
		var _char = $elm$core$Char$toCode(char_);
		return (_char < 128) ? A2($truqu$elm_base64$Base64$Encode$add, _char, acc) : ((_char < 2048) ? A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2($truqu$elm_base64$Base64$Encode$add, 192 | (_char >>> 6), acc)) : (((_char < 55296) || ((_char >= 57344) && (_char <= 65535))) ? A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2(
				$truqu$elm_base64$Base64$Encode$add,
				128 | (63 & (_char >>> 6)),
				A2($truqu$elm_base64$Base64$Encode$add, 224 | (_char >>> 12), acc))) : A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2(
				$truqu$elm_base64$Base64$Encode$add,
				128 | (63 & (_char >>> 6)),
				A2(
					$truqu$elm_base64$Base64$Encode$add,
					128 | (63 & (_char >>> 12)),
					A2($truqu$elm_base64$Base64$Encode$add, 240 | (_char >>> 18), acc))))));
	});
var $elm$core$String$foldl = _String_foldl;
var $truqu$elm_base64$Base64$Encode$initial = _Utils_Tuple3('', 0, 0);
var $truqu$elm_base64$Base64$Encode$wrapUp = function (_v0) {
	var res = _v0.a;
	var cnt = _v0.b;
	var acc = _v0.c;
	switch (cnt) {
		case 1:
			return res + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 2)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc << 4)) + '=='));
		case 2:
			return res + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 10)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 4)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc << 2)) + '=')));
		default:
			return res;
	}
};
var $truqu$elm_base64$Base64$Encode$encode = function (input) {
	return $truqu$elm_base64$Base64$Encode$wrapUp(
		A3($elm$core$String$foldl, $truqu$elm_base64$Base64$Encode$chomp, $truqu$elm_base64$Base64$Encode$initial, input));
};
var $truqu$elm_base64$Base64$encode = $truqu$elm_base64$Base64$Encode$encode;
var $author$project$Page$Settings$encodeShareSettings = function (settings) {
	var sources = A2($elm$core$String$join, '\n', settings.bL.dk);
	var reportCurrencies = A2($elm$core$String$join, ',', settings.bL.go);
	var replication = function () {
		var _v0 = settings.bL.p;
		if (_v0.$ === 1) {
			return _List_fromArray(
				['', '', '']);
		} else {
			var r = _v0.a;
			return _List_fromArray(
				[r.cE, r.be, r.a8]);
		}
	}();
	var password = settings.a8;
	var destinations = A2($elm$core$String$join, '\n', settings.bL.cS);
	var defaultExpenseReportCurrency = settings.bL.ft;
	var currencies = A2(
		$elm$core$String$join,
		',',
		A2($elm$core$List$cons, settings.bL.cQ, settings.bL.es));
	return A2(
		$elm$json$Json$Encode$list,
		$elm$json$Json$Encode$string,
		_Utils_ap(
			_List_fromArray(
				[password, currencies, reportCurrencies, defaultExpenseReportCurrency, destinations, sources]),
			replication));
};
var $pablohirafuji$elm_qrcode$QRCode$Quartile = 2;
var $pablohirafuji$elm_qrcode$QRCode$QRCode = $elm$core$Basics$identity;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex = F3(
	function (size, row, col) {
		return (size * row) + col;
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$isOccupy = F4(
	function (row, col, size, matrix) {
		var _v0 = A2(
			$elm$core$Array$get,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			matrix);
		if ((!_v0.$) && (!_v0.a.$)) {
			return true;
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$nextModule = function (placement) {
	var row = placement.eH;
	var col = placement.dB;
	var isRight = placement.ad;
	var isUp = placement.bw;
	return isRight ? _Utils_update(
		placement,
		{dB: col - 1, ad: false}) : (isUp ? _Utils_update(
		placement,
		{dB: col + 1, ad: true, eH: row - 1}) : _Utils_update(
		placement,
		{dB: col + 1, ad: true, eH: row + 1}));
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$bitToColor = F2(
	function (_byte, offset) {
		return (1 & (_byte >> (7 - offset))) === 1;
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setDataModule = F3(
	function (_v0, _byte, offset) {
		var size = _v0.aK;
		var row = _v0.eH;
		var col = _v0.dB;
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(
					false,
					A2($pablohirafuji$elm_qrcode$QRCode$Matrix$bitToColor, _byte, offset))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$addDataModule = F4(
	function (placement, bytes, offset, matrix) {
		addDataModule:
		while (true) {
			var size = placement.aK;
			var row = placement.eH;
			var col = placement.dB;
			if (!bytes.b) {
				return matrix;
			} else {
				var head = bytes.a;
				var tail = bytes.b;
				if (offset >= 8) {
					var $temp$placement = placement,
						$temp$bytes = tail,
						$temp$offset = 0,
						$temp$matrix = matrix;
					placement = $temp$placement;
					bytes = $temp$bytes;
					offset = $temp$offset;
					matrix = $temp$matrix;
					continue addDataModule;
				} else {
					if (col === 6) {
						var $temp$placement = _Utils_update(
							placement,
							{dB: col - 1, ad: true}),
							$temp$bytes = bytes,
							$temp$offset = offset,
							$temp$matrix = matrix;
						placement = $temp$placement;
						bytes = $temp$bytes;
						offset = $temp$offset;
						matrix = $temp$matrix;
						continue addDataModule;
					} else {
						if (row < 0) {
							var $temp$placement = _Utils_update(
								placement,
								{dB: col - 2, ad: true, bw: false, eH: 0}),
								$temp$bytes = bytes,
								$temp$offset = offset,
								$temp$matrix = matrix;
							placement = $temp$placement;
							bytes = $temp$bytes;
							offset = $temp$offset;
							matrix = $temp$matrix;
							continue addDataModule;
						} else {
							if (_Utils_cmp(row, size) > -1) {
								var $temp$placement = _Utils_update(
									placement,
									{dB: col - 2, ad: true, bw: true, eH: size - 1}),
									$temp$bytes = bytes,
									$temp$offset = offset,
									$temp$matrix = matrix;
								placement = $temp$placement;
								bytes = $temp$bytes;
								offset = $temp$offset;
								matrix = $temp$matrix;
								continue addDataModule;
							} else {
								if (A4($pablohirafuji$elm_qrcode$QRCode$Matrix$isOccupy, row, col, size, matrix)) {
									var $temp$placement = $pablohirafuji$elm_qrcode$QRCode$Matrix$nextModule(placement),
										$temp$bytes = bytes,
										$temp$offset = offset,
										$temp$matrix = matrix;
									placement = $temp$placement;
									bytes = $temp$bytes;
									offset = $temp$offset;
									matrix = $temp$matrix;
									continue addDataModule;
								} else {
									var $temp$placement = $pablohirafuji$elm_qrcode$QRCode$Matrix$nextModule(placement),
										$temp$bytes = bytes,
										$temp$offset = offset + 1,
										$temp$matrix = A4($pablohirafuji$elm_qrcode$QRCode$Matrix$setDataModule, placement, head, offset, matrix);
									placement = $temp$placement;
									bytes = $temp$bytes;
									offset = $temp$offset;
									matrix = $temp$matrix;
									continue addDataModule;
								}
							}
						}
					}
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$initPlacement = function (size) {
	return {dB: size + 1, ad: true, bw: true, eH: size + 1, aK: size};
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$addData = F3(
	function (size, bytes, matrix) {
		return A4(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$addDataModule,
			$pablohirafuji$elm_qrcode$QRCode$Matrix$initPlacement(size),
			bytes,
			0,
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Error$AlignmentPatternNotFound = {$: 0};
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{v: nodeList, o: nodeListSize, t: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentPatternData = $elm$core$Array$fromList(
	_List_fromArray(
		[
			_List_Nil,
			_List_fromArray(
			[6, 18]),
			_List_fromArray(
			[6, 22]),
			_List_fromArray(
			[6, 26]),
			_List_fromArray(
			[6, 30]),
			_List_fromArray(
			[6, 34]),
			_List_fromArray(
			[6, 22, 38]),
			_List_fromArray(
			[6, 24, 42]),
			_List_fromArray(
			[6, 26, 46]),
			_List_fromArray(
			[6, 28, 50]),
			_List_fromArray(
			[6, 30, 54]),
			_List_fromArray(
			[6, 32, 58]),
			_List_fromArray(
			[6, 34, 62]),
			_List_fromArray(
			[6, 26, 46, 66]),
			_List_fromArray(
			[6, 26, 48, 70]),
			_List_fromArray(
			[6, 26, 50, 74]),
			_List_fromArray(
			[6, 30, 54, 78]),
			_List_fromArray(
			[6, 30, 56, 82]),
			_List_fromArray(
			[6, 30, 58, 86]),
			_List_fromArray(
			[6, 34, 62, 90]),
			_List_fromArray(
			[6, 28, 50, 72, 94]),
			_List_fromArray(
			[6, 26, 50, 74, 98]),
			_List_fromArray(
			[6, 30, 54, 78, 102]),
			_List_fromArray(
			[6, 28, 54, 80, 106]),
			_List_fromArray(
			[6, 32, 58, 84, 110]),
			_List_fromArray(
			[6, 30, 58, 86, 114]),
			_List_fromArray(
			[6, 34, 62, 90, 118]),
			_List_fromArray(
			[6, 26, 50, 74, 98, 122]),
			_List_fromArray(
			[6, 30, 54, 78, 102, 126]),
			_List_fromArray(
			[6, 26, 52, 78, 104, 130]),
			_List_fromArray(
			[6, 30, 56, 82, 108, 134]),
			_List_fromArray(
			[6, 34, 60, 86, 112, 138]),
			_List_fromArray(
			[6, 30, 58, 86, 114, 142]),
			_List_fromArray(
			[6, 34, 62, 90, 118, 146]),
			_List_fromArray(
			[6, 30, 54, 78, 102, 126, 150]),
			_List_fromArray(
			[6, 24, 50, 76, 102, 128, 154]),
			_List_fromArray(
			[6, 28, 54, 80, 106, 132, 158]),
			_List_fromArray(
			[6, 32, 58, 84, 110, 136, 162]),
			_List_fromArray(
			[6, 26, 54, 82, 110, 138, 166]),
			_List_fromArray(
			[6, 30, 58, 86, 114, 142, 170])
		]));
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getAreaCoord = F2(
	function (rows, cols) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (row, list) {
					return A3(
						$elm$core$List$foldl,
						F2(
							function (col, list_) {
								return A2(
									$elm$core$List$cons,
									_Utils_Tuple2(row, col),
									list_);
							}),
						list,
						cols);
				}),
			_List_Nil,
			rows);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$isValidAlign = F2(
	function (size, _v0) {
		var row = _v0.a;
		var col = _v0.b;
		return ((row > 10) || ((10 < col) && (_Utils_cmp(col, size - 10) < 0))) && ((_Utils_cmp(row, size - 10) < 0) || (col > 10));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentRange = A2($elm$core$List$range, -2, 2);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentColor = F2(
	function (row, col) {
		return (_Utils_eq(row, -2) || ((row === 2) || (_Utils_eq(col, -2) || ((col === 2) || ((!row) && (!col)))))) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(true, true)) : $elm$core$Maybe$Just(
			_Utils_Tuple2(true, false));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignModule = F4(
	function (size, rowPos, colPos, _v0) {
		var row = _v0.a;
		var col = _v0.b;
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row + rowPos, col + colPos),
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentColor, row, col));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignment = F3(
	function (size, _v0, matrix) {
		var row = _v0.a;
		var col = _v0.b;
		return A3(
			$elm$core$List$foldl,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignModule, size, row, col),
			matrix,
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getAreaCoord, $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentRange, $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentRange));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignments = F3(
	function (size, locations, matrix) {
		return A3(
			$elm$core$List$foldl,
			$pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignment(size),
			matrix,
			A2(
				$elm$core$List$filter,
				$pablohirafuji$elm_qrcode$QRCode$Matrix$isValidAlign(size),
				A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getAreaCoord, locations, locations)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentPattern = F3(
	function (version, size, matrix) {
		return A2(
			$elm$core$Result$map,
			function (a) {
				return A3($pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignments, size, a, matrix);
			},
			A2(
				$elm$core$Result$fromMaybe,
				$pablohirafuji$elm_qrcode$QRCode$Error$AlignmentPatternNotFound,
				A2($elm$core$Array$get, version - 1, $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentPatternData)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$darkModule = F2(
	function (version, size) {
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, (4 * version) + 9, 8),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(true, true)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$finderRange = A2($elm$core$List$range, 0, 8);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$finderColor = F2(
	function (row, col) {
		return ((1 <= row) && ((row <= 7) && ((col === 1) || (col === 7)))) || (((1 <= col) && ((col <= 7) && ((row === 1) || (row === 7)))) || ((3 <= row) && ((row <= 5) && ((3 <= col) && (col <= 5)))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setFinder = F5(
	function (size, rowOffset, colOffset, _v0, matrix) {
		var row = _v0.a;
		var col = _v0.b;
		var finalRow = row + rowOffset;
		var finalCol = col + colOffset;
		return ((finalRow < 0) || ((finalCol < 0) || ((_Utils_cmp(finalRow, size) > -1) || (_Utils_cmp(finalCol, size) > -1)))) ? matrix : A3(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, finalRow, finalCol),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(
					true,
					A2($pablohirafuji$elm_qrcode$QRCode$Matrix$finderColor, row, col))),
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$finderPattern = F4(
	function (size, rowOffset, colOffset, matrix) {
		return A3(
			$elm$core$List$foldl,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$setFinder, size, rowOffset, colOffset),
			matrix,
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getAreaCoord, $pablohirafuji$elm_qrcode$QRCode$Matrix$finderRange, $pablohirafuji$elm_qrcode$QRCode$Matrix$finderRange));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$applyMaskColor = F2(
	function (maybeModule, isChange) {
		if (isChange) {
			if ((!maybeModule.$) && (!maybeModule.a.a)) {
				var _v1 = maybeModule.a;
				var isDark = _v1.b;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(false, !isDark));
			} else {
				return maybeModule;
			}
		} else {
			return maybeModule;
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getCoord = F2(
	function (size, index) {
		return _Utils_Tuple2(
			(index / size) | 0,
			A2($elm$core$Basics$modBy, size, index));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$applyMaskFunction = F4(
	function (_function, size, index, maybeModule) {
		return A2(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$applyMaskColor,
			maybeModule,
			_function(
				A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getCoord, size, index)));
	});
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Elm$JsArray$indexedMap = _JsArray_indexedMap;
var $elm$core$Array$indexedMap = F2(
	function (func, _v0) {
		var len = _v0.a;
		var tree = _v0.c;
		var tail = _v0.d;
		var initialBuilder = {
			v: _List_Nil,
			o: 0,
			t: A3(
				$elm$core$Elm$JsArray$indexedMap,
				func,
				$elm$core$Array$tailIndex(len),
				tail)
		};
		var helper = F2(
			function (node, builder) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, builder, subTree);
				} else {
					var leaf = node.a;
					var offset = builder.o * $elm$core$Array$branchFactor;
					var mappedLeaf = $elm$core$Array$Leaf(
						A3($elm$core$Elm$JsArray$indexedMap, func, offset, leaf));
					return {
						v: A2($elm$core$List$cons, mappedLeaf, builder.v),
						o: builder.o + 1,
						t: builder.t
					};
				}
			});
		return A2(
			$elm$core$Array$builderToArray,
			true,
			A3($elm$core$Elm$JsArray$foldl, helper, initialBuilder, tree));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$maskFunction = function (mask) {
	switch (mask) {
		case 0:
			return function (_v1) {
				var row = _v1.a;
				var col = _v1.b;
				return !A2($elm$core$Basics$modBy, 2, row + col);
			};
		case 1:
			return function (_v2) {
				var row = _v2.a;
				return !A2($elm$core$Basics$modBy, 2, row);
			};
		case 2:
			return function (_v3) {
				var col = _v3.b;
				return !A2($elm$core$Basics$modBy, 3, col);
			};
		case 3:
			return function (_v4) {
				var row = _v4.a;
				var col = _v4.b;
				return !A2($elm$core$Basics$modBy, 3, row + col);
			};
		case 4:
			return function (_v5) {
				var row = _v5.a;
				var col = _v5.b;
				return !A2(
					$elm$core$Basics$modBy,
					2,
					$elm$core$Basics$floor(row / 2) + $elm$core$Basics$floor(col / 3));
			};
		case 5:
			return function (_v6) {
				var row = _v6.a;
				var col = _v6.b;
				return !(A2($elm$core$Basics$modBy, 2, row * col) + A2($elm$core$Basics$modBy, 3, row * col));
			};
		case 6:
			return function (_v7) {
				var row = _v7.a;
				var col = _v7.b;
				return !A2(
					$elm$core$Basics$modBy,
					2,
					A2($elm$core$Basics$modBy, 2, row * col) + A2($elm$core$Basics$modBy, 3, row * col));
			};
		default:
			return function (_v8) {
				var row = _v8.a;
				var col = _v8.b;
				return !A2(
					$elm$core$Basics$modBy,
					2,
					A2($elm$core$Basics$modBy, 3, row * col) + A2($elm$core$Basics$modBy, 2, row + col));
			};
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$applyMask = F3(
	function (size, mask, matrix) {
		return A2(
			$elm$core$Array$indexedMap,
			A2(
				$pablohirafuji$elm_qrcode$QRCode$Matrix$applyMaskFunction,
				$pablohirafuji$elm_qrcode$QRCode$Matrix$maskFunction(mask),
				size),
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$breakList = F3(
	function (width, list, acc) {
		breakList:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(acc);
			} else {
				var $temp$width = width,
					$temp$list = A2($elm$core$List$drop, width, list),
					$temp$acc = A2(
					$elm$core$List$cons,
					A2($elm$core$List$take, width, list),
					acc);
				width = $temp$width;
				list = $temp$list;
				acc = $temp$acc;
				continue breakList;
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$isDarkModule = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($elm$core$Tuple$second),
	$elm$core$Maybe$withDefault(false));
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score_ = F2(
	function (simplifiedList, _v0) {
		rule1Score_:
		while (true) {
			var last = _v0.a;
			var partialScore = _v0.b;
			var score = _v0.c;
			if (!simplifiedList.b) {
				return (partialScore >= 5) ? ((score + partialScore) - 2) : score;
			} else {
				var head = simplifiedList.a;
				var tail = simplifiedList.b;
				if (_Utils_eq(last, head)) {
					var $temp$simplifiedList = tail,
						$temp$_v0 = _Utils_Tuple3(last, partialScore + 1, score);
					simplifiedList = $temp$simplifiedList;
					_v0 = $temp$_v0;
					continue rule1Score_;
				} else {
					if (partialScore >= 5) {
						var $temp$simplifiedList = tail,
							$temp$_v0 = _Utils_Tuple3(head, 0, (score + partialScore) - 2);
						simplifiedList = $temp$simplifiedList;
						_v0 = $temp$_v0;
						continue rule1Score_;
					} else {
						var $temp$simplifiedList = tail,
							$temp$_v0 = _Utils_Tuple3(head, 0, score);
						simplifiedList = $temp$simplifiedList;
						_v0 = $temp$_v0;
						continue rule1Score_;
					}
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		function (a) {
			return A2(
				$pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score_,
				a,
				_Utils_Tuple3(false, 0, 0));
		}),
	$elm$core$List$sum);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule2Score_ = F4(
	function (row1, row2, maybeLast, score) {
		rule2Score_:
		while (true) {
			if (!row1.b) {
				return score;
			} else {
				var head = row1.a;
				var tail = row1.b;
				if (!row2.b) {
					return score;
				} else {
					var head2 = row2.a;
					var tail2 = row2.b;
					if (_Utils_eq(head, head2)) {
						if (_Utils_eq(
							$elm$core$Maybe$Just(head),
							maybeLast)) {
							var $temp$row1 = tail,
								$temp$row2 = tail2,
								$temp$maybeLast = $elm$core$Maybe$Just(head),
								$temp$score = score + 3;
							row1 = $temp$row1;
							row2 = $temp$row2;
							maybeLast = $temp$maybeLast;
							score = $temp$score;
							continue rule2Score_;
						} else {
							var $temp$row1 = tail,
								$temp$row2 = tail2,
								$temp$maybeLast = $elm$core$Maybe$Just(head),
								$temp$score = score;
							row1 = $temp$row1;
							row2 = $temp$row2;
							maybeLast = $temp$maybeLast;
							score = $temp$score;
							continue rule2Score_;
						}
					} else {
						var $temp$row1 = tail,
							$temp$row2 = tail2,
							$temp$maybeLast = $elm$core$Maybe$Nothing,
							$temp$score = score;
						row1 = $temp$row1;
						row2 = $temp$row2;
						maybeLast = $temp$maybeLast;
						score = $temp$score;
						continue rule2Score_;
					}
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule2Score = F2(
	function (list, score) {
		rule2Score:
		while (true) {
			if (list.b && list.b.b) {
				var head1 = list.a;
				var _v1 = list.b;
				var head2 = _v1.a;
				var tail = _v1.b;
				var $temp$list = tail,
					$temp$score = score + A4($pablohirafuji$elm_qrcode$QRCode$Matrix$rule2Score_, head1, head2, $elm$core$Maybe$Nothing, 0);
				list = $temp$list;
				score = $temp$score;
				continue rule2Score;
			} else {
				return score;
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score_ = F2(
	function (simplifiedList, score) {
		rule3Score_:
		while (true) {
			_v0$3:
			while (true) {
				if (!simplifiedList.b) {
					return score;
				} else {
					if (!simplifiedList.a) {
						if (((((((((((((((((((simplifiedList.b.b && (!simplifiedList.b.a)) && simplifiedList.b.b.b) && (!simplifiedList.b.b.a)) && simplifiedList.b.b.b.b) && (!simplifiedList.b.b.b.a)) && simplifiedList.b.b.b.b.b) && simplifiedList.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.b.b.b.b.a) {
							var _v1 = simplifiedList.b;
							var _v2 = _v1.b;
							var _v3 = _v2.b;
							var _v4 = _v3.b;
							var _v5 = _v4.b;
							var _v6 = _v5.b;
							var _v7 = _v6.b;
							var _v8 = _v7.b;
							var _v9 = _v8.b;
							var _v10 = _v9.b;
							var tail = _v10.b;
							var $temp$simplifiedList = tail,
								$temp$score = score + 40;
							simplifiedList = $temp$simplifiedList;
							score = $temp$score;
							continue rule3Score_;
						} else {
							break _v0$3;
						}
					} else {
						if (((((((((((((((((((simplifiedList.b.b && (!simplifiedList.b.a)) && simplifiedList.b.b.b) && simplifiedList.b.b.a) && simplifiedList.b.b.b.b) && simplifiedList.b.b.b.a) && simplifiedList.b.b.b.b.b) && simplifiedList.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.b.b.b.a)) {
							var _v11 = simplifiedList.b;
							var _v12 = _v11.b;
							var _v13 = _v12.b;
							var _v14 = _v13.b;
							var _v15 = _v14.b;
							var _v16 = _v15.b;
							var _v17 = _v16.b;
							var _v18 = _v17.b;
							var _v19 = _v18.b;
							var _v20 = _v19.b;
							var tail = _v20.b;
							var $temp$simplifiedList = tail,
								$temp$score = score + 40;
							simplifiedList = $temp$simplifiedList;
							score = $temp$score;
							continue rule3Score_;
						} else {
							break _v0$3;
						}
					}
				}
			}
			var head = simplifiedList.a;
			var tail = simplifiedList.b;
			var $temp$simplifiedList = tail,
				$temp$score = score;
			simplifiedList = $temp$simplifiedList;
			score = $temp$score;
			continue rule3Score_;
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score = A2($elm$core$List$foldl, $pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score_, 0);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule4Score = F2(
	function (size, simplifiedList) {
		var moduleCount = size * size;
		var darkCount = $elm$core$List$length(
			A2($elm$core$List$filter, $elm$core$Basics$identity, simplifiedList));
		var darkPerc = $elm$core$Basics$round((100 * darkCount) / moduleCount);
		var remOf5 = darkPerc % 5;
		var nextMult5 = $elm$core$Basics$round(
			$elm$core$Basics$abs((darkPerc + (5 - remOf5)) - 50) / 5);
		var prevMult5 = $elm$core$Basics$round(
			$elm$core$Basics$abs((darkPerc - remOf5) - 50) / 5);
		return A2($elm$core$Basics$min, prevMult5, nextMult5) * 10;
	});
var $pablohirafuji$elm_qrcode$QRCode$Helpers$transpose = function (ll) {
	transpose:
	while (true) {
		if (!ll.b) {
			return _List_Nil;
		} else {
			if (!ll.a.b) {
				var xss = ll.b;
				var $temp$ll = xss;
				ll = $temp$ll;
				continue transpose;
			} else {
				var _v1 = ll.a;
				var x = _v1.a;
				var xs = _v1.b;
				var xss = ll.b;
				var tails = A2($elm$core$List$filterMap, $elm$core$List$tail, xss);
				var heads = A2($elm$core$List$filterMap, $elm$core$List$head, xss);
				return A2(
					$elm$core$List$cons,
					A2($elm$core$List$cons, x, heads),
					$pablohirafuji$elm_qrcode$QRCode$Helpers$transpose(
						A2($elm$core$List$cons, xs, tails)));
			}
		}
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getMaskScore = F2(
	function (size, matrix) {
		var list = A2(
			$elm$core$List$map,
			$pablohirafuji$elm_qrcode$QRCode$Matrix$isDarkModule,
			$elm$core$Array$toList(matrix));
		var rowList = A3($pablohirafuji$elm_qrcode$QRCode$Matrix$breakList, size, list, _List_Nil);
		var transposedRowList = $pablohirafuji$elm_qrcode$QRCode$Helpers$transpose(rowList);
		return function (b) {
			return _Utils_Tuple2(rowList, b);
		}(
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$rule4Score, size, list) + ($pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score(transposedRowList) + ($pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score(rowList) + (A2($pablohirafuji$elm_qrcode$QRCode$Matrix$rule2Score, rowList, 0) + ($pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score(transposedRowList) + $pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score(rowList))))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$ecLevelToInt = function (ecLevel) {
	switch (ecLevel) {
		case 0:
			return 1;
		case 1:
			return 0;
		case 2:
			return 3;
		default:
			return 2;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit = function (_int) {
	var helper = F2(
		function (digit, int_) {
			helper:
			while (true) {
				if (!(!int_)) {
					var $temp$digit = digit + 1,
						$temp$int_ = int_ >>> 1;
					digit = $temp$digit;
					int_ = $temp$int_;
					continue helper;
				} else {
					return digit;
				}
			}
		});
	return A2(helper, 0, _int);
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$maskToInt = function (mask) {
	switch (mask) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		default:
			return 7;
	}
};
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$encodeFormatInfo = F2(
	function (ecLevel, mask) {
		var g15Mask = 21522;
		var g15Int = 1335;
		var g15Digit = $pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(g15Int);
		var formatInfoInt = $pablohirafuji$elm_qrcode$QRCode$Matrix$maskToInt(mask) | ($pablohirafuji$elm_qrcode$QRCode$Matrix$ecLevelToInt(ecLevel) << 3);
		var helper = function (d_) {
			helper:
			while (true) {
				if (($pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(d_) - g15Digit) >= 0) {
					var $temp$d_ = d_ ^ (g15Int << ($pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(d_) - g15Digit));
					d_ = $temp$d_;
					continue helper;
				} else {
					return g15Mask ^ (d_ | (formatInfoInt << 10));
				}
			}
		};
		var d = formatInfoInt << 10;
		return helper(d);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$formatInfoHorizontal = F2(
	function (size, count) {
		return (count < 8) ? _Utils_Tuple2(8, (size - count) - 1) : ((count < 9) ? _Utils_Tuple2(8, 15 - count) : _Utils_Tuple2(8, (15 - count) - 1));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$formatInfoVertical = F2(
	function (size, count) {
		return (count < 6) ? _Utils_Tuple2(count, 8) : ((count < 8) ? _Utils_Tuple2(count + 1, 8) : _Utils_Tuple2((size - 15) + count, 8));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatModule = F4(
	function (size, isBlack, row, col) {
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(true, isBlack)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo_ = F4(
	function (size, isBlackFn, count, matrix) {
		setFormatInfo_:
		while (true) {
			if (count < 15) {
				var isBlack = isBlackFn(count);
				var _v0 = A2($pablohirafuji$elm_qrcode$QRCode$Matrix$formatInfoVertical, size, count);
				var x2 = _v0.a;
				var y2 = _v0.b;
				var _v1 = A2($pablohirafuji$elm_qrcode$QRCode$Matrix$formatInfoHorizontal, size, count);
				var x1 = _v1.a;
				var y1 = _v1.b;
				var $temp$size = size,
					$temp$isBlackFn = isBlackFn,
					$temp$count = count + 1,
					$temp$matrix = A5(
					$pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatModule,
					size,
					isBlack,
					x2,
					y2,
					A5($pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatModule, size, isBlack, x1, y1, matrix));
				size = $temp$size;
				isBlackFn = $temp$isBlackFn;
				count = $temp$count;
				matrix = $temp$matrix;
				continue setFormatInfo_;
			} else {
				return matrix;
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo = F4(
	function (ecLevel, size, mask, matrix) {
		var isBlack = F2(
			function (bits_, count) {
				return (1 & (bits_ >> count)) === 1;
			});
		var bits = A2($pablohirafuji$elm_qrcode$QRCode$Matrix$encodeFormatInfo, ecLevel, mask);
		return A4(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo_,
			size,
			isBlack(bits),
			0,
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getBestMask_ = F5(
	function (ecLevel, size, matrix, mask, _v0) {
		var minSMatrix = _v0.a;
		var minScore = _v0.b;
		var maskedMatrix = A4(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo,
			ecLevel,
			size,
			mask,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$applyMask, size, mask, matrix));
		var _v1 = A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getMaskScore, size, maskedMatrix);
		var maskSMatrix = _v1.a;
		var maskScore = _v1.b;
		return ((_Utils_cmp(minScore, maskScore) < 0) && (!_Utils_eq(minScore, -1))) ? _Utils_Tuple2(minSMatrix, minScore) : _Utils_Tuple2(maskSMatrix, maskScore);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern0 = 0;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern1 = 1;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern2 = 2;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern3 = 3;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern4 = 4;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern5 = 5;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern6 = 6;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern7 = 7;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$patternList = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7]);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getBestMask = F3(
	function (ecLevel, size, matrix) {
		return A3(
			$elm$core$List$foldl,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getBestMask_, ecLevel, size, matrix),
			_Utils_Tuple2(_List_Nil, -1),
			$pablohirafuji$elm_qrcode$QRCode$Matrix$patternList).a;
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$reserveFormatInfo = F2(
	function (size, matrix) {
		return A4(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo_,
			size,
			$elm$core$Basics$always(true),
			0,
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$encodeVersionInfo = function (version) {
	var g18Int = 7973;
	var g18Digit = $pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(g18Int);
	var helper = function (d_) {
		helper:
		while (true) {
			if (($pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(d_) - g18Digit) >= 0) {
				var $temp$d_ = d_ ^ (g18Int << ($pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(d_) - g18Digit));
				d_ = $temp$d_;
				continue helper;
			} else {
				return d_ | (version << 12);
			}
		}
	};
	var d = version << 12;
	return helper(d);
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionModule = F3(
	function (size, isBlack, _v0) {
		var row = _v0.a;
		var col = _v0.b;
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(true, isBlack)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionInfo_ = F4(
	function (size, isBlackFn, count, matrix) {
		setVersionInfo_:
		while (true) {
			if (count < 18) {
				var topRight = _Utils_Tuple2(
					$elm$core$Basics$floor(count / 3),
					((A2($elm$core$Basics$modBy, 3, count) + size) - 8) - 3);
				var isBlack = isBlackFn(count);
				var bottomLeft = _Utils_Tuple2(
					((A2($elm$core$Basics$modBy, 3, count) + size) - 8) - 3,
					$elm$core$Basics$floor(count / 3));
				var $temp$size = size,
					$temp$isBlackFn = isBlackFn,
					$temp$count = count + 1,
					$temp$matrix = A4(
					$pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionModule,
					size,
					isBlack,
					bottomLeft,
					A4($pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionModule, size, isBlack, topRight, matrix));
				size = $temp$size;
				isBlackFn = $temp$isBlackFn;
				count = $temp$count;
				matrix = $temp$matrix;
				continue setVersionInfo_;
			} else {
				return matrix;
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionInfo = F3(
	function (version, size, matrix) {
		if (version >= 7) {
			var isBlack = F2(
				function (bits_, count) {
					return (1 & (bits_ >> count)) === 1;
				});
			var bits = $pablohirafuji$elm_qrcode$QRCode$Matrix$encodeVersionInfo(version);
			return A4(
				$pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionInfo_,
				size,
				isBlack(bits),
				0,
				matrix);
		} else {
			return matrix;
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$timingColor = F2(
	function (row, col) {
		return (!A2($elm$core$Basics$modBy, 2, row + col)) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(true, true)) : $elm$core$Maybe$Just(
			_Utils_Tuple2(true, false));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setTiming = F3(
	function (size, row, col) {
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$timingColor, row, col));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$timingPattern = F2(
	function (size, matrix) {
		var range = A2($elm$core$List$range, 8, size - 9);
		return A3(
			$elm$core$List$foldl,
			function (b) {
				return A3($pablohirafuji$elm_qrcode$QRCode$Matrix$setTiming, size, b, 6);
			},
			A3(
				$elm$core$List$foldl,
				A2($pablohirafuji$elm_qrcode$QRCode$Matrix$setTiming, size, 6),
				matrix,
				range),
			range);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$apply = function (_v0) {
	var ecLevel = _v0.a.dM;
	var groupInfo = _v0.a.fK;
	var bytes = _v0.b;
	var version = groupInfo.af;
	var size = ((version - 1) * 4) + 21;
	return A2(
		$elm$core$Result$map,
		A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getBestMask, ecLevel, size),
		A2(
			$elm$core$Result$map,
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$addData, size, bytes),
			A3(
				$pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentPattern,
				version,
				size,
				A2(
					$pablohirafuji$elm_qrcode$QRCode$Matrix$timingPattern,
					size,
					A3(
						$pablohirafuji$elm_qrcode$QRCode$Matrix$darkModule,
						version,
						size,
						A3(
							$pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionInfo,
							version,
							size,
							A2(
								$pablohirafuji$elm_qrcode$QRCode$Matrix$reserveFormatInfo,
								size,
								A4(
									$pablohirafuji$elm_qrcode$QRCode$Matrix$finderPattern,
									size,
									-1,
									size - 8,
									A4(
										$pablohirafuji$elm_qrcode$QRCode$Matrix$finderPattern,
										size,
										size - 8,
										-1,
										A4(
											$pablohirafuji$elm_qrcode$QRCode$Matrix$finderPattern,
											size,
											-1,
											-1,
											A2(
												$elm$core$Array$initialize,
												size * size,
												$elm$core$Basics$always($elm$core$Maybe$Nothing))))))))))));
};
var $pablohirafuji$elm_qrcode$QRCode$ECLevel$H = 3;
var $pablohirafuji$elm_qrcode$QRCode$ECLevel$L = 0;
var $pablohirafuji$elm_qrcode$QRCode$ECLevel$M = 1;
var $pablohirafuji$elm_qrcode$QRCode$ECLevel$Q = 2;
var $pablohirafuji$elm_qrcode$QRCode$convertEC = function (ec) {
	switch (ec) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$AlignmentPatternNotFound = {$: 0};
var $pablohirafuji$elm_qrcode$QRCode$InputLengthOverflow = {$: 7};
var $pablohirafuji$elm_qrcode$QRCode$InvalidAlphanumericChar = {$: 2};
var $pablohirafuji$elm_qrcode$QRCode$InvalidNumericChar = {$: 1};
var $pablohirafuji$elm_qrcode$QRCode$InvalidUTF8Char = {$: 3};
var $pablohirafuji$elm_qrcode$QRCode$LogTableException = function (a) {
	return {$: 4, a: a};
};
var $pablohirafuji$elm_qrcode$QRCode$PolynomialModException = {$: 6};
var $pablohirafuji$elm_qrcode$QRCode$PolynomialMultiplyException = {$: 5};
var $pablohirafuji$elm_qrcode$QRCode$convertError = function (e) {
	switch (e.$) {
		case 0:
			return $pablohirafuji$elm_qrcode$QRCode$AlignmentPatternNotFound;
		case 1:
			return $pablohirafuji$elm_qrcode$QRCode$InvalidNumericChar;
		case 2:
			return $pablohirafuji$elm_qrcode$QRCode$InvalidAlphanumericChar;
		case 3:
			return $pablohirafuji$elm_qrcode$QRCode$InvalidUTF8Char;
		case 4:
			var n = e.a;
			return $pablohirafuji$elm_qrcode$QRCode$LogTableException(n);
		case 5:
			return $pablohirafuji$elm_qrcode$QRCode$PolynomialMultiplyException;
		case 6:
			return $pablohirafuji$elm_qrcode$QRCode$PolynomialModException;
		default:
			return $pablohirafuji$elm_qrcode$QRCode$InputLengthOverflow;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$firstFillerByte = 236;
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$secondFillerByte = 17;
var $pablohirafuji$elm_qrcode$QRCode$Encode$addFiller = F2(
	function (capacity, bytes) {
		var fillerLength = ((capacity / 8) | 0) - $elm$core$List$length(bytes);
		var ns = $elm$core$List$concat(
			A2(
				$elm$core$List$repeat,
				(fillerLength / 2) | 0,
				_List_fromArray(
					[$pablohirafuji$elm_qrcode$QRCode$Encode$firstFillerByte, $pablohirafuji$elm_qrcode$QRCode$Encode$secondFillerByte])));
		return (!A2($elm$core$Basics$modBy, 2, fillerLength)) ? _Utils_ap(bytes, ns) : _Utils_ap(
			bytes,
			_Utils_ap(
				ns,
				_List_fromArray(
					[$pablohirafuji$elm_qrcode$QRCode$Encode$firstFillerByte])));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$addTerminator = F3(
	function (capacity, bitsCount, bits) {
		return _Utils_ap(
			bits,
			_List_fromArray(
				[
					_Utils_Tuple2(
					0,
					A2($elm$core$Basics$min, 4, capacity - bitsCount))
				]));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes3 = function (_v0) {
	bitsToBytes3:
	while (true) {
		var _v1 = _v0.a;
		var bits = _v1.a;
		var length = _v1.b;
		var bytes = _v0.b;
		if (length >= 8) {
			var remLength = length - 8;
			var remBits = bits & ((1 << remLength) - 1);
			var _byte = bits >> remLength;
			var $temp$_v0 = _Utils_Tuple2(
				_Utils_Tuple2(remBits, remLength),
				A2($elm$core$List$cons, _byte, bytes));
			_v0 = $temp$_v0;
			continue bitsToBytes3;
		} else {
			return _Utils_Tuple2(
				_Utils_Tuple2(bits, length),
				bytes);
		}
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes2 = F2(
	function (_v0, _v1) {
		var curBits = _v0.a;
		var curLength = _v0.b;
		var _v2 = _v1.a;
		var remBits = _v2.a;
		var remLength = _v2.b;
		var bytes = _v1.b;
		var lengthSum = curLength + remLength;
		var bitsSum = curBits | (remBits << curLength);
		return $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes3(
			_Utils_Tuple2(
				_Utils_Tuple2(bitsSum, lengthSum),
				bytes));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes1 = F2(
	function (bits, _v0) {
		bitsToBytes1:
		while (true) {
			var _v1 = _v0.a;
			var remBits = _v1.a;
			var remLength = _v1.b;
			var bytes = _v0.b;
			if (bits.b) {
				var head = bits.a;
				var tail = bits.b;
				var $temp$bits = tail,
					$temp$_v0 = A2(
					$pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes2,
					head,
					_Utils_Tuple2(
						_Utils_Tuple2(remBits, remLength),
						bytes));
				bits = $temp$bits;
				_v0 = $temp$_v0;
				continue bitsToBytes1;
			} else {
				return (!remLength) ? $elm$core$List$reverse(bytes) : $elm$core$List$reverse(
					A2($elm$core$List$cons, remBits << (8 - remLength), bytes));
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes = function (bits) {
	return A2(
		$pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes1,
		bits,
		_Utils_Tuple2(
			_Utils_Tuple2(0, 0),
			_List_Nil));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$UTF8 = 3;
var $pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicatorLength = F2(
	function (mode, version) {
		if (version <= 9) {
			switch (mode) {
				case 0:
					return 10;
				case 1:
					return 9;
				case 2:
					return 8;
				default:
					return 8;
			}
		} else {
			if (version <= 26) {
				switch (mode) {
					case 0:
						return 12;
					case 1:
						return 11;
					case 2:
						return 16;
					default:
						return 16;
				}
			} else {
				switch (mode) {
					case 0:
						return 14;
					case 1:
						return 13;
					case 2:
						return 16;
					default:
						return 16;
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicator = F2(
	function (_v0, bits) {
		var groupInfo = _v0.fK;
		var inputStr = _v0.c$;
		var mode = _v0.ch;
		var length = A2($pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicatorLength, mode, groupInfo.af);
		var charCount = (mode === 3) ? $elm$core$List$length(bits) : $elm$core$String$length(inputStr);
		return _Utils_Tuple2(charCount, length);
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$modeIndicator = function (mode) {
	switch (mode) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		default:
			return 4;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$addInfoAndFinalBits = function (_v0) {
	var bits = _v0.a;
	var model = _v0.b;
	return _Utils_Tuple2(
		model,
		A2(
			$pablohirafuji$elm_qrcode$QRCode$Encode$addFiller,
			model.fK.bU,
			$pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes(
				A3(
					$pablohirafuji$elm_qrcode$QRCode$Encode$addTerminator,
					model.fK.bU,
					model.cJ,
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							$pablohirafuji$elm_qrcode$QRCode$Encode$modeIndicator(model.ch),
							4),
						A2(
							$elm$core$List$cons,
							A2($pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicator, model, bits),
							bits))))));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$concatTranspose = function (_v0) {
	var model = _v0.a;
	var dataBlocks = _v0.b;
	var ecBlocks = _v0.c;
	return _Utils_Tuple2(
		model,
		$elm$core$List$concat(
			_Utils_ap(
				$pablohirafuji$elm_qrcode$QRCode$Helpers$transpose(dataBlocks),
				$pablohirafuji$elm_qrcode$QRCode$Helpers$transpose(ecBlocks))));
};
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, list) {
		if ((size <= 0) || (step <= 0)) {
			return _List_Nil;
		} else {
			var go = F2(
				function (xs, acc) {
					go:
					while (true) {
						if ($elm$core$List$isEmpty(xs)) {
							return $elm$core$List$reverse(acc);
						} else {
							var $temp$xs = A2($elm$core$List$drop, step, xs),
								$temp$acc = A2(
								$elm$core$List$cons,
								A2($elm$core$List$take, size, xs),
								acc);
							xs = $temp$xs;
							acc = $temp$acc;
							continue go;
						}
					}
				});
			return A2(go, list, _List_Nil);
		}
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $pablohirafuji$elm_qrcode$QRCode$Error$InvalidAlphanumericChar = {$: 2};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$alphanumericCodes = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('0', 0),
			_Utils_Tuple2('1', 1),
			_Utils_Tuple2('2', 2),
			_Utils_Tuple2('3', 3),
			_Utils_Tuple2('4', 4),
			_Utils_Tuple2('5', 5),
			_Utils_Tuple2('6', 6),
			_Utils_Tuple2('7', 7),
			_Utils_Tuple2('8', 8),
			_Utils_Tuple2('9', 9),
			_Utils_Tuple2('A', 10),
			_Utils_Tuple2('B', 11),
			_Utils_Tuple2('C', 12),
			_Utils_Tuple2('D', 13),
			_Utils_Tuple2('E', 14),
			_Utils_Tuple2('F', 15),
			_Utils_Tuple2('G', 16),
			_Utils_Tuple2('H', 17),
			_Utils_Tuple2('I', 18),
			_Utils_Tuple2('J', 19),
			_Utils_Tuple2('K', 20),
			_Utils_Tuple2('L', 21),
			_Utils_Tuple2('M', 22),
			_Utils_Tuple2('N', 23),
			_Utils_Tuple2('O', 24),
			_Utils_Tuple2('P', 25),
			_Utils_Tuple2('Q', 26),
			_Utils_Tuple2('R', 27),
			_Utils_Tuple2('S', 28),
			_Utils_Tuple2('T', 29),
			_Utils_Tuple2('U', 30),
			_Utils_Tuple2('V', 31),
			_Utils_Tuple2('W', 32),
			_Utils_Tuple2('X', 33),
			_Utils_Tuple2('Y', 34),
			_Utils_Tuple2('Z', 35),
			_Utils_Tuple2(' ', 36),
			_Utils_Tuple2('$', 37),
			_Utils_Tuple2('%', 38),
			_Utils_Tuple2('*', 39),
			_Utils_Tuple2('+', 40),
			_Utils_Tuple2('-', 41),
			_Utils_Tuple2('.', 42),
			_Utils_Tuple2('/', 43),
			_Utils_Tuple2(':', 44)
		]));
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toAlphanumericCode = function (_char) {
	return A2(
		$elm$core$Result$fromMaybe,
		$pablohirafuji$elm_qrcode$QRCode$Error$InvalidAlphanumericChar,
		A2($elm$core$Dict$get, _char, $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$alphanumericCodes));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toBinary = function (chars) {
	_v0$2:
	while (true) {
		if (chars.b) {
			if (chars.b.b) {
				if (!chars.b.b.b) {
					var firstChar = chars.a;
					var _v1 = chars.b;
					var secondChar = _v1.a;
					return A3(
						$elm$core$Result$map2,
						F2(
							function (firstCode, secondCode) {
								return _Utils_Tuple2((firstCode * 45) + secondCode, 11);
							}),
						$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toAlphanumericCode(firstChar),
						$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toAlphanumericCode(secondChar));
				} else {
					break _v0$2;
				}
			} else {
				var _char = chars.a;
				return A2(
					$elm$core$Result$map,
					function (a) {
						return _Utils_Tuple2(a, 6);
					},
					$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toAlphanumericCode(_char));
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Result$Err($pablohirafuji$elm_qrcode$QRCode$Error$InvalidAlphanumericChar);
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$encode = function (str) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Result$map2($elm$core$List$cons),
		$elm$core$Result$Ok(_List_Nil),
		A2(
			$elm$core$List$map,
			$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toBinary,
			A2(
				$elm_community$list_extra$List$Extra$greedyGroupsOf,
				2,
				$elm$core$String$toList(str))));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Byte$encode = function (str) {
	return $elm$core$Result$Ok(
		A2(
			$elm$core$List$map,
			function (a) {
				return _Utils_Tuple2(
					$elm$core$Char$toCode(a),
					8);
			},
			$elm$core$String$toList(str)));
};
var $pablohirafuji$elm_qrcode$QRCode$Error$InvalidNumericChar = {$: 1};
var $elm$core$String$fromList = _String_fromList;
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$numericLength = function (str) {
	var _v0 = $elm$core$String$length(str);
	switch (_v0) {
		case 1:
			return 4;
		case 2:
			return 7;
		default:
			return 10;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$encodeHelp = function (chars) {
	var str = $elm$core$String$fromList(chars);
	return A2(
		$elm$core$Result$fromMaybe,
		$pablohirafuji$elm_qrcode$QRCode$Error$InvalidNumericChar,
		A2(
			$elm$core$Maybe$map,
			function (a) {
				return _Utils_Tuple2(
					a,
					$pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$numericLength(str));
			},
			$elm$core$String$toInt(str)));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$encode = function (str) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Result$map2($elm$core$List$cons),
		$elm$core$Result$Ok(_List_Nil),
		A2(
			$elm$core$List$map,
			$pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$encodeHelp,
			A2(
				$elm_community$list_extra$List$Extra$greedyGroupsOf,
				3,
				$elm$core$String$toList(str))));
};
var $pablohirafuji$elm_qrcode$QRCode$Error$InvalidUTF8Char = {$: 3};
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $elm$bytes$Bytes$Encode$getStringWidth = _Bytes_getStringWidth;
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $elm$bytes$Bytes$Decode$unsignedInt8 = _Bytes_read_u8;
var $pablohirafuji$elm_qrcode$QRCode$Encode$UTF8$step = function (_v0) {
	var n = _v0.a;
	var xs = _v0.b;
	return (n <= 0) ? $elm$bytes$Bytes$Decode$succeed(
		$elm$bytes$Bytes$Decode$Done(
			$elm$core$List$reverse(xs))) : A2(
		$elm$bytes$Bytes$Decode$map,
		function (x) {
			return $elm$bytes$Bytes$Decode$Loop(
				_Utils_Tuple2(
					n - 1,
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(x, 8),
						xs)));
		},
		$elm$bytes$Bytes$Decode$unsignedInt8);
};
var $elm$bytes$Bytes$Encode$Utf8 = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$string = function (str) {
	return A2(
		$elm$bytes$Bytes$Encode$Utf8,
		_Bytes_getStringWidth(str),
		str);
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$UTF8$encode = function (str) {
	var utf8BytesWidth = $elm$bytes$Bytes$Encode$getStringWidth(str);
	var decoder = A2(
		$elm$bytes$Bytes$Decode$loop,
		_Utils_Tuple2(utf8BytesWidth, _List_Nil),
		$pablohirafuji$elm_qrcode$QRCode$Encode$UTF8$step);
	return A2(
		$elm$core$Result$fromMaybe,
		$pablohirafuji$elm_qrcode$QRCode$Error$InvalidUTF8Char,
		A2(
			$elm$bytes$Bytes$Decode$decode,
			decoder,
			$elm$bytes$Bytes$Encode$encode(
				$elm$bytes$Bytes$Encode$string(str))));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$encoder = function (mode) {
	switch (mode) {
		case 0:
			return $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$encode;
		case 1:
			return $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$encode;
		case 2:
			return $pablohirafuji$elm_qrcode$QRCode$Encode$Byte$encode;
		default:
			return $pablohirafuji$elm_qrcode$QRCode$Encode$UTF8$encode;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$expTable = $elm$core$Array$fromList(
	_List_fromArray(
		[1, 2, 4, 8, 16, 32, 64, 128, 29, 58, 116, 232, 205, 135, 19, 38, 76, 152, 45, 90, 180, 117, 234, 201, 143, 3, 6, 12, 24, 48, 96, 192, 157, 39, 78, 156, 37, 74, 148, 53, 106, 212, 181, 119, 238, 193, 159, 35, 70, 140, 5, 10, 20, 40, 80, 160, 93, 186, 105, 210, 185, 111, 222, 161, 95, 190, 97, 194, 153, 47, 94, 188, 101, 202, 137, 15, 30, 60, 120, 240, 253, 231, 211, 187, 107, 214, 177, 127, 254, 225, 223, 163, 91, 182, 113, 226, 217, 175, 67, 134, 17, 34, 68, 136, 13, 26, 52, 104, 208, 189, 103, 206, 129, 31, 62, 124, 248, 237, 199, 147, 59, 118, 236, 197, 151, 51, 102, 204, 133, 23, 46, 92, 184, 109, 218, 169, 79, 158, 33, 66, 132, 21, 42, 84, 168, 77, 154, 41, 82, 164, 85, 170, 73, 146, 57, 114, 228, 213, 183, 115, 230, 209, 191, 99, 198, 145, 63, 126, 252, 229, 215, 179, 123, 246, 241, 255, 227, 219, 171, 75, 150, 49, 98, 196, 149, 55, 110, 220, 165, 87, 174, 65, 130, 25, 50, 100, 200, 141, 7, 14, 28, 56, 112, 224, 221, 167, 83, 166, 81, 162, 89, 178, 121, 242, 249, 239, 195, 155, 43, 86, 172, 69, 138, 9, 18, 36, 72, 144, 61, 122, 244, 245, 247, 243, 251, 235, 203, 139, 11, 22, 44, 88, 176, 125, 250, 233, 207, 131, 27, 54, 108, 216, 173, 71, 142, 1]));
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getExp = function (index) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Array$get,
			A2($elm$core$Basics$modBy, 255, index),
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$expTable));
};
var $pablohirafuji$elm_qrcode$QRCode$Error$PolynomialMultiplyException = {$: 5};
var $pablohirafuji$elm_qrcode$QRCode$Error$LogTableException = function (a) {
	return {$: 4, a: a};
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$logTable = $elm$core$Array$fromList(
	_List_fromArray(
		[0, 1, 25, 2, 50, 26, 198, 3, 223, 51, 238, 27, 104, 199, 75, 4, 100, 224, 14, 52, 141, 239, 129, 28, 193, 105, 248, 200, 8, 76, 113, 5, 138, 101, 47, 225, 36, 15, 33, 53, 147, 142, 218, 240, 18, 130, 69, 29, 181, 194, 125, 106, 39, 249, 185, 201, 154, 9, 120, 77, 228, 114, 166, 6, 191, 139, 98, 102, 221, 48, 253, 226, 152, 37, 179, 16, 145, 34, 136, 54, 208, 148, 206, 143, 150, 219, 189, 241, 210, 19, 92, 131, 56, 70, 64, 30, 66, 182, 163, 195, 72, 126, 110, 107, 58, 40, 84, 250, 133, 186, 61, 202, 94, 155, 159, 10, 21, 121, 43, 78, 212, 229, 172, 115, 243, 167, 87, 7, 112, 192, 247, 140, 128, 99, 13, 103, 74, 222, 237, 49, 197, 254, 24, 227, 165, 153, 119, 38, 184, 180, 124, 17, 68, 146, 217, 35, 32, 137, 46, 55, 63, 209, 91, 149, 188, 207, 205, 144, 135, 151, 178, 220, 252, 190, 97, 242, 86, 211, 171, 20, 42, 93, 158, 132, 60, 57, 83, 71, 109, 65, 162, 31, 45, 67, 216, 183, 123, 164, 118, 196, 23, 73, 236, 127, 12, 111, 246, 108, 161, 59, 82, 41, 157, 85, 170, 251, 96, 134, 177, 187, 204, 62, 90, 203, 89, 95, 176, 156, 169, 160, 81, 11, 245, 22, 235, 122, 117, 44, 215, 79, 174, 213, 233, 230, 231, 173, 232, 116, 214, 244, 234, 168, 80, 88, 175]));
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog = function (index) {
	return (index < 1) ? $elm$core$Result$Err(
		$pablohirafuji$elm_qrcode$QRCode$Error$LogTableException(index)) : A2(
		$elm$core$Result$fromMaybe,
		$pablohirafuji$elm_qrcode$QRCode$Error$LogTableException(index),
		A2($elm$core$Array$get, index - 1, $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$logTable));
};
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getOffset = function (_v0) {
	getOffset:
	while (true) {
		var num = _v0.a;
		var offset = _v0.b;
		if (num.b) {
			var head = num.a;
			var tail = num.b;
			if (!head) {
				var $temp$_v0 = _Utils_Tuple2(tail, offset + 1);
				_v0 = $temp$_v0;
				continue getOffset;
			} else {
				return offset;
			}
		} else {
			return offset;
		}
	}
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial = F2(
	function (num, shift) {
		var offset = $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getOffset(
			_Utils_Tuple2(num, 0));
		var numArray = $elm$core$Array$fromList(num);
		return A2(
			$elm$core$Array$initialize,
			($elm$core$List$length(num) - offset) + shift,
			function (index) {
				return A2(
					$elm$core$Maybe$withDefault,
					0,
					A2($elm$core$Array$get, index + offset, numArray));
			});
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$multiply = F2(
	function (poly1, poly2) {
		var valuesArray = A2(
			$elm$core$List$indexedMap,
			F2(
				function (index1, value1) {
					return A2(
						$elm$core$List$indexedMap,
						F2(
							function (index2, value2) {
								return _Utils_Tuple3(index1 + index2, value1, value2);
							}),
						$elm$core$Array$toList(poly2));
				}),
			$elm$core$Array$toList(poly1));
		var process__ = F3(
			function (indexSum, num_, exp) {
				return A2(
					$elm$core$Result$fromMaybe,
					$pablohirafuji$elm_qrcode$QRCode$Error$PolynomialMultiplyException,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Bitwise$xor(exp),
						A2($elm$core$Array$get, indexSum, num_)));
			});
		var process_ = F2(
			function (_v0, num_) {
				var indexSum = _v0.a;
				var value1 = _v0.b;
				var value2 = _v0.c;
				return A2(
					$elm$core$Result$map,
					function (r) {
						return A3($elm$core$Array$set, indexSum, r, num_);
					},
					A2(
						$elm$core$Result$andThen,
						A2(process__, indexSum, num_),
						A2(
							$elm$core$Result$map,
							$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getExp,
							A3(
								$elm$core$Result$map2,
								$elm$core$Basics$add,
								$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog(value1),
								$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog(value2)))));
			});
		var process = F2(
			function (args, numResult) {
				return A2(
					$elm$core$Result$andThen,
					process_(args),
					numResult);
			});
		var num = A2(
			$elm$core$Array$initialize,
			($elm$core$Array$length(poly1) + $elm$core$Array$length(poly2)) - 1,
			$elm$core$Basics$always(0));
		return A2(
			$elm$core$Result$map,
			function (a) {
				return A2($pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial, a, 0);
			},
			A2(
				$elm$core$Result$map,
				$elm$core$Array$toList,
				A3(
					$elm$core$List$foldl,
					process,
					$elm$core$Result$Ok(num),
					$elm$core$List$concat(valuesArray))));
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getECPolynomial = function (ecLength) {
	var generate = F2(
		function (count, polyResult) {
			generate:
			while (true) {
				if (_Utils_cmp(count, ecLength) < 0) {
					var $temp$count = count + 1,
						$temp$polyResult = A2(
						$elm$core$Result$andThen,
						function (a) {
							return A2(
								$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$multiply,
								a,
								A2(
									$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial,
									_List_fromArray(
										[
											1,
											$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getExp(count)
										]),
									0));
						},
						polyResult);
					count = $temp$count;
					polyResult = $temp$polyResult;
					continue generate;
				} else {
					return polyResult;
				}
			}
		});
	return A2(
		generate,
		0,
		$elm$core$Result$Ok(
			A2(
				$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial,
				_List_fromArray(
					[1]),
				0)));
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get___ = F2(
	function (ecLength, modPoly) {
		return $elm$core$Array$toList(
			A2(
				$elm$core$Array$initialize,
				ecLength,
				function (index) {
					var modIndex = (index + $elm$core$Array$length(modPoly)) - ecLength;
					return (modIndex >= 0) ? A2(
						$elm$core$Maybe$withDefault,
						0,
						A2($elm$core$Array$get, modIndex, modPoly)) : 0;
				}));
	});
var $pablohirafuji$elm_qrcode$QRCode$Error$PolynomialModException = {$: 6};
var $elm$core$Array$foldl = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldl, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldl,
			func,
			A3($elm$core$Elm$JsArray$foldl, helper, baseCase, tree),
			tail);
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$mod = F2(
	function (poly1, poly2) {
		if (($elm$core$Array$length(poly1) - $elm$core$Array$length(poly2)) < 0) {
			return $elm$core$Result$Ok(poly1);
		} else {
			var helper_ = F3(
				function (index2, poly1_, exp) {
					return A2(
						$elm$core$Result$fromMaybe,
						$pablohirafuji$elm_qrcode$QRCode$Error$PolynomialModException,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Bitwise$xor(exp),
							A2($elm$core$Array$get, index2, poly1_)));
				});
			var getHead = function (poly) {
				return A2(
					$elm$core$Result$andThen,
					$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog,
					A2(
						$elm$core$Result$fromMaybe,
						$pablohirafuji$elm_qrcode$QRCode$Error$PolynomialModException,
						A2($elm$core$Array$get, 0, poly)));
			};
			var ratio = A3(
				$elm$core$Result$map2,
				$elm$core$Basics$sub,
				getHead(poly1),
				getHead(poly2));
			var helper = F2(
				function (_v0, poly1_) {
					var index2 = _v0.a;
					var value2 = _v0.b;
					return A2(
						$elm$core$Result$map,
						function (r) {
							return A3($elm$core$Array$set, index2, r, poly1_);
						},
						A2(
							$elm$core$Result$andThen,
							A2(helper_, index2, poly1_),
							A2(
								$elm$core$Result$map,
								$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getExp,
								A3(
									$elm$core$Result$map2,
									$elm$core$Basics$add,
									ratio,
									$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog(value2)))));
				});
			var numFold = F2(
				function (args, poly1Result) {
					return A2(
						$elm$core$Result$andThen,
						helper(args),
						poly1Result);
				});
			var numResult = A3(
				$elm$core$Array$foldl,
				numFold,
				$elm$core$Result$Ok(poly1),
				A2(
					$elm$core$Array$indexedMap,
					F2(
						function (a, b) {
							return _Utils_Tuple2(a, b);
						}),
					poly2));
			return A2(
				$elm$core$Result$andThen,
				function (a) {
					return A2($pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$mod, a, poly2);
				},
				A2(
					$elm$core$Result$map,
					function (a) {
						return A2($pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial, a, 0);
					},
					A2($elm$core$Result$map, $elm$core$Array$toList, numResult)));
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get__ = F2(
	function (rsPoly, dataCodewords) {
		return A2(
			$elm$core$Result$map,
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get___(
				$elm$core$Array$length(rsPoly) - 1),
			A2(
				$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$mod,
				A2(
					$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial,
					dataCodewords,
					$elm$core$Array$length(rsPoly) - 1),
				rsPoly));
	});
var $pablohirafuji$elm_qrcode$QRCode$Helpers$listResult = F3(
	function (fun, listb, lista) {
		if (lista.b) {
			var head = lista.a;
			var tail = lista.b;
			return A2(
				$elm$core$Result$andThen,
				function (a) {
					return A3($pablohirafuji$elm_qrcode$QRCode$Helpers$listResult, fun, a, tail);
				},
				A2(
					$elm$core$Result$map,
					function (r) {
						return A2($elm$core$List$cons, r, listb);
					},
					fun(head)));
		} else {
			return $elm$core$Result$Ok(
				$elm$core$List$reverse(listb));
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get_ = F2(
	function (byteBlocks, rsPoly) {
		return A3(
			$pablohirafuji$elm_qrcode$QRCode$Helpers$listResult,
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get__(rsPoly),
			_List_Nil,
			byteBlocks);
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get = F2(
	function (ecPerBlock, byteBlocks) {
		return A2(
			$elm$core$Result$andThen,
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get_(byteBlocks),
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getECPolynomial(ecPerBlock));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$getErrorCorrection = function (_v0) {
	var model = _v0.a;
	var dataBlocks = _v0.b;
	return A2(
		$elm$core$Result$map,
		function (c) {
			return _Utils_Tuple3(model, dataBlocks, c);
		},
		A2($pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get, model.fK.fy, dataBlocks));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric = 1;
var $pablohirafuji$elm_qrcode$QRCode$Encode$Byte = 2;
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric = 0;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {aC: index, ed: match, gc: number, gw: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$onlyAlphanumeric = A2(
	$elm$regex$Regex$fromStringWith,
	{ff: false, f0: false},
	'^[0-9A-Z $%*+\\-.\\/:]+$');
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$isValid = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (r) {
				return A2($elm$regex$Regex$contains, r, input);
			},
			$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$onlyAlphanumeric));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Byte$only8Bit = A2(
	$elm$regex$Regex$fromStringWith,
	{ff: false, f0: false},
	'^[\\u0000-\\u00ff]+$');
var $pablohirafuji$elm_qrcode$QRCode$Encode$Byte$isValid = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (r) {
				return A2($elm$regex$Regex$contains, r, input);
			},
			$pablohirafuji$elm_qrcode$QRCode$Encode$Byte$only8Bit));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$onlyNumber = A2(
	$elm$regex$Regex$fromStringWith,
	{ff: false, f0: false},
	'^[0-9]+$');
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$isValid = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (r) {
				return A2($elm$regex$Regex$contains, r, input);
			},
			$pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$onlyNumber));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$selectMode = function (input) {
	return $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$isValid(input) ? 0 : ($pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$isValid(input) ? 1 : ($pablohirafuji$elm_qrcode$QRCode$Encode$Byte$isValid(input) ? 2 : 3));
};
var $pablohirafuji$elm_qrcode$QRCode$Error$InputLengthOverflow = {$: 7};
var $pablohirafuji$elm_qrcode$QRCode$Encode$filterCapacity = F3(
	function (mode, dataLength, _v0) {
		var version = _v0.af;
		var capacity = _v0.bU;
		return _Utils_cmp(
			A2($pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicatorLength, mode, version) + dataLength,
			capacity) < 1;
	});
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$blockByteCapacity = function (_v0) {
	var blockCount = _v0.a;
	var bytePerBlock = _v0.b;
	return blockCount * bytePerBlock;
};
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$byteCapacity = F2(
	function (group1, maybeGroup2) {
		if (!maybeGroup2.$) {
			var block2 = maybeGroup2.a;
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$blockByteCapacity(group1) + $pablohirafuji$elm_qrcode$QRCode$GroupInfo$blockByteCapacity(block2);
		} else {
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$blockByteCapacity(group1);
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo = F4(
	function (version, ecPerBlock, group1, maybeGroup2) {
		return {
			bU: A2($pablohirafuji$elm_qrcode$QRCode$GroupInfo$byteCapacity, group1, maybeGroup2) * 8,
			fy: ecPerBlock,
			dY: group1,
			fY: maybeGroup2,
			af: version
		};
	});
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataH = _List_fromArray(
	[
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		1,
		17,
		_Utils_Tuple2(1, 9),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		2,
		28,
		_Utils_Tuple2(1, 16),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		3,
		22,
		_Utils_Tuple2(2, 13),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		4,
		16,
		_Utils_Tuple2(4, 9),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		5,
		22,
		_Utils_Tuple2(2, 11),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 12))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		6,
		28,
		_Utils_Tuple2(4, 15),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		7,
		26,
		_Utils_Tuple2(4, 13),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 14))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		8,
		26,
		_Utils_Tuple2(4, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		9,
		24,
		_Utils_Tuple2(4, 12),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 13))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		10,
		28,
		_Utils_Tuple2(6, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		11,
		24,
		_Utils_Tuple2(3, 12),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(8, 13))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		12,
		28,
		_Utils_Tuple2(7, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		13,
		22,
		_Utils_Tuple2(12, 11),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 12))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		14,
		24,
		_Utils_Tuple2(11, 12),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 13))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		15,
		24,
		_Utils_Tuple2(11, 12),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 13))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		16,
		30,
		_Utils_Tuple2(3, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(13, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		17,
		28,
		_Utils_Tuple2(2, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(17, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		18,
		28,
		_Utils_Tuple2(2, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(19, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		19,
		26,
		_Utils_Tuple2(9, 13),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(16, 14))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		20,
		28,
		_Utils_Tuple2(15, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		21,
		30,
		_Utils_Tuple2(19, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		22,
		24,
		_Utils_Tuple2(34, 13),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		23,
		30,
		_Utils_Tuple2(16, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		24,
		30,
		_Utils_Tuple2(30, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		25,
		30,
		_Utils_Tuple2(22, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(13, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		26,
		30,
		_Utils_Tuple2(33, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		27,
		30,
		_Utils_Tuple2(12, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(28, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		28,
		30,
		_Utils_Tuple2(11, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(31, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		29,
		30,
		_Utils_Tuple2(19, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(26, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		30,
		30,
		_Utils_Tuple2(23, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(25, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		31,
		30,
		_Utils_Tuple2(23, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(28, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		32,
		30,
		_Utils_Tuple2(19, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(35, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		33,
		30,
		_Utils_Tuple2(11, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(46, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		34,
		30,
		_Utils_Tuple2(59, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		35,
		30,
		_Utils_Tuple2(22, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(41, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		36,
		30,
		_Utils_Tuple2(2, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(64, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		37,
		30,
		_Utils_Tuple2(24, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(46, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		38,
		30,
		_Utils_Tuple2(42, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(32, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		39,
		30,
		_Utils_Tuple2(10, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(67, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		40,
		30,
		_Utils_Tuple2(20, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(61, 16)))
	]);
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataL = _List_fromArray(
	[
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		1,
		7,
		_Utils_Tuple2(1, 19),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		2,
		10,
		_Utils_Tuple2(1, 34),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		3,
		15,
		_Utils_Tuple2(1, 55),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		4,
		20,
		_Utils_Tuple2(1, 80),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		5,
		26,
		_Utils_Tuple2(1, 108),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		6,
		18,
		_Utils_Tuple2(2, 68),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		7,
		20,
		_Utils_Tuple2(2, 78),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		8,
		24,
		_Utils_Tuple2(2, 97),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		9,
		30,
		_Utils_Tuple2(2, 116),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		10,
		18,
		_Utils_Tuple2(2, 68),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 69))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		11,
		20,
		_Utils_Tuple2(4, 81),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		12,
		24,
		_Utils_Tuple2(2, 92),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 93))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		13,
		26,
		_Utils_Tuple2(4, 107),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		14,
		30,
		_Utils_Tuple2(3, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		15,
		22,
		_Utils_Tuple2(5, 87),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 88))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		16,
		24,
		_Utils_Tuple2(5, 98),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 99))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		17,
		28,
		_Utils_Tuple2(1, 107),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 108))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		18,
		30,
		_Utils_Tuple2(5, 120),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 121))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		19,
		28,
		_Utils_Tuple2(3, 113),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 114))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		20,
		28,
		_Utils_Tuple2(3, 107),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 108))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		21,
		28,
		_Utils_Tuple2(4, 116),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 117))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		22,
		28,
		_Utils_Tuple2(2, 111),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 112))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		23,
		30,
		_Utils_Tuple2(4, 121),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 122))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		24,
		30,
		_Utils_Tuple2(6, 117),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 118))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		25,
		26,
		_Utils_Tuple2(8, 106),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 107))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		26,
		28,
		_Utils_Tuple2(10, 114),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 115))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		27,
		30,
		_Utils_Tuple2(8, 122),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 123))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		28,
		30,
		_Utils_Tuple2(3, 117),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 118))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		29,
		30,
		_Utils_Tuple2(7, 116),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 117))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		30,
		30,
		_Utils_Tuple2(5, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		31,
		30,
		_Utils_Tuple2(13, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(3, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		32,
		30,
		_Utils_Tuple2(17, 115),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		33,
		30,
		_Utils_Tuple2(17, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		34,
		30,
		_Utils_Tuple2(13, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		35,
		30,
		_Utils_Tuple2(12, 121),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 122))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		36,
		30,
		_Utils_Tuple2(6, 121),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 122))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		37,
		30,
		_Utils_Tuple2(17, 122),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 123))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		38,
		30,
		_Utils_Tuple2(4, 122),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(18, 123))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		39,
		30,
		_Utils_Tuple2(20, 117),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 118))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		40,
		30,
		_Utils_Tuple2(19, 118),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 119)))
	]);
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataM = _List_fromArray(
	[
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		1,
		10,
		_Utils_Tuple2(1, 16),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		2,
		16,
		_Utils_Tuple2(1, 28),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		3,
		26,
		_Utils_Tuple2(1, 44),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		4,
		18,
		_Utils_Tuple2(2, 32),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		5,
		24,
		_Utils_Tuple2(2, 43),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		6,
		16,
		_Utils_Tuple2(4, 27),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		7,
		18,
		_Utils_Tuple2(4, 31),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		8,
		22,
		_Utils_Tuple2(2, 38),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 39))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		9,
		22,
		_Utils_Tuple2(3, 36),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 37))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		10,
		26,
		_Utils_Tuple2(4, 43),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 44))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		11,
		30,
		_Utils_Tuple2(1, 50),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 51))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		12,
		22,
		_Utils_Tuple2(6, 36),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 37))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		13,
		22,
		_Utils_Tuple2(8, 37),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 38))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		14,
		24,
		_Utils_Tuple2(4, 40),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 41))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		15,
		24,
		_Utils_Tuple2(5, 41),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 42))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		16,
		28,
		_Utils_Tuple2(7, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(3, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		17,
		28,
		_Utils_Tuple2(10, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		18,
		26,
		_Utils_Tuple2(9, 43),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 44))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		19,
		26,
		_Utils_Tuple2(3, 44),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(11, 45))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		20,
		26,
		_Utils_Tuple2(3, 41),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(13, 42))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		21,
		26,
		_Utils_Tuple2(17, 42),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		22,
		28,
		_Utils_Tuple2(17, 46),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		23,
		28,
		_Utils_Tuple2(4, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		24,
		28,
		_Utils_Tuple2(6, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		25,
		28,
		_Utils_Tuple2(8, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(13, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		26,
		28,
		_Utils_Tuple2(19, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		27,
		28,
		_Utils_Tuple2(22, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(3, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		28,
		28,
		_Utils_Tuple2(3, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(23, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		29,
		28,
		_Utils_Tuple2(21, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		30,
		28,
		_Utils_Tuple2(19, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		31,
		28,
		_Utils_Tuple2(2, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(29, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		32,
		28,
		_Utils_Tuple2(10, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(23, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		33,
		28,
		_Utils_Tuple2(14, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(21, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		34,
		28,
		_Utils_Tuple2(14, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(23, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		35,
		28,
		_Utils_Tuple2(12, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(26, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		36,
		28,
		_Utils_Tuple2(6, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(34, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		37,
		28,
		_Utils_Tuple2(29, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		38,
		28,
		_Utils_Tuple2(13, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(32, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		39,
		28,
		_Utils_Tuple2(40, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		40,
		28,
		_Utils_Tuple2(18, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(31, 48)))
	]);
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataQ = _List_fromArray(
	[
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		1,
		13,
		_Utils_Tuple2(1, 13),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		2,
		22,
		_Utils_Tuple2(1, 22),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		3,
		18,
		_Utils_Tuple2(2, 17),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		4,
		26,
		_Utils_Tuple2(2, 24),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		5,
		18,
		_Utils_Tuple2(2, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		6,
		24,
		_Utils_Tuple2(4, 19),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		7,
		18,
		_Utils_Tuple2(2, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		8,
		22,
		_Utils_Tuple2(4, 18),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 19))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		9,
		20,
		_Utils_Tuple2(4, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		10,
		24,
		_Utils_Tuple2(6, 19),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 20))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		11,
		28,
		_Utils_Tuple2(4, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		12,
		26,
		_Utils_Tuple2(4, 20),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 21))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		13,
		24,
		_Utils_Tuple2(8, 20),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 21))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		14,
		20,
		_Utils_Tuple2(11, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		15,
		30,
		_Utils_Tuple2(5, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		16,
		24,
		_Utils_Tuple2(15, 19),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 20))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		17,
		28,
		_Utils_Tuple2(1, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(15, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		18,
		28,
		_Utils_Tuple2(17, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		19,
		26,
		_Utils_Tuple2(17, 21),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 22))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		20,
		30,
		_Utils_Tuple2(15, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		21,
		28,
		_Utils_Tuple2(17, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		22,
		30,
		_Utils_Tuple2(7, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(16, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		23,
		30,
		_Utils_Tuple2(11, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		24,
		30,
		_Utils_Tuple2(11, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(16, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		25,
		30,
		_Utils_Tuple2(7, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(22, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		26,
		28,
		_Utils_Tuple2(28, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		27,
		30,
		_Utils_Tuple2(8, 23),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(26, 24))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		28,
		30,
		_Utils_Tuple2(4, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(31, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		29,
		30,
		_Utils_Tuple2(1, 23),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(37, 24))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		30,
		30,
		_Utils_Tuple2(15, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(25, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		31,
		30,
		_Utils_Tuple2(42, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		32,
		30,
		_Utils_Tuple2(10, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(35, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		33,
		30,
		_Utils_Tuple2(29, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(19, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		34,
		30,
		_Utils_Tuple2(44, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		35,
		30,
		_Utils_Tuple2(39, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		36,
		30,
		_Utils_Tuple2(46, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		37,
		30,
		_Utils_Tuple2(49, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		38,
		30,
		_Utils_Tuple2(48, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		39,
		30,
		_Utils_Tuple2(43, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(22, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		40,
		30,
		_Utils_Tuple2(34, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(34, 25)))
	]);
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$getGroupData = function (ecLevel) {
	switch (ecLevel) {
		case 0:
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataL;
		case 1:
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataM;
		case 2:
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataQ;
		default:
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataH;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$getVersion = F3(
	function (ecLevel, mode, dataLength) {
		return A2(
			$elm$core$Result$fromMaybe,
			$pablohirafuji$elm_qrcode$QRCode$Error$InputLengthOverflow,
			$elm$core$List$head(
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.bU;
					},
					A2(
						$elm$core$List$filter,
						A2($pablohirafuji$elm_qrcode$QRCode$Encode$filterCapacity, mode, dataLength),
						$pablohirafuji$elm_qrcode$QRCode$GroupInfo$getGroupData(ecLevel)))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$versionToModel = F5(
	function (inputStr, ecLevel, mode, partialBitsCount, groupInfo) {
		return {
			cJ: partialBitsCount + A2($pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicatorLength, mode, groupInfo.af),
			dM: ecLevel,
			fK: groupInfo,
			c$: inputStr,
			ch: mode
		};
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$selectVersion = F4(
	function (inputStr, ecLevel, mode, encodedStr) {
		var partialBitsCount = 4 + A3(
			$elm$core$List$foldl,
			F2(
				function (a, b) {
					return a.b + b;
				}),
			0,
			encodedStr);
		return A2(
			$elm$core$Result$map,
			function (b) {
				return _Utils_Tuple2(encodedStr, b);
			},
			A2(
				$elm$core$Result$map,
				A4($pablohirafuji$elm_qrcode$QRCode$Encode$versionToModel, inputStr, ecLevel, mode, partialBitsCount),
				A3($pablohirafuji$elm_qrcode$QRCode$Encode$getVersion, ecLevel, mode, partialBitsCount)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$breakList = F3(
	function (checkFinish, _v0, _v1) {
		breakList:
		while (true) {
			var times = _v0.a;
			var itemCount = _v0.b;
			var byteList = _v1.a;
			var progress = _v1.b;
			if (times > 0) {
				var remainList = A2($elm$core$List$drop, itemCount, byteList);
				var block = A2($elm$core$List$take, itemCount, byteList);
				var $temp$checkFinish = checkFinish,
					$temp$_v0 = _Utils_Tuple2(times - 1, itemCount),
					$temp$_v1 = _Utils_Tuple2(
					remainList,
					A2($elm$core$List$cons, block, progress));
				checkFinish = $temp$checkFinish;
				_v0 = $temp$_v0;
				_v1 = $temp$_v1;
				continue breakList;
			} else {
				if (checkFinish && ($elm$core$List$length(byteList) > 0)) {
					return $elm$core$Result$Err($pablohirafuji$elm_qrcode$QRCode$Error$InputLengthOverflow);
				} else {
					return $elm$core$Result$Ok(
						_Utils_Tuple2(byteList, progress));
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$toBlocks = function (_v0) {
	var model = _v0.a;
	var groupInfo = model.fK;
	var byteList = _v0.b;
	var _v1 = groupInfo.fY;
	if (!_v1.$) {
		var group2 = _v1.a;
		return A2(
			$elm$core$Result$map,
			function (b) {
				return _Utils_Tuple2(model, b);
			},
			A2(
				$elm$core$Result$map,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $elm$core$List$reverse),
				A2(
					$elm$core$Result$andThen,
					A2($pablohirafuji$elm_qrcode$QRCode$Encode$breakList, true, group2),
					A3(
						$pablohirafuji$elm_qrcode$QRCode$Encode$breakList,
						false,
						groupInfo.dY,
						_Utils_Tuple2(byteList, _List_Nil)))));
	} else {
		return A2(
			$elm$core$Result$map,
			function (b) {
				return _Utils_Tuple2(model, b);
			},
			A2(
				$elm$core$Result$map,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $elm$core$List$reverse),
				A3(
					$pablohirafuji$elm_qrcode$QRCode$Encode$breakList,
					true,
					groupInfo.dY,
					_Utils_Tuple2(byteList, _List_Nil))));
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$encode = F2(
	function (inputStr, ecLevel) {
		var mode = $pablohirafuji$elm_qrcode$QRCode$Encode$selectMode(inputStr);
		return A2(
			$elm$core$Result$map,
			$pablohirafuji$elm_qrcode$QRCode$Encode$concatTranspose,
			A2(
				$elm$core$Result$andThen,
				$pablohirafuji$elm_qrcode$QRCode$Encode$getErrorCorrection,
				A2(
					$elm$core$Result$andThen,
					$pablohirafuji$elm_qrcode$QRCode$Encode$toBlocks,
					A2(
						$elm$core$Result$map,
						$pablohirafuji$elm_qrcode$QRCode$Encode$addInfoAndFinalBits,
						A2(
							$elm$core$Result$andThen,
							A3($pablohirafuji$elm_qrcode$QRCode$Encode$selectVersion, inputStr, ecLevel, mode),
							A2($pablohirafuji$elm_qrcode$QRCode$Encode$encoder, mode, inputStr))))));
	});
var $pablohirafuji$elm_qrcode$QRCode$fromStringWith = F2(
	function (ecLevel, input) {
		return A2(
			$elm$core$Result$mapError,
			$pablohirafuji$elm_qrcode$QRCode$convertError,
			A2(
				$elm$core$Result$andThen,
				function (_v0) {
					var encodeModel = _v0.a;
					var encodedData = _v0.b;
					return A2(
						$elm$core$Result$map,
						function (matrix) {
							return {bC: matrix, af: encodeModel.fK.af};
						},
						$pablohirafuji$elm_qrcode$QRCode$Matrix$apply(
							_Utils_Tuple2(encodeModel, encodedData)));
				},
				A2(
					$pablohirafuji$elm_qrcode$QRCode$Encode$encode,
					input,
					$pablohirafuji$elm_qrcode$QRCode$convertEC(ecLevel))));
	});
var $pablohirafuji$elm_qrcode$QRCode$fromString = $pablohirafuji$elm_qrcode$QRCode$fromStringWith(2);
var $author$project$Page$Settings$generateShareSettingsQrCode = F2(
	function (url, metadada) {
		var json = A2(
			$elm$json$Json$Encode$encode,
			0,
			$author$project$Page$Settings$encodeShareSettings(
				A2($author$project$Page$Settings$SharableSetings, metadada.bL, metadada.a8)));
		var base64 = $truqu$elm_base64$Base64$encode(json);
		var importFragment = 'import-settings/' + base64;
		var importUrl = _Utils_update(
			url,
			{
				fI: $elm$core$Maybe$Just(importFragment),
				gh: '/'
			});
		return $pablohirafuji$elm_qrcode$QRCode$fromString(
			$elm$url$Url$toString(importUrl));
	});
var $author$project$Page$Settings$getAllTransactions = _Platform_outgoingPort(
	'getAllTransactions',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Page$Settings$importSampleData = _Platform_outgoingPort(
	'importSampleData',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Page$Settings$importTransactions = _Platform_outgoingPort(
	'importTransactions',
	$elm$json$Json$Encode$list(
		function ($) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'accounts',
						$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.dr)),
						_Utils_Tuple2(
						'date',
						$elm$json$Json$Encode$string($.dH)),
						_Utils_Tuple2(
						'description',
						$elm$json$Json$Encode$string($.dK)),
						_Utils_Tuple2(
						'destination',
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'account',
										$elm$json$Json$Encode$string($.e6)),
										_Utils_Tuple2(
										'amount',
										$elm$json$Json$Encode$int($.e9)),
										_Utils_Tuple2(
										'currency',
										$elm$json$Json$Encode$string($.fn)),
										_Utils_Tuple2(
										'date',
										function ($) {
											return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
										}($.dH)),
										_Utils_Tuple2(
										'description',
										function ($) {
											return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
										}($.dK))
									]));
						}($.fu)),
						_Utils_Tuple2(
						'entries',
						function ($) {
							return A3(
								$elm$core$Maybe$destruct,
								$elm$json$Json$Encode$null,
								$elm$json$Json$Encode$list(
									function ($) {
										return $elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'account',
													$elm$json$Json$Encode$string($.e6)),
													_Utils_Tuple2(
													'amount',
													$elm$json$Json$Encode$int($.e9)),
													_Utils_Tuple2(
													'currency',
													$elm$json$Json$Encode$string($.fn)),
													_Utils_Tuple2(
													'date',
													function ($) {
														return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
													}($.dH)),
													_Utils_Tuple2(
													'description',
													function ($) {
														return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
													}($.dK))
												]));
									}),
								$);
						}($.fA)),
						_Utils_Tuple2(
						'id',
						$elm$json$Json$Encode$string($.aj)),
						_Utils_Tuple2(
						'notes',
						function ($) {
							return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
						}($.ga)),
						_Utils_Tuple2(
						'source',
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'account',
										$elm$json$Json$Encode$string($.e6)),
										_Utils_Tuple2(
										'amount',
										$elm$json$Json$Encode$int($.e9)),
										_Utils_Tuple2(
										'currency',
										$elm$json$Json$Encode$string($.fn)),
										_Utils_Tuple2(
										'date',
										function ($) {
											return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
										}($.dH)),
										_Utils_Tuple2(
										'description',
										function ($) {
											return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
										}($.dK))
									]));
						}($.gv)),
						_Utils_Tuple2(
						'timestamp',
						$elm$json$Json$Encode$int($.aP)),
						_Utils_Tuple2(
						'version',
						$elm$json$Json$Encode$string($.af))
					]));
		}));
var $truqu$elm_base64$Base64$Decode$pad = function (input) {
	var _v0 = $elm$core$String$length(input) % 4;
	switch (_v0) {
		case 3:
			return input + '=';
		case 2:
			return input + '==';
		default:
			return input;
	}
};
var $truqu$elm_base64$Base64$Decode$charToInt = function (_char) {
	switch (_char) {
		case 'A':
			return 0;
		case 'B':
			return 1;
		case 'C':
			return 2;
		case 'D':
			return 3;
		case 'E':
			return 4;
		case 'F':
			return 5;
		case 'G':
			return 6;
		case 'H':
			return 7;
		case 'I':
			return 8;
		case 'J':
			return 9;
		case 'K':
			return 10;
		case 'L':
			return 11;
		case 'M':
			return 12;
		case 'N':
			return 13;
		case 'O':
			return 14;
		case 'P':
			return 15;
		case 'Q':
			return 16;
		case 'R':
			return 17;
		case 'S':
			return 18;
		case 'T':
			return 19;
		case 'U':
			return 20;
		case 'V':
			return 21;
		case 'W':
			return 22;
		case 'X':
			return 23;
		case 'Y':
			return 24;
		case 'Z':
			return 25;
		case 'a':
			return 26;
		case 'b':
			return 27;
		case 'c':
			return 28;
		case 'd':
			return 29;
		case 'e':
			return 30;
		case 'f':
			return 31;
		case 'g':
			return 32;
		case 'h':
			return 33;
		case 'i':
			return 34;
		case 'j':
			return 35;
		case 'k':
			return 36;
		case 'l':
			return 37;
		case 'm':
			return 38;
		case 'n':
			return 39;
		case 'o':
			return 40;
		case 'p':
			return 41;
		case 'q':
			return 42;
		case 'r':
			return 43;
		case 's':
			return 44;
		case 't':
			return 45;
		case 'u':
			return 46;
		case 'v':
			return 47;
		case 'w':
			return 48;
		case 'x':
			return 49;
		case 'y':
			return 50;
		case 'z':
			return 51;
		case '0':
			return 52;
		case '1':
			return 53;
		case '2':
			return 54;
		case '3':
			return 55;
		case '4':
			return 56;
		case '5':
			return 57;
		case '6':
			return 58;
		case '7':
			return 59;
		case '8':
			return 60;
		case '9':
			return 61;
		case '+':
			return 62;
		case '/':
			return 63;
		default:
			return 0;
	}
};
var $truqu$elm_base64$Base64$Decode$intToString = A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar);
var $truqu$elm_base64$Base64$Decode$add = F2(
	function (_char, _v0) {
		var curr = _v0.a;
		var need = _v0.b;
		var res = _v0.c;
		var shiftAndAdd = function (_int) {
			return (63 & _int) | (curr << 6);
		};
		return (!need) ? ((!(128 & _char)) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(_char))) : (((224 & _char) === 192) ? _Utils_Tuple3(31 & _char, 1, res) : (((240 & _char) === 224) ? _Utils_Tuple3(15 & _char, 2, res) : _Utils_Tuple3(7 & _char, 3, res)))) : ((need === 1) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(
					shiftAndAdd(_char)))) : _Utils_Tuple3(
			shiftAndAdd(_char),
			need - 1,
			res));
	});
var $truqu$elm_base64$Base64$Decode$toUTF16 = F2(
	function (_char, acc) {
		return _Utils_Tuple3(
			0,
			0,
			A2(
				$truqu$elm_base64$Base64$Decode$add,
				255 & (_char >>> 0),
				A2(
					$truqu$elm_base64$Base64$Decode$add,
					255 & (_char >>> 8),
					A2($truqu$elm_base64$Base64$Decode$add, 255 & (_char >>> 16), acc))));
	});
var $truqu$elm_base64$Base64$Decode$chomp = F2(
	function (char_, _v0) {
		var curr = _v0.a;
		var cnt = _v0.b;
		var utf8ToUtf16 = _v0.c;
		var _char = $truqu$elm_base64$Base64$Decode$charToInt(char_);
		if (cnt === 3) {
			return A2($truqu$elm_base64$Base64$Decode$toUTF16, curr | _char, utf8ToUtf16);
		} else {
			return _Utils_Tuple3((_char << ((3 - cnt) * 6)) | curr, cnt + 1, utf8ToUtf16);
		}
	});
var $truqu$elm_base64$Base64$Decode$initial = _Utils_Tuple3(
	0,
	0,
	_Utils_Tuple3(0, 0, ''));
var $elm$core$String$endsWith = _String_endsWith;
var $truqu$elm_base64$Base64$Decode$stripNulls = F2(
	function (input, output) {
		return A2($elm$core$String$endsWith, '==', input) ? A2($elm$core$String$dropRight, 2, output) : (A2($elm$core$String$endsWith, '=', input) ? A2($elm$core$String$dropRight, 1, output) : output);
	});
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{ff: false, f0: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $truqu$elm_base64$Base64$Decode$validBase64Regex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z0-9\\/+]{4})*([A-Za-z0-9\\/+]{2}[A-Za-z0-9\\/+=]{2})?$'));
var $truqu$elm_base64$Base64$Decode$validate = function (input) {
	return A2($elm$regex$Regex$contains, $truqu$elm_base64$Base64$Decode$validBase64Regex, input) ? $elm$core$Result$Ok(input) : $elm$core$Result$Err('Invalid base64');
};
var $truqu$elm_base64$Base64$Decode$wrapUp = function (_v0) {
	var _v1 = _v0.c;
	var need = _v1.b;
	var res = _v1.c;
	return (need > 0) ? $elm$core$Result$Err('Invalid UTF-16') : $elm$core$Result$Ok(res);
};
var $truqu$elm_base64$Base64$Decode$validateAndDecode = function (input) {
	return A2(
		$elm$core$Result$map,
		$truqu$elm_base64$Base64$Decode$stripNulls(input),
		A2(
			$elm$core$Result$andThen,
			A2(
				$elm$core$Basics$composeR,
				A2($elm$core$String$foldl, $truqu$elm_base64$Base64$Decode$chomp, $truqu$elm_base64$Base64$Decode$initial),
				$truqu$elm_base64$Base64$Decode$wrapUp),
			$truqu$elm_base64$Base64$Decode$validate(input)));
};
var $truqu$elm_base64$Base64$Decode$decode = A2($elm$core$Basics$composeR, $truqu$elm_base64$Base64$Decode$pad, $truqu$elm_base64$Base64$Decode$validateAndDecode);
var $truqu$elm_base64$Base64$decode = $truqu$elm_base64$Base64$Decode$decode;
var $author$project$Page$Settings$parseJsonArray = function (text) {
	return $elm$core$Result$toMaybe(
		A2(
			$elm$json$Json$Decode$decodeString,
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			text));
};
var $author$project$Page$Settings$reconstructSharableSettings = function (list) {
	if (((((((((list.b && list.b.b) && list.b.b.b) && list.b.b.b.b) && list.b.b.b.b.b) && list.b.b.b.b.b.b) && list.b.b.b.b.b.b.b) && list.b.b.b.b.b.b.b.b) && list.b.b.b.b.b.b.b.b.b) && (!list.b.b.b.b.b.b.b.b.b.b)) {
		var password = list.a;
		var _v1 = list.b;
		var currencies = _v1.a;
		var _v2 = _v1.b;
		var reportCurrenciesString = _v2.a;
		var _v3 = _v2.b;
		var defaultExpenseReportCurrency = _v3.a;
		var _v4 = _v3.b;
		var destinations = _v4.a;
		var _v5 = _v4.b;
		var sources = _v5.a;
		var _v6 = _v5.b;
		var replicationUrl = _v6.a;
		var _v7 = _v6.b;
		var replicationUsername = _v7.a;
		var _v8 = _v7.b;
		var replicationPassword = _v8.a;
		var reportCurrencies = A2($elm$core$String$split, ',', reportCurrenciesString);
		var replication = $elm$core$String$isEmpty(replicationUrl) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			{a8: replicationPassword, cE: replicationUrl, be: replicationUsername});
		var maybeCurrencies = $elm_community$list_extra$List$Extra$uncons(
			A2($elm$core$String$split, ',', currencies));
		return A2(
			$elm$core$Maybe$map,
			function (_v9) {
				var defaultCurrency = _v9.a;
				var otherCurrencies = _v9.b;
				return {
					a8: password,
					bL: {
						cQ: defaultCurrency,
						ft: defaultExpenseReportCurrency,
						cS: A2($elm$core$String$split, ',', destinations),
						es: otherCurrencies,
						p: replication,
						go: reportCurrencies,
						dk: A2($elm$core$String$split, ',', sources),
						gy: true,
						af: ''
					}
				};
			},
			maybeCurrencies);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Settings$parseBase64SharedSettings = function (base64) {
	return A2(
		$elm$core$Maybe$andThen,
		$author$project$Page$Settings$reconstructSharableSettings,
		A2(
			$elm$core$Maybe$andThen,
			$author$project$Page$Settings$parseJsonArray,
			$elm$core$Result$toMaybe(
				$truqu$elm_base64$Base64$decode(base64))));
};
var $author$project$Page$Settings$parseScanResult = function (text) {
	return A2(
		$elm$core$Maybe$andThen,
		function (url) {
			var _v0 = $author$project$Route$fromUrl(url);
			if ((((!_v0.$) && (_v0.a.$ === 2)) && (!_v0.a.a.$)) && (_v0.a.a.a.$ === 2)) {
				var base64 = _v0.a.a.a.a;
				return $author$project$Page$Settings$parseBase64SharedSettings(base64);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		},
		$elm$url$Url$fromString(text));
};
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $author$project$Page$Settings$requestImportJson = function (a) {
	return A2(
		$elm$file$File$Select$file,
		_List_fromArray(
			['application/json']),
		a);
};
var $author$project$Page$Settings$saveSettings = _Platform_outgoingPort(
	'saveSettings',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'defaultCurrency',
					$elm$json$Json$Encode$string($.cQ)),
					_Utils_Tuple2(
					'defaultExpenseReportCurrency',
					$elm$json$Json$Encode$string($.ft)),
					_Utils_Tuple2(
					'destinationAccounts',
					$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.cS)),
					_Utils_Tuple2(
					'otherCurrencies',
					$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.es)),
					_Utils_Tuple2(
					'replication',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'password',
											$elm$json$Json$Encode$string($.a8)),
											_Utils_Tuple2(
											'url',
											$elm$json$Json$Encode$string($.cE)),
											_Utils_Tuple2(
											'username',
											$elm$json$Json$Encode$string($.be))
										]));
							},
							$);
					}($.p)),
					_Utils_Tuple2(
					'reportCurrencies',
					$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.go)),
					_Utils_Tuple2(
					'sourceAccounts',
					$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.dk)),
					_Utils_Tuple2(
					'syncOnSave',
					$elm$json$Json$Encode$bool($.gy)),
					_Utils_Tuple2(
					'version',
					$elm$json$Json$Encode$string($.af))
				]));
	});
var $author$project$Page$Settings$scanQrCode = _Platform_outgoingPort(
	'scanQrCode',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Page$Settings$showDeleteAllModal = _Platform_outgoingPort(
	'showDeleteAllModal',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$file$File$Download$string = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(_File_download, name, mime, content));
	});
var $author$project$Page$Settings$testReplication = _Platform_outgoingPort(
	'testReplication',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					function ($) {
					return $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'password',
								$elm$json$Json$Encode$string($.a8)),
								_Utils_Tuple2(
								'url',
								$elm$json$Json$Encode$string($.cE)),
								_Utils_Tuple2(
								'username',
								$elm$json$Json$Encode$string($.be))
							]));
				}(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $elm$file$File$toString = _File_toString;
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $author$project$HLedger$whiteSpaceRegex = $elm$regex$Regex$fromString('\\s+');
var $author$project$HLedger$cleanWhiteSpace = function (s) {
	return A2(
		$elm$core$Maybe$withDefault,
		s,
		A2(
			$elm$core$Maybe$map,
			function (re) {
				return A3(
					$elm$regex$Regex$replace,
					re,
					function (_v0) {
						return ' ';
					},
					s);
			},
			$author$project$HLedger$whiteSpaceRegex));
};
var $author$project$HLedger$buildEntryComment = function (entry) {
	var comments = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				A2($elm$core$Maybe$map, $author$project$HLedger$cleanWhiteSpace, entry.dK),
				A2(
				$elm$core$Maybe$map,
				function (d) {
					return 'date:' + $justinmimbs$date$Date$toIsoString(d);
				},
				entry.dH)
			]));
	return $elm$core$List$isEmpty(comments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		'; ' + A2($elm$core$String$join, ' ', comments));
};
var $elm$core$String$any = _String_any;
var $author$project$HLedger$formatCurrency = function (currency) {
	return A2($elm$core$String$any, $elm$core$Char$isDigit, currency) ? ('\"' + (currency + '\"')) : currency;
};
var $author$project$HLedger$ourLocale = _Utils_update(
	$cuducos$elm_format_number$FormatNumber$Locales$usLocale,
	{f2: '-', gB: ''});
var $author$project$HLedger$buildTextEntries = function (entries) {
	var parts = A2(
		$elm$core$List$map,
		function (e) {
			return _Utils_Tuple2(
				'  ' + (e.e6 + ' '),
				$author$project$HLedger$formatCurrency(e.fn) + (' ' + A2($cuducos$elm_format_number$FormatNumber$format, $author$project$HLedger$ourLocale, e.e9 / 100.0)));
		},
		entries);
	var findMax = function (nums) {
		return A3($elm$core$List$foldl, $elm$core$Basics$max, 0, nums);
	};
	var maxAccLength = findMax(
		A2(
			$elm$core$List$map,
			$elm$core$String$length,
			A2($elm$core$List$map, $elm$core$Tuple$first, parts)));
	var maxAmntLength = findMax(
		A2(
			$elm$core$List$map,
			$elm$core$String$length,
			A2($elm$core$List$map, $elm$core$Tuple$second, parts)));
	var comments = A2($elm$core$List$map, $author$project$HLedger$buildEntryComment, entries);
	var padded = A2(
		$elm$core$List$map,
		function (_v0) {
			var maybeComment = _v0.a;
			var _v1 = _v0.b;
			var acc = _v1.a;
			var amm = _v1.b;
			return _Utils_ap(
				_List_fromArray(
					[
						A3($elm$core$String$padRight, maxAccLength, ' ', acc),
						A3($elm$core$String$padLeft, maxAmntLength, ' ', amm)
					]),
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Maybe$map, $elm$core$List$singleton, maybeComment)));
		},
		A3($elm$core$List$map2, $elm$core$Tuple$pair, comments, parts));
	return A2(
		$elm$core$List$map,
		$elm$core$String$join(' '),
		padded);
};
var $author$project$HLedger$getTxnNotes = function (maybeNotes) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (notes) {
				return '; ' + $author$project$HLedger$cleanWhiteSpace(notes);
			},
			maybeNotes));
};
var $author$project$HLedger$transactionToLedger = function (txn) {
	var entries = $author$project$HLedger$buildTextEntries(
		_Utils_ap(
			_List_fromArray(
				[txn.fu, txn.gv]),
			A2($elm$core$Maybe$withDefault, _List_Nil, txn.fA)));
	var rows = A2(
		$elm$core$List$cons,
		$justinmimbs$date$Date$toIsoString(txn.dH) + ('  ' + ($author$project$HLedger$cleanWhiteSpace(txn.dK) + $author$project$HLedger$getTxnNotes(txn.ga))),
		entries);
	return A2($elm$core$String$join, '\n', rows);
};
var $author$project$HLedger$transactionsToLedger = function (txns) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $author$project$HLedger$transactionToLedger, txns));
};
var $author$project$Session$updateSettings = F2(
	function (session, settings) {
		if (session.$ === 3) {
			var metadata = session.a;
			return $author$project$Session$Unlocked(
				_Utils_update(
					metadata,
					{bL: settings}));
		} else {
			return session;
		}
	});
var $author$project$Page$Settings$ValidSettings = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Settings$validateNewPassword = function (input) {
	var _v0 = _Utils_Tuple2(
		$elm$core$String$isEmpty(input.u),
		$elm$core$String$isEmpty(input.am));
	if (_v0.a) {
		return $elm$core$Result$Err('Encryption password can\'t be empty');
	} else {
		return _Utils_eq(input.u, input.am) ? $elm$core$Result$Ok(input.u) : $elm$core$Result$Err('Passwords don\'t match');
	}
};
var $author$project$Page$Settings$validateReplication = function (input) {
	return input.p ? A2(
		$elm$core$Result$map,
		$elm$core$Maybe$Just,
		A4(
			$elm$core$Result$map3,
			$author$project$Settings$ReplicationSettings,
			A2($author$project$Misc$isFieldNotBlank, 'Replication URL', input.bJ),
			A2($author$project$Misc$isFieldNotBlank, 'Replication username', input.bK),
			A2($author$project$Misc$isFieldNotBlank, 'Replication password', input.bI))) : $elm$core$Result$Ok($elm$core$Maybe$Nothing);
};
var $author$project$Page$Settings$validateForm = F2(
	function (doValidateNewPassword, input) {
		var reportCurrencies = A2($elm$core$String$split, ',', input.go);
		var newPasswordResult = doValidateNewPassword ? $author$project$Page$Settings$validateNewPassword(input) : $elm$core$Result$Ok('');
		var results = {
			cQ: A2($author$project$Misc$isFieldNotBlank, 'Default currency', input.G),
			ft: A2($author$project$Misc$isFieldNotBlank, 'Expense reports default currency', input.ft),
			cS: A2($author$project$Misc$isFieldNotBlank, 'Destination accounts', input.cS),
			u: newPasswordResult,
			p: $author$project$Page$Settings$validateReplication(input),
			dk: A2($author$project$Misc$isFieldNotBlank, 'Source accounts', input.dk)
		};
		var currencies = A2($elm$core$String$split, ',', input.G);
		var _v0 = function () {
			var _v1 = $elm_community$list_extra$List$Extra$uncons(currencies);
			if (_v1.$ === 1) {
				return _Utils_Tuple2('', _List_Nil);
			} else {
				var _v2 = _v1.a;
				var x = _v2.a;
				var xs = _v2.b;
				return _Utils_Tuple2(x, xs);
			}
		}();
		var defaultCurrency = _v0.a;
		var otherCurrencies = _v0.b;
		var settingsResult = A3(
			$elm$core$Result$map2,
			F2(
				function (f, res) {
					return f(res);
				}),
			A2(
				$elm$core$Result$map,
				function (f) {
					return A2(f, input.gy, reportCurrencies);
				},
				A6(
					$elm$core$Result$map5,
					$author$project$Settings$Settings(input.af),
					A2(
						$elm$core$Result$map,
						$elm$core$String$split('\n'),
						results.cS),
					A2(
						$elm$core$Result$map,
						$elm$core$String$split('\n'),
						results.dk),
					A2(
						$elm$core$Result$map,
						function (_v4) {
							return defaultCurrency;
						},
						results.cQ),
					A2(
						$elm$core$Result$map,
						function (_v5) {
							return otherCurrencies;
						},
						results.cQ),
					results.p)),
			results.ft);
		var settings = A3($elm$core$Result$map2, $author$project$Page$Settings$ValidSettings, settingsResult, results.u);
		return A2(
			$elm$core$Result$mapError,
			function (_v3) {
				return results;
			},
			settings);
	});
var $author$project$Page$Settings$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					var currencies = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{G: currencies});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 1:
					var currencies = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{go: currencies});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 2:
					var defaultExpenseReportCurrency = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{ft: defaultExpenseReportCurrency});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 3:
					var accounts = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{cS: accounts});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 4:
					var accounts = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{dk: accounts});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 5:
					var username = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{be: username});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 6:
					var password = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{u: password});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 7:
					var password = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{am: password});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 8:
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{p: !f.p});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 9:
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{gy: !f.gy});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 11:
					var username = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{bK: username});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 10:
					var url = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{bJ: url});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 12:
					var password = msg.a;
					var f = model.d;
					var inputs = _Utils_update(
						f,
						{bI: password});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs}),
						$author$project$Effect$none);
				case 13:
					return _Utils_Tuple2(
						model,
						$author$project$Effect$fromCmd(
							A2($author$project$Route$goToTransactions, $elm$core$Maybe$Nothing, model.e.c0)));
				case 14:
					var isValid = A2($author$project$Page$Settings$validateForm, model.ak, model.d);
					var _v1 = function () {
						if (isValid.$ === 1) {
							var e = isValid.a;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(e),
								$author$project$Effect$none,
								model.aU);
						} else {
							var _v3 = isValid.a;
							var settings = _v3.a;
							var newPassword = _v3.b;
							var cmd = function () {
								var _v4 = model.e.di;
								if (_v4.$ === 1) {
									return $author$project$Page$Settings$createSettings(
										_Utils_Tuple3(settings, model.d.be, newPassword));
								} else {
									return $author$project$Page$Settings$saveSettings(settings);
								}
							}();
							return _Utils_Tuple3(
								$elm$core$Maybe$Nothing,
								$author$project$Effect$fromCmd(cmd),
								1);
						}
					}();
					var results = _v1.a;
					var effect = _v1.b;
					var working = _v1.c;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aI: results, aM: $elm$core$Maybe$Nothing, aU: working}),
						effect);
				case 16:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aU: 0}),
						$author$project$Effect$none);
				case 15:
					if (msg.a.$ === 1) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aU: 0}),
							$author$project$Effect$none);
					} else {
						var resp = msg.a.a;
						var session = A2($author$project$Session$createSettings, model.e.di, resp);
						var effect = $author$project$Effect$fromShared(
							$author$project$Shared$UpdateSession(session));
						return _Utils_Tuple2(model, effect);
					}
				case 18:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aU: 0}),
						$author$project$Effect$none);
				case 17:
					if (msg.a.$ === 1) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aU: 0}),
							$author$project$Effect$none);
					} else {
						var settings = msg.a.a;
						var session = A2($author$project$Session$updateSettings, model.e.di, settings);
						var effect = $author$project$Effect$batch(
							_List_fromArray(
								[
									$author$project$Effect$fromShared(
									$author$project$Shared$UpdateSession(session)),
									$author$project$Effect$fromCmd(
									A2($author$project$Route$goToTransactions, $elm$core$Maybe$Nothing, model.e.c0))
								]));
						return _Utils_Tuple2(model, effect);
					}
				case 19:
					return _Utils_Tuple2(
						model,
						$author$project$Effect$fromCmd(
							$author$project$Page$Settings$importSampleData(0)));
				case 20:
					return _Utils_Tuple2(
						model,
						$author$project$Effect$fromCmd(
							$author$project$Page$Settings$showDeleteAllModal(0)));
				case 21:
					return _Utils_Tuple2(model, $author$project$Effect$none);
				case 22:
					var cmd = function () {
						var _v5 = model.e.di;
						if (_v5.$ === 3) {
							var metadada = _v5.a;
							return $author$project$Page$Settings$deleteAllData(metadada.be);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}();
					return _Utils_Tuple2(
						model,
						$author$project$Effect$fromCmd(cmd));
				case 23:
					return _Utils_Tuple2(
						model,
						$author$project$Effect$fromCmd(
							$author$project$Page$Settings$requestImportJson($author$project$Page$Settings$JsonSelected)));
				case 24:
					var file = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aU: 2}),
						$author$project$Effect$fromCmd(
							A2(
								$elm$core$Task$perform,
								$author$project$Page$Settings$JsonLoaded,
								$elm$file$File$toString(file))));
				case 25:
					var jsonString = msg.a;
					var _v6 = function () {
						var _v7 = A2($elm$json$Json$Decode$decodeString, $author$project$Transactions$transactionsDecoder, jsonString);
						if (!_v7.$) {
							var txns = _v7.a;
							var errors = A2(
								$elm$core$List$filter,
								$author$project$Misc$isError,
								A2($elm$core$List$map, $author$project$Transactions$balanceTransaction, txns));
							return $elm$core$List$isEmpty(errors) ? _Utils_Tuple3(
								$author$project$Effect$fromCmd(
									$author$project$Page$Settings$importTransactions(
										A2(
											$elm$core$List$map,
											$author$project$Transactions$transactionToJson,
											A2($elm$core$List$map, $author$project$Page$Settings$addMissingAccounts, txns)))),
								$elm$core$Maybe$Nothing,
								model.aU) : _Utils_Tuple3(
								$author$project$Effect$none,
								$elm$core$Maybe$Just('Some transactions are not balanced or not supported!'),
								0);
						} else {
							var e = _v7.a;
							return _Utils_Tuple3(
								$author$project$Effect$none,
								$elm$core$Maybe$Just(
									'Error decoding JSON: ' + $elm$json$Json$Decode$errorToString(e)),
								0);
						}
					}();
					var cmd = _v6.a;
					var error = _v6.b;
					var working = _v6.c;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ac: error, aU: working}),
						cmd);
				case 27:
					if (!msg.a.$) {
						var count = msg.a.a;
						var message = 'Imported: ' + $elm$core$String$fromInt(count);
						var popup = $author$project$Effect$fromShared(
							A3(
								$author$project$Shared$SuccessPoupupMessage,
								$elm$core$Maybe$Just('Import OK!'),
								message,
								10000));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aU: 0}),
							$author$project$Effect$batch(
								_List_fromArray(
									[
										popup,
										$author$project$Effect$fromCmd(
										A2($author$project$Route$goToTransactions, $elm$core$Maybe$Nothing, model.e.c0))
									])));
					} else {
						var decodeError = msg.a.a;
						return _Utils_Tuple2(model, $author$project$Effect$none);
					}
				case 29:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aU: 0}),
						$author$project$Effect$none);
				case 26:
					if (msg.a.$ === 1) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aU: 0}),
							$author$project$Effect$none);
					} else {
						var string = msg.a.a;
						var newModel = _Utils_update(
							model,
							{aU: 2});
						var $temp$msg = $author$project$Page$Settings$JsonLoaded(string),
							$temp$model = newModel;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					}
				case 30:
					var exportFormat = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bt: $elm$core$Maybe$Just(exportFormat),
								aU: 3
							}),
						$author$project$Effect$fromCmd(
							$author$project$Page$Settings$getAllTransactions(0)));
				case 31:
					if (!msg.a.$) {
						var transactions = msg.a.a;
						var _v8 = function () {
							var _v9 = model.bt;
							if ((!_v9.$) && (_v9.a === 1)) {
								var _v10 = _v9.a;
								var header = 'commodity ARS 1,000.00\n\n' + ('commodity EUR 1,000.00\n\n' + ('commodity USD 1,000.00\n\n' + 'commodity BRL 1,000.00\n\n'));
								return _Utils_Tuple3(
									_Utils_ap(
										header,
										$author$project$HLedger$transactionsToLedger(transactions)),
									'ledger',
									'text/plain');
							} else {
								return _Utils_Tuple3(
									A2(
										$elm$json$Json$Encode$encode,
										0,
										$author$project$Transactions$encodeTransactions(transactions)),
									'json',
									'application/json');
							}
						}();
						var content = _v8.a;
						var suffix = _v8.b;
						var mimeType = _v8.c;
						var cmd = A3($elm$file$File$Download$string, 'transactions.' + suffix, mimeType, content);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bt: $elm$core$Maybe$Nothing, aU: 0}),
							$author$project$Effect$fromCmd(cmd));
					} else {
						var decodeError = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ac: $elm$core$Maybe$Just(
										$elm$json$Json$Decode$errorToString(decodeError)),
									aU: 0
								}),
							$author$project$Effect$none);
					}
				case 32:
					return _Utils_Tuple2(model, $author$project$Effect$none);
				case 33:
					var _v11 = function () {
						var _v12 = $author$project$Page$Settings$validateReplication(model.d);
						if (!_v12.$) {
							if (!_v12.a.$) {
								var replicationSettings = _v12.a.a;
								var password = function () {
									var _v13 = model.e.di;
									if (_v13.$ === 3) {
										var metadada = _v13.a;
										return metadada.a8;
									} else {
										return model.d.u;
									}
								}();
								return _Utils_Tuple3(
									4,
									$elm$core$Maybe$Nothing,
									$author$project$Effect$fromCmd(
										$author$project$Page$Settings$testReplication(
											_Utils_Tuple2(replicationSettings, password))));
							} else {
								var _v14 = _v12.a;
								return _Utils_Tuple3(
									model.aU,
									$elm$core$Maybe$Just('This shouldn\'t happen!'),
									$author$project$Effect$none);
							}
						} else {
							var message = _v12.a;
							return _Utils_Tuple3(
								model.aU,
								$elm$core$Maybe$Just(message),
								$author$project$Effect$none);
						}
					}();
					var working = _v11.a;
					var error = _v11.b;
					var cmd = _v11.c;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ac: error, aM: $elm$core$Maybe$Nothing, aU: working}),
						cmd);
				case 39:
					if (!msg.a.$) {
						var message = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aM: $elm$core$Maybe$Just(message),
									aU: 0
								}),
							$author$project$Effect$none);
					} else {
						var decodeError = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ac: $elm$core$Maybe$Just(
										$elm$json$Json$Decode$errorToString(decodeError)),
									aU: 0
								}),
							$author$project$Effect$none);
					}
				case 40:
					if (msg.a.$ === 1) {
						var decodeError = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ac: $elm$core$Maybe$Just(
										$elm$json$Json$Decode$errorToString(decodeError)),
									aU: 0
								}),
							$author$project$Effect$none);
					} else {
						var message = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ac: $elm$core$Maybe$Just(message),
									aU: 0
								}),
							$author$project$Effect$none);
					}
				case 41:
					return _Utils_Tuple2(
						model,
						$author$project$Effect$batch(
							_List_fromArray(
								[
									$author$project$Effect$fromCmd(
									$author$project$Route$goToAppLoading(model.e)),
									$author$project$Effect$fromShared($author$project$Shared$ResetApp)
								])));
				case 28:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aU: 0}),
						$author$project$Effect$fromCmd(
							A2($author$project$Route$goToTransactions, $elm$core$Maybe$Nothing, model.e.c0)));
				case 35:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ap: $elm$core$Maybe$Just($author$project$Page$Settings$ScanQrCode)
							}),
						$author$project$Effect$fromCmd(
							$author$project$Page$Settings$scanQrCode(0)));
				case 34:
					var qrCode = function () {
						var _v15 = model.e.di;
						if (_v15.$ === 3) {
							var metadata = _v15.a;
							return $elm$core$Maybe$Just(
								$author$project$Page$Settings$RenderQrCode(
									A2($author$project$Page$Settings$generateShareSettingsQrCode, model.e.cE, metadata)));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ap: qrCode}),
						$author$project$Effect$none);
				case 36:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ap: $elm$core$Maybe$Nothing}),
						$author$project$Effect$none);
				case 37:
					var base64 = msg.a;
					var addPassword = F2(
						function (p, i) {
							return _Utils_update(
								i,
								{u: p, am: p});
						});
					var inputs = function () {
						var _v16 = $author$project$Page$Settings$parseBase64SharedSettings(base64);
						if (!_v16.$) {
							var sharedSettings = _v16.a;
							return A2(
								addPassword,
								sharedSettings.a8,
								$author$project$Page$Settings$buildInputsFromSettings(sharedSettings.bL));
						} else {
							return model.d;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: inputs, ap: $elm$core$Maybe$Nothing}),
						$author$project$Effect$none);
				case 38:
					if (!msg.a.$) {
						var scanResult = msg.a.a;
						var addPassword = F2(
							function (p, i) {
								return _Utils_update(
									i,
									{u: p, am: p});
							});
						var inputs = function () {
							var _v17 = $author$project$Page$Settings$parseScanResult(scanResult);
							if (!_v17.$) {
								var sharedSettings = _v17.a;
								return A2(
									addPassword,
									sharedSettings.a8,
									$author$project$Page$Settings$buildInputsFromSettings(sharedSettings.bL));
							} else {
								return model.d;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{d: inputs, ap: $elm$core$Maybe$Nothing}),
							$author$project$Effect$none);
					} else {
						var decodeError = msg.a.a;
						return _Utils_Tuple2(model, $author$project$Effect$none);
					}
				default:
					return _Utils_Tuple2(model, $author$project$Effect$none);
			}
		}
	});
var $author$project$Ledger$AccountTransactions = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Transactions$AccountTransactions = function (a) {
	return {$: 1, a: a};
};
var $author$project$Ledger$AllTransactions = {$: 0};
var $author$project$Page$Transactions$AllTransactions = {$: 0};
var $author$project$OurInfiniteScroll$Bottom = 1;
var $author$project$Shared$ErrorMessage = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Transactions$FinishedLoadingData = {$: 15};
var $author$project$Page$Transactions$InfiniteScrollMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Shared$LoadDataIntoMemory = {$: 2};
var $author$project$Misc$MainNotification = $elm$core$Basics$identity;
var $author$project$Misc$NegativeMessage = 1;
var $author$project$Misc$NotificationData = F3(
	function (type_, header, message) {
		return {dZ: header, ef: message, eX: type_};
	});
var $author$project$Page$Transactions$TransactionsLoaded = 1;
var $author$project$Shared$UpdateTransactions = function (a) {
	return {$: 5, a: a};
};
var $author$project$Transactions$balanceTransactions = function (txns) {
	var separateResults = function (results) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (result, _v0) {
					var b = _v0.a;
					var e = _v0.b;
					if (!result.$) {
						var balancedTransaction = result.a;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, balancedTransaction, b),
							e);
					} else {
						var balanceError = result.a;
						return _Utils_Tuple2(
							b,
							A2($elm$core$List$cons, balanceError, e));
					}
				}),
			_Utils_Tuple2(_List_Nil, _List_Nil),
			results);
	};
	return separateResults(
		A2($elm$core$List$map, $author$project$Transactions$balanceTransaction, txns));
};
var $author$project$Page$Transactions$D = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Transactions$T = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Transactions$buildListItems = function (txns) {
	var grouped = A2(
		$elm_community$list_extra$List$Extra$groupWhile,
		F2(
			function (a, b) {
				return _Utils_eq(a.dH, b.dH);
			}),
		$elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.aj;
				},
				txns)));
	var listItems = $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function (nonEmptyList) {
				var _v0 = nonEmptyList;
				var head = _v0.a;
				var tail = _v0.b;
				return A2(
					$elm$core$List$cons,
					$author$project$Page$Transactions$D(head.dH),
					A2(
						$elm$core$List$cons,
						$author$project$Page$Transactions$T(head),
						A2($elm$core$List$map, $author$project$Page$Transactions$T, tail)));
			},
			grouped));
	return listItems;
};
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Misc$delayMillis = F2(
	function (millis, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(millis));
	});
var $elm_community$list_extra$List$Extra$findIndexHelp = F3(
	function (index, predicate, list) {
		findIndexHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					return $elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$predicate = predicate,
						$temp$list = xs;
					index = $temp$index;
					predicate = $temp$predicate;
					list = $temp$list;
					continue findIndexHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$findIndex = $elm_community$list_extra$List$Extra$findIndexHelp(0);
var $elm_community$list_extra$List$Extra$elemIndex = function (x) {
	return $elm_community$list_extra$List$Extra$findIndex(
		$elm$core$Basics$eq(x));
};
var $author$project$Ledger$Cursor = F7(
	function (cursorType, transactions, from, to, total, previous, next) {
		return {cN: cursorType, fJ: from, el: next, ex: previous, gC: to, gE: total, B: transactions};
	});
var $author$project$Ledger$slice = F3(
	function (from, to, list) {
		return A2(
			$elm$core$List$take,
			to - from,
			A2($elm$core$List$drop, from, list));
	});
var $author$project$Ledger$slideSize = 100;
var $author$project$Ledger$windowSize = 200;
var $author$project$Ledger$getCursorAt = F3(
	function (cursorType, ledger, from) {
		var previous = (from > 1) ? $elm$core$Maybe$Just(
			A2($elm$core$Basics$max, from - $author$project$Ledger$slideSize, 0)) : $elm$core$Maybe$Nothing;
		var internal = $author$project$Ledger$getInternal(ledger);
		var allTransactions = function () {
			if (!cursorType.$) {
				return internal.B;
			} else {
				var account = cursorType.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.B;
						},
						A2($elm$core$Dict$get, account, internal.aY)));
			}
		}();
		var total = $elm$core$List$length(allTransactions);
		var transactions = A3($author$project$Ledger$slice, from, from + $author$project$Ledger$windowSize, allTransactions);
		var to = from + $elm$core$List$length(transactions);
		var next = (_Utils_cmp(to, total) < 0) ? $elm$core$Maybe$Just(
			A2($elm$core$Basics$min, total - 1, from + $author$project$Ledger$slideSize)) : $elm$core$Maybe$Nothing;
		return A7($author$project$Ledger$Cursor, cursorType, transactions, from + 1, to, total, previous, next);
	});
var $author$project$Ledger$getTransactions = F2(
	function (cursorType, ledger) {
		return A3($author$project$Ledger$getCursorAt, cursorType, ledger, 0);
	});
var $author$project$Route$goToEditTransaction = F2(
	function (appModel, id) {
		return A2(
			$author$project$Route$pushUrl,
			appModel.c0,
			$author$project$Route$EditRoute(id));
	});
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Route$goToImportJson = function (appModel) {
	return A2(
		$author$project$Route$replaceUrl,
		appModel.c0,
		$author$project$Route$SettingsRoute(
			$elm$core$Maybe$Just($author$project$Route$ImportJson)));
};
var $author$project$Route$goToImportSample = function (appModel) {
	return A2(
		$author$project$Route$replaceUrl,
		appModel.c0,
		$author$project$Route$SettingsRoute(
			$elm$core$Maybe$Just($author$project$Route$ImportSample)));
};
var $author$project$Page$Transactions$hasFinishedLoadingData = F2(
	function (previous, current) {
		var _v0 = _Utils_Tuple2(previous, current);
		if (!_v0.b.$) {
			if (!_v0.a.$) {
				var prev = _v0.a.a;
				var curr = _v0.b.a;
				return (prev < 100) && (curr >= 100);
			} else {
				var curr = _v0.b.a;
				return curr >= 100;
			}
		} else {
			return false;
		}
	});
var $author$project$Page$Transactions$isLoadingData = F2(
	function (previous, current) {
		var _v0 = _Utils_Tuple2(previous, current);
		_v0$2:
		while (true) {
			if (!_v0.a.$) {
				if (!_v0.b.$) {
					var prev = _v0.a.a;
					var curr = _v0.b.a;
					return prev < 100;
				} else {
					break _v0$2;
				}
			} else {
				if (!_v0.b.$) {
					var _v1 = _v0.a;
					return true;
				} else {
					break _v0$2;
				}
			}
		}
		return false;
	});
var $author$project$Ledger$version = function (ledger) {
	return $author$project$Ledger$getInternal(ledger).af;
};
var $author$project$Page$Transactions$ledgerChanged = F2(
	function (prev, curr) {
		return _Utils_cmp(
			$author$project$Ledger$version(prev),
			$author$project$Ledger$version(curr)) < 0;
	});
var $author$project$Page$Transactions$sync = _Platform_outgoingPort(
	'sync',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string($.a8)),
					_Utils_Tuple2(
					'url',
					$elm$json$Json$Encode$string($.cE)),
					_Utils_Tuple2(
					'username',
					$elm$json$Json$Encode$string($.be))
				]));
	});
var $author$project$Page$Transactions$maybeSync = function (appModel) {
	var _v0 = appModel.di;
	if (_v0.$ === 3) {
		var metadada = _v0.a;
		var _v1 = metadada.bL.p;
		if (!_v1.$) {
			var replicationSettings = _v1.a;
			return $elm$core$Maybe$Just(
				$author$project$Effect$fromCmd(
					$author$project$Page$Transactions$sync(replicationSettings)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$OurInfiniteScroll$Top = 0;
var $author$project$Ledger$getNext = F2(
	function (ledger, cursor) {
		return A2(
			$elm$core$Maybe$map,
			A2($author$project$Ledger$getCursorAt, cursor.cN, ledger),
			cursor.el);
	});
var $author$project$Ledger$getPrevious = F2(
	function (ledger, cursor) {
		return A2(
			$elm$core$Maybe$map,
			A2($author$project$Ledger$getCursorAt, cursor.cN, ledger),
			cursor.ex);
	});
var $author$project$Page$Transactions$scrollIntoEnd = _Platform_outgoingPort('scrollIntoEnd', $elm$json$Json$Encode$string);
var $author$project$Page$Transactions$scrollIntoTop = _Platform_outgoingPort('scrollIntoTop', $elm$json$Json$Encode$string);
var $author$project$Page$Transactions$setTransactions = F3(
	function (model, direction, cursor) {
		var transactions = cursor.B;
		var totalLoaded = $elm$core$List$length(transactions);
		var listItems = $author$project$Page$Transactions$buildListItems(transactions);
		var last = function () {
			if (direction === 1) {
				return A2($elm$core$Maybe$andThen, $elm_community$list_extra$List$Extra$last, model.B);
			} else {
				return A2($elm$core$Maybe$andThen, $elm$core$List$head, model.B);
			}
		}();
		var cursorInfo = (totalLoaded > 0) ? $elm$core$Maybe$Just(
			{b2: cursor.gC, cw: cursor.fJ, gE: cursor.gE}) : $elm$core$Maybe$Nothing;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					bm: cursorInfo,
					cd: $elm$core$Maybe$Just(cursor),
					a5: listItems,
					B: $elm$core$Maybe$Just(transactions)
				}),
			last);
	});
var $author$project$Page$Transactions$moveWindowFromElm = F2(
	function (direction, model) {
		var _v0 = function () {
			if (!direction) {
				return _Utils_Tuple3($author$project$Ledger$getNext, 1, $author$project$Page$Transactions$scrollIntoEnd);
			} else {
				return _Utils_Tuple3($author$project$Ledger$getPrevious, 0, $author$project$Page$Transactions$scrollIntoTop);
			}
		}();
		var loader = _v0.a;
		var isDirection = _v0.b;
		var scrollCmd = _v0.c;
		var _v2 = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(model, $elm$core$Maybe$Nothing),
			A2(
				$elm$core$Maybe$map,
				function (cursor) {
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Maybe$Nothing),
						A2(
							$elm$core$Maybe$map,
							A2($author$project$Page$Transactions$setTransactions, model, isDirection),
							A2(loader, model.as.fU, cursor)));
				},
				model.cd));
		var newModel = _v2.a;
		var scrollTo = _v2.b;
		var effect = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Effect$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$Effect$fromCmd,
				A2(
					$elm$core$Maybe$map,
					scrollCmd,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.aj;
						},
						scrollTo))));
		return _Utils_Tuple2(newModel, effect);
	});
var $author$project$Page$Transactions$NextPage = 0;
var $author$project$Page$Transactions$defaultGetTransactionsRequest = {e6: $elm$core$Maybe$Nothing, b0: true, c2: 50, N: $elm$core$Maybe$Nothing};
var $author$project$Page$Transactions$getTransactions = _Platform_outgoingPort(
	'getTransactions',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'account',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.e6)),
					_Utils_Tuple2(
					'descending',
					$elm$json$Json$Encode$bool($.b0)),
					_Utils_Tuple2(
					'maxPageSize',
					$elm$json$Json$Encode$int($.c2)),
					_Utils_Tuple2(
					'pageToken',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.N))
				]));
	});
var $author$project$Page$Transactions$moveWindowFromJavaScript = F2(
	function (direction, model) {
		var maybeToken = function () {
			if (!direction) {
				return model.an;
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var descending = !direction;
		if ($author$project$Misc$isSomething(model.X)) {
			return _Utils_Tuple2(model, $author$project$Effect$none);
		} else {
			if (!maybeToken.$) {
				var pageToken = maybeToken.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							X: $elm$core$Maybe$Just(direction)
						}),
					$author$project$Effect$fromCmd(
						$author$project$Page$Transactions$getTransactions(
							_Utils_update(
								$author$project$Page$Transactions$defaultGetTransactionsRequest,
								{
									b0: descending,
									N: $elm$core$Maybe$Just(pageToken)
								}))));
			} else {
				return _Utils_Tuple2(model, $author$project$Effect$none);
			}
		}
	});
var $author$project$Page$Transactions$moveWindow = F2(
	function (direction, model) {
		var _v0 = model.as.fQ;
		if (_v0 === 2) {
			return A2($author$project$Page$Transactions$moveWindowFromElm, direction, model);
		} else {
			return A2($author$project$Page$Transactions$moveWindowFromJavaScript, direction, model);
		}
	});
var $author$project$OurInfiniteScroll$Both = 2;
var $author$project$Page$Transactions$MoveToPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Transactions$TransactionsNotLoaded = 0;
var $author$project$OurInfiniteScroll$Model = $elm$core$Basics$identity;
var $author$project$OurInfiniteScroll$direction = F2(
	function (newDirection, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{bX: newDirection, bp: newDirection});
	});
var $author$project$OurInfiniteScroll$init = function (loadMoreFunc) {
	return {
		bX: 1,
		bp: 1,
		a3: false,
		cc: $elm$time$Time$millisToPosix(0),
		cf: loadMoreFunc,
		bF: 50,
		cC: 5 * 1000
	};
};
var $author$project$Page$Transactions$NoOp = {$: 17};
var $author$project$Page$Transactions$PreviousPage = 1;
var $author$project$Page$Transactions$loadMoreCommand = function (direction) {
	switch (direction) {
		case 1:
			return A2(
				$author$project$Misc$delayMillis,
				10,
				$author$project$Page$Transactions$MoveToPage(0));
		case 0:
			return A2(
				$author$project$Misc$delayMillis,
				10,
				$author$project$Page$Transactions$MoveToPage(1));
		default:
			return A2($author$project$Misc$delayMillis, 10, $author$project$Page$Transactions$NoOp);
	}
};
var $author$project$Page$Transactions$refreshTransactions = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{
				aD: A2(
					$author$project$OurInfiniteScroll$direction,
					2,
					$author$project$OurInfiniteScroll$init($author$project$Page$Transactions$loadMoreCommand)),
				a5: _List_Nil,
				bA: 0,
				X: $elm$core$Maybe$Just(0),
				an: $elm$core$Maybe$Nothing,
				N: $elm$core$Maybe$Nothing,
				cn: $elm$core$Maybe$Nothing,
				B: $elm$core$Maybe$Nothing
			}),
		$author$project$Effect$fromCmd(
			A2(
				$author$project$Misc$delayMillis,
				10,
				$author$project$Page$Transactions$MoveToPage(0))));
};
var $author$project$Page$Transactions$setInitialCursor = F2(
	function (model, ledger) {
		var cursorType = function () {
			var _v0 = model.by;
			if (!_v0.$) {
				return $author$project$Ledger$AllTransactions;
			} else {
				var a = _v0.a;
				return $author$project$Ledger$AccountTransactions(a);
			}
		}();
		return A3(
			$author$project$Page$Transactions$setTransactions,
			model,
			1,
			A2($author$project$Ledger$getTransactions, cursorType, ledger)).a;
	});
var $author$project$OurInfiniteScroll$stopLoading = function (_v0) {
	var model = _v0;
	return _Utils_update(
		model,
		{a3: false});
};
var $author$project$Transactions$toTransaction = function (balanced) {
	var t = balanced;
	return t;
};
var $author$project$OurInfiniteScroll$Timeout = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$OurInfiniteScroll$CurrTime = function (a) {
	return {$: 1, a: a};
};
var $author$project$OurInfiniteScroll$shouldLoadMore = F2(
	function (model, _v0) {
		var scrollTop = _v0.eL;
		var contentHeight = _v0.dF;
		var containerHeight = _v0.dE;
		if (model.a3) {
			return $elm$core$Maybe$Nothing;
		} else {
			var shouldLoadTop = _Utils_cmp(scrollTop, model.bF) < 1;
			var excessHeight = contentHeight - containerHeight;
			var shouldLoadBottom = _Utils_cmp(scrollTop, excessHeight - model.bF) > -1;
			var _v1 = model.bX;
			switch (_v1) {
				case 0:
					return shouldLoadTop ? $elm$core$Maybe$Just(0) : $elm$core$Maybe$Nothing;
				case 1:
					return shouldLoadBottom ? $elm$core$Maybe$Just(1) : $elm$core$Maybe$Nothing;
				default:
					return shouldLoadTop ? $elm$core$Maybe$Just(0) : (shouldLoadBottom ? $elm$core$Maybe$Just(1) : $elm$core$Maybe$Nothing);
			}
		}
	});
var $author$project$OurInfiniteScroll$startLoading = function (_v0) {
	var model = _v0;
	return _Utils_update(
		model,
		{a3: true});
};
var $author$project$OurInfiniteScroll$scrollUpdate = F3(
	function (mapper, pos, _v0) {
		var model = _v0;
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(
				model,
				A2($elm$core$Platform$Cmd$map, mapper, $elm$core$Platform$Cmd$none)),
			A2(
				$elm$core$Maybe$map,
				function (dir) {
					return _Utils_Tuple2(
						$author$project$OurInfiniteScroll$startLoading(
							_Utils_update(
								model,
								{bp: dir})),
						A2(
							$elm$core$Platform$Cmd$map,
							mapper,
							A2($elm$core$Task$perform, $author$project$OurInfiniteScroll$CurrTime, $elm$time$Time$now)));
				},
				A2($author$project$OurInfiniteScroll$shouldLoadMore, model, pos)));
	});
var $author$project$OurInfiniteScroll$update = F3(
	function (mapper, msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				var pos = msg.a;
				return A3($author$project$OurInfiniteScroll$scrollUpdate, mapper, pos, model);
			case 1:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cc: time}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								model.cf(model.bp),
								A2(
								$elm$core$Platform$Cmd$map,
								mapper,
								A2(
									$elm$core$Task$perform,
									$author$project$OurInfiniteScroll$Timeout(time),
									$elm$core$Process$sleep(model.cC)))
							])));
			default:
				var time = msg.a;
				return _Utils_eq(time, model.cc) ? _Utils_Tuple2(
					$author$project$OurInfiniteScroll$stopLoading(model),
					A2($elm$core$Platform$Cmd$map, mapper, $elm$core$Platform$Cmd$none)) : _Utils_Tuple2(
					model,
					A2($elm$core$Platform$Cmd$map, mapper, $elm$core$Platform$Cmd$none));
		}
	});
var $author$project$Page$Transactions$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var shared = msg.a;
				var hasLedgerChanged = A2($author$project$Page$Transactions$ledgerChanged, model.as.fU, shared.fU);
				var newModel = hasLedgerChanged ? A2($author$project$Page$Transactions$setInitialCursor, model, shared.fU) : model;
				var hasFinishedLoading = function () {
					var _v2 = _Utils_Tuple2(model.as.fQ, shared.fQ);
					if ((_v2.a === 1) && (_v2.b === 2)) {
						var _v3 = _v2.a;
						var _v4 = _v2.b;
						return true;
					} else {
						return false;
					}
				}();
				var _v1 = A2($author$project$Page$Transactions$hasFinishedLoadingData, model.as.b9, shared.b9) ? _Utils_Tuple2(
					true,
					$author$project$Effect$fromCmd(
						A2($author$project$Misc$delayMillis, 1000, $author$project$Page$Transactions$FinishedLoadingData))) : (A2($author$project$Page$Transactions$isLoadingData, model.as.b9, shared.b9) ? _Utils_Tuple2(true, $author$project$Effect$none) : _Utils_Tuple2(false, $author$project$Effect$none));
				var loadingData = _v1.a;
				var effect = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{
							dr: $elm$core$List$sort(
								$elm$core$Dict$keys(shared.dr)),
							as: shared,
							bz: loadingData
						}),
					effect);
			case 3:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromShared($author$project$Shared$LoadDataIntoMemory));
			case 4:
				var direction = msg.a;
				return A2($author$project$Page$Transactions$moveWindow, direction, model);
			case 2:
				return $author$project$Page$Transactions$refreshTransactions(model);
			case 1:
				var msg_ = msg.a;
				var _v5 = A3($author$project$OurInfiniteScroll$update, $author$project$Page$Transactions$InfiniteScrollMsg, msg_, model.aD);
				var infScroll = _v5.a;
				var cmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aD: infScroll}),
					$author$project$Effect$fromCmd(cmd));
			case 5:
				if (!msg.a.$) {
					var transactionsResponse = msg.a.a;
					var previousPageToken = A2(
						$elm$core$Maybe$andThen,
						function (d) {
							if (d === 1) {
								return transactionsResponse.an;
							} else {
								return model.N;
							}
						},
						model.X);
					var pageToken = transactionsResponse.N;
					var nextPageToken = A2(
						$elm$core$Maybe$andThen,
						function (d) {
							if (d === 1) {
								return model.N;
							} else {
								return transactionsResponse.an;
							}
						},
						model.X);
					var maybeBroken = transactionsResponse.aI;
					var infScroll = $author$project$OurInfiniteScroll$stopLoading(model.aD);
					var currentTransactions = function () {
						var _v8 = model.bA;
						if (_v8 === 1) {
							return A2($elm$core$Maybe$withDefault, _List_Nil, model.B);
						} else {
							return _List_Nil;
						}
					}();
					var _v6 = $author$project$Transactions$balanceTransactions(maybeBroken);
					var newBalancedTransactions = _v6.a;
					var balanceErrors = _v6.b;
					var notifyBalanceErrorsEffect = function () {
						if (!balanceErrors.b) {
							return $author$project$Effect$none;
						} else {
							var errorMessage = 'Ingored ' + ($elm$core$String$fromInt(
								$elm$core$List$length(balanceErrors)) + ' transactions with errors');
							return $author$project$Effect$fromShared(
								$author$project$Shared$ErrorMessage(errorMessage));
						}
					}();
					var newTransactions = A2($elm$core$List$map, $author$project$Transactions$toTransaction, newBalancedTransactions);
					var transactions = _Utils_ap(currentTransactions, newTransactions);
					var listItems = $author$project$Page$Transactions$buildListItems(transactions);
					var totalLoaded = $elm$core$List$length(transactions);
					var cursorInfo = (totalLoaded > 0) ? $elm$core$Maybe$Just(
						{b2: totalLoaded, cw: 1, gE: transactionsResponse.gE}) : $elm$core$Maybe$Nothing;
					var updateTransactionsEffect = $author$project$Effect$fromShared(
						$author$project$Shared$UpdateTransactions(transactions));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bm: cursorInfo,
								aD: infScroll,
								a5: listItems,
								bA: 1,
								X: $elm$core$Maybe$Nothing,
								an: nextPageToken,
								N: pageToken,
								cn: previousPageToken,
								B: $elm$core$Maybe$Just(transactions)
							}),
						$author$project$Effect$batch(
							_List_fromArray(
								[updateTransactionsEffect, notifyBalanceErrorsEffect])));
				} else {
					var error = msg.a.a;
					var errorMessage = 'Could not get transactions: ' + $elm$json$Json$Decode$errorToString(error);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{X: $elm$core$Maybe$Nothing}),
						$author$project$Effect$fromShared(
							$author$project$Shared$ErrorMessage(errorMessage)));
				}
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{X: $elm$core$Maybe$Nothing}),
					$author$project$Effect$fromShared(
						$author$project$Shared$ErrorMessage('Could not get transactions!')));
			case 8:
				var _v11 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(false, $author$project$Effect$none),
					A2(
						$elm$core$Maybe$map,
						function (e) {
							return _Utils_Tuple2(true, e);
						},
						$author$project$Page$Transactions$maybeSync(model.as)));
				var replicating = _v11.a;
				var effect = _v11.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aH: replicating}),
					effect);
			case 9:
				if (!msg.a.$) {
					var results = msg.a.a;
					var message = '↑ Sent: ' + ($elm$core$String$fromInt(results.eN) + ('  /  ↓ Received: ' + $elm$core$String$fromInt(results.de)));
					var popupEffect = $author$project$Effect$fromShared(
						A3(
							$author$project$Shared$SuccessPoupupMessage,
							$elm$core$Maybe$Just('Synched OK'),
							message,
							5000));
					var effect = (results.de > 0) ? $author$project$Effect$batch(
						_List_fromArray(
							[
								popupEffect,
								$author$project$Page$Transactions$refreshTransactions(model).b
							])) : popupEffect;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aH: false}),
						effect);
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$none);
				}
			case 10:
				var message = 'Could not synchronize';
				var notificationData = A3(
					$author$project$Misc$NotificationData,
					1,
					$elm$core$Maybe$Just('Synchronization Error'),
					message);
				var notification = notificationData;
				var am = model.as;
				var appModel = _Utils_update(
					am,
					{
						gb: $elm$core$Maybe$Just(notification)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{as: appModel, aH: false}),
					$author$project$Effect$none);
			case 14:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromShared($author$project$Shared$DismissNotification));
			case 13:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromShared($author$project$Shared$DismissPopupNotification));
			case 7:
				var transaction = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromCmd(
						A2($author$project$Route$goToEditTransaction, model.as, transaction.aj)));
			case 11:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromCmd(
						$author$project$Route$goToImportSample(model.as)));
			case 12:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$fromCmd(
						$author$project$Route$goToImportJson(model.as)));
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bz: false}),
					$author$project$Effect$none);
			case 16:
				var term = msg.a;
				var searchAccount = $elm$core$Maybe$Just(term);
				var account = $elm$core$String$trim(term);
				var maybeAccount = A2($elm_community$list_extra$List$Extra$elemIndex, account, model.dr);
				var newModel = function () {
					var _v12 = model.as.fQ;
					if (_v12 === 2) {
						var _v13 = _Utils_Tuple2(account, maybeAccount);
						if (!_v13.b.$) {
							var cursor = A2(
								$author$project$Ledger$getTransactions,
								$author$project$Ledger$AccountTransactions(account),
								model.as.fU);
							var m = A3($author$project$Page$Transactions$setTransactions, model, 1, cursor).a;
							return _Utils_update(
								m,
								{
									by: $author$project$Page$Transactions$AccountTransactions(account)
								});
						} else {
							if (_v13.a === '') {
								var _v14 = _v13.b;
								var cursor = A2($author$project$Ledger$getTransactions, $author$project$Ledger$AllTransactions, model.as.fU);
								var m = A3($author$project$Page$Transactions$setTransactions, model, 1, cursor).a;
								return _Utils_update(
									m,
									{by: $author$project$Page$Transactions$AllTransactions});
							} else {
								var _v15 = _v13.b;
								return model;
							}
						}
					} else {
						return model;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{cs: searchAccount}),
					$author$project$Effect$none);
			default:
				return _Utils_Tuple2(model, $author$project$Effect$none);
		}
	});
var $author$project$Shared$DataLoaded = 2;
var $author$project$Shared$DataLoading = 1;
var $author$project$Session$Encrypted = {$: 2};
var $author$project$Session$FirstRun = {$: 1};
var $author$project$Session$Locked = {$: 4};
var $author$project$Misc$PopupNotification = $elm$core$Basics$identity;
var $author$project$Misc$PositiveMessage = 2;
var $author$project$Ledger$accountNames = function (ledger) {
	var internal = $author$project$Ledger$getInternal(ledger);
	return $elm$core$Dict$keys(internal.aY);
};
var $author$project$Ledger$Account = F2(
	function (account, transactions) {
		return {e6: account, B: transactions};
	});
var $author$project$Ledger$ILedger = F5(
	function (transactions, index, byAccount, stats, version) {
		return {aY: byAccount, aC: index, ab: stats, B: transactions, af: version};
	});
var $author$project$Ledger$AccountStats = F2(
	function (account, stats) {
		return {e6: account, ab: stats};
	});
var $author$project$Ledger$Stats = F3(
	function (currency, count, sum) {
		return {aZ: count, fn: currency, bd: sum};
	});
var $author$project$Ledger$entriesToStats = function (entries) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var currency = _v0.a;
				var byCurrency = _v0.b;
				var stats = A3(
					$elm$core$List$foldl,
					F2(
						function (e, s) {
							return _Utils_update(
								s,
								{aZ: s.aZ + 1, bd: s.bd + e.e9});
						}),
					A3($author$project$Ledger$Stats, currency, 0, 0),
					byCurrency);
				return _Utils_Tuple2(currency, stats);
			},
			A2(
				$author$project$Misc$groupingBy,
				function ($) {
					return $.fn;
				},
				entries)));
};
var $author$project$Ledger$expandAccounts = function (account) {
	var parts = A2($elm$core$String$split, ':', account);
	if (parts.b) {
		var head = parts.a;
		var tail = parts.b;
		return $elm$core$List$reverse(
			A3(
				$elm$core$List$foldl,
				F2(
					function (part, acc) {
						var last = A2(
							$elm$core$Maybe$withDefault,
							'',
							$elm$core$List$head(acc));
						return A2($elm$core$List$cons, last + (':' + part), acc);
					}),
				_List_fromArray(
					[head]),
				tail));
	} else {
		return _List_fromArray(
			[account]);
	}
};
var $author$project$Ledger$getStats = function (txn) {
	var entries = $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function (e) {
				return A2(
					$elm$core$List$map,
					function (a) {
						return _Utils_update(
							e,
							{e6: a});
					},
					$author$project$Ledger$expandAccounts(e.e6));
			},
			_Utils_ap(
				_List_fromArray(
					[txn.fu, txn.gv]),
				A2($elm$core$Maybe$withDefault, _List_Nil, txn.fA))));
	var byAccount = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var account = _v0.a;
				var byCurrency = _v0.b;
				return _Utils_Tuple2(
					account,
					A2($author$project$Ledger$AccountStats, account, byCurrency));
			},
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$mapSecond($author$project$Ledger$entriesToStats),
				A2(
					$author$project$Misc$groupingBy,
					function ($) {
						return $.e6;
					},
					entries))));
	return byAccount;
};
var $author$project$Ledger$mergeDicts = F3(
	function (a, b, mergeFn) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (key, val, reduced) {
					return A3(
						$elm$core$Dict$update,
						key,
						function (maybeInB) {
							if (!maybeInB.$) {
								var inB = maybeInB.a;
								return $elm$core$Maybe$Just(
									A2(mergeFn, inB, val));
							} else {
								return $elm$core$Maybe$Just(val);
							}
						},
						reduced);
				}),
			b,
			a);
	});
var $author$project$Ledger$mergeStats = F2(
	function (a, b) {
		return _Utils_update(
			a,
			{aZ: a.aZ + b.aZ, bd: a.bd + b.bd});
	});
var $author$project$Ledger$mergeAccountStats = F2(
	function (a, b) {
		var stats = A3($author$project$Ledger$mergeDicts, a.ab, b.ab, $author$project$Ledger$mergeStats);
		return _Utils_update(
			a,
			{ab: stats});
	});
var $author$project$Ledger$reduceAccountStatsDict = F2(
	function (a, b) {
		return A3($author$project$Ledger$mergeDicts, a, b, $author$project$Ledger$mergeAccountStats);
	});
var $author$project$Ledger$addBalancedTransactions = F2(
	function (ledger, txns) {
		var internal = $author$project$Ledger$getInternal(ledger);
		var newTransactionsDict = A3(
			$elm$core$List$foldl,
			F2(
				function (t, dict) {
					return A3($elm$core$Dict$insert, t.aj, t, dict);
				}),
			internal.aC,
			A2($elm$core$List$map, $author$project$Transactions$toTransaction, txns));
		var allTransactions = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.aj;
				},
				$elm$core$Dict$values(newTransactionsDict)));
		var newByAccount = A2(
			$elm$core$Dict$map,
			F2(
				function (_v2, account) {
					return _Utils_update(
						account,
						{
							B: $elm$core$List$reverse(
								A2(
									$elm$core$List$sortBy,
									function ($) {
										return $.aj;
									},
									account.B))
						});
				}),
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v0, dict) {
						var account = _v0.a;
						var t = _v0.b;
						return A3(
							$elm$core$Dict$update,
							account,
							function (maybeAccount) {
								if (!maybeAccount.$) {
									var acc = maybeAccount.a;
									return $elm$core$Maybe$Just(
										_Utils_update(
											acc,
											{
												B: A2($elm$core$List$cons, t, acc.B)
											}));
								} else {
									return $elm$core$Maybe$Just(
										A2(
											$author$project$Ledger$Account,
											account,
											_List_fromArray(
												[t])));
								}
							},
							dict);
					}),
				$elm$core$Dict$empty,
				A2(
					$elm$core$List$concatMap,
					function (t) {
						return A2(
							$elm$core$List$map,
							function (a) {
								return _Utils_Tuple2(a, t);
							},
							t.dr);
					},
					allTransactions)));
		var newStats = A3(
			$elm$core$List$foldl,
			$author$project$Ledger$reduceAccountStatsDict,
			$elm$core$Dict$empty,
			A2($elm$core$List$map, $author$project$Ledger$getStats, allTransactions));
		return A5($author$project$Ledger$ILedger, allTransactions, newTransactionsDict, newByAccount, newStats, internal.af + 1);
	});
var $author$project$Ledger$accountFromList = F2(
	function (account, transactions) {
		return A2($author$project$Ledger$Account, account, transactions);
	});
var $author$project$Misc$isOk = function (res) {
	if (res.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Ledger$mergeAccount = F2(
	function (a, b) {
		var transactions = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.aj;
				},
				_Utils_ap(a.B, b.B)));
		return _Utils_update(
			a,
			{B: transactions});
	});
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $author$project$Ledger$addTransactions = F2(
	function (ledger, txns) {
		var internal = $author$project$Ledger$getInternal(ledger);
		var foldByAccount = F3(
			function (txn, account, dict) {
				return A3(
					$elm$core$Dict$update,
					account,
					function (maybeList) {
						if (maybeList.$ === 1) {
							return $elm$core$Maybe$Just(
								_List_fromArray(
									[txn]));
						} else {
							var l = maybeList.a;
							return $elm$core$Maybe$Just(
								A2($elm$core$List$cons, txn, l));
						}
					},
					dict);
			});
		var _v0 = A2(
			$elm$core$List$partition,
			A2($elm$core$Basics$composeR, $author$project$Transactions$balanceTransaction, $author$project$Misc$isOk),
			txns);
		var balanced = _v0.a;
		var notBalanced = _v0.b;
		var transactions = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.aj;
				},
				balanced));
		var byAccountReversed = A3(
			$elm$core$List$foldl,
			F2(
				function (t, d) {
					return A3(
						$elm$core$List$foldl,
						foldByAccount(t),
						d,
						t.dr);
				}),
			$elm$core$Dict$empty,
			transactions);
		var byAccount = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (_v2) {
					var k = _v2.a;
					var v = _v2.b;
					return _Utils_Tuple2(
						k,
						A2(
							$author$project$Ledger$accountFromList,
							k,
							$elm$core$List$reverse(v)));
				},
				$elm$core$Dict$toList(byAccountReversed)));
		var newByAccount = A3(
			$elm$core$Dict$foldl,
			F3(
				function (account, existing, d) {
					return A3(
						$elm$core$Dict$update,
						account,
						function (maybeExists) {
							if (!maybeExists.$) {
								var a = maybeExists.a;
								return $elm$core$Maybe$Just(
									A2($author$project$Ledger$mergeAccount, a, existing));
							} else {
								return $elm$core$Maybe$Just(existing);
							}
						},
						d);
				}),
			internal.aY,
			byAccount);
		var index = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (t) {
					return _Utils_Tuple2(t.aj, t);
				},
				transactions));
		var newIndex = A2($elm$core$Dict$union, index, internal.aC);
		var newStatsList = A2($elm$core$List$map, $author$project$Ledger$getStats, transactions);
		var newStats = A3($elm$core$List$foldl, $author$project$Ledger$reduceAccountStatsDict, internal.ab, newStatsList);
		var newTransactions = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.aj;
				},
				_Utils_ap(internal.B, transactions)));
		return _Utils_Tuple2(
			A5($author$project$Ledger$ILedger, newTransactions, newIndex, newByAccount, newStats, internal.af + 1),
			notBalanced);
	});
var $author$project$Ledger$addTransaction = F2(
	function (ledger, txn) {
		var internal = $author$project$Ledger$getInternal(ledger);
		var newIndex = A3($elm$core$Dict$insert, txn.aj, txn, internal.aC);
		var newTransactions = $elm$core$Dict$values(newIndex);
		var newInternal = $author$project$Ledger$getInternal(
			A2($author$project$Ledger$addTransactions, $author$project$Ledger$empty, newTransactions).a);
		return _Utils_update(
			newInternal,
			{af: internal.af + 1});
	});
var $author$project$Transactions$Account = F2(
	function (name, shortName) {
		return {a7: name, gt: shortName};
	});
var $author$project$Transactions$accountShortName = function (a) {
	var parts = $elm$core$List$reverse(
		A2($elm$core$String$split, ':', a));
	var parents = A2(
		$elm$core$List$map,
		$elm$core$String$left(1),
		$elm$core$List$reverse(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(parts))));
	var account = A2(
		$elm$core$Maybe$withDefault,
		a,
		$elm$core$List$head(parts));
	return $elm$core$List$isEmpty(parents) ? account : (A2($elm$core$String$join, ':', parents) + (':' + account));
};
var $author$project$Shared$buildAccounts = function (accounts) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (a) {
				return _Utils_Tuple2(
					a,
					A2(
						$author$project$Transactions$Account,
						a,
						$author$project$Transactions$accountShortName(a)));
			},
			accounts));
};
var $author$project$Shared$buildErrorNotication = F2(
	function (title, message) {
		var data = A3(
			$author$project$Misc$NotificationData,
			1,
			$elm$core$Maybe$Just(title),
			message);
		return data;
	});
var $author$project$Ledger$deleteTransactioninternal = F2(
	function (ledger, id) {
		var internal = $author$project$Ledger$getInternal(ledger);
		var newIndex = A2($elm$core$Dict$remove, id, internal.aC);
		var newTransactions = $elm$core$Dict$values(newIndex);
		var newInternal = $author$project$Ledger$getInternal(
			A2($author$project$Ledger$addTransactions, $author$project$Ledger$empty, newTransactions).a);
		return _Utils_update(
			newInternal,
			{af: internal.af + 1});
	});
var $author$project$Ledger$deleteTransaction = F2(
	function (ledger, id) {
		var internal = $author$project$Ledger$getInternal(ledger);
		var _v0 = A2($elm$core$Dict$get, id, internal.aC);
		if (!_v0.$) {
			return A2($author$project$Ledger$deleteTransactioninternal, ledger, id);
		} else {
			return ledger;
		}
	});
var $author$project$Shared$getInitialTransactions = _Platform_outgoingPort(
	'getInitialTransactions',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Shared$getLatestTransactions = _Platform_outgoingPort(
	'getLatestTransactions',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Shared$initialize = _Platform_outgoingPort(
	'initialize',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Shared$buildJsonDecodeErrorNotification = F2(
	function (title, decodeError) {
		return A2(
			$author$project$Shared$buildErrorNotication,
			title,
			$elm$json$Json$Decode$errorToString(decodeError));
	});
var $author$project$Shared$notifyDecodeError = F3(
	function (title, decodeError, model) {
		var notification = A2($author$project$Shared$buildJsonDecodeErrorNotification, title, decodeError);
		return _Utils_update(
			model,
			{
				gb: $elm$core$Maybe$Just(notification)
			});
	});
var $author$project$Transactions$FrequentDescription = F4(
	function (description, destination, source, count) {
		return {aZ: count, dK: description, fu: destination, gv: source};
	});
var $author$project$Shared$buildFrequentDescriptions = function (txns) {
	var acc = F2(
		function (_v2, dict) {
			var desc = _v2.a;
			var rec = _v2.b;
			return A3(
				$elm$core$Dict$update,
				desc,
				function (exists) {
					if (exists.$ === 1) {
						return $elm$core$Maybe$Just(rec);
					} else {
						var current = exists.a;
						return $elm$core$Maybe$Just(
							_Utils_update(
								current,
								{aZ: current.aZ + 1}));
					}
				},
				dict);
		});
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$take,
			50,
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					function (_v0) {
						var fd = _v0.b;
						return fd.aZ;
					},
					$elm$core$Dict$toList(
						A3(
							$elm$core$List$foldl,
							acc,
							$elm$core$Dict$empty,
							A2(
								$elm$core$List$map,
								function (t) {
									return _Utils_Tuple2(
										t.dK,
										A4($author$project$Transactions$FrequentDescription, t.dK, t.fu.e6, t.gv.e6, 1));
								},
								txns)))))));
};
var $author$project$Shared$processCommonData = function (transactions) {
	var frequentDescriptions = $author$project$Shared$buildFrequentDescriptions(transactions);
	var accounts = $author$project$Shared$buildAccounts(
		$elm_community$list_extra$List$Extra$unique(
			$elm$core$List$sort(
				$elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.dr;
						},
						transactions)))));
	return _Utils_Tuple2(accounts, frequentDescriptions);
};
var $author$project$Shared$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					A2($author$project$Shared$initialModel, model.c0, model.cE),
					$author$project$Shared$initialize(0));
			case 4:
				var _v1 = function () {
					var _v2 = model.di;
					if (_v2.$ === 3) {
						var session = $author$project$Session$Locked;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{di: session}),
							A2($elm$browser$Browser$Navigation$replaceUrl, model.c0, '/#/' + $author$project$RouteNames$loading));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}();
				var newModel = _v1.a;
				var cmd = _v1.b;
				return _Utils_Tuple2(newModel, cmd);
			case 3:
				var session = msg.a;
				var url = (_Utils_eq(
					model.bv.fI,
					$elm$core$Maybe$Just('/e2e')) || _Utils_eq(
					model.bv.fI,
					$elm$core$Maybe$Just('/' + $author$project$RouteNames$loading))) ? '/' : $elm$url$Url$toString(model.bv);
				var cmd = function () {
					var _v3 = _Utils_Tuple2(model.di, session);
					if (_v3.b.$ === 3) {
						switch (_v3.a.$) {
							case 3:
								return $elm$core$Platform$Cmd$none;
							case 4:
								var _v4 = _v3.a;
								return A2($elm$browser$Browser$Navigation$replaceUrl, model.c0, url);
							default:
								return $elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											$author$project$Shared$getLatestTransactions(0),
											A2($elm$browser$Browser$Navigation$replaceUrl, model.c0, url)
										]));
						}
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{di: session}),
					cmd);
			case 5:
				var transactions = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{B: transactions}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					$author$project$Shared$getLatestTransactions(0));
			case 2:
				return _Utils_Tuple2(
					model,
					$author$project$Shared$getInitialTransactions(0));
			case 6:
				var txn = msg.a;
				var ledger = function () {
					var _v5 = model.fQ;
					if (_v5 === 2) {
						return A2($author$project$Ledger$addTransaction, model.fU, txn);
					} else {
						return model.fU;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fU: ledger}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var id = msg.a;
				var ledger = function () {
					var _v6 = model.fQ;
					if (_v6 === 2) {
						return A2($author$project$Ledger$deleteTransaction, model.fU, id);
					} else {
						return model.fU;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fU: ledger}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var header = msg.a;
				var message = msg.b;
				var dismissAfter = msg.c;
				var popupNotification = A3($author$project$Misc$NotificationData, 2, header, message);
				var dismissCmd = A2($author$project$Misc$delayMillis, dismissAfter, $author$project$Shared$DismissPopupNotification);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gi: $elm$core$Maybe$Just(popupNotification)
						}),
					dismissCmd);
			case 9:
				var message = msg.a;
				var notificationData = A3($author$project$Misc$NotificationData, 1, $elm$core$Maybe$Nothing, message);
				var notification = notificationData;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gb: $elm$core$Maybe$Just(notification)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gi: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gb: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			default:
				switch (msg.a.$) {
					case 3:
						if (msg.a.a.$ === 1) {
							var decodeError = msg.a.a.a;
							return _Utils_Tuple2(
								A3($author$project$Shared$notifyDecodeError, 'Error reading latest transactions from DB', decodeError, model),
								$elm$core$Platform$Cmd$none);
						} else {
							var transactions = msg.a.a.a;
							var _v7 = $author$project$Shared$processCommonData(transactions);
							var accounts = _v7.a;
							var frequentDescriptions = _v7.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{dr: accounts, dS: frequentDescriptions}),
								$elm$core$Platform$Cmd$none);
						}
					case 4:
						if (!msg.a.a.$) {
							var message = msg.a.a.a;
							var notificationData = A3($author$project$Misc$NotificationData, 2, $elm$core$Maybe$Nothing, message);
							var notification = notificationData;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										gb: $elm$core$Maybe$Just(notification)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							var decodeError = msg.a.a.a;
							return _Utils_Tuple2(
								A3($author$project$Shared$notifyDecodeError, 'Error reading debug message from JavaScript', decodeError, model),
								$elm$core$Platform$Cmd$none);
						}
					case 0:
						if (msg.a.a.$ === 1) {
							var decodeError = msg.a.a.a;
							return _Utils_Tuple2(
								A3($author$project$Shared$notifyDecodeError, 'Error reading initial transactions from DB', decodeError, model),
								$elm$core$Platform$Cmd$none);
						} else {
							var data = msg.a.a.a;
							var separateResults = function (results) {
								return A3(
									$elm$core$List$foldl,
									F2(
										function (result, _v10) {
											var b = _v10.a;
											var e = _v10.b;
											if (!result.$) {
												var balancedTransaction = result.a;
												return _Utils_Tuple2(
													A2($elm$core$List$cons, balancedTransaction, b),
													e);
											} else {
												var balanceError = result.a;
												return _Utils_Tuple2(
													b,
													A2($elm$core$List$cons, balanceError, e));
											}
										}),
									_Utils_Tuple2(_List_Nil, _List_Nil),
									results);
							};
							var percentage = $elm$core$Maybe$Just(100 * (1 - (data.dg / data.gE)));
							var maybeBalanced = A2($elm$core$List$map, $author$project$Transactions$balanceTransaction, data.B);
							var inMemoryStatus = (data.dg > 0) ? 1 : 2;
							var existing = model.fU;
							var accounts = $author$project$Shared$buildAccounts(
								$author$project$Ledger$accountNames(existing));
							var _v8 = separateResults(maybeBalanced);
							var balanced = _v8.a;
							var errors = _v8.b;
							var ledger = A2($author$project$Ledger$addBalancedTransactions, existing, balanced);
							var notification = function () {
								if (!errors.b) {
									return $elm$core$Maybe$Nothing;
								} else {
									return $elm$core$Maybe$Just(
										A2(
											$author$project$Shared$buildErrorNotication,
											'Unbalanced transactions!',
											'We received ' + ($elm$core$String$fromInt(
												$elm$core$List$length(errors)) + ' unbalanced transactions.')));
								}
							}();
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{dr: accounts, fQ: inMemoryStatus, b9: percentage, fU: ledger, gb: notification}),
								$elm$core$Platform$Cmd$none);
						}
					case 1:
						var _v12 = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{di: $author$project$Session$FirstRun}),
							A2($elm$browser$Browser$Navigation$replaceUrl, model.c0, '/#/' + $author$project$RouteNames$settings));
					default:
						if (!msg.a.a.$) {
							var accounts = msg.a.a.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{cH: accounts, di: $author$project$Session$Encrypted}),
								$elm$core$Platform$Cmd$none);
						} else {
							var decodeError = msg.a.a.a;
							return _Utils_Tuple2(
								A3($author$project$Shared$notifyDecodeError, 'Error reading data stored in browser', decodeError, model),
								$elm$core$Platform$Cmd$none);
						}
				}
		}
	});
var $author$project$Page$Settings$ExportData = function (a) {
	return {$: 30, a: a};
};
var $author$project$Page$Settings$ImportQrCode = function (a) {
	return {$: 37, a: a};
};
var $author$project$Page$Settings$ImportSample = {$: 19};
var $author$project$Page$Settings$JSON = 0;
var $author$project$Page$Settings$JsonRequested = {$: 23};
var $author$project$Main$NotFoundPage = {$: 7};
var $author$project$Route$goToSettings = function (appModel) {
	return A2(
		$author$project$Route$pushUrl,
		appModel.c0,
		$author$project$Route$SettingsRoute($elm$core$Maybe$Nothing));
};
var $author$project$Page$Accounts$Assets = 0;
var $author$project$Ledger$accountStats = function (ledger) {
	return A3(
		$elm$core$Basics$composeR,
		$author$project$Ledger$getInternal,
		function ($) {
			return $.ab;
		},
		ledger);
};
var $author$project$Page$Accounts$fromLedgerCurrencyStats = F2(
	function (_v0, ledgerStats) {
		return A2($author$project$Page$Accounts$CurrencyStats, ledgerStats.fn, ledgerStats.bd);
	});
var $author$project$Page$Accounts$fromLedgerAccount = F2(
	function (_v0, ledgerAccountStats) {
		return A2(
			$author$project$Page$Accounts$AccountStats,
			ledgerAccountStats.e6,
			A2($elm$core$Dict$map, $author$project$Page$Accounts$fromLedgerCurrencyStats, ledgerAccountStats.ab));
	});
var $author$project$Page$Accounts$initialModel = F2(
	function (settings, shared) {
		var ledger = shared.fU;
		var accounts = A2(
			$elm$core$Dict$map,
			$author$project$Page$Accounts$fromLedgerAccount,
			$author$project$Ledger$accountStats(ledger));
		var assets = A2(
			$elm$core$List$filter,
			function (a) {
				return (a.e6 === 'Assets') || A2($elm$core$String$startsWith, 'Assets:', a.e6);
			},
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(accounts)));
		var liabilities = A2(
			$elm$core$List$filter,
			function (a) {
				return (a.e6 === 'Liabilities') || A2($elm$core$String$startsWith, 'Liabilities:', a.e6);
			},
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(accounts)));
		var netWorth = $author$project$Page$Accounts$buildNetWorth(accounts);
		var seenCurrencies = $elm_community$list_extra$List$Extra$unique(
			$elm$core$List$sort(
				$elm$core$List$concat(
					A2(
						$elm$core$List$map,
						$elm$core$Dict$keys,
						A2(
							$elm$core$List$map,
							function ($) {
								return $.G;
							},
							$elm$core$Dict$values(accounts))))));
		var currencies = $elm_community$list_extra$List$Extra$unique(
			_Utils_ap(
				A2($elm$core$List$cons, settings.cQ, settings.es),
				seenCurrencies));
		return {dr: accounts, bS: assets, G: currencies, bq: 0, ce: liabilities, ec: false, bD: netWorth, ba: settings.go, bL: settings, e: shared};
	});
var $author$project$Page$Accounts$init = F2(
	function (shared, settings) {
		return _Utils_Tuple2(
			A2($author$project$Page$Accounts$initialModel, settings, shared),
			$author$project$Effect$none);
	});
var $author$project$Shared$GetCommonData = {$: 1};
var $author$project$Page$Edit$ReceivedDate = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $author$project$Page$Edit$getTransaction = _Platform_outgoingPort('getTransaction', $elm$json$Json$Encode$string);
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.cw, posixMinutes) < 0) {
					return posixMinutes + era.bF;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		dI: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		c4: month,
		e3: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dI;
	});
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).c4;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).e3;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$time$Time$here = _Time_here(0);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $author$project$Page$Edit$init = F4(
	function (shared, settings, maybeId, maybeTxn) {
		var getSharedDataEffect = $author$project$Effect$fromShared($author$project$Shared$GetCommonData);
		var editMode = function () {
			if (maybeTxn.$ === 1) {
				return 0;
			} else {
				var txn = maybeTxn.a;
				return $author$project$Page$Edit$chooseEditMode(txn);
			}
		}();
		var dateEffect = $author$project$Effect$fromCmd(
			A2($elm$core$Task$perform, $author$project$Page$Edit$ReceivedDate, $justinmimbs$date$Date$today));
		var effect = function () {
			var _v0 = _Utils_Tuple2(maybeId, maybeTxn);
			if (!_v0.b.$) {
				return $author$project$Effect$batch(
					_List_fromArray(
						[dateEffect, getSharedDataEffect]));
			} else {
				if (!_v0.a.$) {
					var id = _v0.a.a;
					return $author$project$Effect$batch(
						_List_fromArray(
							[
								dateEffect,
								getSharedDataEffect,
								$author$project$Effect$fromCmd(
								$author$project$Page$Edit$getTransaction(id))
							]));
				} else {
					return dateEffect;
				}
			}
		}();
		return _Utils_Tuple2(
			{
				dr: $elm$core$Dict$keys(shared.dr),
				cM: A3($justinmimbs$date$Date$fromCalendarDate, 2024, 0, 1),
				bo: shared.dS,
				b1: maybeId,
				V: editMode,
				br: maybeTxn,
				a: A2($author$project$Page$Edit$formInput, settings, maybeTxn),
				c0: shared.c0,
				a4: $elm$core$Maybe$Nothing,
				a6: $elm$core$Maybe$Nothing,
				aI: $elm$core$Maybe$Nothing,
				T: false,
				bL: settings,
				e: shared,
				aq: $elm$core$Maybe$Nothing
			},
			effect);
	});
var $author$project$Page$ExpensesBarChart$ReceivedDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ledger$getAllCurrencies = function (ledger) {
	var internal = $author$project$Ledger$getInternal(ledger);
	return $elm_community$list_extra$List$Extra$unique(
		$elm$core$List$sort(
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					$elm$core$Dict$keys,
					A2(
						$elm$core$List$map,
						function ($) {
							return $.ab;
						},
						$elm$core$Dict$values(internal.ab))))));
};
var $author$project$Page$ExpensesBarChart$initialModel = F2(
	function (settings, shared) {
		var from = A3($justinmimbs$date$Date$fromCalendarDate, 2024, 0, 1);
		var to = from;
		var currency = settings.ft;
		var allCurrencies = $elm_community$list_extra$List$Extra$unique(
			_Utils_ap(
				settings.go,
				$author$project$Ledger$getAllCurrencies(shared.fU)));
		return {
			cG: allCurrencies,
			bR: _List_Nil,
			Q: {fn: '', b_: _List_Nil, ct: true, D: ''},
			fn: currency,
			av: false,
			a1: $elm$core$Dict$empty,
			fJ: from,
			aA: '',
			b5: $elm$core$Maybe$Nothing,
			b6: '',
			cj: _List_Nil,
			bH: _List_Nil,
			aJ: $elm$core$Maybe$Nothing,
			e: shared,
			bb: false,
			cu: _List_Nil,
			cv: 0,
			gC: to,
			aQ: ''
		};
	});
var $author$project$Page$ExpensesBarChart$init = F2(
	function (shared, settings) {
		var model = A2($author$project$Page$ExpensesBarChart$initialModel, settings, shared);
		var dateEffect = $author$project$Effect$fromCmd(
			A2($elm$core$Task$perform, $author$project$Page$ExpensesBarChart$ReceivedDate, $justinmimbs$date$Date$today));
		return _Utils_Tuple2(model, dateEffect);
	});
var $author$project$Page$ExpensesPieChart$ReceivedDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ExpensesPieChart$initialModel = F2(
	function (settings, shared) {
		var from = A3($justinmimbs$date$Date$fromCalendarDate, 2024, 0, 1);
		var to = from;
		var currency = settings.ft;
		var allCurrencies = $elm_community$list_extra$List$Extra$unique(
			_Utils_ap(
				settings.go,
				$author$project$Ledger$getAllCurrencies(shared.fU)));
		return {
			cG: allCurrencies,
			bR: _List_Nil,
			Q: {fn: '', b$: _List_Nil, cb: _List_Nil, ct: true, D: ''},
			fn: currency,
			av: false,
			a1: $elm$core$Dict$empty,
			fJ: from,
			aA: '',
			b5: $elm$core$Maybe$Nothing,
			b6: '',
			bH: _List_Nil,
			aJ: $elm$core$Maybe$Nothing,
			e: shared,
			bb: false,
			cu: _List_Nil,
			cv: 0,
			gC: to,
			aQ: ''
		};
	});
var $author$project$Page$ExpensesPieChart$init = F2(
	function (shared, settings) {
		var model = A2($author$project$Page$ExpensesPieChart$initialModel, settings, shared);
		var dateEffect = $author$project$Effect$fromCmd(
			A2($elm$core$Task$perform, $author$project$Page$ExpensesPieChart$ReceivedDate, $justinmimbs$date$Date$today));
		return _Utils_Tuple2(model, dateEffect);
	});
var $author$project$Settings$defaultSettings = {
	cQ: 'USD',
	ft: 'USD',
	cS: _List_fromArray(
		['Expenses:Groceries', 'Expenses:Eat Out & Take Away']),
	es: _List_Nil,
	p: $elm$core$Maybe$Nothing,
	go: _List_fromArray(
		['USD']),
	dk: _List_fromArray(
		['Assets:Cash', 'Assets:Bank:Checking', 'Liabilities:CreditCard']),
	gy: true,
	af: ''
};
var $author$project$Page$Settings$initialModel = function (appModel) {
	var settings = function () {
		var _v1 = appModel.di;
		if (_v1.$ === 3) {
			var metadata = _v1.a;
			return metadata.bL;
		} else {
			return $author$project$Settings$defaultSettings;
		}
	}();
	var isFirstRun = function () {
		var _v0 = appModel.di;
		if (_v0.$ === 3) {
			return false;
		} else {
			return true;
		}
	}();
	var inputs = $author$project$Page$Settings$buildInputsFromSettings(settings);
	return {ac: $elm$core$Maybe$Nothing, bt: $elm$core$Maybe$Nothing, d: inputs, ak: isFirstRun, ap: $elm$core$Maybe$Nothing, aI: $elm$core$Maybe$Nothing, bL: settings, e: appModel, aM: $elm$core$Maybe$Nothing, aU: 0};
};
var $author$project$Page$Settings$init = F2(
	function (maybeMessage, appModel) {
		var effect = function () {
			_v0$4:
			while (true) {
				if (!maybeMessage.$) {
					switch (maybeMessage.a.$) {
						case 19:
							var _v1 = maybeMessage.a;
							return $author$project$Effect$fromCmd(
								$author$project$Page$Settings$importSampleData(0));
						case 23:
							var _v2 = maybeMessage.a;
							return $author$project$Effect$fromCmd(
								$author$project$Page$Settings$requestImportJson($author$project$Page$Settings$JsonSelected));
						case 37:
							var base64 = maybeMessage.a.a;
							return $author$project$Effect$fromCmd(
								A2(
									$elm$core$Task$perform,
									function (_v3) {
										return $author$project$Page$Settings$ImportQrCode(base64);
									},
									$elm$core$Task$succeed($author$project$Page$Settings$ImportQrCode)));
						case 30:
							var format = maybeMessage.a.a;
							return $author$project$Effect$fromCmd(
								$author$project$Misc$msgToCommand(
									$author$project$Page$Settings$ExportData(format)));
						default:
							break _v0$4;
					}
				} else {
					break _v0$4;
				}
			}
			return $author$project$Effect$none;
		}();
		return _Utils_Tuple2(
			$author$project$Page$Settings$initialModel(appModel),
			effect);
	});
var $author$project$Page$Transactions$initialModel = F2(
	function (appModel, session) {
		return {
			dr: $elm$core$List$sort(
				$elm$core$Dict$keys(appModel.dr)),
			as: appModel,
			bm: $elm$core$Maybe$Nothing,
			d3: false,
			aD: A2(
				$author$project$OurInfiniteScroll$direction,
				2,
				$author$project$OurInfiniteScroll$init($author$project$Page$Transactions$loadMoreCommand)),
			cd: $elm$core$Maybe$Nothing,
			a5: $author$project$Page$Transactions$buildListItems(appModel.B),
			by: $author$project$Page$Transactions$AllTransactions,
			bz: false,
			bA: 0,
			X: $elm$core$Maybe$Just(0),
			an: $elm$core$Maybe$Nothing,
			N: $elm$core$Maybe$Nothing,
			cn: $elm$core$Maybe$Nothing,
			aH: false,
			cs: $elm$core$Maybe$Nothing,
			di: session,
			B: $elm$core$List$isEmpty(appModel.B) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(appModel.B)
		};
	});
var $author$project$Page$Transactions$init = F3(
	function (maybeFragment, appModel, session) {
		var defaultModel = A2($author$project$Page$Transactions$initialModel, appModel, session);
		var model = function () {
			var _v3 = appModel.fQ;
			if (_v3 === 2) {
				return A2($author$project$Page$Transactions$setInitialCursor, defaultModel, appModel.fU);
			} else {
				return defaultModel;
			}
		}();
		var defaultEffect = function () {
			var _v2 = appModel.fQ;
			if (_v2 === 2) {
				return $author$project$Effect$none;
			} else {
				return $author$project$Effect$fromCmd(
					$author$project$Page$Transactions$getTransactions($author$project$Page$Transactions$defaultGetTransactionsRequest));
			}
		}();
		var _v0 = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(defaultEffect, false),
			A2(
				$elm$core$Maybe$map,
				function (f) {
					if (f === 'sync') {
						return _Utils_Tuple2(
							$author$project$Effect$batch(
								_List_fromArray(
									[
										defaultEffect,
										A2(
										$elm$core$Maybe$withDefault,
										$author$project$Effect$none,
										$author$project$Page$Transactions$maybeSync(appModel))
									])),
							true);
					} else {
						return _Utils_Tuple2(defaultEffect, false);
					}
				},
				maybeFragment));
		var effect = _v0.a;
		var replicating = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{aH: replicating}),
			effect);
	});
var $author$project$Main$updateUrl = F2(
	function (url, model) {
		var _v0 = model.e.di;
		switch (_v0.$) {
			case 3:
				var metadata = _v0.a;
				var _v1 = $author$project$Route$fromUrl(url);
				if (!_v1.$) {
					switch (_v1.a.$) {
						case 0:
							var _v2 = _v1.a;
							return A2(
								$author$project$Main$toAppLoading,
								model,
								$author$project$Page$AppLoading$init(model.e));
						case 8:
							var _v3 = _v1.a;
							return A2(
								$author$project$Main$toAppLoading,
								model,
								$author$project$Page$AppLoading$init(model.e));
						case 1:
							var maybeFragment = _v1.a.a;
							return A2(
								$author$project$Main$toTransactions,
								model,
								A3($author$project$Page$Transactions$init, maybeFragment, model.e, metadata));
						case 2:
							if (_v1.a.a.$ === 1) {
								var _v4 = _v1.a.a;
								return A2(
									$author$project$Main$toSettings,
									model,
									A2($author$project$Page$Settings$init, $elm$core$Maybe$Nothing, model.e));
							} else {
								switch (_v1.a.a.a.$) {
									case 1:
										var _v5 = _v1.a.a.a;
										return A2(
											$author$project$Main$toSettings,
											model,
											A2(
												$author$project$Page$Settings$init,
												$elm$core$Maybe$Just($author$project$Page$Settings$ImportSample),
												model.e));
									case 0:
										var _v6 = _v1.a.a.a;
										return A2(
											$author$project$Main$toSettings,
											model,
											A2(
												$author$project$Page$Settings$init,
												$elm$core$Maybe$Just($author$project$Page$Settings$JsonRequested),
												model.e));
									case 3:
										var _v7 = _v1.a.a.a;
										return A2(
											$author$project$Main$toSettings,
											model,
											A2(
												$author$project$Page$Settings$init,
												$elm$core$Maybe$Just(
													$author$project$Page$Settings$ExportData(0)),
												model.e));
									default:
										var base64 = _v1.a.a.a.a;
										return A2(
											$author$project$Main$toSettings,
											model,
											A2(
												$author$project$Page$Settings$init,
												$elm$core$Maybe$Just(
													$author$project$Page$Settings$ImportQrCode(base64)),
												model.e));
								}
							}
						case 3:
							var _v8 = _v1.a;
							return A2(
								$author$project$Main$toEdit,
								model,
								A4($author$project$Page$Edit$init, model.e, metadata.bL, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing));
						case 4:
							var id = _v1.a.a;
							var maybeTxn = A2(
								$elm_community$list_extra$List$Extra$find,
								function (t) {
									return _Utils_eq(t.aj, id);
								},
								model.e.B);
							return A2(
								$author$project$Main$toEdit,
								model,
								A4(
									$author$project$Page$Edit$init,
									model.e,
									metadata.bL,
									$elm$core$Maybe$Just(id),
									maybeTxn));
						case 5:
							var _v9 = _v1.a;
							return A2(
								$author$project$Main$toAccounts,
								model,
								A2($author$project$Page$Accounts$init, model.e, metadata.bL));
						case 6:
							var _v10 = _v1.a;
							return A2(
								$author$project$Main$toExpensesPieChart,
								model,
								A2($author$project$Page$ExpensesPieChart$init, model.e, metadata.bL));
						default:
							var _v11 = _v1.a;
							return A2(
								$author$project$Main$toExpensesBarChart,
								model,
								A2($author$project$Page$ExpensesBarChart$init, model.e, metadata.bL));
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j: $author$project$Main$NotFoundPage, cE: url}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				var _v12 = $author$project$Route$fromUrl(url);
				_v12$2:
				while (true) {
					if ((!_v12.$) && (_v12.a.$ === 2)) {
						if (_v12.a.a.$ === 1) {
							var _v13 = _v12.a.a;
							return A2(
								$author$project$Main$toSettings,
								model,
								A2($author$project$Page$Settings$init, $elm$core$Maybe$Nothing, model.e));
						} else {
							if (_v12.a.a.a.$ === 2) {
								var base64 = _v12.a.a.a.a;
								return A2(
									$author$project$Main$toSettings,
									model,
									A2(
										$author$project$Page$Settings$init,
										$elm$core$Maybe$Just(
											$author$project$Page$Settings$ImportQrCode(base64)),
										model.e));
							} else {
								break _v12$2;
							}
						}
					} else {
						break _v12$2;
					}
				}
				return _Utils_Tuple2(
					model,
					$author$project$Route$goToSettings(model.e));
			case 2:
				var _v14 = $author$project$Route$fromUrl(url);
				if ((!_v14.$) && (!_v14.a.$)) {
					var _v15 = _v14.a;
					return A2(
						$author$project$Main$toAppLoading,
						model,
						$author$project$Page$AppLoading$init(model.e));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$Route$goToAppLoading(model.e));
				}
			case 4:
				var _v16 = $author$project$Route$fromUrl(url);
				if ((!_v16.$) && (!_v16.a.$)) {
					var _v17 = _v16.a;
					return A2(
						$author$project$Main$toAppLoading,
						model,
						$author$project$Page$AppLoading$init(model.e));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$Route$goToAppLoading(model.e));
				}
			case 0:
				var _v18 = $author$project$Route$fromUrl(url);
				if ((!_v18.$) && (!_v18.a.$)) {
					var _v19 = _v18.a;
					return A2(
						$author$project$Main$toAppLoading,
						model,
						$author$project$Page$AppLoading$init(model.e));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$Route$goToAppLoading(model.e));
				}
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: $author$project$Main$NotFoundPage, cE: url}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var urlRequest = msg.a;
				if (urlRequest.$ === 1) {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				} else {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.e.c0,
							$elm$url$Url$toString(url)));
				}
			case 1:
				var url = msg.a;
				var _v2 = A2($author$project$Main$updateUrl, url, model);
				var newModel = _v2.a;
				var cmd = _v2.b;
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmd,
								$author$project$Main$closeSidebar(0)
							])));
			case 2:
				var msg_ = msg.a;
				var _v3 = model.j;
				if (!_v3.$) {
					var model_ = _v3.a;
					return A2(
						$author$project$Main$toAppLoading,
						model,
						A2($author$project$Page$AppLoading$update, msg_, model_));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var msg_ = msg.a;
				var _v4 = model.j;
				if (_v4.$ === 1) {
					var model_ = _v4.a;
					return A2(
						$author$project$Main$toTransactions,
						model,
						A2($author$project$Page$Transactions$update, msg_, model_));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var msg_ = msg.a;
				var _v5 = model.j;
				if (_v5.$ === 2) {
					var model_ = _v5.a;
					return A2(
						$author$project$Main$toSettings,
						model,
						A2($author$project$Page$Settings$update, msg_, model_));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var msg_ = msg.a;
				var _v6 = model.j;
				if (_v6.$ === 3) {
					var model_ = _v6.a;
					return A2(
						$author$project$Main$toEdit,
						model,
						A2($author$project$Page$Edit$update, msg_, model_));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var msg_ = msg.a;
				var _v7 = model.j;
				if (_v7.$ === 4) {
					var model_ = _v7.a;
					return A2(
						$author$project$Main$toAccounts,
						model,
						A2($author$project$Page$Accounts$update, msg_, model_));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var msg_ = msg.a;
				var _v8 = model.j;
				if (_v8.$ === 5) {
					var model_ = _v8.a;
					return A2(
						$author$project$Main$toExpensesPieChart,
						model,
						A2($author$project$Page$ExpensesPieChart$update, msg_, model_));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var msg_ = msg.a;
				var _v9 = model.j;
				if (_v9.$ === 6) {
					var model_ = _v9.a;
					return A2(
						$author$project$Main$toExpensesBarChart,
						model,
						A2($author$project$Page$ExpensesBarChart$update, msg_, model_));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 10:
				return A3($author$project$Main$init, 0, model.ca, model.e.c0);
			case 11:
				return _Utils_Tuple2(
					model,
					$author$project$Main$doInitilizeCmd(model.e));
			case 12:
				var visibility = msg.a;
				var cmd = function () {
					var _v10 = model.e.di;
					if (_v10.$ === 3) {
						if (!visibility) {
							return A2($elm$core$Task$perform, $author$project$Main$CheckAutoLock, $elm$time$Time$now);
						} else {
							return A2($elm$core$Task$perform, $author$project$Main$StoreHiddenAt, $elm$time$Time$now);
						}
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{$7: visibility}),
					cmd);
			case 13:
				var t = msg.a;
				var now = $elm$time$Time$posixToMillis(t);
				var _v12 = function () {
					var _v13 = model.bu;
					if (!_v13.$) {
						var hiddenAt = _v13.a;
						if ((now - hiddenAt) > 600000) {
							var _v14 = model.e.di;
							if (_v14.$ === 3) {
								return _Utils_Tuple2(
									model,
									$author$project$Misc$msgToCommand(
										$author$project$Main$Shared($author$project$Shared$LockSession)));
							} else {
								return A3($author$project$Main$init, 0, model.ca, model.e.c0);
							}
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}();
				var newModel = _v12.a;
				var cmd = _v12.b;
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{bu: $elm$core$Maybe$Nothing}),
					cmd);
			case 14:
				var t = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bu: $elm$core$Maybe$Just(
								$elm$time$Time$posixToMillis(t))
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var msg_ = msg.a;
				var _v15 = A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$Main$Shared),
					A2($author$project$Shared$update, msg_, model.e));
				var shared = _v15.a;
				var sharedCmd = _v15.b;
				var _v16 = function () {
					var _v17 = model.j;
					switch (_v17.$) {
						case 0:
							var pageModel = _v17.a;
							var _v18 = A2(
								$author$project$Page$AppLoading$update,
								$author$project$Page$AppLoading$UpdateShared(shared),
								pageModel);
							var newModel = _v18.a;
							var newEffect = _v18.b;
							var newCmd = A2(
								$author$project$Effect$toCmd,
								_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotAppLoadingMsg),
								newEffect);
							return _Utils_Tuple2(
								$author$project$Main$AppLoadingPage(newModel),
								newCmd);
						case 1:
							var pageModel = _v17.a;
							var _v19 = A2(
								$author$project$Page$Transactions$update,
								$author$project$Page$Transactions$UpdateShared(shared),
								pageModel);
							var newModel = _v19.a;
							var newEffect = _v19.b;
							var newCmd = A2(
								$author$project$Effect$toCmd,
								_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotTransactionsMsg),
								newEffect);
							return _Utils_Tuple2(
								$author$project$Main$TransactionsPage(newModel),
								newCmd);
						case 3:
							var pageModel = _v17.a;
							var _v20 = A2(
								$author$project$Page$Edit$update,
								$author$project$Page$Edit$UpdateShared(shared),
								pageModel);
							var newPageModel = _v20.a;
							var effect = _v20.b;
							var newCmd = A2(
								$author$project$Effect$toCmd,
								_Utils_Tuple2($author$project$Main$Shared, $author$project$Main$GotEditMsg),
								effect);
							return _Utils_Tuple2(
								$author$project$Main$EditPage(newPageModel),
								newCmd);
						default:
							return _Utils_Tuple2(model.j, $elm$core$Platform$Cmd$none);
					}
				}();
				var page = _v16.a;
				var pageCmd = _v16.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: page, e: shared}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[sharedCmd, pageCmd])));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Page$Accounts = 6;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Page$viewBottomBar = function (other) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui bottom attached sesgment')
			]),
		other);
};
var $author$project$Page$Accounts$ChangeCurrency = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Accounts$ChangeDispalyType = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$Misc$cyAttr = function (name) {
	return A2($elm$html$Html$Attributes$attribute, 'data-cy', name);
};
var $author$project$Page$Accounts$Liabilities = 1;
var $author$project$Page$Accounts$displayTypeFromString = function (s) {
	switch (s) {
		case 'Assets':
			return 0;
		case 'Liabilities':
			return 1;
		default:
			return 0;
	}
};
var $author$project$Page$Accounts$displayTypeString = function (t) {
	if (!t) {
		return 'Assets';
	} else {
		return 'Liabilities';
	}
};
var $author$project$Page$Accounts$displayTypes = _List_fromArray(
	[0, 1]);
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$multiple = $elm$html$Html$Attributes$boolProperty('multiple');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm_community$html_extra$Html$Events$Extra$targetSelectedOptions = function () {
	var options = A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['target', 'selectedOptions']),
		$elm$json$Json$Decode$keyValuePairs(
			A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string)));
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$List$map($elm$core$Tuple$second),
		options);
}();
var $elm_community$html_extra$Html$Events$Extra$onMultiSelect = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'change',
		A2(
			$elm$json$Json$Decode$map,
			function (x) {
				return _Utils_Tuple2(x, true);
			},
			A2($elm$json$Json$Decode$map, tagger, $elm_community$html_extra$Html$Events$Extra$targetSelectedOptions)));
};
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$size = function (n) {
	return A2(
		_VirtualDom_attribute,
		'size',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$Page$Accounts$fmt = function (amount) {
	return A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, amount / 100.0);
};
var $author$project$Page$Accounts$viewAccountColumn = function (stats) {
	var amount = $author$project$Page$Accounts$fmt(stats.gE);
	return A2(
		$elm$html$Html$td,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(amount)
			]));
};
var $author$project$Page$Accounts$viewAccountRow = F2(
	function (currencies, account) {
		var getStats = function (currency) {
			var _v0 = A2($elm$core$Dict$get, currency, account.G);
			if (!_v0.$) {
				var stats = _v0.a;
				return (!stats.gE) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(stats);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var currencyColumns = A2(
			$elm$core$List$map,
			function (currency) {
				return A2(
					$elm$core$Maybe$map,
					$author$project$Page$Accounts$viewAccountColumn,
					getStats(currency));
			},
			currencies);
		return $elm$core$List$isEmpty(
			A2($elm$core$List$filterMap, $elm$core$Basics$identity, currencyColumns)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$tr,
				_List_Nil,
				A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(account.e6)
							])),
					A2(
						$elm$core$List$map,
						function (maybe) {
							return A2(
								$elm$core$Maybe$withDefault,
								A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('-')
										])),
								maybe);
						},
						currencyColumns))));
	});
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Misc$viewMultiSelectOptions = F2(
	function (selectedOptions, options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				return A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value(opt),
							$elm$html$Html$Attributes$selected(
							$author$project$Misc$isSomething(
								A2($elm_community$list_extra$List$Extra$elemIndex, opt, selectedOptions)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(opt)
						]));
			},
			options);
	});
var $author$project$Misc$viewSelectOptions = F2(
	function (selectedOption, options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				return A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value(opt),
							$elm$html$Html$Attributes$selected(
							_Utils_eq(selectedOption, opt))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(opt)
						]));
			},
			options);
	});
var $author$project$Page$Accounts$viewMain = function (model) {
	var accounts = function () {
		var _v0 = model.bq;
		if (!_v0) {
			return A2($elm$core$List$cons, model.bD, model.bS);
		} else {
			return A2($elm$core$List$cons, model.bD, model.ce);
		}
	}();
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui attached segment main-content')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui form')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('two fields')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Currency')
											])),
										A2(
										$elm$html$Html$select,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui dropdown'),
												$author$project$Misc$cyAttr('currency'),
												$elm_community$html_extra$Html$Events$Extra$onMultiSelect($author$project$Page$Accounts$ChangeCurrency),
												$elm$html$Html$Attributes$multiple(true),
												$elm$html$Html$Attributes$size(3)
											]),
										A2($author$project$Misc$viewMultiSelectOptions, model.ba, model.G))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Display Type')
											])),
										A2(
										$elm$html$Html$select,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui dropdown'),
												$author$project$Misc$cyAttr('display-type'),
												$elm$html$Html$Events$onInput(
												A2($elm$core$Basics$composeR, $author$project$Page$Accounts$displayTypeFromString, $author$project$Page$Accounts$ChangeDispalyType))
											]),
										A2(
											$author$project$Misc$viewSelectOptions,
											$author$project$Page$Accounts$displayTypeString(model.bq),
											A2($elm$core$List$map, $author$project$Page$Accounts$displayTypeString, $author$project$Page$Accounts$displayTypes)))
									]))
							]))
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui unstackable celled table')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								A2(
									$elm$core$List$cons,
									A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Account')
											])),
									A2(
										$elm$core$List$map,
										function (currency) {
											return A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(currency)
													]));
										},
										model.ba)))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							A2(
								$elm$core$List$map,
								$author$project$Page$Accounts$viewAccountRow(model.ba),
								accounts)))
					]))
			]));
};
var $author$project$Page$AppLoading = 9;
var $author$project$Page$ExpensesBarChart = 8;
var $author$project$Page$ExpensesPieChart = 7;
var $author$project$Page$ExportJson = 3;
var $author$project$Page$Import = 2;
var $author$project$Page$New = 5;
var $author$project$Page$Settings = 1;
var $author$project$Page$Transactions = 0;
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm_community$html_extra$Html$Attributes$Extra$empty = $elm$html$Html$Attributes$classList(_List_Nil);
var $elm_community$html_extra$Html$Attributes$Extra$attributeIf = F2(
	function (condition, attr) {
		return condition ? attr : $elm_community$html_extra$Html$Attributes$Extra$empty;
	});
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Route$href = function (targetRoute) {
	return $elm$html$Html$Attributes$href(
		$author$project$Route$routeToString(targetRoute));
};
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm_community$html_extra$Html$Extra$nothing = $elm$html$Html$text('');
var $elm_community$html_extra$Html$Extra$viewIf = F2(
	function (condition, html) {
		return condition ? html : $elm_community$html_extra$Html$Extra$nothing;
	});
var $author$project$Page$viewSideBar = F2(
	function (model, page) {
		var active = function (link) {
			return A2(
				$elm_community$html_extra$Html$Attributes$Extra$attributeIf,
				_Utils_eq(link, page),
				$elm$html$Html$Attributes$class('active'));
		};
		var decryptedItem = A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			model.fQ === 2,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Reporting')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('menu')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										active(6),
										$author$project$Misc$cyAttr('accounts'),
										$author$project$Route$href($author$project$Route$AccountsRoute)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('table icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Assets & Liabilities')
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										active(7),
										$author$project$Misc$cyAttr('expenses-pie-chart'),
										$author$project$Route$href($author$project$Route$ExpensesPieChartRoute)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('chart pie icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Expenses Pie Chart')
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										active(8),
										$author$project$Misc$cyAttr('expenses-bar-chart'),
										$author$project$Route$href($author$project$Route$ExpensesBarChartRoute)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('chart bar icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Expenses Bar Chart')
									]))
							]))
					])));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('View & Edit')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('menu')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										active(0),
										$author$project$Misc$cyAttr('transactions'),
										$author$project$Route$href(
										$author$project$Route$TransactionsRoute($elm$core$Maybe$Nothing))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('list icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Transactions')
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										active(5),
										$author$project$Misc$cyAttr('new-transaction'),
										$author$project$Route$href($author$project$Route$NewRoute)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('plus icon')
											]),
										_List_Nil),
										$elm$html$Html$text('New Transaction')
									]))
							]))
					])),
				decryptedItem,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Import & Export')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('menu')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										active(2),
										$author$project$Misc$cyAttr('import-transactions'),
										$author$project$Route$href(
										$author$project$Route$SettingsRoute(
											$elm$core$Maybe$Just($author$project$Route$ImportJson)))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('folder open icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Import Transactions')
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										active(3),
										$author$project$Misc$cyAttr('export-json'),
										$author$project$Route$href(
										$author$project$Route$SettingsRoute(
											$elm$core$Maybe$Just($author$project$Route$ExportJson)))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('download icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Export Transactions')
									]))
							]))
					])),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item bottom-item'),
						active(1),
						$author$project$Misc$cyAttr('settings'),
						$author$project$Route$href(
						$author$project$Route$SettingsRoute($elm$core$Maybe$Nothing))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('settings icon')
							]),
						_List_Nil),
						$elm$html$Html$text('Settings')
					]))
			]);
	});
var $author$project$Page$viewMaybeSidebar = F2(
	function (shared, page) {
		return (page === 9) ? _List_Nil : A2($author$project$Page$viewSideBar, shared, page);
	});
var $author$project$Page$viewNewTopBar = F2(
	function (page, other) {
		return A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			page !== 9,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui top attached borderless menu')
					]),
				A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('open sidebar item'),
								$author$project$Misc$cyAttr('open-sidebar')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('sidebar icon')
									]),
								_List_Nil)
							])),
					other)));
	});
var $author$project$Page$viewPage = F5(
	function (shared, page, title, topbar, content) {
		return {
			C: _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui sidebar inverted vertical menu')
						]),
					A2($author$project$Page$viewMaybeSidebar, shared, page)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pusher container')
						]),
					A2(
						$elm$core$List$cons,
						A2($author$project$Page$viewNewTopBar, page, topbar),
						content))
				]),
			D: title
		};
	});
var $author$project$Page$Accounts$view = function (model) {
	return A5(
		$author$project$Page$viewPage,
		model.e,
		6,
		'Accounts Balance',
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header item')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Accounts Balance')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container')
					]),
				_List_fromArray(
					[
						$author$project$Page$Accounts$viewMain(model),
						$author$project$Page$viewBottomBar(_List_Nil)
					]))
			]));
};
var $author$project$Page$AppLoading$DismissNotification = {$: 7};
var $author$project$Page$AppLoading$DismissPopupNotification = {$: 6};
var $author$project$Page$AppLoading$DecryptSettings = {$: 8};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Page$AppLoading$viewBottomBar = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui bottom attached menu bottom-bar')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('2024-06-21T21:59')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('right menu')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('disabled', model.aU)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ui positive button right floated'),
										$author$project$Misc$cyAttr('open'),
										$elm$html$Html$Events$onClick($author$project$Page$AppLoading$DecryptSettings)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Open')
									]))
							]))
					]))
			]));
};
var $author$project$Misc$viewLoadingMainContent = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('ui segment main-content')
		]),
	_List_fromArray(
		[
			A2($elm$html$Html$p, _List_Nil, _List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui active inverted dimmer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui loader')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Misc$viewLoadingDocument = {
	C: _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[$author$project$Misc$viewLoadingMainContent]))
		]),
	D: 'Loading...'
};
var $author$project$Page$AppLoading$EditPassword = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$AppLoading$EditUsername = function (a) {
	return {$: 4, a: a};
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Page$AppLoading$viewMainContent = function (model) {
	var hadErrors = A2($elm$core$Maybe$withDefault, false, model.cl);
	var viewError = A2(
		$elm_community$html_extra$Html$Extra$viewIf,
		hadErrors,
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui error message')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Invalid password')
						]))
				])));
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui attached segment main-content')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui large form'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('error', hadErrors)
							])),
						$elm$html$Html$Events$onSubmit($author$project$Page$AppLoading$DecryptSettings)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Username')
									])),
								A2(
								$elm$html$Html$select,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ui dropdown'),
										$elm$html$Html$Attributes$name('username'),
										$author$project$Misc$cyAttr('username'),
										$elm$html$Html$Events$onInput($author$project$Page$AppLoading$EditUsername)
									]),
								A2(
									$elm$core$List$map,
									function (account) {
										return A2(
											$elm$html$Html$option,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$value(account)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(account)
												]));
									},
									model.as.cH))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('error', hadErrors)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Enter password')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$name('currentPassword'),
										$elm$html$Html$Attributes$type_('password'),
										$author$project$Misc$cyAttr('current-password'),
										A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'current-password'),
										$elm$html$Html$Attributes$value(model.a8),
										$elm$html$Html$Events$onInput($author$project$Page$AppLoading$EditPassword)
									]),
								_List_Nil)
							])),
						viewError
					]))
			]));
};
var $author$project$Misc$Notification = function (a) {
	return {$: 0, a: a};
};
var $elm_community$html_extra$Html$Extra$viewMaybe = F2(
	function (fn, maybeThing) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm_community$html_extra$Html$Extra$nothing,
			A2($elm$core$Maybe$map, fn, maybeThing));
	});
var $author$project$Misc$viewNotification = F2(
	function (notification, onClose) {
		var _v0 = function () {
			if (!notification.$) {
				var notificationData = notification.a;
				return _Utils_Tuple2('attached message', notificationData);
			} else {
				var notificationData = notification.a;
				return _Utils_Tuple2('message', notificationData);
			}
		}();
		var messageClass = _v0.a;
		var data = _v0.b;
		var header = function () {
			var _v3 = data.dZ;
			if (!_v3.$) {
				var txt = _v3.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(txt)
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var typeClass = function () {
			var _v2 = data.eX;
			switch (_v2) {
				case 1:
					return 'negative';
				case 2:
					return 'positive';
				default:
					return '';
			}
		}();
		var mainClass = 'ui ' + (typeClass + (' ' + messageClass));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(mainClass)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('close icon'),
							$elm$html$Html$Events$onClick(onClose)
						]),
					_List_Nil),
					header,
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(data.ef)
						]))
				]));
	});
var $author$project$Misc$viewMainNotification = F2(
	function (maybeNotification, m) {
		return A2(
			$elm_community$html_extra$Html$Extra$viewMaybe,
			function (notification) {
				return A2(
					$author$project$Misc$viewNotification,
					$author$project$Misc$Notification(notification),
					m);
			},
			maybeNotification);
	});
var $author$project$Misc$Popup = function (a) {
	return {$: 1, a: a};
};
var $author$project$Misc$viewPopupNotification = F2(
	function (maybeNotification, m) {
		return A2(
			$elm_community$html_extra$Html$Extra$viewMaybe,
			function (notification) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('popup-notification')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Misc$viewNotification,
							$author$project$Misc$Popup(notification),
							m)
						]));
			},
			maybeNotification);
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Page$viewWelcomeHeader = A2(
	$elm$html$Html$h2,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('ui icon header middle aligned')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$i,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('money icon')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Welcome to Elm Expenses!')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sub header')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('This is a work in progress')
				]))
		]));
var $author$project$Page$AppLoading$view = function (model) {
	var _v0 = model.cx;
	if (!_v0) {
		return $author$project$Misc$viewLoadingDocument;
	} else {
		return A5(
			$author$project$Page$viewPage,
			model.as,
			9,
			'Application Locked',
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewWelcomeHeader,
							A2($author$project$Misc$viewMainNotification, model.as.gb, $author$project$Page$AppLoading$DismissNotification),
							A2($author$project$Misc$viewPopupNotification, model.as.gi, $author$project$Page$AppLoading$DismissPopupNotification),
							$author$project$Page$AppLoading$viewMainContent(model),
							$author$project$Page$AppLoading$viewBottomBar(model)
						]))
				]));
	}
};
var $author$project$Page$Edit = 4;
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Misc$viewDataList = F2(
	function (nodeId, list) {
		return A3(
			$elm$html$Html$node,
			'datalist',
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id(nodeId)
				]),
			A2(
				$elm$core$List$map,
				function (a) {
					return A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(a)
							]),
						_List_Nil);
				},
				list));
	});
var $author$project$Page$Edit$viewAccountsDataList = F2(
	function (model, settings) {
		var accounts = _Utils_ap(
			settings.cS,
			_Utils_ap(
				settings.dk,
				$elm$core$List$sort(model.dr)));
		return A2($author$project$Misc$viewDataList, 'accounts', accounts);
	});
var $author$project$Page$Edit$DeleteRequested = {$: 29};
var $author$project$Page$Edit$NoOp = {$: 38};
var $author$project$Page$Edit$SubmitForm = {$: 26};
var $author$project$Page$Edit$viewBottomBar = F2(
	function (model, settings) {
		var maybeDeleteButton = A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			model.a.aj !== '',
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item'),
						$author$project$Misc$cyAttr('delete'),
						$elm$html$Html$Events$onClick($author$project$Page$Edit$DeleteRequested)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('negative ui button')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Delete')
							]))
					])));
		var cmd = model.T ? $author$project$Page$Edit$NoOp : $author$project$Page$Edit$SubmitForm;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui bottom attached menu bottom-bar')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('item')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui button'),
									$author$project$Misc$cyAttr('cancel'),
									$author$project$Route$href(
									$author$project$Route$TransactionsRoute($elm$core$Maybe$Nothing))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Cancel')
								]))
						])),
					maybeDeleteButton,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('right menu')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('item'),
									$author$project$Misc$cyAttr('submit'),
									$elm$html$Html$Events$onClick(cmd)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('positive ui button right floated'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('disabled', model.T)
												]))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Submit')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Edit$AddEntry = {$: 35};
var $author$project$Page$Edit$EditDate = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Edit$EditDescription = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Edit$EditDestinationAccount = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Edit$EditDestinationAmount = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Edit$EditDestinationCurrency = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Edit$EditDestinationDate = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Edit$EditDestinationDescription = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Edit$EditEntryAccount = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$Page$Edit$EditEntryAmount = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$Page$Edit$EditEntryCurrency = F2(
	function (a, b) {
		return {$: 22, a: a, b: b};
	});
var $author$project$Page$Edit$EditEntryDate = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$Page$Edit$EditEntryDescription = F2(
	function (a, b) {
		return {$: 23, a: a, b: b};
	});
var $author$project$Page$Edit$EditNotes = function (a) {
	return {$: 25, a: a};
};
var $author$project$Page$Edit$EditSourceAccount = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Edit$EditSourceAmount = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Edit$EditSourceCurrency = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$Edit$EditSourceDate = function (a) {
	return {$: 19, a: a};
};
var $author$project$Page$Edit$EditSourceDescription = function (a) {
	return {$: 18, a: a};
};
var $author$project$Page$Edit$getEntryError = function (result) {
	if (result.$ === 1) {
		var err = result.a;
		return $elm$core$Maybe$Just(err);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$html$Html$Attributes$list = _VirtualDom_attribute('list');
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Page$Edit$RemoveEntry = function (a) {
	return {$: 36, a: a};
};
var $author$project$Page$Edit$StopSuggestingBalance = {$: 37};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Page$Edit$viewEntryInput = F3(
	function (pos, m, ei) {
		var secondRowClass = m.a9 ? 'three fields' : 'two fields';
		var removeField = A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			m.a9,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('field')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ui button right floated'),
								$author$project$Misc$cyAttr(
								'remove-entry-' + $elm$core$String$fromInt(pos)),
								$elm$html$Html$Events$onClick(
								$author$project$Page$Edit$RemoveEntry(pos))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Remove Entry')
							]))
					])));
		var isFieldError = function (accessor) {
			var _v0 = m.bs;
			if (_v0.$ === 1) {
				return false;
			} else {
				var e = _v0.a;
				return $author$project$Misc$isError(
					accessor(e));
			}
		};
		var addErrorClass = function (accessor) {
			return A2(
				$elm_community$html_extra$Html$Attributes$Extra$attributeIf,
				isFieldError(accessor),
				$elm$html$Html$Attributes$class('error'));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(m.dZ)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('three fields')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									addErrorClass(
									function ($) {
										return $.e6;
									})
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui action input')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$author$project$Misc$cyAttr(m.H + '-account'),
													$elm$html$Html$Attributes$list('accounts'),
													$elm$html$Html$Attributes$placeholder('Account...'),
													$elm$html$Html$Attributes$value(ei.e6),
													$elm$html$Html$Events$onInput(m.aV)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('circular ui icon button'),
													$author$project$Misc$cyAttr(m.H + '-clear-account'),
													$elm$html$Html$Events$onClick(
													m.aV(''))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('icon cancel')
														]),
													_List_Nil)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									addErrorClass(
									function ($) {
										return $.e9;
									})
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui action input')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$author$project$Misc$cyAttr(m.H + '-amount'),
													$elm$html$Html$Attributes$type_('text'),
													$elm$html$Html$Attributes$placeholder('Amount'),
													$elm$html$Html$Attributes$placeholder('10.99'),
													$elm$html$Html$Attributes$value(ei.e9),
													$elm$html$Html$Events$onInput(m.aX),
													$elm$html$Html$Events$onFocus($author$project$Page$Edit$StopSuggestingBalance)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('circular ui icon button'),
													$author$project$Misc$cyAttr(m.H + '-clear-amount'),
													$elm$html$Html$Events$onClick(
													m.aX(''))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('icon cancel')
														]),
													_List_Nil)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									addErrorClass(
									function ($) {
										return $.fn;
									})
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui action input')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$author$project$Misc$cyAttr(m.H + '-currency'),
													$elm$html$Html$Attributes$list('currencies'),
													$elm$html$Html$Attributes$placeholder('USD'),
													$elm$html$Html$Attributes$value(ei.fn),
													$elm$html$Html$Events$onInput(m.a_)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('circular ui icon button'),
													$author$project$Misc$cyAttr(m.H + '-clear-currency'),
													$elm$html$Html$Events$onClick(
													m.a_(''))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('icon cancel')
														]),
													_List_Nil)
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(secondRowClass)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									addErrorClass(
									function ($) {
										return $.dK;
									})
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui action input')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$author$project$Misc$cyAttr(m.H + '-description'),
													$elm$html$Html$Attributes$placeholder('Entry description'),
													$elm$html$Html$Attributes$value(ei.dK),
													$elm$html$Html$Events$onInput(m.a$)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('circular ui icon button'),
													$author$project$Misc$cyAttr(m.H + '-clear-description'),
													$elm$html$Html$Events$onClick(
													m.a$(''))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('icon cancel')
														]),
													_List_Nil)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									addErrorClass(
									function ($) {
										return $.dH;
									})
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$author$project$Misc$cyAttr(m.H + '-date'),
											$elm$html$Html$Attributes$type_('date'),
											$elm$html$Html$Attributes$value(ei.dH),
											$elm$html$Html$Events$onInput(m.bn)
										]),
									_List_Nil)
								])),
							removeField
						]))
				]));
	});
var $author$project$Page$Edit$allEntryErrors = function (result) {
	if (!result.$) {
		return _List_Nil;
	} else {
		var entryResult = result.a;
		return _List_fromArray(
			[
				entryResult.e6,
				entryResult.fn,
				$author$project$Misc$dropSuccess(entryResult.e9),
				$author$project$Misc$dropSuccess(entryResult.dK),
				$author$project$Misc$dropSuccess(entryResult.dH)
			]);
	}
};
var $author$project$Misc$keepError = function (res) {
	if (!res.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var err = res.a;
		return $elm$core$Maybe$Just(err);
	}
};
var $author$project$Page$Edit$getErrorsAsList = function (validationResult) {
	if (!validationResult.$) {
		var results = validationResult.a;
		return A2(
			$elm$core$List$filterMap,
			$author$project$Misc$keepError,
			_List_fromArray(
				[
					$author$project$Misc$dropSuccess(results.dH),
					results.dK,
					results.fu,
					results.gv,
					$author$project$Misc$dropSuccess(results.e9),
					results.fn
				]));
	} else {
		var results = validationResult.a;
		return A2(
			$elm$core$List$filterMap,
			$author$project$Misc$keepError,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$Misc$dropSuccess(results.dH),
						results.dK,
						results.b3
					]),
				_Utils_ap(
					$author$project$Page$Edit$allEntryErrors(results.fu),
					_Utils_ap(
						$author$project$Page$Edit$allEntryErrors(results.gv),
						$elm$core$List$concat(
							A2($elm$core$List$map, $author$project$Page$Edit$allEntryErrors, results.fA))))));
	}
};
var $author$project$Page$Edit$viewFormErrors = function (results) {
	var formErrors = $author$project$Page$Edit$getErrorsAsList(results);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui error message')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Invalid input')
					])),
			A2(
				$elm$core$List$map,
				function (e) {
					return A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(e)
							]));
				},
				formErrors)));
};
var $author$project$Page$Edit$viewFormValidation = function (results) {
	if (results.$ === 1) {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	} else {
		var res = results.a;
		return $author$project$Page$Edit$viewFormErrors(res);
	}
};
var $author$project$Page$Edit$viewComplexForm = function (model) {
	var f = model.a;
	var complexResults = function () {
		var _v1 = model.aI;
		if ((!_v1.$) && (_v1.a.$ === 1)) {
			var res = _v1.a.a;
			return $elm$core$Maybe$Just(res);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	var destinationErrors = A2(
		$elm$core$Maybe$andThen,
		$author$project$Page$Edit$getEntryError,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.fu;
			},
			complexResults));
	var getEntryErrorAt = function (pos) {
		return A2(
			$elm$core$Maybe$andThen,
			$author$project$Page$Edit$getEntryError,
			A2(
				$elm$core$Maybe$andThen,
				$elm_community$list_extra$List$Extra$getAt(pos),
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.fA;
					},
					complexResults)));
	};
	var otherEntries = A2(
		$elm$core$List$indexedMap,
		function (pos) {
			return A2(
				$author$project$Page$Edit$viewEntryInput,
				pos,
				{
					aV: $author$project$Page$Edit$EditEntryAccount(pos),
					aX: $author$project$Page$Edit$EditEntryAmount(pos),
					a_: $author$project$Page$Edit$EditEntryCurrency(pos),
					H: 'entry-' + $elm$core$String$fromInt(pos + 3),
					bn: $author$project$Page$Edit$EditEntryDate(pos),
					a$: $author$project$Page$Edit$EditEntryDescription(pos),
					bs: getEntryErrorAt(pos),
					dZ: 'Entry ' + $elm$core$String$fromInt(pos + 3),
					a9: true
				});
		},
		f.fA);
	var isDateError = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (results) {
				return $author$project$Misc$isError(results.dH);
			},
			complexResults));
	var isDescriptionError = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (results) {
				return $author$project$Misc$isError(results.dK);
			},
			complexResults));
	var isFormError = function () {
		if (!complexResults.$) {
			return true;
		} else {
			return false;
		}
	}();
	var sourceErrors = A2(
		$elm$core$Maybe$andThen,
		$author$project$Page$Edit$getEntryError,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.fu;
			},
			complexResults));
	var cmd = model.T ? $author$project$Page$Edit$NoOp : $author$project$Page$Edit$SubmitForm;
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui attached segment main-content')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui large form'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('error', isFormError)
							])),
						$elm$html$Html$Events$onSubmit(cmd)
					]),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('error', isDateError)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Date')
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$name('date'),
											$author$project$Misc$cyAttr('date'),
											$elm$html$Html$Attributes$type_('date'),
											$elm$html$Html$Attributes$value(f.dH),
											$elm$html$Html$Events$onInput($author$project$Page$Edit$EditDate)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('error', isDescriptionError)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Description')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui action input')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$name('description'),
													$author$project$Misc$cyAttr('description'),
													$elm$html$Html$Attributes$list('descriptions'),
													$elm$html$Html$Attributes$placeholder('Supermarket'),
													$elm$html$Html$Attributes$value(f.dK),
													$elm$html$Html$Events$onInput($author$project$Page$Edit$EditDescription)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('circular ui icon button'),
													$author$project$Misc$cyAttr('clear-description'),
													$elm$html$Html$Events$onClick(
													$author$project$Page$Edit$EditDescription(''))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('icon cancel')
														]),
													_List_Nil)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Notes')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(f.ga),
											$elm$html$Html$Events$onInput($author$project$Page$Edit$EditNotes)
										]),
									_List_Nil)
								])),
							A3(
							$author$project$Page$Edit$viewEntryInput,
							0,
							{aV: $author$project$Page$Edit$EditDestinationAccount, aX: $author$project$Page$Edit$EditDestinationAmount, a_: $author$project$Page$Edit$EditDestinationCurrency, H: 'destination', bn: $author$project$Page$Edit$EditDestinationDate, a$: $author$project$Page$Edit$EditDestinationDescription, bs: destinationErrors, dZ: 'Destination', a9: false},
							f.l),
							A3(
							$author$project$Page$Edit$viewEntryInput,
							0,
							{aV: $author$project$Page$Edit$EditSourceAccount, aX: $author$project$Page$Edit$EditSourceAmount, a_: $author$project$Page$Edit$EditSourceCurrency, H: 'source', bn: $author$project$Page$Edit$EditSourceDate, a$: $author$project$Page$Edit$EditSourceDescription, bs: sourceErrors, dZ: 'Source', a9: false},
							f.m)
						]),
					_Utils_ap(
						otherEntries,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui button right floated'),
												$elm$html$Html$Events$onClick($author$project$Page$Edit$AddEntry)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Add Entry')
											]))
									])),
								$author$project$Page$Edit$viewFormValidation(model.aI)
							]))))
			]));
};
var $author$project$Misc$viewConfirmModal = A3(
	$elm$html$Html$node,
	'modal-wrapper',
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'modal-class', 'confirm-modal')
		]),
	_List_Nil);
var $author$project$Page$Edit$viewDescriptionsDataList = function (descriptions) {
	return A2(
		$author$project$Misc$viewDataList,
		'descriptions',
		A2(
			$elm$core$List$map,
			function ($) {
				return $.dK;
			},
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.aZ;
					},
					$elm$core$Dict$values(descriptions)))));
};
var $author$project$Page$Edit$EditAmount = function (a) {
	return {$: 8, a: a};
};
var $elm$html$Html$Attributes$lang = $elm$html$Html$Attributes$stringProperty('lang');
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Page$Edit$EditCurrency = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Edit$viewCurrencyInput = F2(
	function (model, isCurrencyError) {
		var _v0 = model.V;
		switch (_v0) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('error', isCurrencyError)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Currency')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui action input')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$name('currency'),
											$author$project$Misc$cyAttr('currency'),
											$elm$html$Html$Attributes$list('currencies'),
											$elm$html$Html$Attributes$placeholder('USD'),
											$elm$html$Html$Attributes$value(model.a.fn),
											$elm$html$Html$Events$onInput($author$project$Page$Edit$EditCurrency)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('circular ui icon button'),
											$author$project$Misc$cyAttr('clear-currency'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$Edit$EditCurrency(''))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon cancel')
												]),
											_List_Nil)
										]))
								]))
						]));
			default:
				return $elm$html$Html$text('');
		}
	});
var $author$project$Page$Edit$EditDestination = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Edit$destinationOptions = function (model) {
	var selectedOpt = model.a.fu;
	var options = $elm_community$list_extra$List$Extra$unique(
		_Utils_ap(model.bL.cS, model.a.ax));
	return A2(
		$elm$core$List$map,
		function (opt) {
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(opt),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(selectedOpt, opt))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(opt)
					]));
		},
		options);
};
var $author$project$Page$Edit$viewDestinationInput = function (model) {
	var _v0 = model.V;
	switch (_v0) {
		case 0:
			return A2(
				$elm$html$Html$select,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui fluid dropdown'),
						$author$project$Misc$cyAttr('destination'),
						$elm$html$Html$Attributes$name('destination'),
						$elm$html$Html$Events$onInput($author$project$Page$Edit$EditDestination)
					]),
				$author$project$Page$Edit$destinationOptions(model));
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui action input')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$name('destination'),
								$author$project$Misc$cyAttr('destination'),
								$elm$html$Html$Attributes$placeholder('Expenses:Groceries'),
								$elm$html$Html$Attributes$list('accounts'),
								$elm$html$Html$Attributes$value(model.a.fu),
								$elm$html$Html$Events$onInput($author$project$Page$Edit$EditDestination)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('circular ui icon button'),
								$author$project$Misc$cyAttr('clear-destination'),
								$elm$html$Html$Events$onClick(
								$author$project$Page$Edit$EditDestination(''))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('icon cancel')
									]),
								_List_Nil)
							]))
					]));
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Page$Edit$EditSource = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Edit$sourceOptions = function (model) {
	var selectedOpt = model.a.gv;
	var options = $elm_community$list_extra$List$Extra$unique(
		_Utils_ap(model.bL.dk, model.a.ay));
	return A2(
		$elm$core$List$map,
		function (opt) {
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(opt),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(selectedOpt, opt))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(opt)
					]));
		},
		options);
};
var $author$project$Page$Edit$viewSourceInput = function (model) {
	var _v0 = model.V;
	switch (_v0) {
		case 0:
			return A2(
				$elm$html$Html$select,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui fluid dropdown'),
						$author$project$Misc$cyAttr('source'),
						$elm$html$Html$Attributes$name('source'),
						$elm$html$Html$Events$onInput($author$project$Page$Edit$EditSource)
					]),
				$author$project$Page$Edit$sourceOptions(model));
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui action input')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$name('source'),
								$author$project$Misc$cyAttr('source'),
								$elm$html$Html$Attributes$placeholder('Assets:Cash'),
								$elm$html$Html$Attributes$list('accounts'),
								$elm$html$Html$Attributes$value(model.a.gv),
								$elm$html$Html$Events$onInput($author$project$Page$Edit$EditSource)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('circular ui icon button'),
								$author$project$Misc$cyAttr('clear-source'),
								$elm$html$Html$Events$onClick(
								$author$project$Page$Edit$EditSource(''))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('icon cancel')
									]),
								_List_Nil)
							]))
					]));
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Page$Edit$viewMainContent = function (model) {
	var simpleResults = function () {
		var _v1 = model.aI;
		if ((!_v1.$) && (!_v1.a.$)) {
			var res = _v1.a.a;
			return $elm$core$Maybe$Just(res);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	var isSourceError = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (results) {
				return $author$project$Misc$isError(results.gv);
			},
			simpleResults));
	var isFormError = function () {
		if (!simpleResults.$) {
			return true;
		} else {
			return false;
		}
	}();
	var isDestinationError = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (results) {
				return $author$project$Misc$isError(results.fu);
			},
			simpleResults));
	var isDescriptionError = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (results) {
				return $author$project$Misc$isError(results.dK);
			},
			simpleResults));
	var isDateError = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (results) {
				return $author$project$Misc$isError(results.dH);
			},
			simpleResults));
	var isCurrencyError = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (results) {
				return $author$project$Misc$isError(results.fn);
			},
			simpleResults));
	var isAmountError = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (results) {
				return $author$project$Misc$isError(results.e9);
			},
			simpleResults));
	var f = model.a;
	var cmd = model.T ? $author$project$Page$Edit$NoOp : $author$project$Page$Edit$SubmitForm;
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui attached segment main-content')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui large form'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('error', isFormError)
							])),
						$elm$html$Html$Events$onSubmit(cmd)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('error', isDateError)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Date')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$name('date'),
										$author$project$Misc$cyAttr('date'),
										$elm$html$Html$Attributes$type_('date'),
										$elm$html$Html$Attributes$value(f.dH),
										$elm$html$Html$Events$onInput($author$project$Page$Edit$EditDate)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('error', isDescriptionError)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Description')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ui action input')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$name('description'),
												$author$project$Misc$cyAttr('description'),
												$elm$html$Html$Attributes$list('descriptions'),
												$elm$html$Html$Attributes$placeholder('Supermarket'),
												$elm$html$Html$Attributes$value(f.dK),
												$elm$html$Html$Events$onInput($author$project$Page$Edit$EditDescription)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('circular ui icon button'),
												$author$project$Misc$cyAttr('clear-description'),
												$elm$html$Html$Events$onClick(
												$author$project$Page$Edit$EditDescription(''))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon cancel')
													]),
												_List_Nil)
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('error', isAmountError)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Amount')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ui action input')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$name('amount'),
												$author$project$Misc$cyAttr('amount'),
												$elm$html$Html$Attributes$type_('number'),
												$elm$html$Html$Attributes$step('0.01'),
												$elm$html$Html$Attributes$placeholder('Amount'),
												A2($elm$html$Html$Attributes$attribute, 'inputmode', 'decimal'),
												$elm$html$Html$Attributes$lang('en-US'),
												$elm$html$Html$Attributes$placeholder('10.99'),
												$elm$html$Html$Attributes$value(f.e9),
												$elm$html$Html$Events$onInput($author$project$Page$Edit$EditAmount)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('circular ui icon button'),
												$author$project$Misc$cyAttr('clear-amount'),
												$elm$html$Html$Events$onClick(
												$author$project$Page$Edit$EditAmount(''))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon cancel')
													]),
												_List_Nil)
											]))
									]))
							])),
						A2($author$project$Page$Edit$viewCurrencyInput, model, isCurrencyError),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('error', isDestinationError)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Expense')
									])),
								$author$project$Page$Edit$viewDestinationInput(model)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('error', isSourceError)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Source')
									])),
								$author$project$Page$Edit$viewSourceInput(model)
							])),
						$author$project$Page$Edit$viewFormValidation(model.aI)
					]))
			]));
};
var $author$project$Page$Edit$Advanced = 1;
var $author$project$Page$Edit$SetEditMode = function (a) {
	return {$: 34, a: a};
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Page$Edit$viewTopBarItems = F2(
	function (title, model) {
		var current = function () {
			var _v0 = model.V;
			switch (_v0) {
				case 0:
					return 'Simple Edit';
				case 1:
					return 'Advanced Edit';
				default:
					return 'Full Edit';
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header item')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('right menu')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ui dropdown item needs-js-menu'),
								$author$project$Misc$cyAttr('edit-mode-menu')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$author$project$Misc$cyAttr('edit-mode-menu-text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(current)
									])),
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('dropdown icon')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menu')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item'),
												$author$project$Misc$cyAttr('edit-mode-simple'),
												$elm$html$Html$Events$onClick(
												$author$project$Page$Edit$SetEditMode(0))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Simple')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item'),
												$author$project$Misc$cyAttr('edit-mode-advanced'),
												$elm$html$Html$Events$onClick(
												$author$project$Page$Edit$SetEditMode(1))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Advanced')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('item'),
												$author$project$Misc$cyAttr('edit-mode-full'),
												$elm$html$Html$Events$onClick(
												$author$project$Page$Edit$SetEditMode(2))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Full')
											]))
									]))
							]))
					]))
			]);
	});
var $author$project$Page$Edit$viewEditForm = function (model) {
	var settings = model.bL;
	var mainContent = function () {
		var _v2 = model.V;
		switch (_v2) {
			case 0:
				return $author$project$Page$Edit$viewMainContent(model);
			case 1:
				return $author$project$Page$Edit$viewMainContent(model);
			default:
				return $author$project$Page$Edit$viewComplexForm(model);
		}
	}();
	var _v0 = function () {
		var _v1 = model.b1;
		if (!_v1.$) {
			return _Utils_Tuple2('Edit Transaction', 4);
		} else {
			return _Utils_Tuple2('New Transaction', 5);
		}
	}();
	var title = _v0.a;
	var page = _v0.b;
	return A5(
		$author$project$Page$viewPage,
		model.e,
		page,
		title,
		A2($author$project$Page$Edit$viewTopBarItems, title, model),
		_List_fromArray(
			[
				mainContent,
				A2($author$project$Page$Edit$viewAccountsDataList, model, settings),
				$author$project$Page$Edit$viewDescriptionsDataList(model.bo),
				A2(
				$author$project$Misc$viewDataList,
				'currencies',
				A2($elm$core$List$cons, settings.cQ, settings.es)),
				$author$project$Misc$viewConfirmModal,
				A2($author$project$Page$Edit$viewBottomBar, model, settings)
			]));
};
var $author$project$Page$Edit$view = function (model) {
	var _v0 = _Utils_Tuple2(model.b1, model.br);
	if (_v0.a.$ === 1) {
		var _v1 = _v0.a;
		return $author$project$Page$Edit$viewEditForm(model);
	} else {
		if (_v0.b.$ === 1) {
			var _v2 = _v0.b;
			return $author$project$Misc$viewLoadingDocument;
		} else {
			return $author$project$Page$Edit$viewEditForm(model);
		}
	}
};
var $author$project$Page$ExpensesBarChart$SelectPreset = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$ExpensesBarChart$buildPresetMenuItems = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui menu')
				]),
			A2(
				$elm$core$List$map,
				function (p) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('link item'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'active',
										_Utils_eq(
											p.a7,
											A2($elm$core$Maybe$withDefault, '', model.aJ)))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$Page$ExpensesBarChart$SelectPreset(p.a7))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(p.a7)
							]));
				},
				model.bH))
		]);
};
var $author$project$Page$ExpensesBarChart$EditCurrency = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$ExpensesBarChart$EditFromDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ExpensesBarChart$EditGroupedDepth = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$ExpensesBarChart$EditToDate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ExpensesBarChart$ToggleShowGraphLegend = {$: 8};
var $author$project$Page$ExpensesBarChart$ToggleTotalDisplayMode = {$: 7};
var $elm$html$Html$canvas = _VirtualDom_node('canvas');
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Page$ExpensesBarChart$ToggleExcludedAccount = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ExpensesBarChart$viewExpenseRow = F2(
	function (model, _v0) {
		var account = _v0.a;
		var total = _v0.b;
		var totalValue = function () {
			if (model.av) {
				var _v1 = model.cv;
				if (!_v1) {
					return '-';
				} else {
					var t = _v1;
					return A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, (100.0 * total) / t) + ' %';
				}
			} else {
				return $author$project$Page$ExpensesBarChart$fmt(total);
			}
		}();
		var isExcluded = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Dict$get, account, model.a1));
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(account)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(totalValue)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui toggle checkbox'),
									$elm$html$Html$Events$onClick(
									$author$project$Page$ExpensesBarChart$ToggleExcludedAccount(account))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('checkbox'),
											$elm$html$Html$Attributes$checked(isExcluded)
										]),
									_List_Nil),
									A2($elm$html$Html$label, _List_Nil, _List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$ExpensesBarChart$viewMain = function (model) {
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui attached segment main-content')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$canvas,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('bar-chart')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui form')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h4,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ui dividing header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Source Options')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('two fields')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('From')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$name('date'),
												$author$project$Misc$cyAttr('date'),
												$elm$html$Html$Attributes$type_('date'),
												$elm$html$Html$Attributes$value(model.aA),
												$elm$html$Html$Events$onInput($author$project$Page$ExpensesBarChart$EditFromDate)
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('To')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$name('date'),
												$author$project$Misc$cyAttr('date'),
												$elm$html$Html$Attributes$type_('date'),
												$elm$html$Html$Attributes$value(model.aQ),
												$elm$html$Html$Events$onInput($author$project$Page$ExpensesBarChart$EditToDate)
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('two fields')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Currency')
											])),
										A2(
										$elm$html$Html$select,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui dropdown'),
												$author$project$Misc$cyAttr('currency'),
												$elm$html$Html$Events$onInput($author$project$Page$ExpensesBarChart$EditCurrency)
											]),
										A2($author$project$Misc$viewSelectOptions, model.fn, model.cG))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Account depth')
											])),
										A2(
										$elm$html$Html$select,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui dropdown'),
												$author$project$Misc$cyAttr('grouped-depth'),
												$elm$html$Html$Events$onInput($author$project$Page$ExpensesBarChart$EditGroupedDepth)
											]),
										A2(
											$author$project$Misc$viewSelectOptions,
											model.b6,
											$elm$core$List$sort(
												$elm$core$Dict$keys($author$project$Page$ExpensesBarChart$groupedOptions))))
									]))
							])),
						A2(
						$elm$html$Html$h4,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ui dividing header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Display Options')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('two fields')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui toggle checkbox')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('checkbox'),
														$elm$html$Html$Attributes$id('toggle-show-legend'),
														$author$project$Misc$cyAttr('toggle-show-legend'),
														$elm$html$Html$Attributes$checked(model.bb),
														$elm$html$Html$Events$onClick($author$project$Page$ExpensesBarChart$ToggleShowGraphLegend)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$label,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$for('toggle-show-legend')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Show graph legend')
													]))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui toggle checkbox')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('checkbox'),
														$elm$html$Html$Attributes$id('toggle-show-percentage'),
														$author$project$Misc$cyAttr('toggle-show-percentage'),
														$elm$html$Html$Attributes$checked(model.av),
														$elm$html$Html$Events$onClick($author$project$Page$ExpensesBarChart$ToggleTotalDisplayMode)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$label,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$for('toggle-show-percentage')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Percentage in column')
													]))
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui unstackable celled table')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Account')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												model.av ? 'Percentage' : 'Total')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Exclude')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2(
							$elm$core$List$map,
							$author$project$Page$ExpensesBarChart$viewExpenseRow(model),
							model.bR))
					]))
			]));
};
var $author$project$Page$ExpensesBarChart$view = function (model) {
	return A5(
		$author$project$Page$viewPage,
		model.e,
		7,
		'Expenses',
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header item')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Expenses')
					]))
			]),
		_List_fromArray(
			[
				$author$project$Page$ExpensesBarChart$viewMain(model),
				$author$project$Page$viewBottomBar(
				$author$project$Page$ExpensesBarChart$buildPresetMenuItems(model))
			]));
};
var $author$project$Page$ExpensesPieChart$SelectPreset = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$ExpensesPieChart$buildPresetMenuItems = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui menu')
				]),
			A2(
				$elm$core$List$map,
				function (p) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('link item'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'active',
										_Utils_eq(
											p.a7,
											A2($elm$core$Maybe$withDefault, '', model.aJ)))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$Page$ExpensesPieChart$SelectPreset(p.a7))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(p.a7)
							]));
				},
				model.bH))
		]);
};
var $author$project$Page$ExpensesPieChart$EditCurrency = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$ExpensesPieChart$EditFromDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ExpensesPieChart$EditGroupedDepth = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$ExpensesPieChart$EditToDate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ExpensesPieChart$ToggleShowGraphLegend = {$: 8};
var $author$project$Page$ExpensesPieChart$ToggleTotalDisplayMode = {$: 7};
var $author$project$Page$ExpensesPieChart$ToggleExcludedAccount = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ExpensesPieChart$viewExpenseRow = F2(
	function (model, _v0) {
		var account = _v0.a;
		var total = _v0.b;
		var totalValue = function () {
			if (model.av) {
				var _v1 = model.cv;
				if (!_v1) {
					return '-';
				} else {
					var t = _v1;
					return A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, (100.0 * total) / t) + ' %';
				}
			} else {
				return $author$project$Page$ExpensesPieChart$fmt(total);
			}
		}();
		var isExcluded = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Dict$get, account, model.a1));
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(account)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(totalValue)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui toggle checkbox'),
									$elm$html$Html$Events$onClick(
									$author$project$Page$ExpensesPieChart$ToggleExcludedAccount(account))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('checkbox'),
											$elm$html$Html$Attributes$checked(isExcluded)
										]),
									_List_Nil),
									A2($elm$html$Html$label, _List_Nil, _List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$ExpensesPieChart$viewMain = function (model) {
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui attached segment main-content')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$canvas,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('pie-chart')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui form')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h4,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ui dividing header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Source Options')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('two fields')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('From')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$name('date'),
												$author$project$Misc$cyAttr('date'),
												$elm$html$Html$Attributes$type_('date'),
												$elm$html$Html$Attributes$value(model.aA),
												$elm$html$Html$Events$onInput($author$project$Page$ExpensesPieChart$EditFromDate)
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('To')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$name('date'),
												$author$project$Misc$cyAttr('date'),
												$elm$html$Html$Attributes$type_('date'),
												$elm$html$Html$Attributes$value(model.aQ),
												$elm$html$Html$Events$onInput($author$project$Page$ExpensesPieChart$EditToDate)
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('two fields')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Currency')
											])),
										A2(
										$elm$html$Html$select,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui dropdown'),
												$author$project$Misc$cyAttr('currency'),
												$elm$html$Html$Events$onInput($author$project$Page$ExpensesPieChart$EditCurrency)
											]),
										A2($author$project$Misc$viewSelectOptions, model.fn, model.cG))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Account depth')
											])),
										A2(
										$elm$html$Html$select,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui dropdown'),
												$author$project$Misc$cyAttr('grouped-depth'),
												$elm$html$Html$Events$onInput($author$project$Page$ExpensesPieChart$EditGroupedDepth)
											]),
										A2(
											$author$project$Misc$viewSelectOptions,
											model.b6,
											$elm$core$List$sort(
												$elm$core$Dict$keys($author$project$Page$ExpensesPieChart$groupedOptions))))
									]))
							])),
						A2(
						$elm$html$Html$h4,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ui dividing header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Display Options')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('two fields')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui toggle checkbox')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('checkbox'),
														$elm$html$Html$Attributes$id('toggle-show-legend'),
														$author$project$Misc$cyAttr('toggle-show-legend'),
														$elm$html$Html$Attributes$checked(model.bb),
														$elm$html$Html$Events$onClick($author$project$Page$ExpensesPieChart$ToggleShowGraphLegend)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$label,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$for('toggle-show-legend')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Show graph legend')
													]))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ui toggle checkbox')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('checkbox'),
														$elm$html$Html$Attributes$id('toggle-show-percentage'),
														$author$project$Misc$cyAttr('toggle-show-percentage'),
														$elm$html$Html$Attributes$checked(model.av),
														$elm$html$Html$Events$onClick($author$project$Page$ExpensesPieChart$ToggleTotalDisplayMode)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$label,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$for('toggle-show-percentage')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Percentage in column')
													]))
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui unstackable celled table')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Account')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												model.av ? 'Percentage' : 'Total')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Exclude')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2(
							$elm$core$List$map,
							$author$project$Page$ExpensesPieChart$viewExpenseRow(model),
							model.bR))
					]))
			]));
};
var $author$project$Page$ExpensesPieChart$view = function (model) {
	return A5(
		$author$project$Page$viewPage,
		model.e,
		7,
		'Expenses',
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header item')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Expenses')
					]))
			]),
		_List_fromArray(
			[
				$author$project$Page$ExpensesPieChart$viewMain(model),
				$author$project$Page$viewBottomBar(
				$author$project$Page$ExpensesPieChart$buildPresetMenuItems(model))
			]));
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$NotFound$view = {
	C: _List_fromArray(
		[
			A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('content'),
					$elm$html$Html$Attributes$class('container'),
					$elm$html$Html$Attributes$tabindex(-1)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui segment')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Not Found')
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('/')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Go Home')
										]))
								]))
						]))
				]))
		]),
	D: 'Page Not Found'
};
var $author$project$Page$Settings$CloseQrCode = {$: 36};
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize = 5;
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$appendLastRect = function (_v0) {
	var lastRect = _v0.a;
	var rowLines = _v0.b;
	return A2(
		$elm$core$List$cons,
		'h' + $elm$core$String$fromInt(lastRect.e0 * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize),
		rowLines);
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$Attributes$shapeRendering = _VirtualDom_attribute('shape-rendering');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$toRowLines = F2(
	function (isDark, _v0) {
		var lastRect = _v0.a;
		var rowLines = _v0.b;
		return isDark ? ((!lastRect.aL) ? _Utils_Tuple2(
			_Utils_update(
				lastRect,
				{e0: lastRect.e0 + 1}),
			rowLines) : _Utils_Tuple2(
			{aL: 0, e0: 1},
			A2(
				$elm$core$List$cons,
				$elm$core$String$concat(
					_List_fromArray(
						[
							(lastRect.e0 > 0) ? ('h' + $elm$core$String$fromInt(lastRect.e0 * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize)) : '',
							'm',
							$elm$core$String$fromInt(lastRect.aL * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize),
							' 0'
						])),
				rowLines))) : _Utils_Tuple2(
			_Utils_update(
				lastRect,
				{aL: lastRect.aL + 1}),
			rowLines);
	});
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$viewRow = F3(
	function (quietZoneSize, rowIndex, rowLines) {
		return A2(
			$elm$core$List$cons,
			'M0 ',
			A2(
				$elm$core$List$cons,
				$elm$core$String$fromInt(rowIndex * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize),
				rowLines));
	});
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$viewBase = F3(
	function (quietZoneSize, extraAttrs, matrix) {
		var quietZonePx = quietZoneSize * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize;
		var sizePx = $elm$core$String$fromInt(
			($elm$core$List$length(matrix) * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize) + (2 * quietZonePx));
		return A2(
			$elm$svg$Svg$svg,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$viewBox('0 0 ' + (sizePx + (' ' + sizePx))),
						$elm$svg$Svg$Attributes$shapeRendering('crispEdges'),
						$elm$svg$Svg$Attributes$stroke('#000'),
						$elm$svg$Svg$Attributes$strokeWidth(
						$elm$core$String$fromInt($pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize) + 'px')
					]),
				extraAttrs),
			function (d) {
				return _List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								d,
								$elm$svg$Svg$Attributes$transform(
								'translate(' + ($elm$core$String$fromInt(quietZonePx) + (', ' + ($elm$core$String$fromFloat(quietZonePx + ($pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize / 2)) + ')')))),
								$elm$svg$Svg$Attributes$strokeWidth('5px')
							]),
						_List_Nil)
					]);
			}(
				$elm$svg$Svg$Attributes$d(
					$elm$core$String$concat(
						$elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								$pablohirafuji$elm_qrcode$QRCode$Render$Svg$viewRow(quietZoneSize),
								A2(
									$elm$core$List$map,
									A2(
										$elm$core$Basics$composeR,
										A2(
											$elm$core$List$foldl,
											$pablohirafuji$elm_qrcode$QRCode$Render$Svg$toRowLines,
											_Utils_Tuple2(
												{aL: 0, e0: 0},
												_List_Nil)),
										A2($elm$core$Basics$composeR, $pablohirafuji$elm_qrcode$QRCode$Render$Svg$appendLastRect, $elm$core$List$reverse)),
									matrix)))))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$view = $pablohirafuji$elm_qrcode$QRCode$Render$Svg$viewBase(4);
var $pablohirafuji$elm_qrcode$QRCode$toSvg = F2(
	function (extraAttrs, _v0) {
		var matrix = _v0.bC;
		return A2($pablohirafuji$elm_qrcode$QRCode$Render$Svg$view, extraAttrs, matrix);
	});
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Page$Settings$viewQrCodeMainContent = F2(
	function (model, result) {
		var content = function () {
			if (!result.$) {
				var qr = result.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui huge image')
						]),
					_List_fromArray(
						[
							A2(
							$pablohirafuji$elm_qrcode$QRCode$toSvg,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$width('500px'),
									$elm$svg$Svg$Attributes$height('500px')
								]),
							qr)
						]));
			} else {
				return $elm$html$Html$text('Error :(');
			}
		}();
		return {
			C: _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$main_,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui attached segment main-content')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui dividing header')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Share Settings')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui segment')
										]),
									_List_fromArray(
										[
											content,
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('ui button'),
													$elm$html$Html$Events$onClick($author$project$Page$Settings$CloseQrCode)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Close')
												]))
										]))
								]))
						]))
				]),
			D: 'Share Settings'
		};
	});
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$Settings$viewScanQrCodeMainContent = {
	C: _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$main_,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui attached segment main-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui dividing header')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Scan Settings')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui segment')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$id('qrcode-reader'),
											$elm$html$Html$Attributes$width(600)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui button'),
											$elm$html$Html$Events$onClick($author$project$Page$Settings$CloseQrCode)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Close')
										]))
								]))
						]))
				]))
		]),
	D: 'Scan Settings'
};
var $author$project$Page$Settings$Cancel = {$: 13};
var $author$project$Page$Settings$DeleteAllRequested = {$: 20};
var $author$project$Page$Settings$Ledger = 1;
var $author$project$Page$Settings$ReadQrCode = {$: 35};
var $author$project$Page$Settings$SubmitForm = {$: 14};
var $author$project$Page$Settings$viewBottomBar = F2(
	function (model, settings) {
		var workingIcon = A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			model.aU === 2,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('loading spinner icon')
							]),
						_List_Nil)
					])));
		var scanQrCodeItem = A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			model.ak,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ui blue button'),
								$author$project$Misc$cyAttr('scan-qr-code'),
								$elm$html$Html$Events$onClick($author$project$Page$Settings$ReadQrCode)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Scan QR')
							]))
					])));
		var moreMenu = A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			!model.ak,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui dropdown item needs-js-menu')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('More'),
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('caret up icon')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('menu')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										$author$project$Misc$cyAttr('import-sample'),
										$elm$html$Html$Events$onClick($author$project$Page$Settings$ImportSample)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('angle double down icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Import sample')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										$author$project$Misc$cyAttr('import-json'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('disabled', !(!model.aU))
											])),
										$elm$html$Html$Events$onClick($author$project$Page$Settings$JsonRequested)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('folder open icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Import JSON')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										$author$project$Misc$cyAttr('export-data-json'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('disabled', !(!model.aU))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$Page$Settings$ExportData(0))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('download icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Export JSON Transactions')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										$author$project$Misc$cyAttr('export-data-ledger'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('disabled', !(!model.aU))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$Page$Settings$ExportData(1))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('download icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Export Ledger Transactions')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('item'),
										$author$project$Misc$cyAttr('delete-all-data'),
										$elm$html$Html$Events$onClick($author$project$Page$Settings$DeleteAllRequested)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('red delete icon')
											]),
										_List_Nil),
										$elm$html$Html$text('Deleta ALL data')
									]))
							]))
					])));
		var cancelButton = A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			!model.ak,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ui button'),
								$author$project$Misc$cyAttr('cancel'),
								$elm$html$Html$Events$onClick($author$project$Page$Settings$Cancel)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Cancel')
							]))
					])));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui bottom attached menu bottom-bar')
				]),
			_List_fromArray(
				[
					cancelButton,
					moreMenu,
					workingIcon,
					scanQrCodeItem,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('right menu')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('disabled', !(!model.aU))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ui positive button right floated'),
											$author$project$Misc$cyAttr('save'),
											$elm$html$Html$Events$onClick($author$project$Page$Settings$SubmitForm)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Save')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Settings$EditCurrencies = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Settings$EditDefaultExpenseReportCurrency = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Settings$EditDestinationAccounts = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Settings$EditReportCurrencies = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Settings$EditSourceAccounts = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Settings$isFieldError = F2(
	function (results, accessor) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$author$project$Misc$isError,
				A2($elm$core$Maybe$map, accessor, results)));
	});
var $author$project$Page$Settings$viewFormErrors = function (errors) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui error message')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Invalid input')
					])),
			A2(
				$elm$core$List$map,
				function (e) {
					return A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(e)
							]));
				},
				errors)));
};
var $author$project$Page$Settings$viewFormSuccess = function (maybeMessage) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text(''),
		A2(
			$elm$core$Maybe$map,
			function (message) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui success message')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(message)
								]))
						]));
			},
			maybeMessage));
};
var $author$project$Page$Settings$EditNewPassword = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Settings$EditNewPasswordConfirm = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Settings$EditUsername = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Settings$viewEncryptionPart = function (model) {
	var f = model.d;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h4,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui dividing header')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Encryption')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui segment')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui info message')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('header')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('This cannot be changed later!')
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Use a password manager or write down this password in a safe place.')
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Data cannot be recovered without it.')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'error',
											A2(
												$author$project$Page$Settings$isFieldError,
												model.aI,
												function ($) {
													return $.u;
												}))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Username')
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$name('username'),
											$author$project$Misc$cyAttr('username'),
											$elm$html$Html$Attributes$value(f.be),
											$elm$html$Html$Events$onInput($author$project$Page$Settings$EditUsername)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'error',
											A2(
												$author$project$Page$Settings$isFieldError,
												model.aI,
												function ($) {
													return $.u;
												}))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Encryption password')
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$name('newPassword'),
											$elm$html$Html$Attributes$type_('password'),
											$author$project$Misc$cyAttr('new-password'),
											A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'new-password'),
											$elm$html$Html$Attributes$value(f.u),
											$elm$html$Html$Events$onInput($author$project$Page$Settings$EditNewPassword)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'error',
											A2(
												$author$project$Page$Settings$isFieldError,
												model.aI,
												function ($) {
													return $.u;
												}))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Confirm encryption password')
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$name('newPasswordConfirm'),
											$elm$html$Html$Attributes$type_('password'),
											$author$project$Misc$cyAttr('new-password-confirm'),
											A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'new-password'),
											$elm$html$Html$Attributes$value(f.am),
											$elm$html$Html$Events$onInput($author$project$Page$Settings$EditNewPasswordConfirm)
										]),
									_List_Nil)
								]))
						]))
				]))
		]);
};
var $author$project$Page$Settings$viewMaybeEncryptionPart = function (model) {
	return model.ak ? $author$project$Page$Settings$viewEncryptionPart(model) : _List_Nil;
};
var $author$project$Page$Settings$ToggleReplication = {$: 8};
var $author$project$Page$Settings$EditReplicationPassword = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Settings$EditReplicationUrl = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Settings$EditReplicationUsername = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Settings$TestReplicationSettings = {$: 33};
var $author$project$Page$Settings$ToggleSyncOnSave = {$: 9};
var $author$project$Page$Settings$viewReplicationPartOn = function (model) {
	var isError = A2(
		$author$project$Page$Settings$isFieldError,
		model.aI,
		function ($) {
			return $.p;
		});
	var f = model.d;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('error', isError)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Replication URL')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$name('replicationUrl'),
							$author$project$Misc$cyAttr('replication-url'),
							$elm$html$Html$Attributes$placeholder('https://couchdb.org:6984/user-adf'),
							$elm$html$Html$Attributes$value(f.bJ),
							$elm$html$Html$Events$onInput($author$project$Page$Settings$EditReplicationUrl)
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('error', isError)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Replication username')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$name('replicationUsername'),
							$author$project$Misc$cyAttr('replication-username'),
							$elm$html$Html$Attributes$placeholder('john'),
							$elm$html$Html$Attributes$value(f.bK),
							$elm$html$Html$Events$onInput($author$project$Page$Settings$EditReplicationUsername)
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('error', isError)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Replication password')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$name('replicationPassword'),
							$elm$html$Html$Attributes$type_('password'),
							$author$project$Misc$cyAttr('replication-password'),
							A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'none'),
							$elm$html$Html$Attributes$value(f.bI),
							$elm$html$Html$Events$onInput($author$project$Page$Settings$EditReplicationPassword)
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui toggle checkbox')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('checkbox'),
									$elm$html$Html$Attributes$id('toggle-sync-on-save'),
									$author$project$Misc$cyAttr('toggle-sync-on-save'),
									$elm$html$Html$Attributes$tabindex(0),
									$elm$html$Html$Attributes$checked(f.gy),
									$elm$html$Html$Events$onClick($author$project$Page$Settings$ToggleSyncOnSave)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$for('toggle-sync-on-save')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Synchronize when saving transactions')
								]))
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui green button'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('disabled', !(!model.aU))
						])),
					$elm$html$Html$Events$onClick($author$project$Page$Settings$TestReplicationSettings)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Test Settings')
				]))
		]);
};
var $author$project$Page$Settings$viewReplicationPart = function (model) {
	var f = model.d;
	var content = f.p ? $author$project$Page$Settings$viewReplicationPartOn(model) : _List_Nil;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h4,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui dividing header')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Replication')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui segment')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui toggle checkbox')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('checkbox'),
											$elm$html$Html$Attributes$id('toggle-replication'),
											$author$project$Misc$cyAttr('toggle-replication'),
											$elm$html$Html$Attributes$tabindex(0),
											$elm$html$Html$Attributes$checked(f.p),
											$elm$html$Html$Events$onClick($author$project$Page$Settings$ToggleReplication)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$for('toggle-replication')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Replicate local databse')
										]))
								]))
						])),
				content))
		]);
};
var $author$project$Page$Settings$GenerateQrCode = {$: 34};
var $author$project$Page$Settings$viewShareSettingsPart = function (model) {
	return model.ak ? _List_Nil : _List_fromArray(
		[
			A2(
			$elm$html$Html$h4,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui dividing header')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Share Settings')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui segment')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui blue button'),
							$elm$html$Html$Events$onClick($author$project$Page$Settings$GenerateQrCode)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Generate QR')
						]))
				]))
		]);
};
var $author$project$Page$Settings$viewMainContent = F2(
	function (model, settings) {
		var hadSuccess = $author$project$Misc$isSomething(model.aM);
		var f = model.d;
		var error = function () {
			var _v1 = model.ac;
			if (!_v1.$) {
				var e = _v1.a;
				return _List_fromArray(
					[e]);
			} else {
				return _List_Nil;
			}
		}();
		var errors = function () {
			var _v0 = model.aI;
			if (_v0.$ === 1) {
				return _Utils_ap(_List_Nil, error);
			} else {
				var res = _v0.a;
				return A2(
					$elm$core$List$filterMap,
					$author$project$Misc$keepError,
					_List_fromArray(
						[
							res.cQ,
							res.cS,
							res.dk,
							$author$project$Misc$dropSuccess(res.u),
							res.ft
						]));
			}
		}();
		var hadErrors = !$elm$core$List$isEmpty(errors);
		var errorView = hadErrors ? $author$project$Page$Settings$viewFormErrors(errors) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui attached segment main-content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$form,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui large form'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('error', hadErrors),
									_Utils_Tuple2('success', hadSuccess)
								])),
							$elm$html$Html$Events$onSubmit($author$project$Page$Settings$SubmitForm)
						]),
					_Utils_ap(
						$author$project$Page$Settings$viewMaybeEncryptionPart(model),
						_Utils_ap(
							$author$project$Page$Settings$viewReplicationPart(model),
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'error',
														A2(
															$author$project$Page$Settings$isFieldError,
															model.aI,
															function ($) {
																return $.cQ;
															}))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Default currencies')
													])),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$name('defaultCurrency'),
														$author$project$Misc$cyAttr('default-currency'),
														$elm$html$Html$Attributes$placeholder('USD,EUR'),
														$elm$html$Html$Attributes$value(f.G),
														$elm$html$Html$Events$onInput($author$project$Page$Settings$EditCurrencies)
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'error',
														A2(
															$author$project$Page$Settings$isFieldError,
															model.aI,
															function ($) {
																return $.cS;
															}))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Expense accounts')
													])),
												A2(
												$elm$html$Html$textarea,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$name('destinationAccounts'),
														$author$project$Misc$cyAttr('destination-accounts'),
														$elm$html$Html$Attributes$placeholder('Expenses:Groceries\nExpenses:Eat Out & Take Away'),
														$elm$html$Html$Attributes$value(f.cS),
														$elm$html$Html$Events$onInput($author$project$Page$Settings$EditDestinationAccounts)
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'error',
														A2(
															$author$project$Page$Settings$isFieldError,
															model.aI,
															function ($) {
																return $.dk;
															}))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Source accounts')
													])),
												A2(
												$elm$html$Html$textarea,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$name('sourceAccounts'),
														$author$project$Misc$cyAttr('source-accounts'),
														$elm$html$Html$Attributes$placeholder('Assets:Cash\nLiabilities:CreditCard'),
														$elm$html$Html$Attributes$value(f.dk),
														$elm$html$Html$Events$onInput($author$project$Page$Settings$EditSourceAccounts)
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Report currencies')
													])),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$name('report-currencies'),
														$author$project$Misc$cyAttr('report-currencies'),
														$elm$html$Html$Attributes$placeholder('USD,EUR'),
														$elm$html$Html$Attributes$value(f.go),
														$elm$html$Html$Events$onInput($author$project$Page$Settings$EditReportCurrencies)
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'error',
														A2(
															$author$project$Page$Settings$isFieldError,
															model.aI,
															function ($) {
																return $.ft;
															}))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Expense reports default currency')
													])),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$name('expense-report-default-currency'),
														$author$project$Misc$cyAttr('expense-report-default-currency'),
														$elm$html$Html$Attributes$placeholder('USD'),
														$elm$html$Html$Attributes$value(f.ft),
														$elm$html$Html$Events$onInput($author$project$Page$Settings$EditDefaultExpenseReportCurrency)
													]),
												_List_Nil)
											]))
									]),
								_Utils_ap(
									$author$project$Page$Settings$viewShareSettingsPart(model),
									_List_fromArray(
										[
											errorView,
											$author$project$Page$Settings$viewFormSuccess(model.aM)
										])))))),
					$author$project$Misc$viewConfirmModal
				]));
	});
var $author$project$Page$Settings$viewUnlockedSettings = F3(
	function (model, settings, addWelcomeHeader) {
		return A5(
			$author$project$Page$viewPage,
			model.e,
			1,
			'Settings',
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header item')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Settings')
						]))
				]),
			_List_fromArray(
				[
					A2($elm_community$html_extra$Html$Extra$viewIf, addWelcomeHeader, $author$project$Page$viewWelcomeHeader),
					A2($author$project$Page$Settings$viewMainContent, model, settings),
					A2($author$project$Page$Settings$viewBottomBar, model, settings)
				]));
	});
var $author$project$Page$Settings$viewSettingsOrQrCode = F3(
	function (model, settings, firstRun) {
		var _v0 = model.ap;
		if (_v0.$ === 1) {
			return A3($author$project$Page$Settings$viewUnlockedSettings, model, settings, firstRun);
		} else {
			if (_v0.a.$ === 1) {
				var result = _v0.a.a;
				return A2($author$project$Page$Settings$viewQrCodeMainContent, model, result);
			} else {
				var _v1 = _v0.a;
				return $author$project$Page$Settings$viewScanQrCodeMainContent;
			}
		}
	});
var $author$project$Page$Settings$view = function (model) {
	var _v0 = model.e.di;
	switch (_v0.$) {
		case 3:
			var metadada = _v0.a;
			return A3($author$project$Page$Settings$viewSettingsOrQrCode, model, metadada.bL, false);
		case 1:
			return A3($author$project$Page$Settings$viewSettingsOrQrCode, model, $author$project$Settings$defaultSettings, true);
		default:
			return {
				C: _List_fromArray(
					[
						$elm$html$Html$text('Other Settings')
					]),
				D: 'Settings'
			};
	}
};
var $author$project$Page$Transactions$DismissNotification = {$: 14};
var $author$project$Page$Transactions$DismissPopupNotification = {$: 13};
var $author$project$Page$Transactions$viewBottomBar = function (model) {
	var total = A2(
		$elm_community$html_extra$Html$Extra$viewMaybe,
		function (cursorInfo) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(cursorInfo.cw) + (' to ' + ($elm$core$String$fromInt(cursorInfo.b2) + (' of ' + $elm$core$String$fromInt(cursorInfo.gE)))))
							]))
					]));
		},
		model.bm);
	var previous = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('item'),
				$elm$html$Html$Events$onClick(
				$author$project$Page$Transactions$MoveToPage(1))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('backward icon')
					]),
				_List_Nil)
			]));
	var next = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('item'),
				$elm$html$Html$Events$onClick(
				$author$project$Page$Transactions$MoveToPage(0))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('forward icon')
					]),
				_List_Nil)
			]));
	var dataPercentage = A2(
		$elm_community$html_extra$Html$Extra$viewIf,
		model.bz,
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui tiny progress'),
					$elm$html$Html$Attributes$id('decrypting-progress')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bar')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Decrypting...')
						]))
				])));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui bottom attached sesgment')
			]),
		_List_fromArray(
			[
				dataPercentage,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui menu')
					]),
				_List_fromArray(
					[previous, total, next]))
			]));
};
var $author$project$Page$Transactions$ImportJson = {$: 12};
var $author$project$Page$Transactions$ImportSample = {$: 11};
var $author$project$Page$Transactions$viewEmptyList = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('container')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui icon header middle aligned')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('money icon')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Welcome to Elm Expenses!')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sub header')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('This is a work in progress')
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui center aligned placeholder segment')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui positive button'),
							$author$project$Misc$cyAttr('import-sample'),
							$elm$html$Html$Events$onClick($author$project$Page$Transactions$ImportSample)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Import Sample')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui horizontal divider')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Or')
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui blue button'),
							$author$project$Misc$cyAttr('import-json'),
							$elm$html$Html$Events$onClick($author$project$Page$Transactions$ImportJson)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Import JSON')
						]))
				]))
		]));
var $author$project$OurInfiniteScroll$Scroll = function (a) {
	return {$: 0, a: a};
};
var $author$project$OurInfiniteScroll$ScrollPos = F3(
	function (scrollTop, contentHeight, containerHeight) {
		return {dE: containerHeight, dF: contentHeight, eL: scrollTop};
	});
var $author$project$OurInfiniteScroll$clientHeight = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'clientHeight']),
			$elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'scrollingElement', 'clientHeight']),
			$elm$json$Json$Decode$int)
		]));
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$OurInfiniteScroll$offsetHeight = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'offsetHeight']),
			$elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'scrollingElement', 'offsetHeight']),
			$elm$json$Json$Decode$int)
		]));
var $author$project$OurInfiniteScroll$decodeScrollPos = A4(
	$elm$json$Json$Decode$map3,
	$author$project$OurInfiniteScroll$ScrollPos,
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'scrollTop']),
				$elm$json$Json$Decode$float),
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'scrollingElement', 'scrollTop']),
				$elm$json$Json$Decode$float)
			])),
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'scrollHeight']),
				$elm$json$Json$Decode$int),
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'scrollingElement', 'scrollHeight']),
				$elm$json$Json$Decode$int)
			])),
	A3($elm$json$Json$Decode$map2, $elm$core$Basics$max, $author$project$OurInfiniteScroll$offsetHeight, $author$project$OurInfiniteScroll$clientHeight));
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $author$project$OurInfiniteScroll$infiniteScroll = function (mapper) {
	return A2(
		$elm$html$Html$Attributes$map,
		mapper,
		A2(
			$elm$html$Html$Events$stopPropagationOn,
			'scroll',
			A2(
				$elm$json$Json$Decode$map,
				function (pos) {
					return _Utils_Tuple2(
						$author$project$OurInfiniteScroll$Scroll(pos),
						true);
				},
				$author$project$OurInfiniteScroll$decodeScrollPos)));
};
var $author$project$Page$Transactions$viewDate = function (date) {
	var prettyDate = A2($justinmimbs$date$Date$format, 'd MMM y', date);
	var dayOfWeek = A2($justinmimbs$date$Date$format, 'EEEE', date);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('item date')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('left floated content')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(dayOfWeek)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('right floated content')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(prettyDate)
					]))
			]));
};
var $author$project$Page$Transactions$EditTransaction = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Transactions$viewAmount = function (entry) {
	var amount = A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, entry.e9 / 100.0);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('right floated content')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(entry.fn + (' ' + amount))
			]));
};
var $author$project$Page$Transactions$getAccountShortName = F2(
	function (account, accounts) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Transactions$accountShortName(account),
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.gt;
				},
				A2($elm$core$Dict$get, account, accounts)));
	});
var $author$project$Page$Transactions$viewDescriptionComplex = F2(
	function (txn, accounts) {
		var sources = A2(
			$elm$core$List$map,
			$elm$core$Tuple$mapSecond($elm$core$List$length),
			A2(
				$author$project$Misc$groupingBy,
				function ($) {
					return $.e6;
				},
				A2(
					$elm$core$List$cons,
					txn.gv,
					A2($elm$core$Maybe$withDefault, _List_Nil, txn.fA))));
		var source = function () {
			if (sources.b && (!sources.b.b)) {
				var _v1 = sources.a;
				var accnt = _v1.a;
				var cnt = _v1.b;
				return $elm$core$String$fromInt(cnt) + (' x ' + accnt);
			} else {
				return '(' + ($elm$core$String$fromInt(
					$elm$core$List$length(sources)) + ' accounts)');
			}
		}();
		var destination = A2($author$project$Page$Transactions$getAccountShortName, txn.fu.e6, accounts);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('left floated content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header txn-description')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(txn.dK)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('description')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(source + (' ↦ ' + destination))
						]))
				]));
	});
var $author$project$Page$Transactions$viewDescriptionSimple = F2(
	function (txn, accounts) {
		var source = A2($author$project$Page$Transactions$getAccountShortName, txn.gv.e6, accounts);
		var destination = A2($author$project$Page$Transactions$getAccountShortName, txn.fu.e6, accounts);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('left floated content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header txn-description')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(txn.dK)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('description')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(source + (' ↦ ' + destination))
						]))
				]));
	});
var $author$project$Page$Transactions$viewDescription = F2(
	function (txn, accounts) {
		var _v0 = txn.fA;
		if (!_v0.$) {
			return A2($author$project$Page$Transactions$viewDescriptionComplex, txn, accounts);
		} else {
			return A2($author$project$Page$Transactions$viewDescriptionSimple, txn, accounts);
		}
	});
var $author$project$Page$Transactions$viewTransaction = F2(
	function (txn, accounts) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('item transaction'),
					$elm$html$Html$Attributes$id(txn.aj),
					$elm$html$Html$Events$onClick(
					$author$project$Page$Transactions$EditTransaction(txn))
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Transactions$viewDescription, txn, accounts),
					$author$project$Page$Transactions$viewAmount(txn.fu)
				]));
	});
var $author$project$Page$Transactions$viewList = function (model) {
	var scrollListener = $author$project$OurInfiniteScroll$infiniteScroll($author$project$Page$Transactions$InfiniteScrollMsg);
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui attached segment main-content'),
				scrollListener
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui celled list relaxed')
					]),
				A2(
					$elm$core$List$map,
					function (item) {
						if (item.$ === 1) {
							var transaction = item.a;
							return A2($author$project$Page$Transactions$viewTransaction, transaction, model.as.dr);
						} else {
							var date = item.a;
							return $author$project$Page$Transactions$viewDate(date);
						}
					},
					model.a5)),
				A2($author$project$Misc$viewDataList, 'accounts', model.dr)
			]));
};
var $author$project$Page$Transactions$viewMainContent = function (model) {
	var _v0 = model.B;
	if (_v0.$ === 1) {
		return $author$project$Misc$viewLoadingMainContent;
	} else {
		if (!_v0.a.b) {
			return $author$project$Page$Transactions$viewEmptyList;
		} else {
			return $author$project$Page$Transactions$viewList(model);
		}
	}
};
var $author$project$Page$Transactions$EditSearchAccount = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Transactions$RequestDecryptData = {$: 3};
var $author$project$Page$Transactions$SyncRequested = {$: 8};
var $author$project$Page$Transactions$hasReplicationEnabled = function (model) {
	var _v0 = model.as.di;
	if (_v0.$ === 3) {
		var metadada = _v0.a;
		return $author$project$Misc$isSomething(metadada.bL.p);
	} else {
		return false;
	}
};
var $author$project$Page$Transactions$viewTopBarItems = function (model) {
	var syncItem = A2(
		$elm_community$html_extra$Html$Extra$viewIf,
		$author$project$Page$Transactions$hasReplicationEnabled(model),
		function () {
			var _v1 = model.aH ? _Utils_Tuple2('loading spinner icon', $author$project$Page$Transactions$NoOp) : _Utils_Tuple2('sync icon', $author$project$Page$Transactions$SyncRequested);
			var icon = _v1.a;
			var msg = _v1.b;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('right menu')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item'),
								$author$project$Misc$cyAttr('sync'),
								$elm$html$Html$Events$onClick(msg)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class(icon)
									]),
								_List_Nil)
							]))
					]));
		}());
	var decryptOrOtherItems = function () {
		var _v0 = model.as.fQ;
		switch (_v0) {
			case 0:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('item'),
							$author$project$Misc$cyAttr('decrypt-data'),
							$elm$html$Html$Events$onClick($author$project$Page$Transactions$RequestDecryptData)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('unlock icon')
								]),
							_List_Nil)
						]));
			case 1:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('item')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('loading spinner icon')
								]),
							_List_Nil)
						]));
			default:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui category search item')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui transparent icon input')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('prompt'),
											$elm$html$Html$Attributes$placeholder('Search accounts...'),
											$elm$html$Html$Attributes$list('accounts'),
											$elm$html$Html$Attributes$value(
											A2($elm$core$Maybe$withDefault, '', model.cs)),
											$elm$html$Html$Events$onInput($author$project$Page$Transactions$EditSearchAccount)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel link icon'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$Transactions$EditSearchAccount(''))
										]),
									_List_Nil)
								]))
						]));
		}
	}();
	return _List_fromArray(
		[decryptOrOtherItems, syncItem]);
};
var $author$project$Page$Transactions$view = function (model) {
	return A5(
		$author$project$Page$viewPage,
		model.as,
		0,
		'Transactions',
		$author$project$Page$Transactions$viewTopBarItems(model),
		_List_fromArray(
			[
				A2($author$project$Misc$viewMainNotification, model.as.gb, $author$project$Page$Transactions$DismissNotification),
				A2($author$project$Misc$viewPopupNotification, model.as.gi, $author$project$Page$Transactions$DismissPopupNotification),
				$author$project$Page$Transactions$viewMainContent(model),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('huge circular ui blue icon button fab'),
						$author$project$Misc$cyAttr('add-transaction'),
						$author$project$Route$href($author$project$Route$NewRoute)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('icon add')
							]),
						_List_Nil)
					])),
				$author$project$Page$Transactions$viewBottomBar(model)
			]));
};
var $author$project$Main$view = function (model) {
	var document = function () {
		var _v0 = model.j;
		switch (_v0.$) {
			case 0:
				var model_ = _v0.a;
				var doc_ = $author$project$Page$AppLoading$view(model_);
				return {
					C: A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$Main$GotAppLoadingMsg),
						doc_.C),
					D: doc_.D
				};
			case 1:
				var model_ = _v0.a;
				var doc_ = $author$project$Page$Transactions$view(model_);
				return {
					C: A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$Main$GotTransactionsMsg),
						doc_.C),
					D: doc_.D
				};
			case 3:
				var model_ = _v0.a;
				var doc_ = $author$project$Page$Edit$view(model_);
				return {
					C: A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$Main$GotEditMsg),
						doc_.C),
					D: doc_.D
				};
			case 2:
				var model_ = _v0.a;
				var doc_ = $author$project$Page$Settings$view(model_);
				return {
					C: A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$Main$GotSettingsMsg),
						doc_.C),
					D: doc_.D
				};
			case 4:
				var pageModel = _v0.a;
				var doc = $author$project$Page$Accounts$view(pageModel);
				return {
					C: A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$Main$GotAccountsMsg),
						doc.C),
					D: doc.D
				};
			case 5:
				var pageModel = _v0.a;
				var doc = $author$project$Page$ExpensesPieChart$view(pageModel);
				return {
					C: A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$Main$GotExpensesPieChartMsg),
						doc.C),
					D: doc.D
				};
			case 6:
				var pageModel = _v0.a;
				var doc = $author$project$Page$ExpensesBarChart$view(pageModel);
				return {
					C: A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$Main$GotExpensesBarChartMsg),
						doc.C),
					D: doc.D
				};
			default:
				return $author$project$Page$NotFound$view;
		}
	}();
	return document;
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{fS: $author$project$Main$init, gd: $author$project$Main$ChangedUrl, ge: $author$project$Main$ClickedLink, gx: $author$project$Main$subscriptions, gH: $author$project$Main$update, gI: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));