import { isEncrypted, buildEncryption } from './EncryptionTransformation';
import PouchDb from 'pouchdb-browser';
import { DecryptionError } from './encryption';
import MemoryAdapter from 'pouchdb-adapter-memory';
import PouchTransform from 'transform-pouch';
import PouchDbFind from 'pouchdb-find';


PouchDb.plugin(MemoryAdapter);
PouchDb.plugin(PouchTransform);
PouchDb.plugin(PouchDbFind);

class InvalidPassphrase extends Error {
    constructor(...params) {
        super(...params);
    
        if (Error.captureStackTrace) {
          Error.captureStackTrace(this, InvalidPassphrase);
        }
    
        this.name = "InvalidPassphrase";
      }
}

class NotEncrypted extends Error {
    constructor(...params) {
        super(...params);
    
        if (Error.captureStackTrace) {
          Error.captureStackTrace(this, NotEncrypted);
        }
    
        this.name = "NotEncrypted";
      }
}

class MaybeEncrypted extends Error {
    constructor(...params) {
        super(...params);
    
        if (Error.captureStackTrace) {
          Error.captureStackTrace(this, MaybeEncrypted);
        }
    
        this.name = "MaybeEncrypted";
      }
}

function getSyncUrl(settings) {
    const url = new URL(settings.url);
    url.username = settings.username;
    url.password = settings.password;
    return url.href;
}

function remoteDb(settings) {
    if (settings.url.startsWith('pouchdb://')) {
        return new PouchDb(settings.url.substr(10));
    }
    if (settings.url.startsWith('memory://')) {
        return new PouchDb(settings.url.substr(9), {adapter: 'memory'});
    }
    return new PouchDb(getSyncUrl(settings));
}

class Db {
    constructor(name, db) {
        this.name = name;
        this.db = db || new PouchDb(name);
    }

    async initialize() {
        const result = await this.db.allDocs({ limit: 1, include_docs: true});
        if (result.rows.length == 0) {
            return;
        }
        if (isEncrypted(result.rows[0].doc)) {
            throw new MaybeEncrypted('We might have an encrypted document');
        }
    }

    info() {
        return this.db.info();
    }

    put(doc, ...params) {
        return this.db.put(doc, ...params);
    }

    get(id) {
        return this.db.get(id);
    }

    remove(...params) {
        return this.db.remove(...params);
    }

    allDocs(...params) {
        return this.db.allDocs(...params);
    }

    bulkDocs(...params) {
        return this.db.bulkDocs(...params);
    }

    destroy() {
        return this.db.destroy();
    }

    isEncrypted() {
        return false;
    }

    getPassphrase() {
        return null;
    }

    async encrypt(passphrase) {
        const result = await this.db.allDocs({include_docs: true});
        const docs = result.rows.map(row => {
            delete row.doc._rev;
            return row.doc;
        });
        await this.db.destroy();
        const encrypted = new EncryptedDb(this.name, passphrase);
        await encrypted.initialize();
        await encrypted.bulkDocs(docs);
        return encrypted;

    }

    decrypt() {
        // no-op
        return Promise.resolve();
    }

    oneShotSync(settings) {
        const remote = remoteDb(settings);
        return new Promise((resolve, reject) => {
            this.db.sync(remote)
                .on('complete', info => resolve(info))
                .on('error', error => reject(error))
        });
    }

    testSync(settings, password) {
        const remote = remoteDb(settings);
        const wrapper = new EncryptedDb("", password, remote);
        return wrapper.initialize().then(() => remote.info());
    }

}

class EncryptedDb extends Db {
    constructor(name, passphrase, db) {
        super(name, db);
        this.passphrase = passphrase;
        this.encryption = buildEncryption(passphrase);
        this.searchDb = new PouchDb(name + "_memory", {adapter: 'memory'});
        window.searchDb = this.searchDb;
        this.searchDb.transform({
            incoming: d => this.encryption.decrypt(d)
        });
    }

    async initialize() {
        const result = await this.db.allDocs({ limit: 1, include_docs: true});
        if (result.rows.length == 0) {
            return;
        }
        if (!isEncrypted(result.rows[0].doc)) {
            throw new NotEncrypted("The DB appears not to be encrypted");
        }
        try {
            const doc = await this.encryption.decrypt(result.rows[0].doc);
        } catch (e) {
            if (e instanceof DecryptionError) {
                throw new InvalidPassphrase("The encryption passphrase is invalid")
            }
            throw e;
        }
    }

    async startSearchDbReplication() {
        const info = await this.db.info();
        const replication = this.db.replicate.to(this.searchDb, {
            live: true,
            retry: true
        });
        return { info, replication };
    }

    async startSearchDbIndexing(onIndexed) {
        const info = await this.searchDb.info();
        this.searchDb.on('indexing', onIndexed);
        return info;
    }

    put(doc, ...params) {
        return this.encryption.encrypt(doc).then(enc => this.db.put(enc, ...params));
    }

    get(id) {
        return this.db.get(id).then(enc => this.encryption.decrypt(enc));
    }

    async allDocs(options) {
        const result = await this.db.allDocs(options);
        for (const row of result.rows) {
            if (row.doc) {
                row.doc = await this.encryption.decrypt(row.doc);
            }
        }
        return result;
    }

    async bulkDocs(docs, ...params) {
        const encrypted = [];
        for (const doc of docs) {
            encrypted.push(await this.encryption.encrypt(doc));
        }
        return this.db.bulkDocs(encrypted, ...params);
    }

    isEncrypted() {
        return true;
    }

    getPassphrase() {
        return this.passphrase;
    }

    async encrypt(passphrase) {
        if (passphrase == this.passphrase) {
            // no-op
            return Promise.resolve(this);
        }
        const result = await this.allDocs({include_docs: true});
        const docs = result.rows.map(row => {
            delete row.doc._rev;
            return row.doc;
        });
        await this.db.destroy();
        const encrypted = new EncryptedDb(this.name, passphrase);
        await encrypted.initialize();
        await encrypted.bulkDocs(docs);
        return encrypted;

    }

    async decrypt() {
        const result = await this.allDocs({include_docs: true});
        const docs = result.rows.map(row => {
            delete row.doc._rev;
            return row.doc;
        });
        await this.db.destroy();
        const decrypted = new Db(this.name);
        await decrypted.initialize();
        await decrypted.bulkDocs(docs);
        return decrypted;
    }

    find(...params) {
        return this.searchDb.find(...params);
    }

    query(...params) {
        return this.searchDb.query(...params);
    }

    createIndex(...params) {
        return this.searchDb.createIndex(...params);
    }

    putDesignDoc(doc) {
        return this.searchDb.put(doc, {force:true})
        .catch(function (err) {
            if (err.name !== 'conflict') {
              throw err;
            }
        });
    }

    replicateToSearchDb() {
        return new Promise((resolve, reject) => {
            this.db.replicate.to(this.searchDb)
                .on('complete', info => resolve(info))
                .on('error', error => reject(error))
        });
    }

}



/**
 * 
 * @param {String} name
 * @param {String} passphrase
 * @returns {PouchDB.Database}
 */
async function buildDb(name, passphrase) {
    const db = new EncryptedDb(name, passphrase);
    await db.initialize();
    return db;
}

function destroyDb(name) {
    const db = new PouchDb(name);
    return db.destroy();
}

export { buildDb, destroyDb, InvalidPassphrase, MaybeEncrypted, NotEncrypted };

