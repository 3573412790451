import { DbPort, InvalidPassphrase } from "./DbPort";
import buildSample from "./SampleData";
import { Html5QrcodeScanner, Html5QrcodeScannerState } from "html5-qrcode";

function getAccounts() {
  const jsonAccounts = localStorage.getItem("accounts");
  if (null == jsonAccounts) {
    return [];
  }
  return JSON.parse(jsonAccounts);
}

function addToAccounts(account) {
  const jsonAccounts = localStorage.getItem("accounts");
  if (null == jsonAccounts) {
    localStorage.setItem("accounts", JSON.stringify([account]));
  } else {
    const accounts = JSON.parse(jsonAccounts);
    accounts.push(account);
    localStorage.setItem("accounts", JSON.stringify(accounts));
  }
}

function removeFromAccounts(account) {
  const jsonAccounts = localStorage.getItem("accounts");
  if (null == jsonAccounts) {
    return;
  } else {
    const accounts = JSON.parse(jsonAccounts);
    const index = accounts.indexOf(account);
    if (index >= 0) {
      accounts.splice(index, 1);
      localStorage.setItem("accounts", JSON.stringify(accounts));
    }
  }
}

function createSettings(username, password, settings) {
  const dbPort = new DbPort(username);
  return dbPort.createSettings(settings, password).then((newSettings) => {
    addToAccounts(username);
    return [dbPort, newSettings];
  });
}

function deleteAllData(extraAccounts = []) {
  const accounts = getAccounts().concat(extraAccounts);
  return Promise.all(
    accounts.map((account) => {
      const dbPort = new DbPort(account);
      return dbPort.deleteAllData();
    }),
  ).then(() => window.localStorage.clear());
}

function buildGlue(app) {
  let dbPort;

  app.ports.initialize.subscribe(async () => {
    try {
      const accounts = getAccounts();
      if (accounts.length === 0) {
        app.ports.gotFirstRun.send();
      } else {
        app.ports.gotInitAccounts.send(accounts);
      }
    } catch (e) {
      console.error(e);
      app.ports.gotInitError.send(e.message);
    }
  });

  app.ports.decryptSettings.subscribe(async function (usernameAndPassword) {
    const [username, password] = usernameAndPassword;
    try {
      dbPort = new DbPort(username);
      await dbPort.openDbs(password);
      const settings = await dbPort.getSettings();
      app.ports.decryptedSettings.send({ settings, username, password });
    } catch (e) {
      if (e instanceof InvalidPassphrase) {
        app.ports.decryptedSettingsError.send();
      } else {
        console.error(e);
        app.ports.gotInitError.send(e.message);
      }
    }
  });

  async function doInitialIndexing() {
    const indexed = {};
    let lastNotified = 0;

    function onIndexed(event) {
      indexed[event.view] = event.last_seq;
      const progress = sumKeys(indexed);
      const updatedPercentage = 100 * (progress / target);
      if (updatedPercentage - lastNotified >= 5 || updatedPercentage >= 100) {
        lastNotified = updatedPercentage;
        app.ports.gotIndexingPercentage.send(updatedPercentage);
      }
      $("#indexing-progress").progress("set percent", updatedPercentage);
    }

    const info = await dbPort.startSearchDbIndexing(onIndexed);
    const target = info.update_seq * 2;
  }

  function sumKeys(object) {
    return Object.values(object).reduce((curr, acc) => acc + curr, 0);
  }

  app.ports.getTransaction.subscribe(async (id) => {
    try {
      const transaction = await dbPort.getTransaction(id);
      app.ports.transactionLoaded.send(transaction);
    } catch (e) {
      console.error(e);
      app.ports.transactionLoadedError.send(e.message);
    }
  });

  app.ports.getTransactions.subscribe(async (request) => {
    try {
      const transactions = await dbPort.getTransactions(request);
      app.ports.gotTransactions.send(transactions);
    } catch (e) {
      console.error(e);
      app.ports.gotTransactionsError.send(e.message);
    }
  });

  app.ports.getLatestTransactions.subscribe(async () => {
    try {
      const start = Date.now();
      const transactions = await dbPort
        .getTransactions({ maxPageSize: 100 })
        .then((results) => results.results);
      console.log(
        `Fetched ${transactions.length} transactions in ${Date.now() - start}ms`,
      );
      app.ports.gotLatestTransactions.send(transactions);
    } catch (e) {
      console.error(e);
    }
  });

  app.ports.getInitialTransactions.subscribe(async () => {
    try {
      const start = Date.now();
      const request = { maxPageSize: 500, pageToken: null };
      let fetchMore = true;
      let done = 0;

      do {
        let response = await dbPort.getTransactions(request);
        done += response.results.length;
        app.ports.gotInitialTransactions.send({
          transactions: response.results,
          total: response.total,
          remaining: response.total - done,
        });
        fetchMore = response.nextPageToken != null;
        request.pageToken = response.nextPageToken;
        try {
          $("#decrypting-progress").progress(
            "set percent",
            100 * (done / response.total),
          );
        } catch (e) {
          console.error(e);
        }
      } while (fetchMore);
      console.log(`All transactions took ${Date.now() - start}ms`);
    } catch (e) {
      console.error(e);
    }
  });

  app.ports.scrollIntoTop.subscribe((id) => {
    if (!id) {
      console.error("Skipping !", id);
      return;
    }
    requestAnimationFrame(() => {
      console.log("Scrolling to " + id);
      document.getElementById(id).scrollIntoView(true);
    });
  });

  app.ports.scrollIntoEnd.subscribe((id) => {
    if (!id) {
      console.error("Skipping !", id);
      return;
    }
    requestAnimationFrame(() => {
      console.log("Scrolling to " + id);
      document.getElementById(id).scrollIntoView(false);
    });
  });

  app.ports.createSettings.subscribe(async (createSettingsArgs) => {
    const [elmSettings, username, password] = createSettingsArgs;
    try {
      const [newDbPort, newElmSettings] = await createSettings(
        username,
        password,
        elmSettings,
      );
      dbPort = newDbPort;
      app.ports.settingsCreated.send({
        settings: newElmSettings,
        username,
        password,
      });
    } catch (e) {
      console.error(e);
      app.ports.settingsCreatedError.send(e.message);
    }
  });

  app.ports.saveSettings.subscribe(async (elmSettings) => {
    try {
      const newElmSettings = await dbPort.saveSettings(elmSettings);
      app.ports.settingsSaved.send(newElmSettings);
    } catch (e) {
      console.error(e);
      app.ports.settingsSavedError.send(e.message);
    }
  });

  app.ports.saveTransaction.subscribe(async (txn) => {
    try {
      const saved = await dbPort.saveTransaction(txn);
      app.ports.transactionSaved.send(saved);
    } catch (e) {
      console.error(e);
      app.ports.transactionSavedError.send(e.message);
    }
  });

  app.ports.deleteTransaction.subscribe(async function (idAndVersion) {
    try {
      const [id, version] = idAndVersion;
      await dbPort.deleteTransaction(id, version);
      app.ports.transactionDeleted.send(id);
    } catch (e) {
      console.error(e);
      app.ports.transactionDeletedError.send(e.message);
    }
  });

  app.ports.deleteAllData.subscribe(async (username) => {
    await dbPort.deleteAllData(username);
    removeFromAccounts(username);
    app.ports.deletedAllData.send();
  });

  app.ports.importSampleData.subscribe(async () => {
    await dbPort.saveTransactions(
      buildSample().map((t) => {
        t.id = "";
        const accounts = [t.destination, t.source]
          .concat(t.entries || [])
          .map((e) => e.account);
        const uniqueAccounts = new Set(accounts);
        t.accounts = [...uniqueAccounts];
        return t;
      }),
    );
    app.ports.importedSampleData.send();
  });

  app.ports.importTransactions.subscribe(async (transactions) => {
    try {
      // TODO FIXME
      const result = await dbPort.saveTransactions(
        transactions.map(removeVersion),
      );
      app.ports.transactionsImported.send(transactions.length);
    } catch (e) {
      console.error(e);
      app.ports.transactionsImportedError.send(e.message);
    }
  });

  function removeVersion(t) {
    delete t.version;
    return t;
  }

  app.ports.showDeleteModal.subscribe(() => {
    $(".confirm-modal")
      .modal({
        detachable: true,
        closable: false,
        onDeny: () => {
          app.ports.deleteCancelled.send();
          return true;
        },
        onApprove: () => {
          app.ports.deleteConfirmed.send();
          return true;
        },
      })
      .modal("show");
  });

  app.ports.showDeleteAllModal.subscribe(() => {
    $(".confirm-modal")
      .modal({
        detachable: true,
        closable: false,
        onDeny: () => {
          app.ports.deleteAllCancelled.send();
          return true;
        },
        onApprove: () => {
          app.ports.deleteAllConfirmed.send();
          return true;
        },
      })
      .modal("show");
  });

  app.ports.sync.subscribe(async function (settings) {
    try {
      const info = await dbPort.sync(settings);
      if (info.push.ok && info.pull.ok) {
        app.ports.syncFinished.send({
          sent: info.push.docs_written,
          received: info.pull.docs_read,
        });
      } else {
        console.error(info);
        app.ports.syncFailed.send();
      }
    } catch (e) {
      app.ports.syncFailed.send();
    }
  });

  app.ports.testReplication.subscribe(async function (testReplicationArgs) {
    const [settings, password] = testReplicationArgs;
    try {
      const info = await dbPort.testSync(settings, password);
      app.ports.gotReplicationTest.send(
        `Replication settings seem Ok! Remote documents: ${info.doc_count}`,
      );
    } catch (e) {
      console.error(e);
      app.ports.gotReplicationTestError.send(e.message);
    }
  });

  app.ports.getAllTransactions.subscribe(async function () {
    try {
      const transactions = await dbPort.getAllTransactions();
      app.ports.gotAllTransactions.send(transactions);
    } catch (e) {
      console.error(e);
      app.ports.gotAllTransactionsError.send(e.message);
    }
  });

  app.ports.initializeSemanticUi.subscribe(function (components) {
    requestAnimationFrame(function () {
      if (components.includes("dropdowns")) {
        $(".needs-js-menu").dropdown();
      }
      $('.ui.sidebar')
        .sidebar({dimPage: false})
        .sidebar('attach events', '.open.sidebar.item', 'show');
    });
  });

  app.ports.closeSidebar.subscribe(function() {
    requestAnimationFrame(function () {
      $('.ui.sidebar').sidebar('hide');
    });
  });

  app.ports.scanQrCode.subscribe(function () {
    let scanner;
    let sent = false;
    function onScanSuccess(decodedText, decodedResult) {
      if (sent) {
        return;
      }
      sent = true;
      app.ports.gotQrScanResult.send(decodedText);
      if (scanner.getState() === Html5QrcodeScannerState.SCANNING) {
        scanner.pause(true);
        scanner.clear();
      }
    }

    function onScanFailure() {}
    requestAnimationFrame(function () {
      scanner = new Html5QrcodeScanner(
        "qrcode-reader",
        {
          fps: 10,
          qrbox: { width: 600, height: 600 },
        },
        false,
      );
      scanner.render(onScanSuccess, onScanFailure);
    });
  });

  function destroyPreviousCharts() {
    if (myBarChart != null) myBarChart.destroy();
    if (myPieChart != null) myPieChart.destroy();
  }

  let myPieChart = null;
  app.ports.renderPieVisualization.subscribe(function (chartData) {
    chartData.datasets[0].total = chartData.datasets[0].data.reduce(
      (acc, curr) => acc + curr,
      0,
    );
    requestAnimationFrame(function () {
      const ctx = document.getElementById("pie-chart");
      destroyPreviousCharts();
      myPieChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: chartData.labels,
          datasets: chartData.datasets,
        },
        options: {
          responsive: true,

          plugins: {
            title: {
              display: true,
              text: chartData.title.split("\n"),
            },
            legend: {
              display: chartData.showLegend,
              position: "right",
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }

                  if (context.parsed !== null) {
                    label += new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: chartData.currency,
                    }).format(context.parsed);
                  }
                  if (context.dataset.total > 0) {
                    label +=
                      "  (" +
                      (100 * (context.parsed / context.dataset.total)).toFixed(
                        2,
                      ) +
                      " %)";
                  }
                  return label;
                },
              },
            },
          },
        },
      });
    });
  });

  let myBarChart = null;
  app.ports.renderBarVisualization.subscribe(function (chartData) {
    const {data} = chartData;
    const colors = [
      'rgb(255, 99, 132)',   // red
      'rgb(54, 162, 235)',   // blue
      'rgb(255, 206, 86)',   // yellow
      'rgb(75, 192, 192)',   // teal
      'rgb(153, 102, 255)',  // purple
      'rgb(255, 159, 64)',   // orange
      'rgb(0, 204, 150)',    // green
      'rgb(250, 128, 114)',  // salmon
      'rgb(128, 0, 128)',    // deep purple
      'rgb(0, 128, 128)'     // dark teal
  ];

  // Find all unique expense categories
  const allCategories = new Set();
  data.forEach(item => {
      item.expenses.forEach(([category]) => {
          allCategories.add(category);
      });
  });
  const categories = Array.from(allCategories);

  // Prepare datasets
  const datasets = categories.map((category, index) => ({
      label: category,
      data: data.map(item => {
          const expense = item.expenses.find(([cat]) => cat === category);
          return expense ? expense[1] / 100 : 0;
      }),
      backgroundColor: colors[index % colors.length]
  }));

    requestAnimationFrame(function () {
      const ctx = document.getElementById("bar-chart");
      destroyPreviousCharts();
      myBarChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: data.map(item => item.month),
          datasets: datasets
        },
        options: {
          responsive: true,
          scales: {
            x: { stacked: true },
            y: { stacked: true }
          },
          plugins: {
            title: {
              display: true,
              text: chartData.title.split("\n"),
            },
            legend: {
              display: chartData.showLegend,
              position: "right",
            },
            tooltip: {
              callbacks: {
                  label: function(context) {
                      let label = context.dataset.label || '';
                      if (label) {
                          label += ': ';
                      }
                      if (context.parsed.y !== null) {
                          label += new Intl.NumberFormat('en-US', { 
                              style: 'currency', 
                              currency: 'USD',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0
                          }).format(context.parsed.y);
                      }
                      return label;
                  },
                  afterLabel: function(context) {
                      if (context.dataset.label === 'Other') {
                          const monthData = data[context.dataIndex];
                          const totalMonthExpense = monthData.expenses.reduce((sum, [, amount]) => sum + amount, 0) / 100;
                          
                          let otherBreakdown = monthData.otherDetails.slice(0, 5).map(([category, amount]) => {
                              const amountInMajorUnit = amount / 100;
                              const percentage = (amountInMajorUnit / totalMonthExpense * 100).toFixed(1);
                              return `${category}: ${new Intl.NumberFormat('en-US', { 
                                  style: 'currency', 
                                  currency: 'USD',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0
                              }).format(amountInMajorUnit)} (${percentage}%)`;
                          });

                          if (monthData.otherDetails.length > 5) {
                              otherBreakdown.push(`(+ ${monthData.otherDetails.length - 5} more)`);
                          }

                          return otherBreakdown;
                      }
                      return null;
                  }
                }
              }
          },
        },
      });
    });
  });

  return {
    setDbPort(newDbPort) {
      dbPort = newDbPort;
    },
  };
}

export { buildGlue, createSettings, deleteAllData };
